import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { supabase } from "../supabaseClient";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Input } from '../comp/ui/input';
import { Label } from '@radix-ui/react-label';
import { Button } from '../comp/ui/button';
import PurpleFooter from '../comp/PurpleFooter';
import PurpleHeader from '../comp/PurpleHeader';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handlePasswordReset = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: 'https://www.careerredesigned.com/reset-password',
      });

      if (error) throw error;

      toast.success('Password reset email sent! Please check your inbox.');
      setEmail('');
    } catch (error) {
      console.error('Error:', error.message);
      toast.error(error.message || 'An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen" style={{ fontFamily: "Poppins" }}>
      <Helmet>
        <title>Reset Password - Career Resigned</title>
        <link rel="icon" href="" />
      </Helmet>

      <PurpleHeader />

      <main className="flex items-center justify-center flex-grow bg-white text-black mt-24">
        <div className="w-full max-w-md px-4">
          <div className="space-y-6">
            <h1 className="text-3xl font-bold">Reset Password</h1>
            <p className="text-gray-400">Enter your email to receive a password reset link</p>
          </div>
          <form onSubmit={handlePasswordReset} className="space-y-6 mt-8">
            <div className="space-y-2">
              <Label htmlFor="email" className="text-sm font-medium">
                Email
              </Label>
              <Input
                id="email"
                type="email"
                placeholder="you@example.com"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full h-12 px-4 py-2 rounded-md bg-white text-black focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <Button
              type="submit"
              disabled={loading}
              className="w-full h-12 flex items-center justify-center bg-blue-600 hover:bg-blue-700 rounded-md text-sm font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50"
            >
              {loading ? 'Sending...' : 'Send Reset Link'}
            </Button>
          </form>
          <div className="mt-8 text-center text-sm">
            <p>
              Remember your password?{' '}
              <a className="underline text-green-800 hover:text-blue-400" href="/login">
                Log in
              </a>
            </p>
          </div>
        </div>
      </main>

      <ToastContainer />
      <PurpleFooter />
    </div>
  );
};

export default ForgotPassword;