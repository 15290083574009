import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient'; // Adjust this import based on your project structure
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const IssueReportButton = () => {
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    page: '',
    issue: '',
    screenshot: null,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      page: window.location.href,
    }));
  }, []);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'screenshot') {
      setFormData({ ...formData, screenshot: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Upload screenshot if provided
      let screenshotUrl = '';
      if (formData.screenshot) {
        const { data, error } = await supabase
          .storage
          .from('screenshots')
          .upload(`public/${formData.screenshot.name}`, formData.screenshot);

        if (error) throw error;
        screenshotUrl = data.path;
      }

      const { data: { user } } = await supabase.auth.getUser();
      const { error } = await supabase
        .from('issue_reports')
        .insert([
          {
            user_id: user.id,
            user_email: user.email,
            page: formData.page,
            issue: formData.issue,
            created_at: new Date(),
          },
        ]);

        if (error) throw error;

        toast.success('Issue reported successfully!');
        setFormData({ page: window.location.href, issue: '', screenshot: null });
        setShowForm(false);
      } catch (error) {
        console.error('Error reporting issue:', error);
        toast.error('Failed to report issue.');
      } finally {
        setLoading(false);
      }
    };

  return (
    <div className="fixed bottom-5 right-5 z-50">
      <button 
        onClick={toggleForm} 
        className="p-3 bg-cutler-green text-white rounded-full shadow-lg hover:bg-green-700 focus:outline-none"
      >
        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" d="M16.712 4.33a9.027 9.027 0 0 1 1.652 1.306c.51.51.944 1.064 1.306 1.652M16.712 4.33l-3.448 4.138m3.448-4.138a9.014 9.014 0 0 0-9.424 0M19.67 7.288l-4.138 3.448m4.138-3.448a9.014 9.014 0 0 1 0 9.424m-4.138-5.976a3.736 3.736 0 0 0-.88-1.388 3.737 3.737 0 0 0-1.388-.88m2.268 2.268a3.765 3.765 0 0 1 0 2.528m-2.268-4.796a3.765 3.765 0 0 0-2.528 0m4.796 4.796c-.181.506-.475.982-.88 1.388a3.736 3.736 0 0 1-1.388.88m2.268-2.268 4.138 3.448m0 0a9.027 9.027 0 0 1-1.306 1.652c-.51.51-1.064.944-1.652 1.306m0 0-3.448-4.138m3.448 4.138a9.014 9.014 0 0 1-9.424 0m5.976-4.138a3.765 3.765 0 0 1-2.528 0m0 0a3.736 3.736 0 0 1-1.388-.88 3.737 3.737 0 0 1-.88-1.388m2.268 2.268L7.288 19.67m0 0a9.024 9.024 0 0 1-1.652-1.306 9.027 9.027 0 0 1-1.306-1.652m0 0 4.138-3.448M4.33 16.712a9.014 9.014 0 0 1 0-9.424m4.138 5.976a3.765 3.765 0 0 1 0-2.528m0 0c.181-.506.475-.982.88-1.388a3.736 3.736 0 0 1 1.388-.88m-2.268 2.268L4.33 7.288m6.406 1.18L7.288 4.33m0 0a9.024 9.024 0 0 0-1.652 1.306A9.025 9.025 0 0 0 4.33 7.288"/>
        </svg>
      </button>

      {showForm && (
        <div className="fixed bottom-16 right-5 w-96 p-6 bg-white rounded-lg shadow-lg">
          <h2 className="text-lg font-semibold mb-4">Report an Issue</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Page</label>
              <input 
                type="text" 
                name="page" 
                value={formData.page} 
                onChange={handleChange} 
                required 
                readOnly
                className="block mt-2 w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Issue Description</label>
              <textarea 
                name="issue" 
                value={formData.issue} 
                onChange={handleChange} 
                rows="4" 
                required 
                className="block mt-2 w-full rounded-md border border-gray-300 shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50"
              />
            </div>
  
            <div className="flex justify-end space-x-4">
              <button 
                type="button" 
                className="px-4 py-2 bg-gray-300 rounded-md shadow-sm hover:bg-gray-400 focus:outline-none"
                onClick={toggleForm}
              >
                Cancel
              </button>
              <button 
                type="submit" 
                className="px-4 py-2 bg-cutler-green text-white rounded-md shadow-sm hover:bg-green-700 focus:outline-none"
                disabled={loading}
              >
                Submit
              </button>
            </div>
          </form>
          <ToastContainer />
        </div>
      )}
    </div>
  );
};

export default IssueReportButton;
