import { GoogleGenerativeAI } from "@google/generative-ai";

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
const genAI = new GoogleGenerativeAI(apiKey);

export const sendToResumeAnalysisAI = async (resumeData, jobDetails) => {
  const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

  const prompt = `
    Analyze the following resume and job description:
    Resume: ${JSON.stringify(resumeData)}
    Job Description: ${JSON.stringify(jobDetails)}

    Provide the following analysis, ensuring all scores are numerical values between 0 and 100:

    1. Required keywords and skills from the job description
    2. Present keywords and skills in the resume
    3. Missing keywords and skills from the resume
    4. Skill relevance score (0-100) based on how well the resume's skills match the job requirements
    5. Experience match score (0-100) based on how well the resume's experience aligns with the job requirements
    6. Calculate overall match using this formula:
       - Skill Relevance (35% weight)
       - Experience Match (35% weight)
       - Keyword Match (30% weight, calculated as: present keywords / required keywords * 100)
    7. Top 20 words for resume word cloud
    8. Top 20 words for job description word cloud
    9. 3-5 improvement suggestions
    10. List any missing keywords or skills from the resume that are relevant to the job description

    Return the results in the following JSON format:
    {
      "keywordComparison": {
        "required": ["skill1", "skill2", ...],
        "present": ["skill1", "skill3", ...],
        "missing": ["skill2", ...]
      },
      "skillRelevance": 80,
      "experienceMatch": 75,
      "resumeWordCloud": ["word1", "word2", ...],
      "jobDescriptionWordCloud": ["word1", "word2", ...],
      "improvementSuggestions": [
        "Suggestion 1",
        "Suggestion 2",
        ...
      ],
      "missingSkills": ["Python", "Data Analysis", ...]
    }
  `;

  try {
    const result = await model.generateContent(prompt);
    const response = await result.response;
    const responseText = response.text();

    // Strip any content before or after JSON
    const jsonString = responseText.replace(/^[^{]*|[^}]*$/g, '');

    // Parse JSON response
    const rawAnalysis = JSON.parse(jsonString);

    // Calculate keyword match percentage
    const keywordMatchPercentage = (rawAnalysis.keywordComparison.present.length / 
                                  rawAnalysis.keywordComparison.required.length) * 100;

    // Calculate weighted overall match
    const weights = {
      skillRelevance: 0.35,
      experienceMatch: 0.35,
      keywordMatch: 0.30
    };

    const overallMatch = Math.round(
      (rawAnalysis.skillRelevance * weights.skillRelevance) +
      (rawAnalysis.experienceMatch * weights.experienceMatch) +
      (keywordMatchPercentage * weights.keywordMatch)
    );

    // Return the analysis with the calculated overall match
    return {
      ...rawAnalysis,
      overallMatch: overallMatch
    };

  } catch (error) {
    console.error("Error in AI analysis:", error);
    throw new Error("Failed to analyze resume");
  }
};

export const generateAIPoweredResume = async (resumeData, jobDescriptionWordCloud, selectedSkills) => {
  const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

  const prompt = `
  Given the following resume and job-related keywords and skills, enhance the job experience section to align more closely with the job requirements.
  Resume: ${JSON.stringify(resumeData)}
  Job Description Word Cloud: ${JSON.stringify(jobDescriptionWordCloud)}
  Selected Skills to Integrate: ${JSON.stringify(selectedSkills)}

  Update the resume's job experience section as follows:
  1. Format all bullet points using the X, Y, Z format:
     - X: The action/skill used
     - Y: The specific task/project
     - Z: The measurable result or impact
     Example: "Implemented [skill] to develop [specific project], resulting in [quantifiable outcome]"

  2. For integrating new skills:
     a. First, try to modify existing bullet points to naturally incorporate the selected skills while maintaining the X, Y, Z format
     b. If a skill cannot be naturally integrated into existing points, create new bullet points following the X, Y, Z format
     c. Each bullet point must clearly show:
        - The skill/action used (X)
        - The specific application or project (Y)
        - Quantifiable results where possible (Z)

  3. Do not add any formatting (e.g., **bold**, _italic_, bullet points dot) around the keywords or skills. Write in plain text.

  4. Only return the modified sections in JSON format, including:
     - Company name
     - Job title
     - Location
     - Duration
     - Updated or new bullet points in X, Y, Z format

  Return your response strictly in the following JSON format:
  {
    "updatedExperience": [
      {
        "company": "Modified Company Name",
        "jobTitle": "Modified Job Title",
        "location": "Location",
        "duration": "Duration",
        "description": [
          "Utilized [skill X] to implement [project Y], resulting in [outcome Z with metrics]",
          "Leveraged [skill X] for [task Y], achieving [measurable result Z]"
        ]
      }
    ]
  }

  Important: 
  - Maintain X, Y, Z format for ALL bullet points
  - Include quantifiable metrics whenever possible
  - Ensure all selected skills are naturally integrated
  - Return only JSON format without any additional explanations
  - Keep bullet points concise and impactful
`;

  try {
    const result = await model.generateContent(prompt);
    const response = await result.response;
    const responseText = response.text();

    // Clean response to extract JSON
    const jsonString = responseText.replace(/^[^{]*|[^}]*$/g, '');
    const aiResponse = JSON.parse(jsonString);
    return aiResponse;

  } catch (error) {
    console.error("Error in AI resume generation:", error);
    throw new Error("Failed to generate AI-powered resume");
  }
};

