import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from "../supabaseClient";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Input } from '../comp/ui/input';
import { Label } from '@radix-ui/react-label';
import { Button } from '../comp/ui/button';
import PurpleFooter from '../comp/PurpleFooter';
import PurpleHeader from '../comp/PurpleHeader';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleRecoveryToken = async () => {
      // Get the URL parameters
      const params = new URLSearchParams(window.location.search);
      const token = params.get('token');
      const type = params.get('type');

      if (type === 'recovery' && token) {
        try {
          // Verify the recovery token
          const { error: verifyError } = await supabase.auth.verifyOtp({
            token,
            type: 'recovery'
          });

          if (verifyError) {
            throw verifyError;
          }
        } catch (error) {
          console.error('Error verifying token:', error);
          toast.error('Invalid or expired reset link. Please request a new one.');
          setTimeout(() => navigate('/forgot-password'), 2000);
        }
      }
    };

    handleRecoveryToken();
  }, [navigate]);

  const validatePassword = (password) => {
    return /(?=.*[a-zA-Z])(?=.*\d).{8,}/.test(password);
  };

  const handlePasswordUpdate = async (event) => {
    event.preventDefault();

    if (!validatePassword(password)) {
      setPasswordError('Password must be at least 8 characters long and include at least one letter and number.');
      return;
    }

    setLoading(true);

    try {
      const { error } = await supabase.auth.updateUser({ 
        password: password
      });

      if (error) throw error;

      toast.success('Password updated successfully!');
      
      // Sign out the user after password reset
      await supabase.auth.signOut();
      
      setTimeout(() => {
        navigate('/login', { 
          state: { passwordReset: true } 
        });
      }, 2000);
    } catch (error) {
      console.error('Error:', error.message);
      toast.error(error.message || 'An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen" style={{ fontFamily: "Poppins" }}>
      <Helmet>
        <title>Set New Password - Career Resigned</title>
        <link rel="icon" href="" />
      </Helmet>

      <PurpleHeader />

      <main className="flex items-center justify-center flex-grow bg-white text-black mt-24">
        <div className="w-full max-w-md px-4">
          <div className="space-y-6">
            <h1 className="text-3xl font-bold">Set New Password</h1>
            <p className="text-gray-400">Enter your new password below</p>
          </div>
          <form onSubmit={handlePasswordUpdate} className="space-y-6 mt-8">
            <div className="space-y-2">
              <Label htmlFor="password" className="text-sm font-medium">
                New Password
              </Label>
              <Input
                id="password"
                type="password"
                placeholder="••••••••"
                required
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordError('');
                }}
                className="w-full h-12 px-4 py-2 rounded-md bg-white text-black focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              {passwordError && <p className="text-red-500 text-sm">{passwordError}</p>}
            </div>
            <Button
              type="submit"
              disabled={loading}
              className="w-full h-12 flex items-center justify-center bg-blue-600 hover:bg-blue-700 rounded-md text-sm font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50"
            >
              {loading ? 'Updating...' : 'Update Password'}
            </Button>
          </form>
        </div>
      </main>

      <ToastContainer />
      <PurpleFooter />
    </div>
  );
};

export default ResetPassword;