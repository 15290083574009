import React, { useState, useEffect } from "react";
import Sidebar from "../comp/Sidebar";
import { supabase } from "../supabaseClient";
import { toast, ToastContainer } from "react-toastify";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from "../comp/ui/card";
import { Button } from "../comp/ui/button";
import {
  Clipboard,
  Award,
  TrendingUp,
  FileText,
  AlertTriangle,
} from "lucide-react";
import { gradeResumeWithGoogleAI } from "../comp/GradingAI";
import { useNavigate } from "react-router-dom";
import { CheckCircle, ChevronDown, ChevronUp, Check, X } from "lucide-react";
import BulletPointAnalysis from "../comp/BulletPtAnalysisCmp";

const Grader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [resumes, setResumes] = useState([]);
  const [selectedResume, setSelectedResume] = useState(null);
  const [gradeResults, setGradeResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedSections, setExpandedSections] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("critical");
  const [calculatedScore, setCalculatedScore] = useState(0);

   // Helper function to calculate total score
   const calculateTotalScore = (breakdown) => {
    const sections = [
      { 
        score: Math.min(11,
          breakdown.linkedinURLScore + 
          breakdown.eduSectionPoints + 
          breakdown.achievementScore + 
          breakdown.technicalSkills + 
          breakdown.leadershipScore
        )
      },
      { 
        score: Math.min(12,
          breakdown.grammarAndSpellcheck + 
          breakdown.consistentVerbTense + 
          breakdown.fullStopCheck + 
          breakdown.actionVerbFrequency + 
          breakdown.proNounResult
        )
      },
      { 
        score: Math.min(18,
          breakdown.lengthCompliance + 
          breakdown.quantifiableAchievements + 
          breakdown.actionVerbEffectiveness + 
          breakdown.contentParameters
        )
      },
      { 
        score: Math.min(9,
          breakdown.sectionChronology + 
          breakdown.pageNumbers + 
          breakdown.orderScore
        )
      }
    ];

    return sections.reduce((sum, section) => sum + section.score, 0);
  };

  // Update score calculation when grade results change
  useEffect(() => {
    if (gradeResults?.score?.breakdown) {
      const newTotal = calculateTotalScore(gradeResults.score.breakdown);
      setCalculatedScore(newTotal);
      // Update the total in gradeResults to keep it consistent
      setGradeResults(prev => ({
        ...prev,
        score: {
          ...prev.score,
          total: newTotal
        }
      }));
    }
  }, [gradeResults?.score?.breakdown]);


  const sections1 = [
    { key: "critical", title: "Critical Issues" },
    { key: "grammar", title: "Grammar Check" },
    { key: "bullets", title: "Bullet Point Analysis" },
    { key: "section", title: "Section Check" },
  ];

  useEffect(() => {
    fetchResumes();
  }, []);

  const fetchResumes = async () => {
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      const { data, error } = await supabase
        .from("resumes")
        .select("*")
        .eq("user_id", user.id)
        .eq("visibility", true)
        .order("created_at", { ascending: false });

      if (error) throw error;
      setResumes(data);
    } catch (error) {
      console.error("Error retrieving resumes:", error);
      toast.error("Error loading resumes.");
    }
  };

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const handleGradeResume = async (resumeData) => {
    console.log("Grading resume:", resumeData);
    setIsLoading(true);
    try {
      setSelectedResume(resumeData);
      const result = await gradeResumeWithGoogleAI(resumeData);
      setGradeResults(result);
      toast.success("Resume graded successfully!");
    } catch (error) {
      console.error("Error grading resume:", error);
      toast.error("Error grading resume.");
    } finally {
      setIsLoading(false);
    }
  };

  // Helper function to get score color
  const getScoreColor = (score, maxScore) => {
    const percentage = (score / maxScore) * 100;
    if (percentage >= 80) return "text-green-600";
    if (percentage >= 60) return "text-yellow-600";
    return "text-red-600";
  };

  const renderScoreBreakdown = () => {
    const { breakdown } = gradeResults.score;
    const sections = [
        { 
            name: "Sections", 
            score: Math.min(11, // Cap at 11
                breakdown.linkedinURLScore + 
                breakdown.eduSectionPoints + 
                breakdown.achievementScore + 
                breakdown.technicalSkills + 
                breakdown.leadershipScore
            ), 
            max: 11 
        },
        { 
            name: "Grammar and Consistency", 
            score: Math.min(12, // Cap at 12
                breakdown.grammarAndSpellcheck + 
                breakdown.consistentVerbTense + 
                breakdown.fullStopCheck + 
                breakdown.actionVerbFrequency + 
                breakdown.proNounResult
            ), 
            max: 12 
        },
        { 
            name: "Bullets and Impact", 
            score: Math.min(18, // Cap at 18
                breakdown.lengthCompliance + 
                breakdown.quantifiableAchievements + 
                breakdown.actionVerbEffectiveness + 
                breakdown.contentParameters
            ), 
            max: 18 
        },
        { 
            name: "Visuals and Order", 
            score: Math.min(9, // Cap at 9
                breakdown.sectionChronology + 
                breakdown.pageNumbers + 
                breakdown.orderScore
            ), 
            max: 9 
        }
    ];

    // Calculate actual total from the capped section scores
    const actualTotal = sections.reduce((sum, section) => sum + section.score, 0);
    
    // Update the total score in the results
    gradeResults.score.total = actualTotal;

    return (
      <div className="grid grid-cols-2 gap-4 mb-6">
          {sections.map((section) => (
              <div key={section.name} className="bg-gray-50 p-4 rounded-lg">
                  <div className="flex justify-between items-center mb-2">
                      <span className="font-medium">{section.name}</span>
                      <span className={getScoreColor(section.score, section.max)}>
                          {section.score}/{section.max}
                      </span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2">
                      <div
                          className="bg-cutler-green rounded-full h-2"
                          style={{ width: `${(section.score / section.max) * 100}%` }}
                      />
                  </div>
              </div>
          ))}
      </div>
  );
};


  const TabsUI = ({ sections, activeTab, setActiveTab }) => (
    <div className="flex justify-center items-center">
      <div className="inline-flex space-x-4 border-b border-purple-200 dark:border-gray-700">
        {sections.map((section, index) => (
          <button
            key={index}
            className={`inline-flex items-center h-10 px-4 py-2 text-center ${
              activeTab === section.key
                ? "text-purple-600 border-b-2 border-purple-500"
                : "text-gray-700 border-transparent"
            } bg-transparent whitespace-nowrap cursor-pointer focus:outline-none hover:border-purple-200`}
            style={{
              borderBottomWidth: activeTab === section.key ? "2px" : "0",
            }}
            onClick={() => setActiveTab(section.key)}
          >
            <span className="mx-1 text-sm sm:text-base">{section.title}</span>
          </button>
        ))}
      </div>
    </div>
  );

  const renderContent = () => {
    switch (activeTab) {
      case "critical":
        return gradeResults.criticalIssues?.length > 0 ? (
          <div className="mt-6">
            {/* Header */}
            <div className="flex items-center space-x-2 text-red-700">
              <AlertTriangle className="h-6 w-6 flex-shrink-0" />
              <h3 className="text-lg font-semibold">Critical Issues</h3>
            </div>

            {/* Issue List */}
            <ul className="mt-4 space-y-3">
              {gradeResults.criticalIssues.map((issue, index) => (
                <li
                  key={index}
                  className="flex items-start p-3 rounded-lg bg-red-50 border-l-4 border-red-500"
                >
                  <span className="text-sm text-red-800">{issue}</span>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="mt-6 text-gray-500 text-sm italic">
            No critical issues found.
          </div>
        );

      case "grammar":
        return (
          <div className="mt-6">
            {/* Header */}
            <div
              className={`flex items-center space-x-2 p-4 rounded-lg ${
                gradeResults.grammarCheck.status === "good"
                  ? "bg-green-50 border-l-4 border-green-500"
                  : "bg-red-50 border-l-4 border-red-500"
              }`}
            >
              {gradeResults.grammarCheck.status === "good" ? (
                <Check className="h-6 w-6 text-green-600" />
              ) : (
                <X className="h-6 w-6 text-red-600" />
              )}
              <h3
                className={`text-lg font-semibold ${
                  gradeResults.grammarCheck.status === "good"
                    ? "text-green-700"
                    : "text-red-700"
                }`}
              >
                {gradeResults.grammarCheck.status === "good"
                  ? "Grammar looks good!"
                  : "Grammar needs improvement"}
              </h3>
            </div>

            {/* Issues List */}
            {gradeResults.grammarCheck.issues?.length > 0 && (
              <div className="mt-4">
                <h4 className="text-gray-800 font-medium">
                  Identified Issues:
                </h4>
                <ul className="mt-2 space-y-2">
                  {gradeResults.grammarCheck.issues.map((issue, index) => (
                    <li
                      key={index}
                      className="flex items-start p-3 rounded-md bg-gray-100 text-gray-700"
                    >
                      <span className="flex-shrink-0 h-2 w-2 bg-gray-500 rounded-full mt-1.5 mr-2"></span>
                      <span className="text-sm">{issue}</span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        );

      case "bullets":
        return (
          <BulletPointAnalysis gradeResults={gradeResults} />
        );

        case "section":
          return (
            <div className="mt-6">
              {gradeResults.sectionErrors?.length > 0 ? (
                <div>
                  <h4 className="text-xl font-semibold text-orange-600 mb-4">
                    Sections Needing Improvement
                  </h4>
                  {/* Existing error rendering code */}
                  {gradeResults.sectionErrors
                    .filter(
                      (item) =>
                        item.comments &&
                        item.comments.length > 0 &&
                        item.comments[0].length > 0
                    )
                    .map((item, index) => (
                      <div
                        key={index}
                        className="mb-5 p-4 rounded-lg bg-orange-50 border-l-4 border-orange-500 shadow-md"
                      >
                        <h5 className="text-lg font-semibold text-gray-800">
                          {item.title}
                        </h5>
                        <div className="mt-3 space-y-2">
                          {item.comments.map((comment, idx) => (
                            <p key={idx} className="text-sm text-gray-700">
                              - {comment}
                            </p>
                          ))}
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                <div className="p-4 rounded-lg bg-green-50 border-l-4 border-green-500">
                  <div className="flex items-center">
                    <CheckCircle className="h-6 w-6 text-green-600 mr-2" />
                    <h4 className="text-lg font-semibold text-green-800">
                      Excellent Section Organization!
                    </h4>
                  </div>
                  <p className="mt-2 text-green-700">
                    All your resume sections are well-structured and properly formatted. Keep up the great work!
                  </p>
                </div>
              )}
            </div>
          );

      default:
        return null;
    }
  };

  return (
    <div
      className="flex flex-col md:flex-row h-screen bg-gray-100"
      style={{ fontFamily: "Poppins" }}
    >
      <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />

      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-6">
          {isLoading && (
            <div className="loading-container">
              <div className="spinner"></div>
              <div className="loading-text">Analyzing Resume...</div>
            </div>
          )}

          {!isLoading && !gradeResults && (
            <div className="container mx-auto p-4">
              <header className="text-center mb-8">
                <h1 className="text-3xl font-bold mb-2">
                  Resume Grading System
                </h1>
                <p className="text-gray-600">
                  Select a resume to grade according to OHIO COB standards
                </p>
              </header>
              <div className="flex justify-left items-center mb-4 bg-gray-100">
                <Card className="w-full max-6w-lg">
                  <CardContent className="space-y-6">
                    <ul className="space-y-4 mt-4">
                      {[
                        {
                          text: "Comprehensive scoring based on industry standards",
                          icon: Clipboard,
                        },
                        {
                          text: "Detailed feedback on formatting and content",
                          icon: FileText,
                        },
                        {
                          text: "Actionable improvement suggestions",
                          icon: TrendingUp,
                        },
                        {
                          text: "Professional evaluation criteria",
                          icon: CheckCircle,
                        },
                        {
                          text: "Industry-specific recommendations",
                          icon: Award,
                        },
                      ].map((item, index) => (
                        <li key={index} className="flex items-start gap-3">
                          <item.icon className="h-6 w-6 text-primary mt-1 flex-shrink-0" />
                          <span>{item.text}</span>
                        </li>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
              </div>

              <div className="grid gap-4 md:grid-cols-3">
                {resumes.map((resume) => (
                  <Card key={resume.id}>
                    <CardHeader>
                      <CardTitle>{resume.name || "Untitled Resume"}</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <p className="text-sm text-gray-500">
                        Created on:{" "}
                        {new Date(resume.created_at).toLocaleDateString()}
                      </p>
                      <p className="text-sm text-gray-500">
                        Last updated:{" "}
                        {new Date(resume.last_updated).toLocaleDateString()}
                      </p>
                    </CardContent>
                    <CardFooter>
                      <Button
                        onClick={() => handleGradeResume(resume)}
                        disabled={isLoading}
                        className="w-full bg-cutler-green hover:bg-darker-cutler-green text-white"
                      >
                        {isLoading ? "Grading..." : "Grade Resume"}
                      </Button>
                    </CardFooter>
                  </Card>
                ))}
              </div>
            </div>
          )}

          {!isLoading && gradeResults && (
            <div className="container mx-auto p-4">
              <button
                onClick={() => {
                  setGradeResults(null);
                  setSelectedResume(null);
                }}
                className="mb-6 flex items-center text-cutler-green hover:text-darker-cutler-green"
              >
                <ChevronDown className="mr-1 h-4 w-4" />
                Back to Resume List
              </button>

              <div className="bg-white rounded-lg shadow-lg p-6">
                {/* Overall Score */}
                <div className="text-center mb-8">
                <h2 className="text-2xl font-bold mb-2">Overall Grade</h2>
      <div className={`text-5xl font-bold ${getScoreColor(calculatedScore, 50)}`}>
          {calculatedScore}/50
      </div>
    </div>
                {/* Score Breakdown */}
                {renderScoreBreakdown()}

                <TabsUI
                  sections={sections1}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />

                {/* Section Content */}
                <div className="mt-4">{renderContent()}</div>

                {/* Submit Button */}
                <div className="mt-8 flex justify-center">
                  <Button
                    disabled={submitting}
                    className="bg-cutler-green hover:bg-darker-cutler-green text-white px-8 py-3 text-lg"
                  >
                    {submitting ? "Submitting..." : "Submit Resume Assignment"}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Grader;
