import { GoogleGenerativeAI } from "@google/generative-ai";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const genAI = new GoogleGenerativeAI(API_KEY);

export async function getAnswerFeedback(question, suggestedAnswer, userAnswer) {
  const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

  const prompt = `
    Given the following interview question, suggested answer, and user's answer, provide feedback on the user's response:

    Question: ${question}

    Suggested Answer: ${suggestedAnswer}

    User's Answer: ${userAnswer}

    Please provide feedback in the following JSON format:
    {
      "score": <number between 1 and 5>,
      "feedback": "<detailed feedback on the user's answer>",
      "improvements": ["<suggestion 1>", "<suggestion 2>", "<suggestion 3>"]
    }

    Ensure that the response is a valid JSON object.
  `;

  try {
    const result = await model.generateContent(prompt);
    const response = await result.response;
    const text = response.text();
    
    // Remove any potential markdown formatting
    const cleanedText = text.replace(/```json\n?|\n?```/g, '').trim();
    
    return JSON.parse(cleanedText);
  } catch (error) {
    console.error("Error generating answer feedback:", error);
    throw new Error("Failed to generate answer feedback");
  }
}