import React from 'react';

const StepIndicator = ({ steps, level, setLevel }) => (
  <div className="flex gap-2 mt-6 text-sm font-normal">
    {steps.map((step, index) => (
      <div 
        key={index} 
        className="flex flex-col flex-1 cursor-pointer"
        onClick={() => setLevel(index + 1)}
      >
        <div
          className={`h-1 rounded transition-colors duration-300 ${
            index < level ? 'bg-purple-600' : 'bg-gray-300'
          }`}
        />
        <div className={`mt-2 transition-colors duration-300 ${
          index === level - 1 ? 'text-purple-600 font-medium' : 'text-gray-500'
        }`}>
          {step}
        </div>
      </div>
    ))}
  </div>
);

const Modal = ({ level, setLevel }) => {
  const steps = [
    'Personal Information',
    'Education',
    'Work Experience',
    'Clubs',
    'Awards',
    'Skills',
    'Save Resume'
  ];

  return (
    <div className="bg-white rounded-3xl box-shadow max-w-4xl mx-auto">
      <header className="px-6 py-4">
        <h2 className="text-2xl font-bold text-gray-800">Build Your Resume</h2>
        <StepIndicator steps={steps} level={level} setLevel={setLevel} />
      </header>

    </div>
  );
};


export default Modal;
