import React from 'react';
import { useNavigate } from 'react-router-dom';


const UpgradePopup = ({ message, onClose }) => {

  const navigate = useNavigate();

  const handleUpgrade = () => {
    navigate('/payment');
  };


  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-xl font-bold mb-4 text-cutler-green">Usage Limit Reached</h2>
        <p className="mb-6">{message}</p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
          >
            Close
          </button>
          <button
            onClick={handleUpgrade}
            className="px-4 py-2 bg-cutler-green text-white rounded hover:bg-darker-cutler-green transition-colors"
          >
            Upgrade Plan
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpgradePopup;