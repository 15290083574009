import React from 'react'
import { FileText, Search, GitCompare, MessageSquare, Briefcase } from "lucide-react"

const Card = ({ children }) => (
  <div className="bg-white rounded-lg shadow-md w-full">
    <div className="p-6">{children}</div>
  </div>
)

const Arrow = () => (
  <svg className="w-8 h-8 text-indigo-500 hidden md:block" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

const steps = [
  { icon: FileText, title: "Create Resume", description: "Build your professional resume" },
  { icon: Search, title: "Find Jobs", description: "Discover opportunities with AI" },
  { icon: GitCompare, title: "Compare Jobs", description: "Match jobs to your resume" },
  { icon: MessageSquare, title: "Prepare Interview", description: "Practice with AI assistance" },
  { icon: Briefcase, title: "Land a Job", description: "Secure your dream position" },
]

const ProcessGuide = () => {
  return (
    <div className="mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl mb-8">
      <Card>
   
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-6 md:gap-4 relative">
          
          {steps.map((step, index) => (
            <React.Fragment key={index}>
              <div className="flex flex-col items-center text-center w-full md:w-auto relative">
                <div className="relative">
                  <div className="w-16 h-16 bg-indigo-100 rounded-full flex items-center justify-center mb-3 relative z-10">
                    <step.icon className="w-8 h-8 text-indigo-600" />
                  </div>
                  {index < steps.length - 1 && (
                    <div className="md:hidden absolute left-8 top-16 h-full border-l-2 border-dashed border-indigo-200" />
                  )}
                </div>
                <h3 className="font-semibold text-base mb-2">{step.title}</h3>
                <p className="text-sm text-gray-500 max-w-[150px]">{step.description}</p>
              </div>
              {index < steps.length - 1 && <Arrow />}
            </React.Fragment>
          ))}
        </div>
      </Card>
    </div>
  )
}

export default ProcessGuide