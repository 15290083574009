import React, { useEffect, useState } from 'react';
import { ArrowRight, PlusCircle, Trash2 } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../comp/Sidebar'; // Adjust the path if needed
import { Button } from '../comp/ui/button'; // Adjust the import path
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '../comp/ui/card'; // Adjust the import path
import { supabase } from '../supabaseClient';
import { Lightbulb, MessageSquare, Zap, BarChart2, TrendingUp, Award } from "lucide-react"

const MyInterview = () => {
  const [resources, setResources] = useState([]);
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [resourceToDelete, setResourceToDelete] = useState(null);
  const [user, setUser] = useState(null); // Initialize state to store the user
  const [isOpen, setIsOpen] = useState(false); // Sidebar state

  useEffect(() => {
    const fetchUser = async () => {
      // Fetch the current authenticated user from Supabase
      const { data, error } = await supabase.auth.getUser();

      if (error) {
        console.error('Error fetching user:', error.message);
      } else {
        // Set the user in state if available
        setUser(data.user);
      }
    };

    fetchUser(); // Call the function to fetch user data
  }, []);

  useEffect(() => {
    fetchResources();
  }, []);

  const fetchResources = async () => {
    try {
      const {
        data: { user }
      } = await supabase.auth.getUser();
      const { data, error } = await supabase
        .from('interview_questions')
        .select('id, job_data, created_at, unique_code')
        .eq('user_id', user.id)
        .eq('visibility', true)
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching data:', error);
        toast.error('Error fetching data.');
      } else {
        setResources(data);
      }
    } catch (error) {
      console.error('Error fetching resources:', error);
      toast.error('Error fetching resources.');
    }
  };

  const handleDelete = (resource) => {
    setResourceToDelete(resource);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      const { error } = await supabase
        .from('interview_questions')
        .delete()
        .eq('id', resourceToDelete.id)
        .eq('visibility', true);

      if (error) {
        console.error('Error deleting resource:', error);
        toast.error('Error deleting resource.');
      } else {
        setResources(resources.filter((r) => r.id !== resourceToDelete.id));
        toast.success('Resource deleted successfully.');
      }
    } catch (error) {
      console.error('Error confirming delete:', error);
      toast.error('Error confirming delete.');
    } finally {
      setShowDeleteModal(false);
    }
  };

  return (
    <div
      className="flex flex-col md:flex-row h-screen bg-gray-100"
      style={{ fontFamily: 'Poppins' }}>
      {/* Sidebar */}
      <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
      
        {/* Content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-6">
          <div className="container mx-auto p-4">
          <header className="text-center mb-4">
              <h1 className="text-3xl font-bold mb-2">AI Interview Prep: Master Your Next Interview</h1>
              <p className="text-gray-600">
              Personalized practice sessions to boost your confidence and performance.
              </p>
            </header>

            <div className="flex justify-left items-center mb-4  bg-gray-100">
            <Card className="w-full max-6w-lg ">
        <CardContent className="space-y-6">
          <ul className="space-y-4 mt-4">
            {[
              { text: "Get tailored questions based on the job description and your resume", icon: MessageSquare },
              { text: "Receive real-time feedback on your responses", icon: Zap },
              { text: "Practice with an AI interviewer that adapts to your performance", icon: Lightbulb },
              { text: "Track your progress and identify areas for improvement", icon: BarChart2 },
              { text: "Boost your confidence with realistic interview simulations", icon: TrendingUp },
            ].map((item, index) => (
              <li key={index} className="flex items-start gap-3">
                <item.icon className="h-6 w-6 text-primary mt-1 flex-shrink-0" />
                <span>{item.text}</span>
              </li>
            ))}
          </ul>
          <div className="bg-primary/10 p-4 rounded-lg">
            <p className="flex items-center gap-2 text-sm font-medium text-primary">
              <Award className="h-5 w-5" />
              Pro Tip
            </p>
            <p className="mt-2 text-sm text-muted-foreground">
              Practice at least 3 full interview sessions before your big day!
            </p>
          </div>
        </CardContent>
      </Card>
    </div>

            <div className="mb-8">
              <Button
                className="bg-navy-700 text-white flex items-center px-3 py-2 rounded-md transition-colors hover:bg-gray-800"
                onClick={() => navigate('/interview')}>
                <PlusCircle className="mr-2 h-4 w-4" /> Start Fresh Interview Prep
              </Button>
            </div>

            {resources.length === 0 ? (
              <div className="flex flex-col items-center justify-center text-center">
                <svg
                  className="h-16 w-16 text-gray-400 mb-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15.182 16.318A4.486 4.486 0 0 0 12.016 15a4.486 4.486 0 0 0-3.198 1.318M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Z"
                  />
                </svg>
                <p className="text-gray-500">
                  No interview preparations created yet. Click on the &quot;Start a new Interview Prep&quot;
                  button to get started.
                </p>
              </div>
            ) : (
              <div className="grid gap-4 md:grid-cols-3">
                {resources.map((resource) => {
                  const jobDetails = resource.job_data || {};
                  return (
                    <Card key={resource.id}>
                      <CardHeader>
                        <CardTitle>{jobDetails.jobTitle || 'Untitled Job'}</CardTitle>
                      </CardHeader>
                      <CardContent>
                        <p className="text-sm text-gray-600">
                          {jobDetails.company || 'Unknown Company'}
                        </p>
                        <p className="text-sm text-gray-500">
                          Created on: {new Date(resource.created_at).toLocaleDateString()}
                        </p>
                      </CardContent>
                      <CardFooter className="flex justify-between items-center">
                        <Button
                          variant="outline"
                          className="bg-navy-700 text-white flex items-center px-3 py-2 rounded-md transition-colors hover:bg-gray-800"
                          onClick={() => navigate(`/interview-prep?code=${resource.unique_code}`)}>
                          Open <ArrowRight className="ml-2 h-4 w-4" />
                        </Button>
                        <button
                          onClick={() => handleDelete(resource)}
                          className="text-red-500 hover:text-red-700 p-2">
                          <Trash2 size={20} />
                        </button>
                      </CardFooter>
                    </Card>
                  );
                })}
              </div>
            )}
          </div>
        </main>
      </div>
      <ToastContainer />

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-navy-700 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
            <p>Are you sure you want to delete this interview preparation?</p>
            <div className="mt-4 flex justify-end space-x-2">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="px-4 py-2 bg-gray-200 rounded">
                Cancel
              </button>
              <button onClick={confirmDelete} className="px-4 py-2 bg-red-500 text-white rounded">
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyInterview;
