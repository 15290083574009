import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { supabase } from "../supabaseClient.js"; // Import your Supabase client
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NewHeader from "../comp/newheader.js";
import NewFooter from "../comp/NewFooter.js";
import { Input } from '../comp/ui/input';
import { Label } from '@radix-ui/react-label';
import { Button } from '../comp/ui/button';

const Recovery = () => {
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    const checkUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();

      if (user) {
        window.location.href = "/dashboard";
      }
    };

    checkUser();
  }, []);

  useEffect(() => {
    if (location.state?.loggedOut) {
      toast.success('Logged out successfully!');
    }
  }, [location.state]);

  const handleResetPassword = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email);

      if (error) {
        toast.error("An error occurred while sending the password reset email. Please try again.");
      } else {
        toast.success("Password reset email sent successfully! Please check your inbox.");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      toast.error("An unexpected error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen" style={{ fontFamily: "Poppins" }}>
      <Helmet>
        <title>Reset Password - Career Resigned</title>
        <link rel="icon" href="" />
      </Helmet>

      <NewHeader />

      <main className="flex items-center justify-center flex-grow bg-white text-black">
        <form onSubmit={handleResetPassword} className="flex flex-col space-y-4 p-6 bg-gray-100 rounded shadow-md">
          <h2 className="text-2xl font-semibold mb-4">Reset Your Password</h2>
          <Label htmlFor="email" className="text-lg">Email Address</Label>
          <Input
            type="email"
            id="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="p-2 border rounded"
          />
          <Button type="submit" disabled={isLoading} className="mt-4">
            {isLoading ? "Sending..." : "Send Password Reset Email"}
          </Button>
        </form>
      </main>

      <ToastContainer />

      <NewFooter />
    </div>
  );
};

export default Recovery;
