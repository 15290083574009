
import React from "react";
import MinimalSidebar from '../comp/MinimalSidebar'; 
import { Helmet } from 'react-helmet';


const ResumeUpload = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Helmet>
        <title>Upload Resume - Career Resigned</title>
        <link rel="icon" href="" />
      </Helmet>

      <MinimalSidebar />

      <main className="flex-1 mt-12"></main>
    </div>
  );
};

export default ResumeUpload;
