import React from "react";
import { Helmet } from 'react-helmet';
import PurpleFooter from '../comp/PurpleFooter';
import PurpleHeader from '../comp/PurpleHeader';

const Privacy = () => {
  return (


<div className="flex flex-col min-h-screen">



<Helmet>
<title>Privacy Policy - Career Resigned</title>
  <link rel="icon" href="" />
</Helmet>




      <PurpleHeader />
      

      <main className="flex-1 mb-18 mt-20" style={{ fontFamily: "Poppins" }}>

<section className="py-12">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-black sm:text-4xl">
                Your Privacy Matters to Us
            </p>
        </div>

        <div className="mt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">

                <div className="relative">
                    <dt>
                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md">
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                            </svg>
                        </div>
                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">1. Information We Collect</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                        We collect personal information that you provide, such as your name, contact details, education, and work experience, which are necessary to build your resume.
                    </dd>
                </div>

                <div className="relative">
                    <dt>
                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md ">
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                            </svg>
                        </div>
                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">2. Use of Information</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                        The personal information we collect is used solely for building and improving your resume and customizing our services to better meet your needs.
                    </dd>
                </div>

                <div className="relative">
                    <dt>
                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md">
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
                            </svg>
                        </div>
                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">3. Data Sharing and Disclosure</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                        We do not share your personal information with third parties. Your data is kept confidential within our organization and used only for the services we provide.
                    </dd>
                </div>

                <div className="relative">
                    <dt>
                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md">
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </div>
                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">4. Data Storage and Security</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                        We implement reasonable security measures to protect your personal data from unauthorized access, disclosure, alteration, or destruction.
                    </dd>
                </div>

                <div className="relative">
                    <dt>
                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md">
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
                            </svg>
                        </div>
                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">5. User Rights</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                        You have the right to access, correct, or request the deletion of your personal information at any time by contacting us at info@careerredesigned.com.
                    </dd>
                </div>

                <div className="relative">
                    <dt>
                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md">
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
                            </svg>
                        </div>
                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">6. Cookies and Tracking Technologies</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                        We may use cookies and similar technologies to enhance your experience. You can control or disable cookies through your browser settings.
                    </dd>
                </div>

                <div className="relative">
                    <dt>
                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md ">
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.

943-9.542-7z" />
                            </svg>
                        </div>
                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">7. Third-Party Links</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                        Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites.
                    </dd>
                </div>

                <div className="relative">
                    <dt>
                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md ">
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </div>
                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">8. Children’s Privacy</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                        We do not knowingly collect personal information from children under 13. If we become aware that we have collected data from a child under 13, we will take steps to delete it.
                    </dd>
                </div>

                <div className="relative">
                    <dt>
                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md ">
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
                            </svg>
                        </div>
                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">9. Changes to This Privacy Policy</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                        We may update this Privacy Policy to reflect changes in our practices. We will notify you via email if significant changes occur.
                    </dd>
                </div>

                <div className="relative">
                    <dt>
                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md ">
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
                            </svg>
                        </div>
                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">10. Contact Information</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                        If you have any questions about this policy, please contact us at info@careerredesigned.com.
                    </dd>
                </div>

            </dl>
        </div>
    </div>
</section>


    </main>


      <PurpleFooter/>

    </div>
  );
};

export default Privacy;
