import { jsPDF } from 'jspdf';
import { parse, format } from 'date-fns';


const formatDate2 = (dateString) => {
  console.log('Input dateString:', dateString);

  if (!dateString) return '';
  
  try {
    // Handle array input
    const date = Array.isArray(dateString) ? dateString[0] : dateString;
    
    // Split the date string and create date properly
    const [year, month] = date.split('-');
    const parsedDate = new Date(parseInt(year), parseInt(month) - 1);
    
    const formattedDate = format(parsedDate, 'MMMM yyyy');
    
    return formattedDate;
  } catch (error) {
    return Array.isArray(dateString) ? dateString[0] : dateString;
  }
};

const isValidGPA = (gpa) => {
  return gpa !== null && gpa !== '' && gpa !== '0' && parseFloat(gpa) !== 0;
};


const formatDate = (dateInput) => {
  // Return empty string for null/undefined inputs
  if (!dateInput) return '';

  try {
    // Case 1: Handle array format (existing format)
    if (Array.isArray(dateInput)) {
      if (dateInput.length === 0) return '';
      const dateString = dateInput[0];
      if (typeof dateString !== 'string') return '';
      // Parse date in yyyy-MM format
      const parsedDate = parse(dateString, 'yyyy-MM', new Date());
      return format(parsedDate, 'MMMM yyyy');
    }

    // Case 2: Handle ISO date string (new format)
    if (typeof dateInput === 'string') {
      // Check if it's an ISO date string
      if (dateInput.includes('T')) {
        const parsedDate = new Date(dateInput);
        // Check if the date is valid
        if (isNaN(parsedDate.getTime())) return '';
        return format(parsedDate, 'MMMM yyyy');
      }
      
      // Case 3: Handle simple yyyy-MM format (fallback for existing format)
      const parsedDate = parse(dateInput, 'yyyy-MM', new Date());
      return format(parsedDate, 'MMMM yyyy');
    }

    return '';
  } catch (error) {
    console.error('Error parsing date:', error);
    return typeof dateInput === 'string' ? dateInput : ''; // Fallback to original string if parsing fails
  }
};


const downloadPDF = (formData, hiddenSections, fileName, font = 'Helvetica, sans-serif', fontSize = '10px') => {
  if (!formData) {
    console.error('formData is undefined');
    return;
  }


  // Initialize jsPDF
  const doc = new jsPDF();
  const lineHeight = 5;
  const leftMargin = 10;
  const rightMargin = 210 - leftMargin;
  const pageWidth = doc.internal.pageSize.width;
  
  // Font size conversion from string to integer
  const marginFontSize = parseInt(fontSize, 9);

  // Map of font selections
  const jsPdfFontMap = {
    'Helvetica, sans-serif': 'helvetica',
    'Times New Roman, serif': 'times',
    'Courier New, monospace': 'courier'
  };

  // Use selected font or fallback to Helvetica
  const selectedFont = jsPdfFontMap[font] || 'helvetica';

  // Set the selected font and font size
  doc.setFont(selectedFont);
  doc.setFontSize(marginFontSize);

  // Personal Information section - Modified to start at 15 instead of 20
  doc.setFontSize(marginFontSize + 5);
  doc.text(formData.personal_information?.name || formData.name, pageWidth / 2, 15, { align: "center" });
  doc.setFontSize(marginFontSize);
  const contactInfo = `${formData.personal_information?.phone || formData.phone} | ${formData.personal_information?.email || formData.email} | ${formData.personal_information?.linkedin || formData.linkedin}`;
  doc.text(contactInfo, pageWidth / 2, 20, { align: "center" });

  let currentY = 30; // Modified from 35 to maintain proportional spacing


  // Function to check page overflow and add a new page if necessary
  const checkAndAddPage = (y) => {
    if (y > doc.internal.pageSize.height - 20) {
      doc.addPage();
      return 20;
    }
    return y;
  };

  // Function to render section headers
  const renderSectionHeader = (title, y) => {
    doc.setFont(selectedFont, "bold");
    doc.setFontSize(marginFontSize + 2); // Larger for section headers
    doc.text(title, leftMargin, y);
    doc.line(leftMargin, y + 2, rightMargin, y + 2); // Draw underline
    doc.setFont(selectedFont, "normal");
    return y + 7;
  };

  // Render Education section
const renderEducation = (startY) => {
  let y = renderSectionHeader("EDUCATION", startY);
  formData.education.forEach((edu) => {
    y = checkAndAddPage(y);
    doc.setFont(selectedFont, "bold");
    doc.setFontSize(marginFontSize + 1); // Slightly larger for institutions
    doc.text(`${edu.university} | ${edu.location}`, leftMargin, y);
    doc.setFont(selectedFont, "normal");
    doc.text(formatDate(edu.graduation), rightMargin, y, { align: "right" });
    y += lineHeight;

    doc.setFont(selectedFont, "italic");
    const degreeLines = doc.splitTextToSize(edu.degree, pageWidth - 2 * leftMargin - 20);
    degreeLines.forEach((line) => {
      y = checkAndAddPage(y);
      doc.text(line, leftMargin, y);
      y += lineHeight;
    });

    doc.setFont(selectedFont, "normal");
    const majorLines = doc.splitTextToSize(`Major: ${edu.major}`, pageWidth - 2 * leftMargin - 20);
    majorLines.forEach((line) => {
      y = checkAndAddPage(y);
      doc.text(line, leftMargin, y);
      y += lineHeight;
    });

    // Only render GPA if it's valid
    if (isValidGPA(edu.gpa)) {
      const gpaLines = doc.splitTextToSize(`GPA: ${edu.gpa}`, pageWidth - 2 * leftMargin - 20);
      gpaLines.forEach((line) => {
        y = checkAndAddPage(y);
        doc.text(line, leftMargin, y);
        y += lineHeight;
      });
    }

    y += lineHeight * 0.5;
  });
  return y;
};

  
  // Render Experience section
  const renderExperience = (startY) => {
    let y = renderSectionHeader("EXPERIENCE", startY);
    formData.experience.forEach((exp) => {
      y = checkAndAddPage(y);
      doc.setFontSize(marginFontSize + 1);
      doc.setFont(selectedFont, "bold");
      doc.text(`${exp.company} | ${exp.location}`, leftMargin, y);
      doc.setFont(selectedFont, "normal");
      doc.text(exp.duration, rightMargin, y, { align: "right" });
      y += lineHeight;

      doc.setFont(selectedFont, "italic");
      doc.text(exp.jobTitle, leftMargin, y);
      y += lineHeight;

      doc.setFont(selectedFont, "normal");
      exp.description.forEach((desc) => {
        const lines = doc.splitTextToSize(/^[A-Za-z]/.test(desc) ? `• ${desc}` : desc, pageWidth - 2 * leftMargin - 10);
        lines.forEach((line) => {
          y = checkAndAddPage(y);
          doc.text(line, leftMargin + 5, y);
          y += lineHeight;
        });
      });
      y += lineHeight * 0.25;
    });
    return y;
  };

  // Render Leadership section
  const renderLeadership = (startY) => {
    let y = renderSectionHeader("LEADERSHIP ACTIVITIES AND MEMBERSHIPS", startY);
    formData.leadership.forEach((leader) => {
      y = checkAndAddPage(y);
      doc.setFont(selectedFont, "bold");
      doc.setFontSize(marginFontSize + 1);
      doc.text(`${leader.organization} | ${leader.location}`, leftMargin, y);
      doc.setFont(selectedFont, "normal");
      doc.text(leader.duration, rightMargin, y, { align: "right" });
      y += lineHeight;

      doc.setFont(selectedFont, "italic");
      doc.text(leader.title, leftMargin, y);
      y += lineHeight;

      doc.setFont(selectedFont, "normal");
      leader.accomplishment.forEach((acc) => {
        const lines = doc.splitTextToSize(/^[A-Za-z]/.test(acc) ? `• ${acc}` : acc, pageWidth - 2 * leftMargin - 10);
        lines.forEach((line) => {
          y = checkAndAddPage(y);
          doc.text(`${line}`, leftMargin + 5, y);
          y += lineHeight;
        });
      });
      y += lineHeight * 0.25;
        });
    return y;
  };

  // Render Awards section
  const renderAwards = (startY) => {
    let y = renderSectionHeader("HONORS AND AWARDS", startY);
    formData.awards.forEach((award) => {
      y = checkAndAddPage(y);
      doc.setFont(selectedFont, "normal");
      doc.setFontSize(marginFontSize + 1);

      const awardText = `${award.award} | ${award.location}`;
      const awardLines = doc.splitTextToSize(awardText, pageWidth - 2 * leftMargin - 50);
      doc.text(awardLines, leftMargin, y);

      const dateText = `${formatDate2(award.date)}`;
      doc.text(dateText, rightMargin, y, { align: "right" });

      y += lineHeight * Math.max(awardLines.length, 1);
      y += lineHeight * 0.25;
    });
    return y;
  };

  // Render Certifications section
  const renderCertifications = (startY) => {
    let y = renderSectionHeader("CERTIFICATIONS", startY);
    formData.certifications.forEach((cert) => {
      y = checkAndAddPage(y);
      doc.setFont(selectedFont, "normal");
      doc.setFontSize(marginFontSize + 1);

      const certText = `${cert.certification_name} | ${cert.certification_location}`;
      const certLines = doc.splitTextToSize(certText, pageWidth - 2 * leftMargin - 50);
      doc.text(certLines, leftMargin, y);

      const yearText = `${cert.certification_year}`;
      doc.text(yearText, rightMargin, y, { align: "right" });

      y += lineHeight * Math.max(certLines.length, 1);
      y += lineHeight * 0.25;
    });
    return y;
  };

  // Render Skills section
const renderSkills = (startY) => {
  let y = renderSectionHeader("TECHNICAL AND LANGUAGE SKILLS", startY);
  doc.setFontSize(marginFontSize);
  const rightMargin = 20;

  // Changed to check for "yes" string value
  if (formData.skillsVisibility?.technical === "yes" && formData.skills?.technical) {
    y = checkAndAddPage(y);
    doc.setFont(selectedFont, "bold");
    doc.text("Technical:", leftMargin, y);
    doc.setFont(selectedFont, "normal");
    const techLines = doc.splitTextToSize(formData.skills.technical, pageWidth - leftMargin - rightMargin - 10);
    techLines.forEach((line) => {
      y = checkAndAddPage(y);
      doc.text(line, leftMargin + 20, y, { maxWidth: pageWidth - leftMargin - rightMargin - 10 });
      y += lineHeight;
    });
    y += lineHeight;
  }

  // Changed to check for "yes" string value
  if (formData.skillsVisibility?.languages === "yes" && formData.skills?.languages) {
    y = checkAndAddPage(y);
    doc.setFont(selectedFont, "bold");
    doc.text("Language:", leftMargin, y);
    doc.setFont(selectedFont, "normal");
    const langLines = doc.splitTextToSize(formData.skills.languages, pageWidth - leftMargin - rightMargin - 10);
    langLines.forEach((line) => {
      y = checkAndAddPage(y);
      doc.text(line, leftMargin + 20, y, { maxWidth: pageWidth - leftMargin - rightMargin - 10 });
      y += lineHeight;
    });
  }

  return y + lineHeight * 1.5;
};

  // Render custom sections
  const renderCustomSection = (startY, section) => {
    let y = renderSectionHeader(section.title, startY);
    doc.setFont(selectedFont, "normal");
    const contentLines = doc.splitTextToSize(section.content, pageWidth - 2 * leftMargin - 10);
    contentLines.forEach((line) => {
      y = checkAndAddPage(y);
      doc.text(line, leftMargin, y);
      y += lineHeight;
    });
    return y + lineHeight;
  };

  // Render sections in the defined order
  const renderSection = (sectionName) => {
    currentY = checkAndAddPage(currentY);
    switch (sectionName) {
      case 'education':
        if (formData.education && formData.education.length > 0) {
          currentY = renderEducation(currentY);
        }
        break;
      case 'experience':
        if (formData.experience && formData.experience.length > 0) {
          currentY = renderExperience(currentY);
        }
        break;
      case 'leadership':
        if (!hiddenSections.leadership && formData.leadership && formData.leadership.length > 0) {
          currentY = renderLeadership(currentY);
        }
        break;
      case 'awards':
        if (!hiddenSections.awards && formData.awards && formData.awards.length > 0) {
          currentY = renderAwards(currentY);
        }
        break;
      case 'certifications':
        if (!hiddenSections.certifications && formData.certifications && formData.certifications.length > 0) {
          currentY = renderCertifications(currentY);
        }
        break;
      case 'skills':
        if (!hiddenSections.skills && formData.skills) {
          currentY = renderSkills(currentY);
        }
        break;
      default:
        if (sectionName.startsWith('custom-')) {
          const index = parseInt(sectionName.split('-')[1]);
          if (formData.customSections && formData.customSections[index]) {
            currentY = renderCustomSection(currentY, formData.customSections[index]);
          }
        }
        break;
    }
    currentY += lineHeight / 4;
  };

  // Render the resume sections in the defined order
  const sectionOrder = (formData.sectionOrder || [
    'education',
    'experience',
    'leadership',
    'awards',
    'certifications',
    'skills',
    ...(formData.customSections || []).map((_, index) => `custom-${index}`)
  ]).filter(section => {
    if (section === 'leadership' && hiddenSections.leadership) return false;
    if (section === 'awards' && hiddenSections.awards) return false;
    if (section === 'skills' && hiddenSections.skills) return false;
    if (section === 'certifications' && hiddenSections.certifications) return false;

    if (section.startsWith('custom-')) {
      const index = parseInt(section.split('-')[1]);
      return formData.customSections && formData.customSections[index];
    }
    return formData[section] && (Array.isArray(formData[section]) ? formData[section].length > 0 : true);
  });

  sectionOrder.forEach(renderSection);

  // Save the generated PDF
  doc.save(fileName);
};

export default downloadPDF;
