import resumeKeywords from './resume_keywords';
import resumeSuggestions from './ResumeSuggestions';

class ResumeScorer {
  constructor(resumeData) {
    this.resumeData = resumeData;
    this.score = 0;
    this.feedback = {};
    this.suggestions = [];
  }

  calculateScore() {
    this.scoreContactInfo();
    this.scoreEducation();
    this.scoreExperience();
    this.scoreSkills();
    this.scoreLeadership();
    this.scoreAwards();
    this.scoreCertifications();
    this.scoreKeywords();
    this.scoreAchievements();

    this.generateSuggestions();

    return {
      totalScore: Math.round(this.score),
      feedback: this.feedback,
      suggestions: this.suggestions
    };
  }

  scoreContactInfo() {
    let contactScore = 0;
    const { name, phone, email, linkedin } = this.resumeData;
    if (name && name.trim().length > 0) contactScore += 2;
    if (phone && phone.trim().length > 0) contactScore += 1;
    if (email && email.trim().length > 0) contactScore += 1;
    if (linkedin && linkedin.trim().length > 0) contactScore += 1;
    this.score += contactScore;
    this.feedback.contactInfo = `${contactScore}/5`;
  }

  scoreEducation() {
    let educationScore = 0;
    if (this.resumeData.education && this.resumeData.education.length > 0) {
      this.resumeData.education.forEach(edu => {
        if (edu.university && edu.degree) educationScore += 3;
        if (edu.major) educationScore += 1;
        if (edu.gpa && parseFloat(edu.gpa) >= 3.0) educationScore += 1;
      });
    }
    educationScore = Math.min(educationScore, 10);
    this.score += educationScore;
    this.feedback.education = `${educationScore}/10`;
  }

  // Improved experience scoring with checks for action verbs and quantifiable achievements
  scoreExperience() {
    let experienceScore = 0;
    if (this.resumeData.experience && this.resumeData.experience.length > 0) {
      this.resumeData.experience.forEach(exp => {
        let entryScore = 0;
        if (exp.company && exp.jobTitle) entryScore += 2;
        if (exp.duration) entryScore += 1;
        if (exp.description && exp.description.length > 0) {
          // Scoring for action verbs and quantifiable achievements
          entryScore += this.checkActionVerbs(exp.description.join(' ')) ? 2 : 0;
          entryScore += this.checkQuantifiableAchievements(exp.description.join(' ')) ? 2 : 0;
          entryScore += Math.min(exp.description.length, 5);  // Existing scoring for description length
        }
        experienceScore += entryScore;
      });
    }
    experienceScore = Math.min(experienceScore, 30);
    this.score += experienceScore;
    this.feedback.experience = `${experienceScore}/30`;
  }

  // Leadership scoring updated with similar logic to experience
  scoreLeadership() {
    let leadershipScore = 0;
    if (this.resumeData.leadership && this.resumeData.leadership.length > 0) {
      this.resumeData.leadership.forEach(leader => {
        let entryScore = 0;
        if (leader.organization && leader.title) entryScore += 2;
        if (leader.accomplishment && leader.accomplishment.length > 0) {
          entryScore += this.checkActionVerbs(leader.accomplishment.join(' ')) ? 2 : 0;
          entryScore += this.checkQuantifiableAchievements(leader.accomplishment.join(' ')) ? 2 : 0;
          entryScore += Math.min(leader.accomplishment.length, 3);
        }
        leadershipScore += entryScore;
      });
    }
    leadershipScore = Math.min(leadershipScore, 10);
    this.score += leadershipScore;
    this.feedback.leadership = `${leadershipScore}/10`;
  }

  // Helper method for checking action verbs
  checkActionVerbs(text) {
    return resumeKeywords.actionVerbs.some(verb => text.toLowerCase().includes(verb.toLowerCase()));
  }

  // Helper method for checking quantifiable achievements (numbers or percentages)
  checkQuantifiableAchievements(text) {
    return /\d+%|\d+/.test(text);  // Look for numbers or percentages in the text
  }

  scoreSkills() {
    let skillsScore = 0;
    if (this.resumeData.skills) {
      if (this.resumeData.skills.technical && this.resumeData.skills.technical.trim().length > 0) {
        skillsScore += 5;
      }
      if (this.resumeData.skills.languages && this.resumeData.skills.languages.trim().length > 0) {
        skillsScore += 5;
      }
    }
    this.score += skillsScore;
    this.feedback.skills = `${skillsScore}/10`;
  }


  scoreAwards() {
    let awardsScore = 0;
    if (this.resumeData.awards && this.resumeData.awards.length > 0) {
      awardsScore = Math.min(this.resumeData.awards.length * 2, 10);
    }
    this.score += awardsScore;
    this.feedback.awards = `${awardsScore}/10`;
  }

  scoreCertifications() {
    let certificationsScore = 0;
    if (this.resumeData.certifications && this.resumeData.certifications.length > 0) {
      certificationsScore = Math.min(this.resumeData.certifications.length * 2, 5);
    }
    this.score += certificationsScore;
    this.feedback.certifications = `${certificationsScore}/5`;
  }

  scoreKeywords() {
    const allText = JSON.stringify(this.resumeData);
    const usedKeywords = new Set([
      ...this.checkActionVerbs(allText),
      ...this.checkIndustryKeywords(allText),
      ...this.checkTechnicalSkills(allText)
    ]);
    const keywordScore = Math.min(usedKeywords.size, 10);
    this.score += keywordScore;
    this.feedback.keywords = `${keywordScore}/10`;
  }

  scoreAchievements() {
    let achievementScore = 0;
    const sections = ['experience', 'leadership'];
    sections.forEach(section => {
      if (this.resumeData[section]) {
        this.resumeData[section].forEach(entry => {
          const descriptions = Array.isArray(entry.description) ? entry.description : 
                               (entry.accomplishment ? entry.accomplishment : []);
          descriptions.forEach(desc => {
            if (/\d+%|\d+/.test(desc)) achievementScore++;
            if (/increased|decreased|improved|reduced|achieved|won|awarded/.test(desc.toLowerCase())) achievementScore++;
          });
        });
      }
    });
    achievementScore = Math.min(achievementScore, 10);
    this.score += achievementScore;
    this.feedback.achievements = `${achievementScore}/10`;
  }

  scoreOverallImpact() {
    const overallScore = 5; // Placeholder score
    this.score += overallScore;
    this.feedback.overallImpact = `${overallScore}/5`;
  }

  generateSuggestions() {

     // Updated logic for generating specific suggestions based on new checks
     if (parseInt(this.feedback.experience) < 20) {
      this.suggestions.push(resumeSuggestions[6]); // Suggest expanding experience
    }
    if (!this.checkQuantifiableAchievements(JSON.stringify(this.resumeData))) {
      this.suggestions.push(resumeSuggestions[3]); // Suggest quantifying achievements
    }
    if (!this.checkActionVerbs(JSON.stringify(this.resumeData))) {
      this.suggestions.push(resumeSuggestions[0]); // Suggest improving action verbs
    }
    if (this.score < 70) {
      this.suggestions.push(resumeSuggestions[1]); // Enhance formatting and structure
    }
    if (parseInt(this.feedback.actionVerbs) < 7) {
      this.suggestions.push(resumeSuggestions[0]); // Improve your action verbs
    }
    if (parseInt(this.feedback.skills) < 7) {
      this.suggestions.push(resumeSuggestions[2]); // Add relevant skills and certifications
    }
    if (parseInt(this.feedback.achievements) < 3) {
      this.suggestions.push(resumeSuggestions[3]); // Quantify your achievements
    }
    if (parseInt(this.feedback.keywords) < 7) {
      this.suggestions.push(resumeSuggestions[4]); // Tailor your resume
    }
    if (parseInt(this.feedback.contactInfo) < 4) {
      this.suggestions.push(resumeSuggestions[5]); // Improve your contact information
    }
    if (parseInt(this.feedback.experience) < 20) {
      this.suggestions.push(resumeSuggestions[6]); // Expand on your work experience
    }

    // Add more general suggestions if we have less than 3 specific ones
    while (this.suggestions.length < 3) {
      const randomSuggestion = resumeSuggestions[Math.floor(Math.random() * resumeSuggestions.length)];
      if (!this.suggestions.includes(randomSuggestion)) {
        this.suggestions.push(randomSuggestion);
      }
    }
  }

  // Helper methods for keyword checking
  checkActionVerbs(text) {
    return resumeKeywords.actionVerbs.filter(verb => 
      text.toLowerCase().includes(verb.toLowerCase())
    );
  }

  checkIndustryKeywords(text) {
    return resumeKeywords.industryKeywords.filter(keyword => 
      text.toLowerCase().includes(keyword.toLowerCase())
    );
  }

  checkTechnicalSkills(text) {
    return resumeKeywords.technicalSkills.filter(skill => 
      text.toLowerCase().includes(skill.toLowerCase())
    );
  }

  checkSoftSkills(text) {
    return resumeKeywords.softSkills.filter(skill => 
      text.toLowerCase().includes(skill.toLowerCase())
    );
  }

  checkCertifications(text) {
    return resumeKeywords.certifications.filter(cert => 
      text.toLowerCase().includes(cert.toLowerCase())
    );
  }
}

export default ResumeScorer;