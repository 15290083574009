import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

const DateRangeSelector = ({
  startDate,
  endDate,
  isCurrentPosition,
  onStartDateChange,
  onEndDateChange,
  onCurrentPositionChange,
  sectionName,
  index
}) => {
  const [dateError, setDateError] = useState('');

  const handleStartDateChange = (date) => {
    // If end date exists and is before the new start date, update error
    if (endDate && !isCurrentPosition && date > endDate) {
      setDateError('Start date cannot be later than end date');
      return;
    }
    setDateError('');
    onStartDateChange(date);
  };

  const handleEndDateChange = (date) => {
    // If the new end date is before start date, update error
    if (startDate && date < startDate) {
      setDateError('End date cannot be earlier than start date');
      return;
    }
    setDateError('');
    onEndDateChange(date);
  };

  const handleCurrentPositionToggle = (value) => {
    setDateError('');
    onCurrentPositionChange(value);
  };

  return (
    <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
      <div className="space-y-4">
        <div>
          <label htmlFor={`${sectionName}StartDate-${index}`} className="block text-m font-medium text-black">
            Start Date
          </label>
          <DatePicker
            selected={startDate instanceof Date ? startDate : null}
            onChange={handleStartDateChange}
            dateFormat="MMMM yyyy"
            showMonthYearPicker
            placeholderText="Select Start Date"
            className="input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
          />
        </div>

        <div className="flex items-center space-x-2 pt-2">
          <button
            type="button"
            role="switch"
            aria-checked={isCurrentPosition}
            onClick={() => handleCurrentPositionToggle(!isCurrentPosition)}
            className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
              isCurrentPosition ? 'bg-blue-600' : 'bg-gray-200'
            }`}
          >
            <span
              className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                isCurrentPosition ? 'translate-x-6' : 'translate-x-1'
              }`}
            />
          </button>
          <label className="text-sm font-medium text-gray-700">
            Present
          </label>
        </div>
      </div>
      
      <div>
        <div>
          <label htmlFor={`${sectionName}EndDate-${index}`} className="block text-m font-medium text-black">
            End Date
          </label>
          <DatePicker
            selected={endDate instanceof Date && !isCurrentPosition ? endDate : null}
            onChange={handleEndDateChange}
            dateFormat="MMMM yyyy"
            showMonthYearPicker
            placeholderText="Select End Date"
            disabled={isCurrentPosition}
            className={`input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ${
              dateError ? 'ring-red-500' : 'ring-gray-300'
            } placeholder:text-gray-400 sm:text-sm sm:leading-6`}
          />
          {dateError && (
            <p className="mt-2 text-sm text-red-600">
              {dateError}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DateRangeSelector;