// src/AppRouter.js
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Dashboard from "./pages/Dashboard";
import ResumeBuilder from './pages/Resume-Builder';
import Build from "./pages/Build";
import ResumeUpload from "./pages/Resume-upload"
import Settings from "./pages/Settings"
import ResumeCreator from "./pages/resumecreator"
import ResumeGrader from "./pages/resumegrader"
import RequireAuth from './comp/RequireAuth';
import ResumeResult from './pages/resume-result';
import ResumeByAI from './pages/ResumeByAI'; 
import Testing from './pages/TestingHome';
import Error from "./pages/error";
import Interview from "./pages/Interview";
import InterviewPrep from "./pages/InterviewPrep";
import MyInterview from "./pages/myinterview";
import JobSearch from "./pages/JobSearch";
import JobProfile from "./pages/JobProfile";
import Payment from "./pages/payment";
import Referral from "./pages/referral";
import InterviewQA from "./pages/InterviewQA";
import CompareResume from "./pages/CompareResume";
import CompareByAI from "./pages/ComparisonResult";
import Recovery from "./pages/recovery";
import ResetPassword from './pages/ResetPassword'; 
import GoogleTagManager from './comp/GoogleTagManager';
import ForgotPassword from './pages/ForgotPassword';
import RecoveryHandler from './pages/RecoveryHandler';
import GetStartedPage from "./pages/Get started";
import Grader from "./pages/Grader";
import CoverLetterCreator from "./pages/Cover letter";
import CoverLetterDisplay from "./pages/CoverLetterDisplay";

const AppRouter = () => {

  const GTM_ID = process.env.REACT_APP_GTM_ID; 


  return (
    <Router>


<GoogleTagManager gtmId={GTM_ID} />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/recovery" element={<Recovery/>} />
        <Route path="/get-started" element={<GetStartedPage />} />
        <Route path="/reset-password" element={<ResetPassword />} /> 
        <Route path="/grader" element={<Grader />} />
        <Route path="/dashboard" element={<RequireAuth><Dashboard /></RequireAuth>} />
        <Route path="/resume-builder" element={<RequireAuth><ResumeBuilder /></RequireAuth>} />
        <Route path="/builder" element={<RequireAuth><Build /></RequireAuth>} />
        <Route path="/upload" element={<RequireAuth><ResumeUpload /></RequireAuth>} />
        <Route path="/settings" element={<RequireAuth><Settings /></RequireAuth>} />
        <Route path="/resume-creator" element={<RequireAuth><ResumeCreator /></RequireAuth>} />
        <Route path="/resume-grader" element={<RequireAuth><ResumeGrader /></RequireAuth>} />
        <Route path="/resume-result" element={<RequireAuth><ResumeResult /></RequireAuth>} />
        <Route path="/resumebyai" element={<ResumeByAI />} />
        <Route path="/testing" element={<RequireAuth><Testing /></RequireAuth>} />
        <Route path="/error" element={<Error />} />
        <Route path="/interview" element={<RequireAuth><Interview/></RequireAuth>} />
        <Route path="/interview-prep" element={<RequireAuth><InterviewQA/></RequireAuth>} />
        <Route path="/myinterview" element={<RequireAuth><MyInterview/></RequireAuth>} />
        <Route path="/jobsearch" element={<RequireAuth><JobSearch/></RequireAuth>} />
        <Route path="/jobprofile" element={<RequireAuth><JobProfile/></RequireAuth>} />
        <Route path="*" element={<Navigate to="/error" replace />} />
        <Route path="/payment" element={<RequireAuth><Payment/></RequireAuth>} />
        <Route path="/referral" element={<RequireAuth><Referral/></RequireAuth>} />
        <Route path="/compareResume" element={<RequireAuth><CompareResume/></RequireAuth>} />
        <Route path="/comparison-result" element={<RequireAuth><CompareByAI/></RequireAuth>} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/auth/callback" element={<RecoveryHandler />} />
        <Route path="/cover-letter" element={<RequireAuth><CoverLetterCreator/></RequireAuth>} />
        <Route path="/cover-letter-display" element={<RequireAuth><CoverLetterDisplay/></RequireAuth>} />
        



      </Routes>
    </Router>
  );
};

export default AppRouter;
