import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoaderSkeleton from './ux/LoaderSkeleton';
import 'w3-css/w3.css';

function GradingResumes() {
    const [resumes, setResumes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [renameResume, setRenameResume] = useState(null);
    const [newResumeName, setNewResumeName] = useState('');
    const [selectedResume, setSelectedResume] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);


    const [checked, setChecked] = useState(false);

    const handleCheckboxChange = () => {
      setChecked(!checked);
    };
  
    const navigate = useNavigate();
  useEffect(() => {
    fetchResumes();
  }, []);

  const fetchResumes = async () => {
    try {
      setLoading(true);
      const { data: { user } } = await supabase.auth.getUser();
      const { data, error } = await supabase
        .from('resumes')
        .select('*')
        .eq('user_id', user.id);

      if (error) throw error;

      setResumes(data);
    } catch (error) {
      console.error('Error retrieving resumes:', error);
      setError('Error loading resumes.');
    } finally {
      setLoading(false);
    }
  };

  const handleResumeClick = (code) => {
    setSelectedResume(code);
  };

  const handleSubmit = () => {
    if (selectedResume) {
      setShowConfirmation(true);
    } else {
      toast.error("Please select a resume first.");
    }
  };

  const confirmSubmit = async () => {
    setShowConfirmation(false);
    toast.success("Resume submitted for grading!");
    navigate(`/resume-result?code=${selectedResume}`);
  };

  const cancelSubmit = () => {
    setShowConfirmation(false);
  };

  if (loading) return <LoaderSkeleton/>;
  if (error) return <p>{error}</p>;

  const selectedResumeData = resumes.find(resume => resume.code === selectedResume);

  return (
    <div className="container mx-auto p-6">

<div className="box">
    <ul id="resumeList">
                <ul id="resumeList">
          {resumes.length === 0 ? (
            <div className="flex flex-col items-center justify-center text-center">
              <svg className="h-16 w-16 text-gray-400 mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.182 16.318A4.486 4.486 0 0 0 12.016 15a4.486 4.486 0 0 0-3.198 1.318M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Z" />
              </svg>
              <p className="text-gray-500">No resumes created yet. Click on Resume Builder to get Started</p>
            </div>
          ) : (
            resumes.map(resume => (
                <li 
                  key={resume.id}
                  className=" mb-4	 px-6 py-4 rounded-lg border bg-card hover:bg-gray-100 transition-colors duration-300 flex items-center justify-between"
                  onClick={() => handleResumeClick(resume.code)}
                >
                  <div className="flex items-center">
                  <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="stroke-cutler-green w-7 h-7"
        >
          <path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z"></path>
          <path d="M14 2v4a2 2 0 0 0 2 2h4"></path>
        </svg>
                    <div className='ml-6'>
                      {renameResume && renameResume.id === resume.id ? (
                        <input 
                          type="text" 
                          value={newResumeName} 
                          onChange={(e) => setNewResumeName(e.target.value)} 
                          className="border rounded px-2 py-1" 
                        />
                      ) : (
                        <>
                          <h3 className="text-lg font-bold">{resume.name}</h3>
                          <div className="text-sm text-muted-foreground">
          Created: {new Date(resume.created_at).toLocaleDateString()} | 
          Updated: {new Date(resume.last_updated).toLocaleDateString()}
        </div>
                        </>
                      )}
                    </div>
                  </div>
                  {selectedResume === resume.code && (
              <svg className="h-6 w-6 text-green-500 ml-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
                  )}
                </li>
              ))
           
          )}
        </ul>

    </ul>
</div>

<div className="flex justify-end">
    <button className="bg-cutler-green text-white py-2 px-4 rounded mt-10" 
    style={{fontSize:'16px' , fontFamily:'Poppins'}}
    onClick={handleSubmit}>
        Submit Resume for Grading
    </button>




</div>


{showConfirmation && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white p-5 rounded-lg shadow-lg">
            <h2 className="text-m font-bold mb-1">Confirm Submission</h2>



            <p style={{ fontFamily:'Poppins', fontSize:"18px"}}>Are you sure you want to get this resume graded?</p>



            <p style={{ fontFamily:'Poppins', fontSize:"16px", marginTop:"15px"}}><strong>Resume Name:</strong> {selectedResumeData?.name}</p>



            <p   style={{ fontFamily:'Poppins', fontSize:"15px", marginTop:"5px"}}><strong>Created on:</strong> {new Date(selectedResumeData?.created_at).toLocaleDateString()}</p>
            <div className="mt-4 flex justify-end space-x-3">
              <button className="bg-gray-300 hover:bg-gray-400 text-black py-2 px-4 rounded" 
              
              style={{ fontFamily:'Poppins', fontSize:"15px", marginTop:"5px"}}
              onClick={cancelSubmit}>
                Cancel
              </button>
              <button className="bg-cutler-green hover:bg-green-700 text-white  py-2 px-4 rounded"
              
              style={{ fontFamily:'Poppins', fontSize:"15px", marginTop:"5px"}}
              onClick={confirmSubmit}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
}

export default GradingResumes;