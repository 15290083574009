'use client'
import { useState } from 'react'

const bubbles = [
  { icon: "🚀", title: "Launch Your Career" },
  { icon: "🎓", title: "Built for Students" },
  { icon: "🤖", title: "AI-Powered" },
  { icon: "✨", title: "Stand Out" },
  { icon: "💼", title: "From Class to Career" },
  { icon: "🔍", title: "Beat the ATS" },
  { icon: "📈", title: "Grow with Feedback" },
]

export default function Component() {
  const [hoveredIndex, setHoveredIndex] = useState(null)

  return (
    <div className="w-full px-2 ">
      <div className="max-w-6xl mx-auto">
      <p className="text-xl text-center text-slate-800 mb-10">
      Don't let your dream job slip away. Give yourself the competitive edge you deserve.
      </p>
        <div className="flex flex-wrap justify-center gap-6 mb-8">
          {bubbles.map((bubble, index) => (
            <div
              key={index}
              className="flex-shrink-0"
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <div
                className={`bg-white rounded-full p-4 shadow-lg w-28 h-28 sm:w-32 sm:h-32 flex flex-col items-center justify-center text-center transition-transform duration-300 ${
                  hoveredIndex === index ? 'scale-110' : 'scale-100'
                }`}
              >
                <span className="text-3xl sm:text-4xl mb-2">{bubble.icon}</span>
                <span className="text-xs sm:text-sm font-semibold text-slate-700">{bubble.title}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}