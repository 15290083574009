import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MinimalSidebar from "../comp/MinimalSidebar";



const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);


const CheckIcon = () => (
  <svg
    className="w-4 h-4 text-green-500 mr-2 flex-shrink-0"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
  </svg>
);

const plans = [
  {
    name: "Basic",
    price: "Free",
    description: "Perfect for students just starting their job search",
    features: [
      "3 AI-optimized resumes per day",
      "2 job searches per day",
      "3 interview prep sessions per day",
      "Basic job market insights",
      "Email support"
    ],
    upgradeReason: "Great for trying out our services and getting a feel for the platform.",
    limits: {
      ai_resume_limit: 3,
      job_search_limit: 2,
      interview_prep_limit: 3
    }
  },
  {
    name: "Scholar",
    price: "9.99",
    description: "Ideal for active job seekers applying to multiple positions",
    features: [
      "10 AI-optimized resumes per day",
      "15 job searches per day",
      "10 interview prep sessions per day",
      "Advanced job market analytics",
      "Priority email support"
    ],
    upgradeReason: "Upgrade to Scholar for more opportunities to tailor your applications and practice interviews, increasing your chances of landing your dream internship or entry-level position.",
    limits: {
      ai_resume_limit: 10,
      job_search_limit: 15,
      interview_prep_limit: 10
    }
  },
  {
    name: "Pro Graduate",
    price: "19.99",
    description: "Best for soon-to-be graduates maximizing their job prospects",
    features: [
      "25 AI-optimized resumes per day",
      "50 job searches per day",
      "25 interview prep sessions per day",
      "Comprehensive job market insights and salary data",
      "24/7 priority support"
    ],
    upgradeReason: "Choose Pro Graduate to get the full power of our platform. With extensive resources and personalized guidance, you'll be fully equipped to stand out in the competitive job market and secure top opportunities.",
    limits: {
      ai_resume_limit: 25,
      job_search_limit: 50,
      interview_prep_limit: 25
    }
  }
];

const PlanOption = ({ plan, isCurrentPlan, onSelect }) => (
  <div className={`flex flex-col border rounded-lg overflow-hidden shadow-lg ${isCurrentPlan ? 'border-primary' : ''}`}>
    <div className="p-6 bg-white">
      <h2 className="text-2xl font-semibold mb-2">{plan.name}</h2>
      <p className="text-gray-600 mb-4">{plan.description}</p>
      <p className="text-4xl font-bold mb-6">{plan.price === "Free" ? "Free" : `$${plan.price}/mo`}</p>
      <ul className="space-y-2 mb-6">
        {plan.features.map((feature) => (
          <li key={feature} className="flex items-center">
            <CheckIcon />
            <span>{feature}</span>
          </li>
        ))}
      </ul>
    </div>
    <div className="p-6 bg-gray-50 mt-auto">
      <button
        onClick={() => onSelect(plan)}
        className={`w-full py-2 px-4 rounded-md font-semibold transition-colors ${
          isCurrentPlan
            ? 'bg-gray-200 text-gray-800 hover:bg-gray-300'
            : 'bg-primary text-white hover:bg-primary/90'
        }`}
      >
        {isCurrentPlan ? "Current Plan" : "Select Plan"}
      </button>
    </div>
  </div>
);

const PaymentForm = ({ selectedPlan, onBack, userId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentError, setPaymentError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    setPaymentError(null);

    try {
      // For free plan, skip payment processing
      if (selectedPlan.price !== "Free") {
        if (!stripe || !elements) {
          throw new Error("Stripe has not been initialized");
        }

        const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
        });

        if (stripeError) {
          throw new Error(stripeError.message);
        }

        console.log("Payment method created:", paymentMethod.id);
      }

      // Calculate subscription dates
      const startDate = new Date();
      const endDate = new Date(startDate);
      endDate.setMonth(endDate.getMonth() + 1);

      // Update Supabase with new subscription details
      const { data, error } = await supabase
        .from('subscriptions')
        .update({
          plan_name: selectedPlan.name === "Pro Graduate" ? "pro" : selectedPlan.name.toLowerCase(),
          start_date: startDate.toISOString(),
          end_date: endDate.toISOString(),
          is_active: true,
          ai_resume_limit: selectedPlan.limits.ai_resume_limit,
          job_search_limit: selectedPlan.limits.job_search_limit,
          interview_prep_limit: selectedPlan.limits.interview_prep_limit
        })
        .eq('user_id', userId);

      if (error) throw error;

      console.log("Subscription updated:", data);
      
      toast.success("Your plan has been updated successfully!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      // Redirect to dashboard after 3 seconds
      setTimeout(() => {
        navigate('/dashboard');
      }, 3000);

    } catch (error) {
      console.error("Error processing request:", error);
      setPaymentError(error.message);
      toast.error("Failed to update plan. Please try again.");
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-bold text-center mb-6">Complete Your Purchase</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-2">Selected Plan</h3>
          <div className="flex justify-between items-center p-4 bg-gray-100 rounded-md">
            <span>{selectedPlan.name} Plan</span>
            <span className="font-bold">{selectedPlan.price === "Free" ? "Free" : `$${selectedPlan.price}/month`}</span>
          </div>
        </div>
        
        {selectedPlan.price !== "Free" && (
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-4">Card Details</h3>
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#424770',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
              }}
            />
          </div>
        )}

        {paymentError && (
          <div className="text-red-500 mb-4">{paymentError}</div>
        )}

        <div className="flex justify-between items-center mt-8">
          <button
            type="button"
            onClick={onBack}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors"
          >
            Back
          </button>
          <button
            type="submit"
            disabled={isProcessing || (selectedPlan.price !== "Free" && !stripe)}
            className={`px-6 py-2 bg-primary text-white rounded-md hover:bg-primary/90 transition-colors ${
              (isProcessing || (selectedPlan.price !== "Free" && !stripe)) ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {isProcessing ? 'Processing...' : (selectedPlan.price === "Free" ? "Confirm" : `Pay $${selectedPlan.price}`)}
          </button>
        </div>
      </form>
    </div>
  );
};

export default function PricingPage() {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const fetchUserAndPlan = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) throw new Error("No user found");

        // Fetch the user's ID from the users table
        const { data: userData, error: userError } = await supabase
          .from('users')
          .select('id')
          .eq('email', user.email)
          .single();

        if (userError) throw userError;

        setUserId(userData.id);

        // Now use this ID to fetch the subscription
        const { data: subscriptionData, error: subscriptionError } = await supabase
          .from('subscriptions')
          .select('plan_name')
          .eq('user_id', userData.id)
          .single();

        if (subscriptionError) {
          // If no subscription exists, create a basic one
          if (subscriptionError.code === 'PGRST116') {
            const { data: newSubscription, error: createError } = await supabase
              .from('subscriptions')
              .insert({
                user_id: userData.id,
                plan_name: 'basic',
                start_date: new Date().toISOString(),
                end_date: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString(),
                is_active: true,
                ai_resume_limit: plans[0].limits.ai_resume_limit,
                job_search_limit: plans[0].limits.job_search_limit,
                interview_prep_limit: plans[0].limits.interview_prep_limit
              })
              .single();

            if (createError) throw createError;
            subscriptionData = newSubscription;
          } else {
            throw subscriptionError;
          }
        }

        if (subscriptionData) {
          switch (subscriptionData.plan_name) {
            case 'basic':
              setCurrentPlan('Basic');
              break;
            case 'scholar':
              setCurrentPlan('Scholar');
              break;
            case 'pro':
              setCurrentPlan('Pro Graduate');
              break;
            default:
              setCurrentPlan('Basic');
          }
        } else {
          setCurrentPlan('Basic');
        }
      } catch (error) {
        console.error("Error fetching user and plan:", error);
        setCurrentPlan('Basic');
      }
    };

    fetchUserAndPlan();
  }, []);

  const handlePlanSelect = (plan) => {
    if (plan.name === currentPlan) {
      // User clicked on current plan
      return;
    }
    setSelectedPlan(plan);
  };

  return (
    <main className="flex-1 flex gap-8 overflow-y-auto bg-white" style={{ fontFamily: "poppins" }}>
      <MinimalSidebar />
      <div className="container mx-auto px-8 py-16">
        {!selectedPlan ? (
          <>
            <h1 className="text-4xl font-bold text-center mb-4">Choose Your Plan</h1>
            <p className="text-xl text-center text-gray-600 mb-12">
              Select the perfect plan for your needs and scale as you grow
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {plans.map((plan) => (
                <PlanOption
                  key={plan.name}
                  plan={plan}
                  isCurrentPlan={currentPlan === plan.name}
                  onSelect={handlePlanSelect}
                />
              ))}
            </div>
          </>
        ) : (
          <Elements stripe={stripePromise}>
            <PaymentForm selectedPlan={selectedPlan} onBack={() => setSelectedPlan(null)} userId={userId} />
          </Elements>
        )}
      </div>
      <ToastContainer />
    </main>
  );
}