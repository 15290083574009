// resume_keywords.js

const resumeKeywords = {
  actionVerbs: [
    "Achieved", "Accelerated", "Accomplished", "Acquired", "Adapted", "Addressed", "Administered", "Advanced",
    "Advised", "Advocated", "Allocated", "Analyzed", "Approved", "Arbitrated", "Arranged", "Assembled",
    "Assessed", "Assigned", "Assisted", "Attained", "Audited", "Authored", "Automated", "Balanced",
    "Boosted", "Briefed", "Budgeted", "Built", "Calculated", "Catalogued", "Chaired", "Championed",
    "Changed", "Clarified", "Coached", "Collaborated", "Communicated", "Compiled", "Completed", "Composed",
    "Computed", "Conceptualized", "Conducted", "Conserved", "Consolidated", "Constructed", "Consulted", "Contacted",
    "Contributed", "Controlled", "Converted", "Coordinated", "Counseled", "Created", "Cultivated", "Customized",
    "Debugged", "Decreased", "Defined", "Delegated", "Delivered", "Demonstrated", "Designed", "Determined",
    "Developed", "Devised", "Diagnosed", "Directed", "Discovered", "Dispatched", "Distributed", "Documented",
    "Doubled", "Drafted", "Edited", "Educated", "Eliminated", "Empowered", "Enabled", "Encouraged",
    "Engineered", "Enhanced", "Ensured", "Established", "Evaluated", "Examined", "Exceeded", "Executed",
    "Expanded", "Expedited", "Experienced", "Experimented", "Explained", "Explored", "Facilitated", "Finalized",
    "Forecasted", "Formulated", "Founded", "Generated", "Guided", "Hypothesized", "Identified", "Illustrated",
    "Implemented", "Improved", "Increased", "Influenced", "Informed", "Initiated", "Innovated", "Inspected",
    "Inspired", "Installed", "Instituted", "Instructed", "Integrated", "Interpreted", "Interviewed", "Introduced",
    "Invented", "Investigated", "Launched", "Lectured", "Led", "Leveraged", "Maintained", "Managed",
    "Marketed", "Maximized", "Measured", "Mediated", "Mentored", "Modeled", "Modified", "Monitored",
    "Motivated", "Negotiated", "Operated", "Optimized", "Organized", "Originated", "Overhauled", "Oversaw",
    "Participated", "Performed", "Persuaded", "Planned", "Practiced", "Prepared", "Presented", "Presided",
    "Prioritized", "Processed", "Produced", "Programmed", "Projected", "Promoted", "Proposed", "Provided",
    "Published", "Purchased", "Recommended", "Reconciled", "Recorded", "Recruited", "Reduced", "Refined",
    "Reengineered", "Referred", "Reformed", "Reinforced", "Rejuvenated", "Renovated", "Reorganized", "Repaired",
    "Replaced", "Reported", "Represented", "Researched", "Resolved", "Responded", "Restored", "Restructured",
    "Revamped", "Reviewed", "Revised", "Revitalized", "Saved", "Scheduled", "Screened", "Simplified",
    "Simulated", "Sketched", "Solicited", "Solved", "Spearheaded", "Standardized", "Stimulated", "Streamlined",
    "Strengthened", "Structured", "Studied", "Supervised", "Supported", "Surveyed", "Synthesized", "Systematized",
    "Taught", "Tested", "Trained", "Translated", "Troubleshot", "Tutored", "Upgraded", "Utilized",
    "Validated", "Verified", "Visualized", "Volunteered", "Wrote"
  ],

  industryKeywords: [
    "Agile", "Analytics", "API", "Artificial Intelligence", "Automation", "Big Data", "Blockchain", "Brand Management",
    "Business Intelligence", "Cloud Computing", "Content Marketing", "Customer Relationship Management", "Cybersecurity",
    "Data Analysis", "Data Mining", "Data Visualization", "Database Management", "Digital Marketing", "E-commerce",
    "Financial Analysis", "Full Stack Development", "Growth Hacking", "Human Resources Information System", "Information Security",
    "Internet of Things", "Machine Learning", "Market Research", "Mobile Development", "Network Administration",
    "Product Management", "Project Management", "Quality Assurance", "Risk Management", "Sales Force Automation",
    "Search Engine Optimization", "Social Media Marketing", "Software Development", "Statistical Analysis", "Supply Chain Management",
    "Systems Analysis", "User Experience Design", "User Interface Design", "Version Control", "Virtualization",
    "Web Analytics", "Web Development"
  ],

  softSkills: [
    "Adaptability", "Collaboration", "Communication", "Creativity", "Critical Thinking", "Decision Making",
    "Emotional Intelligence", "Empathy", "Flexibility", "Initiative", "Innovation", "Leadership",
    "Multitasking", "Negotiation", "Organization", "Problem Solving", "Teamwork", "Time Management"
  ],

  technicalSkills: [
    "Adobe Creative Suite", "Android Development", "Angular", "AWS", "Azure", "C++", "CSS", "Docker",
    "Excel", "Git", "HTML", "iOS Development", "Java", "JavaScript", "Kubernetes", "Linux",
    "MATLAB", "MongoDB", "MySQL", "Node.js", "NoSQL", "Oracle", "PHP", "PowerBI", "Python",
    "R", "React", "Ruby on Rails", "SAS", "SPSS", "SQL", "Swift", "Tableau", "TensorFlow",
    "TypeScript", "Unity", "Unreal Engine", "Vue.js"
  ],

  certifications: [
    "AWS Certified Solutions Architect", "Certified Information Systems Security Professional (CISSP)",
    "Certified Public Accountant (CPA)", "Certified ScrumMaster (CSM)", "Chartered Financial Analyst (CFA)",
    "Cisco Certified Network Associate (CCNA)", "CompTIA A+", "Google Analytics Certification",
    "ITIL Foundation", "Microsoft Certified: Azure Solutions Architect", "Oracle Certified Professional (OCP)",
    "PMI Project Management Professional (PMP)", "Salesforce Certified Administrator",
    "Six Sigma Green Belt", "SHRM Certified Professional (SHRM-CP)"
  ]
};

export default resumeKeywords;
