import logo from './logo.svg';
import './App.css';
import React from 'react';
import './index.css'; 
import Home from './pages/Home';
import AppRouter from "./AppRouter";
   



import ReactGA from "react-ga4";

ReactGA.initialize("G-H8N5V1ZNCT");



function App() {
  return <AppRouter />;
}

export default App;