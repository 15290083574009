import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Check, X } from 'lucide-react';

const BulletPointSelector = ({
  isOpen,
  onClose,
  suggestions,
  onAcceptSuggestion,
  onRejectSuggestion,
  isLoading,
  type = 'experience',
}) => {
  useEffect(() => {
    if (!isOpen) return;

    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEsc);

    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  // Context-specific text
  const contextText = {
    experience: {
      title: 'Professional Experience',
      loadingTitle: 'Analyzing Your Experience',
      loadingDescription:
        "We're reviewing your work experience to generate impactful professional bullet points...",
      resultsTitle: 'Generated Experience Bullet Points',
      resultsDescription:
        "Select the professional achievements you'd like to add to your experience",
    },
    leadership: {
      title: 'Leadership Experience',
      loadingTitle: 'Analyzing Your Leadership',
      loadingDescription:
        "We're reviewing your leadership experience to generate impactful accomplishment bullet points...",
      resultsTitle: 'Generated Leadership Bullet Points',
      resultsDescription:
        "Select the leadership achievements you'd like to add to your accomplishments",
    },
  };

  // Get the appropriate text based on type
  const getText = (key) => {
    const defaultText = contextText.experience[key];
    return contextText[type]?.[key] || defaultText;
  };

  // Validate and filter suggestions
  const validSuggestions = Array.isArray(suggestions)
    ? suggestions.filter(
        (suggestion) =>
          typeof suggestion === 'string' && suggestion.trim() !== ''
      )
    : [];

  // Handlers
  const handleAccept = (suggestion) => {
    try {
      onAcceptSuggestion(suggestion);
    } catch (error) {
      console.error('Error accepting suggestion:', error);
    }
  };

  const handleReject = (suggestion) => {
    try {
      onRejectSuggestion(suggestion);
    } catch (error) {
      console.error('Error rejecting suggestion:', error);
    }
  };

  const handleClose = () => {
    try {
      onClose();
    } catch (error) {
      console.error('Error closing selector:', error);
    }
  };

  return createPortal(
    <div
      className="fixed inset-0 z-[1055] flex items-center justify-center overflow-y-auto overflow-x-hidden bg-black bg-opacity-50 outline-none focus:outline-none"
      onClick={(e) => e.target === e.currentTarget && handleClose()}
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <div className="relative w-auto max-w-2xl mx-auto my-6">
        {/* Modal content */}
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/* Header */}
          <div className="flex items-start justify-between p-5 border-b border-gray-200 rounded-t">
            <h3 id="modal-title" className="text-xl font-semibold">
              {isLoading ? getText('loadingTitle') : getText('resultsTitle')}
            </h3>
            <button
              onClick={handleClose}
              className="p-1 ml-auto bg-transparent border-0 text-gray-500 leading-none font-semibold outline-none focus:outline-none"
              aria-label="Close modal"
            >
              <X className="h-6 w-6" />
            </button>
          </div>
          {/* Body */}
          <div className="relative p-6 flex-auto">
            {isLoading ? (
              <div className="text-center">
                <p id="modal-description" className="text-gray-600 mb-6">
                  {getText('loadingDescription')}
                </p>
                <div className="flex justify-center py-8">
                  <div className="animate-spin rounded-full h-12 w-12 border-4 border-blue-600 border-t-transparent"></div>
                </div>
              </div>
            ) : (
              <>
                <p id="modal-description" className="text-gray-600 mb-4">
                  {getText('resultsDescription')}
                </p>
                {validSuggestions.length > 0 ? (
                  <ul className="space-y-4">
                    {validSuggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        className="flex items-start gap-4 p-4 rounded-lg border border-gray-200"
                      >
                        <p className="flex-1 text-gray-800">{suggestion}</p>
                        <div className="flex gap-2">
                          <button
                            onClick={() => handleAccept(suggestion)}
                            className="min-w-[36px] h-9 p-2 bg-green-50 text-green-600 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-green-500"
                            aria-label="Accept suggestion"
                            title="Accept"
                          >
                            <Check className="h-5 w-5" />
                          </button>
                          <button
                            onClick={() => handleReject(suggestion)}
                            className="min-w-[36px] h-9 p-2 bg-red-50 text-red-600 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-red-500"
                            aria-label="Reject suggestion"
                            title="Reject"
                          >
                            <X className="h-5 w-5" />
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="flex flex-col items-center justify-center h-full text-center text-gray-500">
                    <p>No suggestions available at this time.</p>
                  </div>
                )}
              </>
            )}
          </div>
          {/* Footer */}
          <div className="flex items-center justify-end p-6 border-t border-gray-200 rounded-b">
            <button
              onClick={handleClose}
              className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default BulletPointSelector;
