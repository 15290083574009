import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../comp/Sidebar';
import { generateCoverLetter } from '../comp/AICoverLetter';

import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from '../comp/ui/card';
import { RadioGroup, RadioGroupItem } from '../comp/ui/radio-group';
import { Label } from '../comp/ui/label';
import { Button } from '../comp/ui/button';
import { Input } from '../comp/ui/input';
import { Textarea } from '../comp/ui/textarea';
import { ChevronDown, Pen, Sparkles, FileText, Shield, Brain, Target } from "lucide-react";
import ReactModal from 'react-modal';


import { History, FileDown, Eye } from 'lucide-react';
import { downloadCoverLetterPDF } from '../comp/CoverLetterDownloadPDF';
import { generateCoverLetterWord } from '../comp/CoverLetterDownloadWord';

const CoverLetterCreator = () => {
  const [selectedResume, setSelectedResume] = useState(null);
  const [resumes, setResumes] = useState([]);
  const [resumeLimit, setResumeLimit] = useState(10);
  const [jobTitle, setJobTitle] = useState('');
  const [company, setCompany] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchResumes();
  }, [resumeLimit]);

  const [selectedResumeData, setSelectedResumeData] = useState(null);
// Add these state variables in the CoverLetterCreator component
const [coverLetterHistory, setCoverLetterHistory] = useState([]);
const [isHistoryOpen, setIsHistoryOpen] = useState(false);
const [historyLoading, setHistoryLoading] = useState(false);

  const generateUniqueCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const codeLength = 8;
    let code = '';
    
    for (let i = 0; i < codeLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters.charAt(randomIndex);
    }
    
    return code;
  };

// Add this function to fetch cover letter history
const fetchCoverLetterHistory = async () => {
  setHistoryLoading(true);
  try {
    const { data: { user } } = await supabase.auth.getUser();
    const { data: coverLetters, error: coverLetterError } = await supabase
      .from('cover_letters')
      .select(`
        *,
        resume_id,
        resumes (
          personal_information
        )
      `)
      .eq('user_id', user.id)
      .order('created_at', { ascending: false });

    if (coverLetterError) throw coverLetterError;
    setCoverLetterHistory(coverLetters);
  } catch (error) {
    console.error('Error fetching cover letter history:', error);
    toast.error('Failed to load cover letter history');
  } finally {
    setHistoryLoading(false);
  }
};


// Add useEffect to fetch history when modal opens
useEffect(() => {
  if (isHistoryOpen) {
    fetchCoverLetterHistory();
  }
}, [isHistoryOpen]);

const deleteCoverLetter = async (id) => {
  try {
    const { error } = await supabase
      .from('cover_letters')
      .delete()
      .eq('id', id);

    if (error) throw error;
    
    fetchCoverLetterHistory();
    toast.success('Cover letter deleted successfully');
  } catch (error) {
    console.error('Error deleting cover letter:', error);
    toast.error('Failed to delete cover letter');
  }
};

const formatDate = (date) => {
  return new Date(date).toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

  const fetchResumes = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const { data, error } = await supabase
        .from('resumes')
        .select('*')
        .eq('user_id', user.id)
        .eq('visibility', true)
        .order('created_at', { ascending: false })
        .limit(resumeLimit);

      if (error) throw error;
      setResumes(data);
    } catch (error) {
      console.error('Error fetching resumes:', error);
      toast.error('Failed to load resumes. Please try again.');
    }
  };

  const handleResumeSelect = async (resumeId) => {
    setSelectedResume(resumeId);
    try {
      const { data, error } = await supabase
        .from('resumes')
        .select('*')
        .eq('id', resumeId)
        .single();

      if (error) throw error;
      setSelectedResumeData(data);
    } catch (error) {
      console.error('Error fetching resume data:', error);
      toast.error('Failed to load resume data');
    }
  };
  const handleLoadMoreResumes = () => {
    setResumeLimit(prevLimit => prevLimit + 10);
  };

  const isFormComplete = () => {
    return selectedResume && jobTitle && company && jobDescription;
  };

  const handleCreateCoverLetter = async (e) => {
    e.preventDefault();
    
    if (!isFormComplete()) {
      toast.error('Please fill in all required fields');
      return;
    }

    setIsLoading(true);
    
    try {
      // Get current user
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;

      // Generate cover letter name
      const coverLetterName = `Cover Letter - ${company} - ${jobTitle}`;
      const uniqueCode = generateUniqueCode();

      // Prepare job details
      const jobDetails = {
        title: jobTitle,
        company: company,
        description: jobDescription
      };

      // Generate cover letter using AI
      const generatedCoverLetter = await generateCoverLetter(selectedResumeData, jobDetails);

      // Save to database
      const { data: coverLetterData, error: saveError } = await supabase
        .from('cover_letters')
        .insert([
          {
            user_id: user.id,
            resume_id: selectedResume,
            name: coverLetterName,
            unique_code: uniqueCode,
            job_title: jobTitle,
            company_name: company,
            job_description: jobDescription,
            cover_letter_content: generatedCoverLetter,
            visibility: true
          }
        ])
        .select()
        .single();

      if (saveError) throw saveError;

      toast.success('Cover letter generated successfully!');
      
      // Navigate to the display page
      navigate(`/cover-letter-display?code=${uniqueCode}`);

    } catch (error) {
      console.error('Error creating cover letter:', error);
      toast.error(error.message || 'Failed to generate cover letter');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100" style={{ fontFamily: "Poppins" }}>
      <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />

      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          {isLoading && (
            <div class="loading-container">
              <div class="spinner"></div>
              <div class="loading-text">Loading...</div>
            </div>
          )}
          <div className="container mx-auto p-4">
            <header className="text-center mb-4">
              <h1 className="text-3xl font-bold mb-2">
                AI Cover Letter Creator
              </h1>
              <p className="text-gray-600">
                Create personalized, professional cover letters that sound
                authentically human
              </p>
            </header>

            <div className="flex justify-center items-center p-4">
              <Card className="w-full max-w-6xl">
                <CardContent className="grid md:grid-cols-2 gap-6">
                  <div className="space-y-4">
                    <h3 className="text-xl font-semibold flex items-center gap-2">
                      <Sparkles className="h-6 w-6 text-primary" />
                      Key Features
                    </h3>
                    <ul className="space-y-2">
                      <li className="flex items-center gap-2">
                        <Brain className="h-5 w-5 text-primary" />
                        <span>AI-powered, human-like writing</span>
                      </li>
                      <li className="flex items-center gap-2">
                        <Target className="h-5 w-5 text-primary" />
                        <span>Tailored to specific job requirements</span>
                      </li>
                      <li className="flex items-center gap-2">
                        <FileText className="h-5 w-5 text-primary" />
                        <span>Matches your resume's tone and style</span>
                      </li>
                      <li className="flex items-center gap-2">
                        <Shield className="h-5 w-5 text-primary" />
                        <span>Passes AI detection tools</span>
                      </li>
                    </ul>
                  </div>
                  <div className="space-y-4">
                    <h3 className="text-xl font-semibold flex items-center gap-2">
                      <Pen className="h-6 w-6 text-primary" />
                      What Makes It Human
                    </h3>
                    <ul className="space-y-2">
                      <li className="flex items-center gap-2">
                        <span>
                          • Natural language patterns and tone variations
                        </span>
                      </li>
                      <li className="flex items-center gap-2">
                        <span>• Personalized experiences and achievements</span>
                      </li>
                      <li className="flex items-center gap-2">
                        <span>• Company-specific research integration</span>
                      </li>
                      <li className="flex items-center gap-2">
                        <span>
                          • Unique value propositions for each application
                        </span>
                      </li>
                    </ul>
                  </div>
                </CardContent>
              </Card>
            </div>

            <div className="container mx-auto max-w-6xl">
              <div className="flex justify-end">
                <Button
                  variant="outline"
                  className="flex items-center gap-2 mb-4"
                  onClick={() => setIsHistoryOpen(true)}
                >
                  <History className="h-5 w-5" />
                  View Cover Letter History
                </Button>

                {/* React Modal Component */}
                <ReactModal
                  isOpen={isHistoryOpen}
                  onRequestClose={() => setIsHistoryOpen(false)}
                  contentLabel="Cover Letter History"
                  style={{
                    overlay: {
                      backgroundColor: "rgba(0, 0, 0, 0.75)",
                      zIndex: 1000,
                    },
                    content: {
                      top: "50%",
                      left: "50%",
                      right: "auto",
                      bottom: "auto",
                      marginRight: "-50%",
                      transform: "translate(-50%, -50%)",
                      maxWidth: "1000px",
                      width: "90%",
                      maxHeight: "80vh",
                      overflowY: "auto",
                    },
                  }}
                >
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold">Cover Letter History</h2>
                    <button
                      onClick={() => setIsHistoryOpen(false)}
                      className="text-gray-500 hover:text-gray-700 focus:outline-none"
                    >
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <line
                          x1="18"
                          y1="6"
                          x2="6"
                          y2="18"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <line
                          x1="6"
                          y1="6"
                          x2="18"
                          y2="18"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="space-y-4">
                    {historyLoading ? (
                      <div className="text-center py-4">
                        Loading cover letters...
                      </div>
                    ) : coverLetterHistory.length === 0 ? (
                      <div className="text-center py-4">
                        No cover letters found
                      </div>
                    ) : (
                      coverLetterHistory.map((coverLetter) => (
                        <Card key={coverLetter.id} className="p-4">
                          <div className="flex justify-between items-start">
                            <div>
                              <h3 className="font-semibold">
                                {coverLetter.name}
                              </h3>
                              <p className="text-sm text-gray-500">
                                Company: {coverLetter.company_name}
                              </p>
                              <p className="text-sm text-gray-500">
                                Job Title: {coverLetter.job_title}
                              </p>
                              <p className="text-sm text-gray-500">
                                Created: {formatDate(coverLetter.created_at)}
                              </p>
                            </div>
                            <div className="flex gap-2">
                              <Button
                                variant="outline"
                                size="sm"
                                onClick={() =>
                                  downloadCoverLetterPDF(
                                    coverLetter,
                                    coverLetter.resumes
                                  )
                                }
                                title="Download as PDF"
                              >
                                <FileDown className="h-4 w-4" />
                                PDF
                              </Button>
                              <Button
                                variant="outline"
                                size="sm"
                                onClick={() =>
                                  generateCoverLetterWord(
                                    coverLetter,
                                    coverLetter.resumes
                                  )
                                }
                                title="Download as Word"
                              >
                                <FileDown className="h-4 w-4" />
                                Word
                              </Button>
                              <Button
                                variant="outline"
                                size="sm"
                                onClick={() =>
                                  navigate(
                                    `/cover-letter-display?code=${coverLetter.unique_code}`
                                  )
                                }
                                title="View"
                              >
                                <Eye className="h-4 w-4" />
                                View
                              </Button>
                            </div>
                          </div>
                          <div className="mt-4">
                            <div className="max-h-32 overflow-y-auto text-sm text-gray-600 bg-gray-50 p-3 rounded">
                              {coverLetter.cover_letter_content
                                .split("\n")
                                .map((paragraph, index) => (
                                  <p key={index} className="mb-2">
                                    {paragraph}
                                  </p>
                                ))}
                            </div>
                          </div>
                        </Card>
                      ))
                    )}
                  </div>
                </ReactModal>
              </div>
            </div>

            <form
              onSubmit={handleCreateCoverLetter}
              className="flex flex-col lg:flex-row gap-8"
            >
              <div className="w-full lg:w-1/2">
                <Card className="h-full">
                  <CardHeader>
                    <CardTitle>Select Your Resume</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <RadioGroup
                      className="h-96 overflow-y-scroll "
                      value={selectedResume || ""}
                      onValueChange={handleResumeSelect}
                    >
                      {resumes.map((resume) => (
                        <div
                          key={resume.id}
                          className="flex items-start space-x-2 mb-4"
                        >
                          <RadioGroupItem
                            value={resume.id}
                            id={`resume-${resume.id}`}
                          />
                          <Label
                            htmlFor={`resume-${resume.id}`}
                            className="flex-grow"
                          >
                            <p
                              className="block text-l font-large text-black"
                              style={{ fontSize: "16px" }}
                            >
                              {resume.name || "Untitled Resume"}
                            </p>
                            <p className="text-sm text-gray-500">
                              Created:{" "}
                              {new Date(resume.created_at).toLocaleDateString()}
                            </p>
                          </Label>
                        </div>
                      ))}
                    </RadioGroup>
                    {resumes.length >= resumeLimit && (
                      <Button
                        type="button"
                        variant="outline"
                        className="mt-4 w-full flex text-center align-middle justify-center"
                        onClick={handleLoadMoreResumes}
                      >
                        Load More Resumes{" "}
                        <ChevronDown className="ml-2 h-4 w-4" />
                      </Button>
                    )}
                  </CardContent>
                </Card>
              </div>

              <div className="w-full lg:w-1/2">
                <Card className="h-full">
                  <CardHeader>
                    <CardTitle>Job Details</CardTitle>
                  </CardHeader>
                  <CardContent className="space-y-4">
                    <div className="space-y-2">
                      <Label htmlFor="jobTitle">Job Title</Label>
                      <Input
                        id="jobTitle"
                        value={jobTitle}
                        onChange={(e) => setJobTitle(e.target.value)}
                        required
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="company">Company Name</Label>
                      <Input
                        id="company"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        required
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="jobDescription">Job Description</Label>
                      <Textarea
                        id="jobDescription"
                        value={jobDescription}
                        onChange={(e) => setJobDescription(e.target.value)}
                        className="min-h-[150px] h-24"
                        required
                        placeholder="Paste the job description here..."
                      />
                      <p className="text-sm text-gray-500 mt-1">
                        {jobDescription.length} / 500 characters
                      </p>
                      {jobDescription.length < 500 && (
                        <p className="text-sm text-red-500 mt-1">
                          Please enter at least 500 characters.
                        </p>
                      )}
                    </div>
                  </CardContent>
                </Card>
              </div>
            </form>

            <Button
              type="submit"
              className="w-full mt-8"
              onClick={handleCreateCoverLetter}
              disabled={
                !isFormComplete() || isLoading || jobDescription.length < 500
              }
            >
              {isLoading ? "Creating..." : "Generate Cover Letter"}
            </Button>
          </div>
        </main>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CoverLetterCreator;