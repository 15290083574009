import React from "react";
import { Link } from "react-router-dom";


const Error = () => {


    return (
        <div className="flex min-h-[100dvh] flex-col items-center justify-center bg-background px-4 py-12 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-md text-center">
          <div className="text-9xl font-bold text-primary">404</div>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-foreground sm:text-4xl">
            Oops, the page you were looking for doesn't exist.
          </h1>
          <p className="mt-4 text-muted-foreground">
            It looks like you've stumbled upon a page that doesn't exist. Don't worry, we'll help you find your way back.
          </p>
          <div className="mt-6">
            <Link
              to={"/"}
              className="inline-flex items-center rounded-md bg-primary px-4 py-2 text-sm font-medium text-primary-foreground shadow-sm transition-colors hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
              prefetch={false}
            >
              Go to Homepage
            </Link>
          </div>
        </div>
      </div>
    );
    };

export default Error;