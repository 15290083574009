import React, { useState, useEffect } from "react";
import MinimalSidebar from "../comp/MinimalSidebar";
import IssueReportButton from "../comp/IssueReportButton";

const JobSearch = () => {
    const [selectedJob, setSelectedJob] = useState(null);
    const [jobs, setJobs] = useState([]);
    const [displayedJobs, setDisplayedJobs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showDescription, setShowDescription] = useState(false);
    const [visibleCount, setVisibleCount] = useState(8);

    useEffect(() => {
        fetchJobs();
    }, []);

    useEffect(() => {
        setDisplayedJobs(jobs.slice(0, visibleCount));
    }, [jobs, visibleCount]);

    const fetchJobs = async () => {
        try {
            setIsLoading(true);
            const response = await fetch('http://localhost:8000/internships');
            if (!response.ok) {
                throw new Error('Failed to fetch jobs');
            }
            const data = await response.json();

            console.log(data);
            setJobs(data);
        } catch (error) {
            console.error('Error fetching jobs:', error);
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleJobClick = (job) => {
        setSelectedJob(job);
        setShowDescription(false);
    };

    const handleLoadMore = () => {
        setVisibleCount(visibleCount + 20);
    };

    if (isLoading) {
        return <div className="flex justify-center items-center h-screen">Loading...</div>;
    }

    if (error) {
        return <div className="flex justify-center items-center h-screen">Error: {error}</div>;
    }


    return (
        <div className="flex" style={{fontFamily:"Poppins"}}>
            <div className="pro hidden md:block">
                <MinimalSidebar />
                <IssueReportButton />
            </div>



            
            <div className="w-full md:w-7/8 bg-white">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 p-6 md:p-10">
                    <div className="md:col-span-1 border-2 border-bg-card rounded-lg shadow-md overflow-hidden">
                        <div className="bg-muted px-6 py-4 text-white " style={{ background:"#267326"}}>
                            <h2 className="text-lg font-semibold">Internship Listings</h2>
                        </div>
                        <div className="divide-y">
                        {displayedJobs.map((job) => (
                                <div
                                    key={job.id}
                                    className={`px-6 py-4 cursor-pointer transition-colors hover:bg-muted ${
                                        selectedJob?.id === job.id ? "bg-muted" : ""
                                    }`}
                                    onClick={() => handleJobClick(job)}
                                >
                                    <div className="font-semibold">{job.title}</div>
                                    <div className="text-muted-foreground">{job.company}</div>
                                </div>
                            ))}
                        </div>

                        {visibleCount < jobs.length && (
                            <div className="flex justify-center py-4">
                                <button
                                    onClick={handleLoadMore}
                                    className="inline-flex items-center justify-center h-10 px-6 rounded-md bg-primary text-primary-foreground font-medium transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring"
                                >
                                    Load More
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="md:col-span-2 border-2 border-bg-card  rounded-lg shadow-md p-6 md:p-10">
                        {selectedJob ? (
                            <>
                                <h2 className="text-2xl font-bold mb-4 text-cutler-green">{selectedJob.title}</h2>
                                <div className="text-muted-foreground mb-6">Company: {selectedJob.company}</div>
                                <div className="text-muted-foreground mb-6">Location: {selectedJob.location}</div>
                                {selectedJob.job_highlights && selectedJob.job_highlights.length > 0 ? (
                                    selectedJob.job_highlights.map((highlight, index) => (
                                        <div key={index} className="mb-6">
                                            <h3 className="text-lg font-semibold mb-2">{highlight.title}</h3>
                                            {highlight.items && highlight.items.length > 0 ? (
                                                <ul className="list-disc pl-6 space-y-2">
                                                    {highlight.items.map((item, itemIndex) => (
                                                        <li key={itemIndex}>{item}</li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p>No details available</p>
                                            )}
                                        </div>
                                    ))
                                ) : (
                                    <p>No job highlights available</p>
                                )}
                                {selectedJob.apply_options && selectedJob.apply_options.length > 0 && (
                                    <div className="mb-6">
                                        <a
                                            href={selectedJob.apply_options[0].link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="inline-flex items-center justify-center h-10 px-6 rounded-md bg-primary text-primary-foreground font-medium transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring"
                                        >
                                            Apply Now
                                        </a>
                                    </div>
                                )}
                                <button
                                    onClick={() => setShowDescription(!showDescription)}
                                    className="inline-flex items-center justify-center h-10 px-6 rounded-md bg-secondary text-secondary-foreground font-medium transition-colors hover:bg-secondary/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring"
                                >
                                    {showDescription ? "Hide Description" : "Show Description"}
                                </button>
                                {showDescription && (
                                    <div className="mt-4">
                                        <h3 className="text-lg font-semibold mb-2">Description</h3>
                                        <p>{selectedJob.description}</p>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="text-center">
                                <p className="text-muted-foreground">Select an internship to see the details</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default JobSearch;
