import { jsPDF } from 'jspdf';

const downloadCoverLetterPDF = (coverLetterData, resumeData) => {
  if (!coverLetterData || !resumeData) {
    console.error('Missing required data');
    return;
  }

  const doc = new jsPDF();
  const lineHeight = 5;
  const leftMargin = 20;
  const rightMargin = doc.internal.pageSize.width - 20;
  const contentWidth = rightMargin - leftMargin;
  let currentY = 20; // Starting Y position

  // **Name** (Bold and Larger Font)
  const personalInfo = resumeData.personal_information || {};
  if (personalInfo.name) {
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(16); // Larger font for the name
    doc.text(personalInfo.name, doc.internal.pageSize.width / 2, currentY, { align: 'center' });
    currentY += lineHeight + 5; // Add some space after the name
  }

  // **Personal Details** (Centered on the next line, separated by '|')
  let personalDetails = '';
  if (personalInfo.phone) personalDetails += `${personalInfo.phone}`;
  if (personalInfo.email) personalDetails += ` | ${personalInfo.email}`;
  if (personalInfo.linkedin) personalDetails += ` | ${personalInfo.linkedin.replace(' https://', '')}`;

  if (personalDetails) {
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(12); // Default font size for personal details
    doc.text(personalDetails, doc.internal.pageSize.width / 2, currentY, { align: 'center' });
    currentY += lineHeight + 10; // Add space after personal details
  }

  // **Cover Letter Content**
  const contentLines = doc.splitTextToSize(coverLetterData.cover_letter_content, contentWidth);
  contentLines.forEach((line) => {
    if (currentY > doc.internal.pageSize.height - 20) {
      doc.addPage();
      currentY = 20;
    }
    doc.text(line, leftMargin, currentY);
    currentY += lineHeight;
  });

  // Save the PDF
  const fileName = `Cover_Letter_${coverLetterData.company_name.replace(/\s+/g, '_')}.pdf`;
  doc.save(fileName);
};

export { downloadCoverLetterPDF };
