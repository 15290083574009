import React from 'react';

const AIResumeCard = ({ jobLikelihoodImprovement, aiChanges, improvementSuggestions }) => {


  // Helper function to process and render AI changes
  const renderAIChanges = (changes) => {
    if (!Array.isArray(changes)) return null;

    return changes.map((change, index) => {
      // Split the change into number, section title, and content
      const match = change.match(/^(\d+)\.\s*\*\*([^:]+):\*\*\s*(.+)$/);
      if (match) {
        const [, number, title, content] = match;
        return (
          <li key={index} className="list-none mb-2">
            <span className="font-semibold">{number}. <span className="font-bold">{title}:</span></span> {content}
          </li>
        );
      } else {
        // If the change doesn't follow the expected format, just render it as is
        return <li key={index} className="list-none mb-2">{change}</li>;
      }
    });
  };



  return (
    <div className="rounded-lg border bg-white shadow-sm w-full max-w-2xl mx-auto my-4" data-v0-t="card"  style={{fontFamily:'Poppins'}}        >
      <div
        className="bg-primary text-primary-foreground p-6 rounded-t-md"
        style={{ backgroundColor: "#00694E" }}
      >
        <div className="flex items-center justify-between">
          <div>
            <h2 className="text-2xl font-bold text-white">AI Resume Assessment</h2>
            <div className="text-4xl font-bold text-white mt-2">+{jobLikelihoodImprovement || 0}%</div>
          </div>
          <BriefcaseIcon className="w-12 h-12 text-white" />
        </div>
        <p className="text-sm mt-2 text-white">
          {jobLikelihoodImprovement >= 30
            ? "Your chances have significantly improved!"
            : jobLikelihoodImprovement >= 15
            ? "Your chances have improved, but there's still room for more."
            : "Your chances have improved slightly. Consider further enhancements."}
        </p>
      </div>

      <div className="p-6 border-b">
        <h3 className="text-lg font-medium mb-2">AI Changes</h3>
        <ul className="space-y-2 pl-5">
          {renderAIChanges(aiChanges)}
        </ul>
      </div>

      <div className="p-6">
        <h3 className="text-lg font-medium mb-2">Improvement Suggestions</h3>
        <div className="space-y-2">
          {improvementSuggestions.map((suggestion, index) => (
            <div key={index} className="flex items-start gap-3">
              <LightbulbIcon className="w-5 h-5 mt-1" />
              <div>
                <p className="font-medium">{suggestion.title}</p>
                <p className="text-sm text-muted-foreground">{suggestion.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};



function BriefcaseIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="20" height="14" x="2" y="7" rx="2" ry="2" />
      <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16" />
    </svg>
  );
}

function LightbulbIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M15 14c.2-1 .7-1.7 1.5-2.5 1-.9 1.5-2.2 1.5-3.5A6 6 0 0 0 6 8c0 1 .2 2.2 1.5 3.5.7.7 1.3 1.5 1.5 2.5" />
      <path d="M9 18h6" />
      <path d="M10 22h4" />
    </svg>
  );
}

export default AIResumeCard;
