import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ResumePreview from '../comp/ResumePreview';
import MinimalSidebar from '../comp/MinimalSidebar';
import IssueReportButton from '../comp/IssueReportButton';
import AIResumeCard from '../comp/AIResumeCard';
import { jsPDF } from "jspdf";


const ResumeByAI = () => {
  const [resumeData, setResumeData] = useState(null);

  console.log(resumeData);
  const [aiData, setAIData] = useState({
    jobLikelihoodImprovement: 0,
    aiChanges: [],
    improvementSuggestions: []
  });
  const [hiddenSections, setHiddenSections] = useState({});
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchResumeData = async () => {
      const params = new URLSearchParams(location.search);
      const code = params.get('code');
      
      if (code) {
        try {
          const { data, error } = await supabase
            .from('resumes_ai')
            .select('*')
            .eq('code', code)
            .single();

          if (error) throw error;
          if (!data) {
            setError('No resume found with the provided code.');
            return;
          }

          // Parse aiChanges and improvementSuggestions from JSON strings to JavaScript arrays/objects
          const aiChanges = JSON.parse(data.aiChanges || '[]');
          const improvementSuggestions = JSON.parse(data.improvementSuggestions || '[]');

          setResumeData(data);
          setAIData({
            jobLikelihoodImprovement: parseInt(data.jobLikelihoodImprovement, 10) || 0,
            aiChanges: Array.isArray(aiChanges) ? aiChanges : [],
            improvementSuggestions: Array.isArray(improvementSuggestions) ? improvementSuggestions : []
          });
          setHiddenSections({
            leadership: data.leadership_visibility == 'yes',
            awards: data.awards_visibility == 'yes',
            skills: data.skills_visibility == 'yes',
          });
        } catch (error) {
          console.error('Error fetching resume:', error);
          setError('Failed to load resume. Please try again.');
        }
      } else {
        setError('No resume code provided.');
      }
    };

    fetchResumeData();
  }, [location]);


  const handleEditMore = () => {
    if (resumeData && resumeData.code) {
      navigate(`/resume-builder?code=${resumeData.code}`);
    }
  };


  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    const lineHeight = 5;
    const leftMargin = 10;
    const rightMargin = 200 - leftMargin;
    const pageWidth = doc.internal.pageSize.width;
  
    doc.setFont("helvetica");
  
    // Personal Information
    doc.setFontSize(16);
    doc.text(resumeData.personal_information?.name, pageWidth / 2, 20, { align: "center" });
    doc.setFontSize(11);
    const contactInfo = `${resumeData.personal_information?.phone} | ${resumeData.personal_information?.email} | ${resumeData.personal_information?.linkedin}`;
    doc.text(contactInfo, pageWidth / 2, 25, { align: "center" });
  
    let currentY = 35;
  
    const checkAndAddPage = (y) => {
      if (y > doc.internal.pageSize.height - 20) {
        doc.addPage();
        return 20;
      }
      return y;
    };
  
    const renderSectionHeader = (title, y) => {
      doc.setFontSize(14);
      doc.setFont("helvetica", "bold");
      doc.text(title, leftMargin, y);
      doc.setFont("helvetica", "normal");
      doc.line(leftMargin, y + 2, rightMargin, y + 2);
      return y + 7;
    };
  
    const renderEducation = (startY) => {
      let y = renderSectionHeader("EDUCATION", startY);
      resumeData.education.forEach((edu) => {
        y = checkAndAddPage(y);
        doc.setFontSize(11);
        doc.setFont("helvetica", "bold");
        doc.text(`${edu.university}, ${edu.location}`, leftMargin, y);
        doc.setFont("helvetica", "normal");
        doc.text(edu.graduation, rightMargin, y, { align: "right" });
        y += lineHeight;
    
        doc.setFont("helvetica", "italic");
        const degreeLines = doc.splitTextToSize(edu.degree, pageWidth - 2 * leftMargin - 20); // Adding margin on the right side
        degreeLines.forEach((line) => {
          y = checkAndAddPage(y);
          doc.text(line, leftMargin, y);
          y += lineHeight;
        });
    
        doc.setFont("helvetica", "normal");
        const majorLines = doc.splitTextToSize(`Major: ${edu.major}`, pageWidth - 2 * leftMargin - 20);
        majorLines.forEach((line) => {
          y = checkAndAddPage(y);
          doc.text(line, leftMargin, y);
          y += lineHeight;
        });
    
        const gpaLines = doc.splitTextToSize(`GPA: ${edu.gpa}`, pageWidth - 2 * leftMargin - 20);
        gpaLines.forEach((line) => {
          y = checkAndAddPage(y);
          doc.text(line, leftMargin, y);
          y += lineHeight;
        });
    
        y += lineHeight * 0.5;
      });
      return y;
    };
    
  
    const renderExperience = (startY) => {
      let y = renderSectionHeader("EXPERIENCE", startY);
      resumeData.experience.forEach((exp) => {
        y = checkAndAddPage(y);
        doc.setFontSize(11);
        doc.setFont("helvetica", "bold");
        doc.text(`${exp.company}, ${exp.location}`, leftMargin, y);
        doc.setFont("helvetica", "normal");
        doc.text(exp.duration, rightMargin, y, { align: "right" });
        y += lineHeight;
    
        doc.setFont("helvetica", "italic");
        doc.text(exp.jobTitle, leftMargin, y);
        y += lineHeight;
    
        doc.setFont("helvetica", "normal");
        exp.description.forEach((desc) => {
          const lines = doc.splitTextToSize(
            /^[A-Za-z]/.test(desc) ? `• ${desc}` : desc, // Prepend bullet if it starts with a letter
            pageWidth - 2 * leftMargin - 20
          );
          lines.forEach((line) => {
            y = checkAndAddPage(y);
            doc.text(line, leftMargin + 5, y);
            y += lineHeight;
          });
        });
        y += lineHeight / 2;
      });
      return y;
    };
    
    
  
    const renderLeadership = (startY) => {
      let y = renderSectionHeader("LEADERSHIP ACTIVITIES AND MEMBERSHIPS", startY);
      resumeData.leadership.forEach((leader) => {
        y = checkAndAddPage(y);
        doc.setFontSize(11);
        doc.setFont("helvetica", "bold");
        doc.text(`${leader.organization}, ${leader.location}`, leftMargin, y);
        doc.setFont("helvetica", "normal");
        doc.text(leader.duration, rightMargin, y, { align: "right" });
        y += lineHeight;
    
        doc.setFont("helvetica", "italic");
        doc.text(leader.title, leftMargin, y);
        y += lineHeight;
    
        doc.setFont("helvetica", "normal");
        leader.accomplishment.forEach((acc) => {
          const lines = doc.splitTextToSize(
            /^[A-Za-z]/.test(acc) ? `• ${acc}` : acc, // Prepend bullet if it starts with a letter
            pageWidth - 2 * leftMargin - 20
          );
          lines.forEach((line) => {
            y = checkAndAddPage(y);
            doc.text(`${line}`, leftMargin + 5, y);
            y += lineHeight;
          });
        });
        y += lineHeight / 2;
      });
      return y;
    };
    


    const renderAwards = (startY) => {
      let y = renderSectionHeader("HONORS AND AWARDS", startY);
      resumeData.awards.forEach((award) => {
        y = checkAndAddPage(y);
        doc.setFontSize(11);
        doc.setFont("helvetica", "normal");
    
        const awardText = `${award.award} | ${award.location}`;
        const awardLines = doc.splitTextToSize(awardText, pageWidth - 2 * leftMargin - 50);
        doc.text(awardLines, leftMargin, y);
    
        const dateText = `${award.date}`;
        doc.text(dateText, rightMargin, y, { align: "right" });
    
        y += lineHeight * Math.max(awardLines.length, 1);
        y += lineHeight * 0.25; // Reduced bottom margin
      });
      return y;
    };
    
    const renderCertifications = (startY) => {
      let y = renderSectionHeader("CERTIFICATIONS", startY);
      resumeData.certifications.forEach((cert) => {
        y = checkAndAddPage(y);
        doc.setFontSize(11);
        doc.setFont("helvetica", "normal");
    
        const certText = `${cert.certification_name} | ${cert.certification_location}`;
        const certLines = doc.splitTextToSize(certText, pageWidth - 2 * leftMargin - 50);
        doc.text(certLines, leftMargin, y);
    
        const yearText = `${cert.certification_year}`;
        doc.text(yearText, rightMargin, y, { align: "right" });
    
        y += lineHeight * Math.max(certLines.length, 1);
        y += lineHeight * 0.25; // Reduced bottom margin
      });
      return y;
    };
    
    
    
  
    const renderSkills = (startY) => {
      let y = renderSectionHeader("TECHNICAL AND LANGUAGE SKILLS", startY);
      doc.setFontSize(11);
      doc.setFont("helvetica", "normal");
    
      if (resumeData.skillsVisibility?.technical && resumeData.skills?.technical) {
        y = checkAndAddPage(y);
        doc.setFont("helvetica", "bold");
        doc.text("Technical:", leftMargin, y);
        doc.setFont("helvetica", "normal");
        const techLines = doc.splitTextToSize(resumeData.skills.technical, pageWidth - 2 * leftMargin - 20);
        techLines.forEach((line) => {
          y = checkAndAddPage(y);
          doc.text(line, leftMargin + 20, y);
          y += lineHeight;
        });
        y += lineHeight;
      }
    
      if (resumeData.skillsVisibility?.languages && resumeData.skills?.languages) {
        y = checkAndAddPage(y);
        doc.setFont("helvetica", "bold");
        doc.text("Language:", leftMargin, y);
        doc.setFont("helvetica", "normal");
        const langLines = doc.splitTextToSize(resumeData.skills.languages, pageWidth - 2 * leftMargin - 20);
        langLines.forEach((line) => {
          y = checkAndAddPage(y);
          doc.text(line, leftMargin + 20, y);
          y += lineHeight;
        });
      }
    
      return y + lineHeight * 1.5;
    };
    
  
    const renderCustomSection = (startY, section) => {
      let y = renderSectionHeader(section.title, startY);
      doc.setFontSize(11);
      doc.setFont("helvetica", "normal");
      const contentLines = doc.splitTextToSize(section.content, pageWidth - 2 * leftMargin);
      contentLines.forEach((line) => {
        y = checkAndAddPage(y);
        doc.text(line, leftMargin, y);
        y += lineHeight;
      });
      return y + lineHeight;
    };
  
    const renderSection = (sectionName) => {
      currentY = checkAndAddPage(currentY);
      switch (sectionName) {
        case 'education':
          currentY = renderEducation(currentY);
          break;
        case 'experience':
          currentY = renderExperience(currentY);
          break;
        case 'leadership':
          if (!hiddenSections.leadership) currentY = renderLeadership(currentY);
          break;
        case 'awards':
          if (!hiddenSections.awards) currentY = renderAwards(currentY);
          break;
        case 'certifications':
          if (!hiddenSections.certifications) currentY = renderCertifications(currentY);
          break;
        case 'skills':
          if (!hiddenSections.skills) currentY = renderSkills(currentY);
          break;
        default:
          if (sectionName.startsWith('custom-')) {
            const index = parseInt(sectionName.split('-')[1]);
            currentY = renderCustomSection(currentY, resumeData.customSections[index]);
          }
          break;
      }
      currentY += lineHeight;
    };
  
    (resumeData.sectionOrder || [
      'education',
      'experience',
      'leadership',
      'awards',
      'certifications',
      'skills',
      ...resumeData.customSections.map((_, index) => `custom-${index}`)
    ]).forEach(renderSection);
    // Save the PDF
    doc.save(`${resumeData.name || 'Resume'}.pdf`);
  };

  const handleGoBack = () => {
    navigate('/dashboard');
  };

  if (error) {
    return <div className="text-center mt-10 text-red-500">{error}</div>;
  }

  if (!resumeData) {
    return <div className="text-center mt-10">Loading...</div>;
  }

  return (
    <div className="flex min-h-dvh bg-white" style={{ fontFamily: "Poppins" }}>
      <MinimalSidebar />
      <div className="flex-grow pl-20">
        <div className="flex">
          <div className="w-1/2 p-4">
            <AIResumeCard 
              jobLikelihoodImprovement={aiData.jobLikelihoodImprovement}
              aiChanges={aiData.aiChanges}
              improvementSuggestions={aiData.improvementSuggestions}
            />
            
            
            <div className="mt-8 flex justify-evenly space-x-4">
              <button
                onClick={handleGoBack}
                style={{fontFamily:'Poppins'}}
                className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 transition duration-300"
              >
                Go Back
              </button>
              <button
                onClick={handleEditMore}
                style={{fontFamily:'Poppins'}}
                className="bg-cutler-green text-white py-2 px-4 rounded hover:bg-darker-cutler-green transition duration-300"
              >
                Edit it more
              </button>
              <button
                onClick={handleDownloadPDF}
                style={{fontFamily:'Poppins'}}
                className="bg-cutler-green text-white py-2 px-4 rounded hover:bg-darker-cutler-green transition duration-300"
              >
                Download PDF
              </button>
            </div>

          </div>
          <div className="w-1/2 p-4">
            <ResumePreview
              formData={resumeData}
              hiddenSections={hiddenSections}
            />


<div className="mt-4 text-center">
             
            </div>
          </div>
        </div>
        <IssueReportButton />
      </div>
    </div>
  );
};

export default ResumeByAI;