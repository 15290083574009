import React, { useEffect, useState } from 'react';
import Description from './Description';
import Header from './Header';
import './style.css';
import { Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';


function HeroSection() {

  const [isLoggedIn, setIsLoggedIn] = useState(true);

  useEffect(() => {
    const checkUser = async () => {
      const {
        data: { user }
      } = await supabase.auth.getUser();
      setIsLoggedIn(!!user);
    };

    checkUser();
  }, []);


  return (
    <main className="flex flex-col justify-center items-center pt-24">
      <section className="flex flex-col justify-center items-center px-4 md:px-8 lg:px-35 py-12">
        <div className="flex flex-col justify-center items-center w-full max-w-[1069px]">
          <Header />
          <Description />
          <div className="flex flex-wrap justify-center items-center w-full text-sm leading-tight text-center">
            <div className="flex flex-col justify-center items-center self-stretch my-auto">
            <Link className="flex items-center"  to={isLoggedIn ? '/dashboard' : '/login'}>
            <button className="rounded-full py-5 px-6 md:px-9 lg:px-9 min-h-[54px] rounded-[25px] gradient" style={{fontSize:"15px"}}>
              Start Building Your Future
              </button> 
            </Link>
            
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default HeroSection;
