const { GoogleGenerativeAI } = require("@google/generative-ai");

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
const genAI = new GoogleGenerativeAI(apiKey);

const sendToGoogleAI = async (resumeData, jobDetails) => {
  const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

  const prompt = `
Given the following resume data:
${JSON.stringify(resumeData)}

And the following job details:
${JSON.stringify(jobDetails)}

Please perform the following tasks:

1. Optimize the resume for this job by thoroughly updating relevant sections to match the job requirements as closely as possible. You have the freedom to make significant changes to the experience and leadership sections. Use action verbs to start each bullet point, and avoid adverbs or adjectives. You may:
   - Modify job titles to better align with the target position
   - Completely rewrite job description bullet points to highlight relevant skills and experiences
   - Add new, relevant experiences or responsibilities that align with the job requirements (while maintaining plausibility)
   - Remove or modify existing experiences that are less relevant
   - Adjust durations of experiences if it helps to better fit the job requirements
   - Add, remove, or modify skills in the technical and language skills sections
   - Add or remove certifications based on their relevance to the job
   - Adjust the content of leadership and other sections to emphasize relevant experiences
   - Create new, relevant projects or achievements that align with the job requirements

   Do not alter personal details like name, education institutions, or graduation dates. Ensure that all bullet points in the experience and leadership sections are formatted as an array of strings, with each bullet point as a separate string in the array. Do not use any bold formatting or '**' in the text.

2. Provide a job likelihood improvement percentage (0-100) indicating how much the chances of getting this job have increased using the AI-optimized resume compared to the original resume. Ensure this percentage is realistic and typically falls between 10-75%.

3. List the key changes made to the resume (maximum 5 points).

4. Suggest improvements the candidate can make to increase their chances, such as acquiring new skills or certifications (maximum 3 suggestions).

Return the results in the following JSON format:

{
  "optimizedResume": {
    /* optimized resume data, including updated skills, certifications, and experiences */
    "experience": [
      {
        "company": "Company Name",
        "jobTitle": "Optimized Job Title",
        "location": "Location",
        "duration": "Optimized Duration",
        "description": [
          "• Optimized or new bullet point 1",
          "• Optimized or new bullet point 2",
          "• Optimized or new bullet point 3"
        ]
      }
    ],
    "leadership": [
      {
        "organization": "Organization Name",
        "title": "Optimized Title",
        "location": "Location",
        "duration": "Optimized Duration",
        "accomplishment": [
          "• Optimized or new bullet point 1",
          "• Optimized or new bullet point 2",
          "• Optimized or new bullet point 3"
        ]
      }
    ],
    "skills": {
      "technical": "Updated technical skills",
      "languages": "Updated language skills"
    },
    "certifications": [
      {
        "certification_name": "Relevant Certification Name",
        "certification_location": "Certification Location",
        "certification_year": "Year"
      }
    ]
  },
  "jobLikelihoodImprovement": 25,
  "aiChanges": [
    "Change 1: Description of significant change 1",
    "Change 2: Description of significant change 2",
    "Change 3: Description of significant change 3",
    "Change 4: Description of significant change 4",
    "Change 5: Description of significant change 5"
  ],
  "improvementSuggestions": [
    {
      "title": "Suggestion 1 Title",
      "description": "Suggestion 1 Description"
    },
    {
      "title": "Suggestion 2 Title",
      "description": "Suggestion 2 Description"
    },
    {
      "title": "Suggestion 3 Title",
      "description": "Suggestion 3 Description"
    }
  ]
}
`;

  

  try {
    const result = await model.generateContent(prompt);
    const response = await result.response;
    const responseText = response.text();
    
    // Remove any markdown formatting or extra text
    const jsonString = responseText.replace(/```json\s*|\s*```/g, '').trim();
    
    let aiResponse;
    try {
      aiResponse = JSON.parse(jsonString);
    } catch (parseError) {
      console.error("Error parsing AI response:", parseError);
      throw new Error("Failed to parse AI response");
    }
    
    return aiResponse;
  } catch (error) {
    console.error("Error in AI generation:", error);
    throw new Error("Failed to optimize resume with AI");
  }
};

module.exports = { sendToGoogleAI };