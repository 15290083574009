import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NewLogo from '../img/Logo Ai.png';
import MobileLogo from '../img/favicon.png';
import { supabase } from '../supabaseClient';

const Header = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  useEffect(() => {
    const checkUser = async () => {
      const {
        data: { user }
      } = await supabase.auth.getUser();
      setIsLoggedIn(!!user);
    };

    checkUser();
  }, []);

  // const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="z-20 fixed top-5 font-poppins left-0 right-0 px-4 rounded-2xl shadow-500 bg-white/30 backdropblur lg:px-6 h-20 flex items-center justify-between mr-10 ml-10">
      {/* Left: Logo and Name */}
      <div className="flex items-center">
        <Link className="flex items-center" to="/">
          {/* Mobile Logo */}
          <img
            className="w-auto h-5 block sm:hidden" // Visible only on mobile
            src={MobileLogo}
            alt="Mobile Logo"
            style={{ width: 'auto', height: '40px', marginTop: '0' }}
          />
          {/* Desktop Logo */}
          <img
            className="w-auto h-5 hidden sm:block" // Hidden on mobile, visible on larger screens
            src={NewLogo}
            alt="Logo"
            style={{ width: 'auto', height: '50px', marginTop: '0' }}
          />
          <span className="ml-2 font-semibold text-xl sr-only">Redesigned</span>
        </Link>
      </div>

      {/* Right: Login and Register */}
      <div className="flex font-poppinsMedium items-center space-x-8 hidden md:flex">
        <Link className="text-gray-700 text-md font-medium" to="/terms">
          Terms of Service
        </Link>
        <Link className="text-gray-700 text-md font-medium" to="/privacy">
          Privacy Policy
        </Link>
        {!isLoggedIn && (
          <Link className="text-gray-700 text-md font-medium  hidden sm:block" to="/signup">
            Get Started!
          </Link>
        )}
        <Link
          className="text-gray-900 font-semibold inline-flex h-10 items-center justify-center rounded-full border border-gray-200 bg-white px-6 text-md font-medium shadow-sm transition-colors hover:bg-gray-100 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 hidden sm:inline-flex"
          to={isLoggedIn ? '/dashboard' : '/login'}
        >
          {isLoggedIn ? 'Dashboard' : 'Login'}
          <svg
            width="10px"
            height="10px"
            viewBox="-4.5 0 20 20"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fill="#000000"
            className="inline-block ml-2"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
              {' '}
              <title>arrow_right [#336]</title> <desc>Created with Sketch.</desc> <defs> </defs>{' '}
              <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                {' '}
                <g
                  id="Dribbble-Light-Preview"
                  transform="translate(-305.000000, -6679.000000)"
                  fill="#000000"
                >
                  {' '}
                  <g id="icons" transform="translate(56.000000, 160.000000)">
                    {' '}
                    <path
                      d="M249.365851,6538.70769 L249.365851,6538.70769 C249.770764,6539.09744 250.426289,6539.09744 250.830166,6538.70769 L259.393407,6530.44413 C260.202198,6529.66364 260.202198,6528.39747 259.393407,6527.61699 L250.768031,6519.29246 C250.367261,6518.90671 249.720021,6518.90172 249.314072,6519.28247 L249.314072,6519.28247 C248.899839,6519.67121 248.894661,6520.31179 249.302681,6520.70653 L257.196934,6528.32352 C257.601847,6528.71426 257.601847,6529.34685 257.196934,6529.73759 L249.365851,6537.29462 C248.960938,6537.68437 248.960938,6538.31795 249.365851,6538.70769"
                      id="arrow_right-[#336]"
                    >
                      {' '}
                    </path>{' '}
                  </g>{' '}
                </g>{' '}
              </g>{' '}
            </g>
          </svg>
        </Link>
      </div>

      <div className="relative md:hidden">
        {/* Mobile Menu Button */}
        <button
          className="md:hidden focus:outline-none"
          id="menu-toggle"
          onClick={() => document.getElementById('mobile-menu').classList.toggle('scale-100')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>

        {/* Mobile Menu */}
        <div
          className="fixed top-20 right-4 w-64 bg-white shadow-lg rounded-xl transition-transform duration-300 transform scale-0 origin-top-right border border-gray-500"
          id="mobile-menu"
        >
          <nav className="mt-6 px-4 py-4 space-y-2">
            <Link
              className="block text-gray-900 text-md font-thin font-poppins hover:bg-gray-100 rounded-md px-4 py-2 transition-colors"
              to="/terms"
            >
              Terms of Service
            </Link>
            <Link
              className="block text-gray-900 text-md font-thin font-poppins hover:bg-gray-100 rounded-md px-4 py-2 transition-colors"
              to="/privacy"
            >
              Privacy Policy
            </Link>

            <div className="flex items-center space-x-4">
              {!isLoggedIn && (
                // Sign up Button with different color
                <Link
                  className="text-white bg-custom-gradient px-4 py-2 rounded-lg text-md font-poppins hover:transition-colors w-full text-center md:w-auto"
                  to="/signup"
                >
                  Sign up
                </Link>
              )}

              {/* Dynamic Login or Dashboard Button */}
              <Link
                className="text-gray-900 font-semibold inline-flex h-10 items-center justify-center rounded-lg border border-gray-200 bg-white px-6 py-3 text-md font-poppins shadow-sm transition-colors hover:bg-gray-100 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 w-full text-center md:w-auto"
                to={isLoggedIn ? '/dashboard' : '/login'}
              >
                {isLoggedIn ? 'Dashboard' : 'Login'}
                <svg
                  width="10px"
                  height="10px"
                  viewBox="-4.5 0 20 20"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000000"
                  className="inline-block ml-2"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g
                        id="Dribbble-Light-Preview"
                        transform="translate(-305.000000, -6679.000000)"
                        fill="#000000"
                      >
                        <g id="icons" transform="translate(56.000000, 160.000000)">
                          <path
                            d="M249.365851,6538.70769 L249.365851,6538.70769 C249.770764,6539.09744 250.426289,6539.09744 250.830166,6538.70769 L259.393407,6530.44413 C260.202198,6529.66364 260.202198,6528.39747 259.393407,6527.61699 L250.768031,6519.29246 C250.367261,6518.90671 249.720021,6518.90172 249.314072,6519.28247 L249.314072,6519.28247 C248.899839,6519.67121 248.894661,6520.31179 249.302681,6520.70653 L257.196934,6528.32352 C257.601847,6528.71426 257.601847,6529.34685 257.196934,6529.73759 L249.365851,6537.29462 C248.960938,6537.68437 248.960938,6538.31795 249.365851,6538.70769"
                            id="arrow_right-[#336]"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </Link>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
