import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoaderSkeleton from './ux/LoaderSkeleton';
import { generateWord } from '../comp/DownloadWord';
import downloadPDF from '../comp/DownloadPDF';
import { Button } from './ui/button';
import { jsPDF } from 'jspdf';
import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  AlignmentType,
  HeadingLevel,
  Footer,
  PageNumber,
  TabStopType,
  TabStopPosition,
} from 'docx';
import { saveAs } from 'file-saver';

import { MoreVertical, Download, Copy, Trash2, Edit } from 'lucide-react'; // Import necessary icons
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@radix-ui/react-dropdown-menu'; // Import Radix UI DropdownMenu components



const MyResumes = () => {
  const [resumes, setResumes] = useState([]);
  const [visibleResumes, setVisibleResumes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [resumeToDelete, setResumeToDelete] = useState(null);
  const [renameResume, setRenameResume] = useState(null);
  const [newResumeName, setNewResumeName] = useState('');
  const [displayCount, setDisplayCount] = useState(4);
  const [filter, setFilter] = useState('Newest First');
  const [showDownloadDialog, setShowDownloadDialog] = useState(false);
  const [resumeToDownload, setResumeToDownload] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchResumes();
  }, []);

  useEffect(() => {
    setVisibleResumes(resumes.slice(0, displayCount));
  }, [resumes, displayCount]);

  useEffect(() => {
    applyFilter();
  }, [filter, resumes]);

  const fetchResumes = async () => {
    try {
      setLoading(true);
      const { data: { user } } = await supabase.auth.getUser();
      const { data, error } = await supabase
        .from('resumes')
        .select('*')
        .eq('user_id', user.id)
        .eq('visibility', 'true');

      if (error) throw error;
      setResumes(data);
    } catch (error) {
      console.error('Error retrieving resumes:', error);
      setError('Error loading resumes.');
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch font settings from Supabase
const fetchFontSettings = async (resumeCode) => {
  try {
    const { data, error } = await supabase
      .from('resumes')
      .select('font_family, font_size')
      .eq('code', resumeCode)
      .single();

    if (error) {
      console.error('Error fetching font settings:', error);
      return { font_family: 'Helvetica', font_size: 12 }; // default values
    }

    return data;
  } catch (error) {
    console.error('Unexpected error fetching font settings:', error);
    return { font_family: 'Helvetica', font_size: 12 }; // default values
  }
};


  const applyFilter = () => {
    let sortedResumes = [...resumes];
    switch (filter) {
      case 'Newest First':
        sortedResumes.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        break;
      case 'Oldest First':
        sortedResumes.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        break;
      case 'A-Z':
        sortedResumes.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 'Z-A':
        sortedResumes.sort((a, b) => b.name.localeCompare(a.name));
        break;
    }
    setVisibleResumes(sortedResumes.slice(0, displayCount));
  };

  const loadMoreResumes = () => {
    setDisplayCount(prevCount => prevCount + 4);
  };

  const handleResumeClick = (code) => {
    if (!renameResume) {
      navigate(`/resume-builder?code=${code}`);
    }
  };

  const handleDeleteResume = async () => {
    if (resumeToDelete) {
      try {
        const { error } = await supabase
          .from('resumes')
          .update({ visibility: false })
          .eq('id', resumeToDelete.id);

        if (error) throw error;
        setResumes(resumes.filter(resume => resume.id !== resumeToDelete.id));
        toast.success('Resume deleted successfully!');
      } catch (error) {
        console.error('Error deleting resume:', error);
        toast.error('Failed to delete resume.');
      } finally {
        setShowDeleteModal(false);
        setResumeToDelete(null);
      }
    }
  };

  const handleRenameResume = async (resume) => {
    if (renameResume && renameResume.id === resume.id) {
      try {
        const { error } = await supabase
          .from('resumes')
          .update({ name: newResumeName })
          .eq('id', resume.id);

        if (error) throw error;
        setResumes(resumes.map(r => r.id === resume.id ? { ...r, name: newResumeName } : r));
        toast.success('Resume renamed successfully!');
        setRenameResume(null);
        setNewResumeName('');
      } catch (error) {
        console.error('Error renaming resume:', error);
        toast.error('Failed to rename resume.');
      }
    } else {
      setRenameResume(resume);
      setNewResumeName(resume.name);
    }
  };

  const handleDuplicateResume = async (resumeToDuplicate) => {
    try {
      // Get the current user's ID and email from Supabase
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) {
        console.error('Error fetching user:', userError);
        toast.error('Failed to fetch user data.');
        return;
      }
  
      // Create a new resume object
      const newResume = {
        name: `Duplicate of ${resumeToDuplicate.name}`,
        code: generateUniqueCode(),
        user_id: user.id,
        user_email: user.email,
        personal_information: resumeToDuplicate.personal_information,
        education: resumeToDuplicate.education,
        experience: resumeToDuplicate.experience,
        leadership: resumeToDuplicate.leadership,
        awards: resumeToDuplicate.awards,
        certifications: resumeToDuplicate.certifications,
        skills: resumeToDuplicate.skills,
        customSections: resumeToDuplicate.customSections,
        sectionOrder: resumeToDuplicate.sectionOrder,
        created_at: new Date().toISOString(),
        last_updated: new Date().toISOString(),
        leadership_visibility: resumeToDuplicate.leadership_visibility,
        awards_visibility: resumeToDuplicate.awards_visibility,
        skills_visibility: resumeToDuplicate.skills_visibility
      };
  
      // Remove any properties that might be null or undefined
      Object.keys(newResume).forEach(key => 
        (newResume[key] === null || newResume[key] === undefined) && delete newResume[key]
      );
  
      // Insert the new resume into the database
      const { data, error } = await supabase
        .from('resumes')
        .insert([newResume])
        .select();
  
      if (error) throw error;
  
      console.log('Resume duplicated successfully:', data);
      toast.success("Resume has been duplicated successfully!");
      
      await fetchResumes();
    } catch (error) {
      console.error('Error duplicating resume:', error);
      toast.error("An error occurred while duplicating the resume. Please try again.");
    }
  };

  const handleShowDownloadDialog = (resume) => {
    setResumeToDownload(resume);
    setHiddenSections({
      leadership: resume.leadership_visibility === 'yes',
      awards: resume.awards_visibility === 'yes',
      skills: resume.skills_visibility === 'yes',
      certifications: resume.certifications_visibility === 'yes',
    });
    setShowDownloadDialog(true);
  };
  

  const [hiddenSections, setHiddenSections] = useState({
    leadership: false,
    awards: false,
    skills: false,
    certifications: false,
  });  

  const handleDownloadPDF = async () => {
    if (!resumeToDownload) {
      toast.error('Resume data not available');
      return;
    }
  
    const { font_family, font_size } = await fetchFontSettings(resumeToDownload.code);
  
    downloadPDF(
      resumeToDownload,
      hiddenSections,
      `${resumeToDownload.name || 'Resume'}.pdf`,
      font_family,
      font_size
    );
    setShowDownloadDialog(false);
  };
  const handleDownloadWord = () => {
    if (!resumeToDownload) {
      toast.error('Resume data not available');
      return;
    }
    generateWord(resumeToDownload, hiddenSections);
    setShowDownloadDialog(false);
  };


  
  // Don't forget to define the formatDate function if it's not already defined
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleString('default', { month: 'long', year: 'numeric' });
  };
  

  const generateUniqueCode = () => {
    return Math.random().toString(36).substr(2, 8);
  };

  if (loading) return <LoaderSkeleton />;
  if (error) return <p>{error}</p>;

  return (
    <div className="bg-background text-foreground rounded-lg shadow-md p-6">
      <ToastContainer />
      <div className="flex justify-end mb-4">
        <DropdownMenu filter={filter} setFilter={setFilter} />
      </div>
      <div className="grid gap-3">
        {visibleResumes.length === 0 ? (
          <div className="flex flex-col items-center justify-center text-center">
            <svg className="h-16 w-16 text-gray-400 mb-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.182 16.318A4.486 4.486 0 0 0 12.016 15a4.486 4.486 0 0 0-3.198 1.318M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Z" />
            </svg>
            <p className="text-gray-500">No resumes created yet. Click on Resume Builder to get Started</p>
          </div>
        ) : (
          visibleResumes.map((resume) => (
            <ResumeItem
              key={resume.id}
              resume={resume}
              onResumeClick={handleResumeClick}
              onRename={handleRenameResume}
              onDuplicate={handleDuplicateResume}
              onDelete={() => {
                setResumeToDelete(resume);
                setShowDeleteModal(true);
              }}
              isRenaming={renameResume && renameResume.id === resume.id}
              newResumeName={newResumeName}
              setNewResumeName={setNewResumeName}
              onDownloadClick={() => handleShowDownloadDialog(resume)}
            />
          ))
        )}
      </div>
      {showDeleteModal && (
        <DeleteModal
          onDelete={handleDeleteResume}
          onCancel={() => setShowDeleteModal(false)}
        />
      )}
      {showDownloadDialog && resumeToDownload && (
        <DownloadDialog
          isOpen={showDownloadDialog}
          onClose={() => setShowDownloadDialog(false)}
          onDownloadPDF={handleDownloadPDF}
          onDownloadWord={handleDownloadWord}
          resumeName={resumeToDownload.name}
        />
      )}
      {visibleResumes.length < resumes.length && (
        <div className="flex justify-end mt-4">
          <Button
            onClick={loadMoreResumes}
            className="text-white flex items-center justify-center py-3 rounded-lg"
            style={{ fontSize: '14px', fontFamily: 'Poppins',  padding: '10px 20px',
              fontSize: '14px',
              setFontFamily: 'font-poppin',
              border: 'none',
              borderRadius: '0.5rem',
              cursor: 'pointer',
              transform: 'scale(1)',
              // transition: 'background-color 0.3s, opacity 0.3s',
              transition: 'transform 1s ease',
              backgroundColor: '#603CFF', // Primary color
              color: '#ffffff'}}
          >
            Load More Resumes
          </Button>
        </div>
      )}
    </div>
  );
};

const ResumeItem = ({ resume, onResumeClick, onRename, onDuplicate, onDelete, isRenaming, newResumeName, setNewResumeName, onDownloadClick }) => (

<div
      className="rounded-md p-4 flex items-center justify-between hover:bg-gray-100 transition-all duration-300 ease-in-out cursor-pointer border border-gray-200"
      onClick={() => onResumeClick(resume.code)}
    >
      <div className="flex flex-col">
        {/* Resume Title */}
        {isRenaming ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onRename(resume);
            }}
          >
            <input
              type="text"
              value={newResumeName}
              onChange={(e) => setNewResumeName(e.target.value)}
              className="border rounded px-2 py-1"
              onClick={(e) => e.stopPropagation()}
              autoFocus
            />
          </form>
        ) : (
          <div className="text-gray-900 text-lg">{resume.name}</div>
        )}

        {/* Last Modified Date */}
        <div className="text-sm text-gray-500">
          Last modified: {new Date(resume.last_updated).toLocaleDateString()}
        </div>
      </div>

      {/* Action Icons for Desktop */}
      <div className="hidden md:flex items-center gap-2">
        <button
          onClick={(e) => {
            e.stopPropagation();
            onDownloadClick(resume);
          }}
          className="p-2 rounded-md hover:bg-gray-200 transition-colors"
        >
          <Download className="w-5 h-5 text-gray-700" />
        </button>

        <button
          onClick={(e) => {
            e.stopPropagation();
            onRename(resume);
          }}
          className="p-2 rounded-md hover:bg-gray-200 transition-colors"
        >
          <Edit className="w-5 h-5 text-gray-700" />
        </button>

        <button
          onClick={(e) => {
            e.stopPropagation();
            onDuplicate(resume);
          }}
          className="p-2 rounded-md hover:bg-gray-200 transition-colors"
        >
          <Copy className="w-5 h-5 text-gray-700" />
        </button>

        <button
          onClick={(e) => {
            e.stopPropagation();
            onDelete(resume);
          }}
          className="p-2 rounded-md hover:bg-gray-200 transition-colors"
        >
          <Trash2 className="w-5 h-5 text-red-500" />
        </button>
      </div>

     {/* Action Menu for Mobile */}
<div className="flex md:hidden items-center relative">
  <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <button
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="p-2 rounded-md hover:bg-gray-200 transition-colors"
      >
        <MoreVertical className="h-5 w-5 text-gray-700" />
      </button>
    </DropdownMenuTrigger>
    <DropdownMenuContent
      align="end"
      sideOffset={5}
      className="bg-white rounded-md shadow-lg border border-gray-200"
    >
      <DropdownMenuItem
        onClick={(e) => {
          e.stopPropagation();
          onDownloadClick(resume);
        }}
        className="flex items-center gap-2 px-4 py-2 hover:bg-gray-100"
      >
        <Download className="w-4 h-4 text-gray-700" />
        Download
      </DropdownMenuItem>
      {/* Exclude 'Rename' option on mobile devices */}
      <DropdownMenuItem
        onClick={(e) => {
          e.stopPropagation();
          onDuplicate(resume);
        }}
        className="flex items-center gap-2 px-4 py-2 hover:bg-gray-100"
      >
        <Copy className="w-4 h-4 text-gray-700" />
        Duplicate
      </DropdownMenuItem>
      <DropdownMenuItem
        onClick={(e) => {
          e.stopPropagation();
          onDelete(resume);
        }}
        className="flex items-center gap-2 px-4 py-2 text-red-600 hover:bg-gray-100"
      >
        <Trash2 className="w-4 h-4 text-red-600" />
        Delete
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
</div>

    </div>

);

const DownloadDialog = ({ isOpen, onClose, onDownloadPDF, onDownloadWord, resumeName }) => {


  const [hiddenSections, setHiddenSections] = useState({
    leadership: false,
    awards: false,
    skills: false,
    certifications: false
  });

  
  if (!isOpen) return null;
  return (
    <div className="modal fixed inset-0 z-10 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
              Download {resumeName}
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Choose a format to download your resume.
              </p>
            </div>
          </div>
          
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button type="button" onClick={onClose} className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              Cancel
            </button>

          <button type="button" onClick={onDownloadPDF} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">
              Download PDF
            </button>
            <button type="button" onClick={onDownloadWord} className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              Download Word
            </button>
          
          </div>
        </div>
      </div>
    </div>
  );
};

const DeleteModal = ({ onDelete, onCancel }) => (
  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center" id="my-modal">
    <div className="p-5 border w-96 shadow-lg rounded-md bg-white">
      <div className="text-center">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Delete Resume</h3>
        <div className="mt-2 px-7 py-3">
          <p className="text-sm text-gray-500">
            Are you sure you want to delete this resume? This action cannot be undone.
          </p>
        </div>
        <div className="items-center px-4 py-3">
          <button
            id="ok-btn"
            className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300"
            onClick={onDelete}
          >
            Delete
          </button>
          <button
            id="cancel-btn"
            className="mt-3 px-4 py-2 bg-white text-gray-800 text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
);


export default MyResumes;