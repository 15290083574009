import React, { useState } from "react";

const BulletPointAnalysis = ({ gradeResults }) => {
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);

  // Helper function to clean bullet points
  const formatBulletPoint = (text) => {
    if (!text) return "";
    // Remove any leading whitespace and existing bullet points
    const cleanText = text.trim().replace(/^[•\s]+/, '');
    // Add a single bullet point
    return `• ${cleanText}`;
  };

  // Flatten nested arrays in improvementBullets
  const improvementBullets = (
    gradeResults?.bulletPointAnalysis?.improvementNeeded || []
  ).flat();

  const totalItems = improvementBullets.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePrevious = () => {
    setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const handleNext = () => {
    setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev));
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const currentItems = improvementBullets.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="mt-6 space-y-8">
      {/* Strong Bullets Section */}
      {gradeResults?.bulletPointAnalysis?.strongBullets?.length > 0 && (
        <div>
          <h4 className="text-2xl font-semibold text-green-600 mb-4">
            Strong Examples
          </h4>
          {gradeResults.bulletPointAnalysis.strongBullets.map((item, index) => (
            <div
              key={index}
              className="bg-green-50 p-5 mb-4 rounded-lg shadow-md hover:shadow-lg transition-all"
            >
              <p className="text-lg text-gray-800 font-medium mb-2">
                {formatBulletPoint(item.bullet)}
              </p>
              <p className="text-sm text-green-600">{`Why it's strong: ${item.reason}`}</p>
            </div>
          ))}
        </div>
      )}
      {/* Needs Improvement Section */}
      {totalItems > 0 && (
        <div>
          <h4 className="text-2xl font-semibold text-purple-600 mb-4">
            Needs Improvement
          </h4>
          {currentItems.map((item, index) => (
            <div
              key={index}
              className="bg-purple-50 p-5 mb-4 rounded-lg shadow-md hover:shadow-lg transition-all"
            >
              <p className="text-lg text-gray-800 font-medium mb-2">
                {formatBulletPoint(item.bullet)}
              </p>
              <p className="text-sm text-purple-600">
                Issues:{" "}
                {item.issues?.length
                  ? item.issues.join(", ")
                  : "No issues listed"}
              </p>
              <p className="text-sm text-gray-600 mt-2">
                Suggestion: {item.suggestion || "No suggestions provided"}
              </p>
            </div>
          ))}

          {/* Pagination Controls */}
          <div className="flex items-center justify-center py-10">
            <div className="flex items-center justify-between border-t border-gray-200 lg:w-3/5 w-full">
              <div
                className={`flex items-center pt-3 cursor-pointer ${
                  currentPage === 1
                    ? "text-gray-400"
                    : "text-purple-600 hover:text-purple-700"
                }`}
                onClick={handlePrevious}
              >
                <svg
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-3"
                >
                  <path
                    d="M1.1665 4H12.8332"
                    stroke="currentColor"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1.1665 4L4.49984 7.33333"
                    stroke="currentColor"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1.1665 4.00002L4.49984 0.666687"
                    stroke="currentColor"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="text-sm font-medium leading-none">Previous</p>
              </div>
              <div className="sm:flex hidden">
                {Array.from({ length: totalPages }, (_, index) => (
                  <p
                    key={index}
                    className={`text-sm font-medium leading-none cursor-pointer ${
                      currentPage === index + 1
                        ? "text-purple-700 border-t border-purple-400"
                        : "text-gray-600 hover:text-purple-700 hover:border-purple-400"
                    } pt-3 mr-4 px-2`}
                    onClick={() => handlePageClick(index + 1)}
                  >
                    {index + 1}
                  </p>
                ))}
              </div>
              <div
                className={`flex items-center pt-3 cursor-pointer ${
                  currentPage === totalPages
                    ? "text-gray-400"
                    : "text-purple-600 hover:text-purple-700"
                }`}
                onClick={handleNext}
              >
                <p className="text-sm font-medium leading-none mr-3">Next</p>
                <svg
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.1665 4H12.8332"
                    stroke="currentColor"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.5 7.33333L12.8333 4"
                    stroke="currentColor"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.5 0.666687L12.8333 4.00002"
                    stroke="currentColor"
                    strokeWidth="1.25"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BulletPointAnalysis;
