export const MESSAGES = {
  ERROR: {
  },
  SUCCESS: {
  },
  INFO: {
  },
};

export const graderMessage = {
  PERSONAL: {
    ERROR: {
      LINKURLMISSING: "No LinkedIn URL provided. Please add a LinkedIn profile link to enhance your resume.",
    },
    INFO: {
      LINKFEEDBACK: `Your LinkedIn URL appears to be standard. Consider customizing it by editing your LinkedIn profile settings. Use your full name or a professional identifier to create a more polished URL (e.g., 'https://linkedin.com/in/johndoe').`
    }
  },
  GRAMMER: {
    ERROR: {
      PUNCTUATION: "Unconsistent usage of full stops (either all have full stops or none have full stops)",
      EDUORDER: "Education section must be the first element in resume.",
      LEADORDER: "Ensure the Experience section stands out by moving it before the Leadership section.",
    }
  }
}

export const personalProCollection = ["I", "Me", "My", "Mine", "We", "Us", "Our", "Ours", "Them"];

export const geminiPromptMessage = `
Please analyze the attached resume and assess it based on the following criteria. Provide detailed feedback for each, along with a score. Be fair and constructive to allow students to achieve full marks if they meet reasonable standards. Return only the JSON object in the specified format.

1. **Grammar and Spellcheck (5 points):**
   - Evaluate the entire document for grammar and spelling errors. Deduct points only for significant mistakes, such as repeated errors or those that affect readability. Allow minor typos or less impactful mistakes without heavy penalties.  
   - Provide examples of grammar corrections where necessary.
   - Important: Specify the exact location (word) of each issue and suggest the correct form.

2. **Consistent Verb Tense (2 points):**
   - Check for consistent use of verb tense throughout the document.  
   - Deduct points only for multiple mismatches or unclear transitions that confuse the reader.
   - Important: Indicate the exact bullet points or sentences with tense inconsistencies and suggest corrections.

3. **Content Parameters for Bullet Points in Experience, Projects, and Leadership Activities (5 points):**
   - For each bullet point in these sections, check if it addresses:
     - **What did you do?**
     - **How did you do it?**
     - **Why did you do it?**
     - **What was the result?**  
   - Award full points if 90%+ of these bullet points address at least three of the four questions. Deduct proportionally for missing details.
   - Important: Identify specific bullet points that lack sufficient detail and explain what is missing.

4. **Action Verb Effectiveness in Experience, Projects, and Leadership Activities section(4 points):**
   - Evaluate whether bullet points start with strong action verbs like **Led, Developed, Optimized**.
   - Deduct points for weak or vague verbs like **Responsible for** or **Worked on**.
   - 4 points: 90%+ of bullet points begin with strong action verbs. 3 points: 75–89% compliance. 2 points: 50–74% compliance. 1 point: 25–49% compliance. 0 points: Less than 25% compliance.
   - Provide examples of better verbs if weak ones are found.
   - Important: Highlight specific bullet points with weak verbs and suggest stronger alternatives.

5. **Action Verb Frequency (2 points):**
   - Check for **repeated use of the same action verb** in multiple bullet points (each action verb should ideally appear only once in a section).
   - Important: Identify the repeated verbs and the exact bullet points where they occur.

6. **Technical Skills (2 points):**
   - Evaluate the presence of relevant technical skills (hard skills only).
   - If no technical skills are present, give 0 points.
   - If less than 50% of the listed technical skills match known hard skills, give 0 points.
   - If at least 50% of the technical skills match known hard skills, award 2 points.
   - Important: Provide feedback on which relevant skills are missing if any and add in the JSON sectionErrors title Technical Skills.

7. **Education Section (2 points):**
   - Check that the education section lists only degree-qualifying education, uses no abbreviations.
   - Check for presence of education university && education graduation year && education location
   - Award 2 points if all criteria are met. Deduct points for missing information.
   - Important: Specify any missing information or issues in the education section and provide the feedback in the JSON sectionError titled Education Section.
   
   Return ONLY a JSON object in this exact format:

\`\`\`json
{
  "score": {
    "total": (number from 0-22),  // Total of all points, including the new 3 points for action verb frequency
    "breakdown": {
      "grammarAndSpellcheck": (0-5),
      "consistentVerbTense": (0-2),
      "contentParameters": (0-5),
      "actionVerbEffectiveness": (0-4),
      "actionVerbFrequency": (0-2),
      "technicalSkills": (0-2),
      "eduSectionPoints": (0-2)
    }
  },
  "criticalIssues": [
    "List major issues that must be fixed, e.g., 'Repeated use of the action verb 'Managed' in multiple bullet points.'"
  ],
  "grammarCheck": {
    "status": "good" or "needs improvement",
    "issues": ["List grammar issues with their specific location, e.g., 'Inconsistent use of tense in Experience section.'"]
  },
  "bulletPointAnalysis": {
    "strongBullets": [
      {
        "bullet": "The bullet point text",
        "reason": "Why it is strong, e.g., 'Uses quantifiable results and a strong action verb.'"
      }
    ],
    "improvementNeeded": [
      {
        "bullet": "The bullet point text",
        "issues": ["Specific issues, e.g., 'Missing a result statement.'"],
        "suggestion": "How to improve, e.g., 'Add a measurable result to demonstrate impact.'"
      }
    ]
  },
  "improvements": [
    {
      "section": "Section name, e.g., 'Leadership Activities'",
      "issue": "What needs improvement, e.g., 'Bullet points lack strong verbs.'",
      "suggestion": "How to improve, e.g., 'Start each bullet point with a strong action verb.'"
    }
  ],
  "sectionErrors": [
    {
      "title": "Technical Skills",
      "comments": [
        "List relevant technical hard skills that could be added to improve the resume."
      ]
    },
    {
      "title": "Education Section",
      "comments": [
        [
          "The education section is well-formatted, but the degree listed is not recognized."
        ]
      ]
    }
  ]
}
\`\`\`

Resume Content: 
`;
