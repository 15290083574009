// src/pages/Terms.js
import React, { useEffect, useState } from "react";  // Import useEffect and useState
import { Helmet } from 'react-helmet';
import { supabase } from '../supabaseClient';

import PurpleFooter from '../comp/PurpleFooter';
import PurpleHeader from '../comp/PurpleHeader';

const Terms = () => {

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const checkUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setIsLoggedIn(!!user);
    };
  
    checkUser();
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <Helmet>
        <title>Terms of Services - Career Resigned</title>
        <link rel="icon" href="" />
      </Helmet>

      <PurpleHeader />
    
      <main className="flex-1 mt-20 " style={{ fontFamily: "Poppins" }}>
        <section className="bg-white py-12 mb-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center">
              <p className="text-black mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-4xl">
                Terms and Conditions
              </p>
            </div>

            <div className="mt-10">
              <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                {/* 1. Acceptance of Terms */}
                <div className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-m">
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      1. Acceptance of Terms
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    By accessing and using careerredesigned.com, you agree to comply with and be
                    bound by these Terms and Conditions. If you disagree, please do not use the
                    Site.
                  </dd>
                </div>

                {/* 2. Use of the Site */}
                <div className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md">
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9"
                        />
                      </svg>
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      2. Use of the Site
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    You agree to use the Site for lawful purposes only. Prohibited activities
                    include engaging in unlawful or fraudulent activities, posting defamatory
                    content, or interfering with the Site&apos;s functionality.
                  </dd>
                </div>

                {/* 3. Services Provided */}
                <div className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md">
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                        />
                      </svg>
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      3. Services Provided
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    We offer tools for building and grading resumes. While we strive for accuracy,
                    we do not guarantee employment outcomes or the effectiveness of resumes created
                    using our services.
                  </dd>
                </div>

                {/* 4. User-Generated Content */}
                <div className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md">
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                        />
                      </svg>
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      4. User-Generated Content
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    By submitting content, you grant us a license to use it for our services. You
                    are responsible for ensuring your content doesn&apos;t violate third-party rights.
                  </dd>
                </div>

                {/* 5. Intellectual Property */}
                <div className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md">
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                        />
                      </svg>
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      5. Intellectual Property
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    All content on the Site is the property of careerredesigned.com or its licensors
                    and is protected by intellectual property laws. You may not reproduce or
                    distribute this content without permission.
                  </dd>
                </div>

                {/* 6. Limitation of Liability */}
                <div className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md">
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        />
                      </svg>
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      6. Limitation of Liability
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    careerredesigned.com will not be liable for any damages arising from your use of
                    the Site. The Site and its services are provided &quot;as is&quot; without warranties of
                    any kind.
                  </dd>
                </div>

                {/* 7. Data Security and Privacy */}
                <div className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md">
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                        />
                      </svg>
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      7. Data Security and Privacy
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    We take your privacy seriously. Please refer to our Privacy Policy for details
                    on data handling. However, we cannot guarantee absolute security, and you use
                    the Site at your own risk.
                  </dd>
                </div>

                {/* 8. Termination */}
                <div className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md">
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      8. Termination
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    We reserve the right to terminate or restrict your access to the Site at any
                    time, without notice, for any reason, including violation of these Terms and
                    Conditions.
                  </dd>
                </div>

                {/* 9. Governing Law */}
                <div className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md">
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                        />
                      </svg>
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      9. Governing Law
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    These Terms and Conditions are governed by the laws of the State of Ohio, USA.
                    Any disputes will be subject to the exclusive jurisdiction of the courts in
                    Ohio.
                  </dd>
                </div>

                {/* 10. Changes to Terms */}
                <div className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-m">
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                        />
                      </svg>
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      10. Changes to Terms
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    We reserve the right to modify these Terms at any time. Changes are effective
                    immediately upon posting. Your continued use of the Site constitutes acceptance
                    of any changes.
                  </dd>
                </div>

                {/* 11. Dispute Resolution */}
                <div className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md">
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                        />
                      </svg>
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      11. Dispute Resolution
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    In case of disputes, parties agree to first attempt negotiation. If unresolved,
                    disputes may be settled through arbitration, mediation, or by filing a lawsuit
                    in Ohio courts.
                  </dd>
                </div>

                {/* 12. Contact Information */}
                <div className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md">
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                        />
                      </svg>
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      12. Contact Information
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    If you have any questions about these Terms and Conditions, please contact us at
                    info@careerredesigned.com.
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </section>
      </main>

     <PurpleFooter/>    </div>
  );
};

export default Terms;
