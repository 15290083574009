import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const RecoveryHandler = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleRecovery = async () => {
      const hashParams = new URLSearchParams(window.location.hash.substring(1));
      const accessToken = hashParams.get('access_token');
      const refreshToken = hashParams.get('refresh_token');
      const type = hashParams.get('type');

      if (type === 'recovery' && accessToken) {
        // Set the session in Supabase
        await supabase.auth.setSession({
          access_token: accessToken,
          refresh_token: refreshToken
        });
        
        // Redirect to the reset password page
        navigate('/reset-password', { 
          state: { 
            recovery: true,
            accessToken 
          }
        });
      } else {
        navigate('/login');
      }
    };

    handleRecovery();
  }, [navigate]);

  // Return null as this is just a handler component
  return null;
};

export default RecoveryHandler;