import React from 'react';
import { Radar} from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';

ChartJS.register(...registerables);

const ComparisonVisualizations = ({ data }) => {


  const calculateOverallScore = () => {
    const weights = {
      skillRelevance: 0.35,
      experienceMatch: 0.35,
      keywordMatch: 0.30
    };
  
    const keywordMatchPercentage = (data.keywordComparison.present.length / data.keywordComparison.required.length) * 100;
    
    const overallScore = Math.round(
      (data.skillRelevance * weights.skillRelevance) +
      (data.experienceMatch * weights.experienceMatch) +
      (keywordMatchPercentage * weights.keywordMatch)
    );
  
    return overallScore;
  };

  

  // Overall Match Bar Chart
  const overallMatchData = {
    labels: ['Match Percentage'],
    datasets: [
      {
        label: 'Overall Match',
        data: [data.overallMatch],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const overallMatchOptions = {
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
      },
    },
    plugins: {
      title: {
        display: true,
        text: 'Overall Resume-Job Match',
      },
    },
  };

  // Skill Relevance Radar Chart
  const skillRelevanceData = {
    labels: ['Skill Relevance', 'Experience Match', 'Keyword Match'],
    datasets: [
      {
        label: 'Score',
        data: [data.skillRelevance, data.experienceMatch, (data.keywordComparison.present.length / data.keywordComparison.required.length) * 100],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  };

  const skillRelevanceOptions = {
    scales: {
      r: {
        angleLines: {
          display: false
        },
        suggestedMin: 0,
        suggestedMax: 100
      }
    },
    plugins: {
      title: {
        display: true,
      },
    },
  };

  // Keyword Comparison Doughnut Chart
  const keywordComparisonData = {
    labels: ['Present', 'Missing'],
    datasets: [
      {
        data: [data.keywordComparison.present.length, data.keywordComparison.missing.length],
        backgroundColor: ['rgba(75, 192, 192, 0.6)', 'rgba(255, 99, 132, 0.6)'],
        borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 99, 132, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const keywordComparisonOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Keyword Comparison',
      },
    },
  };

  // Word Clouds
  const resumeWordCloudData = data.resumeWordCloud.map(word => ({ value: word, count: Math.floor(Math.random() * 100) + 1 }));
  const jobDescriptionWordCloudData = data.jobDescriptionWordCloud.map(word => ({ value: word, count: Math.floor(Math.random() * 100) + 1 }));


  const calculateWidth = (value) => `${value}%`

  const renderProgressBar = (value, label) => (
    <div className="mb-2">
      <div className="flex justify-between mb-1">
        <span className="text-sm font-medium text-gray-700">{label}</span>
        <span className="text-sm font-medium text-gray-700">{value}%</span>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-2.5">
        <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: calculateWidth(value) }}></div>
      </div>
    </div>
  )

  const renderKeywordBadge = (keyword, present) => (
    <span key={keyword} className={`inline-block px-2 py-1 text-sm font-semibold mr-2 mb-2 rounded-full ${present ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
      {keyword}
    </span>
  )

  const renderWordCloud = (words) => (
    <div className="flex flex-wrap justify-center">
      {words.map((word, index) => (
        <span key={index} className="inline-block px-2 py-1 m-1 text-sm font-semibold text-gray-700 bg-gray-200 rounded-full" style={{ fontSize: `${Math.random() * 1.5 + 0.5}rem` }}>
          {word}
        </span>
      ))}
    </div>
  )

  
  return (
    <div className="comparison-visualizations" style={{ fontFamily: "Poppins" }}> 
    
    <div className="grid grid-cols-1 md:grid-cols-1 gap-6 mb-6">
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold mb-4 text-gray-700">Overall Resume-Job Match:</h3>
        <div className="flex items-center justify-center mb-4">
          <div className="text-5xl font-bold text-black">{data.overallMatch}%</div>
        </div>

        <div className="w-full bg-gray-200 rounded-full h-4">
          <div className="bg-black h-4 rounded-full" style={{ width: calculateWidth(data.overallMatch) }}></div>
        </div>

        <h3 className="text-xl font-semibold mb-4 text-gray-700 mt-2">Improvement Suggestions</h3>
      <div className="max-h-80 overflow-y-auto">
        <ul className="space-y-2">
          {data.improvementSuggestions.map((suggestion, index) => (
            <li key={index} className="flex items-start">
              <span className="inline-flex items-center justify-center w-6 h-6 mr-2 text-sm font-semibold text-black rounded-full">
                {index + 1}
              </span>
              <span className="text-gray-700">{suggestion}</span>
            </li>
          ))}
        </ul>
      </div>
      </div>
      
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold text-gray-700">Skill and Experience Relevance</h3>
        <Radar data={skillRelevanceData} options={skillRelevanceOptions} />
      </div>
    </div>
    
    <div className="grid grid-cols-1 md:grid-cols-1 gap-6 mb-6">
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold mb-4 text-gray-700">Keyword Comparison</h3>
        <div className="flex justify-around mb-4">
          <div className="text-center">
            <div className="text-3xl font-bold text-cutler-green">{data.keywordComparison.present.length}</div>
            <div className="text-sm text-gray-600">Present</div>
          </div>
          <div className="text-center">
            <div className="text-3xl font-bold text-red-600">{data.keywordComparison.missing.length}</div>
            <div className="text-sm text-gray-600">Missing</div>
          </div>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-4">
          <div className="bg-cutler-green h-4 rounded-l-full" style={{ width: `${(data.keywordComparison.present.length / (data.keywordComparison.present.length + data.keywordComparison.missing.length)) * 100}%` }}></div>
        </div>
      </div>
      
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold mb-4 text-gray-700">Keyword Details</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <h4 className="font-semibold mb-2 text-gray-600">Present Keywords</h4>
            <div className="max-h-40 overflow-y-auto">
              {data.keywordComparison.present.map(keyword => renderKeywordBadge(keyword, true))}
            </div>
          </div>
          <div>
            <h4 className="font-semibold mb-2 text-gray-600">Missing Keywords</h4>
            <div className="max-h-40 overflow-y-auto">
              {data.keywordComparison.missing.map(keyword => renderKeywordBadge(keyword, false))}
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold mb-4 text-gray-700">Resume Word Cloud</h3>
        {renderWordCloud(data.resumeWordCloud)}
      </div>
      
      <div className="bg-white p-6 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold mb-4 text-gray-700">Job Description Word Cloud</h3>
        {renderWordCloud(data.jobDescriptionWordCloud)}
      </div>
    </div>

  </div>
  );
};

export default ComparisonVisualizations;