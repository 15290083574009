import { Document, Paragraph, TextRun, AlignmentType, Packer } from 'docx';
import { saveAs } from 'file-saver';

export const generateCoverLetterWord = (coverLetterData, resumeData) => {
  if (!coverLetterData || !resumeData) {
    console.error('Missing required data');
    return;
  }

  const content = [];
  const font = 'Poppins';
  const fontSize = 24; // Word's half-points (e.g., 12pt is 24 in docx)

  // **Name** (Bold and Larger Font)
  const personalInfo = resumeData.personal_information || {};
  if (personalInfo.name) {
    content.push(
      new Paragraph({
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({
            text: personalInfo.name,
            bold: true,
            size: fontSize + 6, // Larger font for name
            font,
          }),
        ],
        spacing: { after: 200 },
      })
    );
  }

  // **Personal Details** (Centered on the next line, separated by '|')
  let personalDetails = '';
  if (personalInfo.phone) personalDetails += `${personalInfo.phone}`;
  if (personalInfo.email) personalDetails += ` | ${personalInfo.email}`;
  if (personalInfo.linkedin) personalDetails += ` | ${personalInfo.linkedin}`;

  if (personalDetails) {
    content.push(
      new Paragraph({
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({
            text: personalDetails,
            size: fontSize, // Default size for personal details
            font,
          }),
        ],
        spacing: { after: 400 },
      })
    );
  }

  // **Cover Letter Content**
  const paragraphs = coverLetterData.cover_letter_content.split('\n\n');
  paragraphs.forEach((para) => {
    content.push(
      new Paragraph({
        alignment: AlignmentType.LEFT,
        children: [
          new TextRun({
            text: para,
            size: fontSize,
            font,
          }),
        ],
        spacing: { after: 200 },
      })
    );
  });

  const doc = new Document({
    sections: [
      {
        children: content,
      },
    ],
  });

  // Generate and save the document
  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `Cover_Letter_${coverLetterData.company_name.replace(/\s+/g, '_')}.docx`);
  });
};
