import React, { useState } from 'react'
import { Button } from "../comp/ui/button"
import { Input } from "../comp/ui/input"
import { FileText, Sparkles, GraduationCap, Clock } from 'lucide-react'
import PurpleHeader from '../comp/PurpleHeader'
import PurpleFooter from '../comp/PurpleFooter'
import { Link } from "react-router-dom"

export default function GetStartedPage() {
  const [message, setMessage] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [email, setEmail] = useState('')

  // Email validation function
  const isValidEduEmail = (email) => {
    const eduEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.edu$/
    return eduEmailRegex.test(email)
  }

  // Email sending function using FastAPI backend
  const sendWelcomeEmail = async (recipientEmail) => {
    try {
      const response = await fetch('https://cr-api.vercel.app/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: recipientEmail })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Failed to send email');
      }

      const data = await response.json();
      return data.success;
    } catch (error) {
      console.error('Error sending email:', error);
      return false;
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setIsSubmitting(true)
    
    if (!isValidEduEmail(email)) {
      setMessage('Please enter a valid .edu email address')
      setIsSubmitting(false)
      return
    }

    try {
      const emailSent = await sendWelcomeEmail(email)
      if (emailSent) {
        setMessage('Welcome email sent! Please check your inbox.')
      } else {
        setMessage('Error sending email. Please try again later.')
      }
    } catch (error) {
      setMessage('An error occurred. Please try again later.')
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div className="min-h-screen bg-background text-foreground flex flex-col" style={{ fontFamily: "Poppins" }}>
      <PurpleHeader/>
      <main className="flex-grow">
        <section className="w-full mt-20 py-12 md:py-24 lg:py-32 xl:py-48 bg-gradient-to-b from-white to-gray-50">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center space-y-4 text-center">
              <div className="space-y-2">
                <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none">
                  Create Your Perfect Resume with AI
                </h1>
                <p className="mx-auto max-w-[700px] text-gray-500 md:text-xl">
                  Transform your experiences into a professional resume that stands out. 
                  Perfect for students and recent graduates.
                </p>
              </div>
              <div className="w-full max-w-sm space-y-2">
                <form onSubmit={handleSubmit} className="mb-4">
                  <Input
                    className="max-w-lg flex-1 mb-6"
                    placeholder="Enter your .edu email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? 'Processing...' : 'Get Started Free'}
                  </Button>
                </form>
                {message && <p className={`text-sm ${message.includes('error') ? 'text-red-600' : 'text-green-600'}`}>{message}</p>}
                <p className="text-xs text-gray-500">
                  Free for students with .edu email addresses
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="features" className="w-full py-12 md:py-24 lg:py-32 bg-white" style={{marginTop:'-60px'}}>
          <div className="container px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl text-center mb-8">
              Why Students Love Our AI Resume Builder
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="flex flex-col items-center text-center p-6 bg-gray-50 rounded-lg">
                <Sparkles className="h-12 w-12 mb-4 text-primary" />
                <h3 className="text-xl font-bold mb-2">AI-Powered Writing</h3>
                <p className="text-gray-500">
                  Transform basic descriptions into professional achievements using advanced AI
                </p>
              </div>
              <div className="flex flex-col items-center text-center p-6 bg-gray-50 rounded-lg">
                <GraduationCap className="h-12 w-12 mb-4 text-primary" />
                <h3 className="text-xl font-bold mb-2">Student-Focused Templates</h3>
                <p className="text-gray-500">
                  Specially designed templates that highlight academic projects and internships
                </p>
              </div>
              <div className="flex flex-col items-center text-center p-6 bg-gray-50 rounded-lg">
                <Clock className="h-12 w-12 mb-4 text-primary" />
                <h3 className="text-xl font-bold mb-2">Quick and Easy</h3>
                <p className="text-gray-500">
                  Create an ATS-friendly resume in minutes, not hours
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="w-full py-12 md:py-24 lg:py-32 bg-gray-50 mb-8">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center space-y-4 text-center">
              <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl">
                Ready to Stand Out?
              </h2>
              <p className="mx-auto max-w-[600px] text-gray-500 md:text-lg">
                Join thousands of students who have already created professional resumes using our AI-powered platform
              </p>
              <Link to="/">
                <Button size="lg" className="mt-4">
                  Create Your Resume Now
                </Button>
              </Link>
            </div>
          </div>
        </section>
      </main>
      <PurpleFooter />
    </div>
  )
}