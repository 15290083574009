import React, { useState, useEffect, useCallback } from 'react';
import ResumeSettings from '../comp/ResumeSettings';
import { useNavigate  } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import downloadPDF from '../comp/DownloadPDF.js'; 
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { parse, format, isValid, parseISO } from 'date-fns';
import {  Trash2
 } from 'lucide-react'; // Import necessary icons
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; 
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { supabase } from '../supabaseClient';
import ResumeCard from '../comp/ResumeCard';

import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid';
import AiLogo from '../img/ai.png';

import ResumeScorer from '../comp/ResumeScorer';
import { generateBulletPoints } from '../comp/AIGenerator';
import {generateLeadershipBullets} from '../comp/LeadershipAIGenerator.js'
import Modal from '../comp/ux/stepper'
import BulletPointSelector from '../comp/BulletPointSelector';

import ResumePreviewWithPDF from '../comp/ResumePreviewWithPDF.js';

import Sidebar from '../comp/Sidebar';
import { Button } from '../comp/ui/button.js';
import { CloudIcon } from 'lucide-react';

import { enUS } from 'date-fns/locale';
import { Card, CardContent } from "../comp/ui/card";
import DateRangeSelector from '../comp/DateRangeSelector';

// Function to fetch font settings from Supabase
const fetchFontSettings = async (resumeCode) => {
  try {
    const { data, error } = await supabase
      .from('resumes')
      .select('font_family, font_size')
      .eq('code', resumeCode)
      .single();

    if (error) {
      console.error('Error fetching font settings:', error);
      return null;
    }

    return data;
  } catch (error) {
    console.error('Unexpected error fetching font settings:', error);
    return null;
  }
};

// Function to save font settings to Supabase
const saveFontSettings = async (resumeCode, fontFamily, fontSize) => {
  if (!resumeCode) {
    console.error("No resume code found.");
    return;
  }

  try {
    // Update the font_family and font_size in the public.resumes table
    const { data, error } = await supabase
      .from('resumes')
      .update({
        font_family: fontFamily,
        font_size: fontSize,
      })
      .eq('code', resumeCode); // Assuming 'code' is the unique identifier for the resume

    if (error) {
      console.error("Error updating font settings:", error);
    } else {
    }
  } catch (error) {
    console.error("Unexpected error updating font settings:", error);
  }
};

export default function ResumeBuilder() {

  const location = useLocation();
  const [resumeCode, setResumeCode] = useState(null);
  const [user, setUser] = useState(null); // Initialize state to store the user
  const [isOpen, setIsOpen] = useState(false); // Sidebar state

  const toggleSidebar = () => setIsOpen(!isOpen);

  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    const fetchUser = async () => {
      // Fetch the current authenticated user from Supabase
      const { data, error } = await supabase.auth.getUser();

      if (error) {
        console.error('Error fetching user:', error.message);
      } else {
        // Set the user in state if available
        setUser(data.user);
      }
    };

    fetchUser(); // Call the function to fetch user data
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    if (code) {
      setResumeCode(code);
      populateResumeData(code);
    }
  }, [location]);


  // Fetch font settings from Supabase and apply them
  useEffect(() => {
    const loadFontSettings = async () => {
      if (!resumeCode) return;

      const fontSettings = await fetchFontSettings(resumeCode);
      if (fontSettings) {
        setFont(fontSettings.font_family || 'Helvetica, sans-serif');
        setFontSize(fontSettings.font_size || '11px');
      }
    };

    loadFontSettings();
  }, [resumeCode]); // Fetch when resumeCode is available


  // Add these new states at the top of your ResumeBuilder component
const [showSaveBox, setShowSaveBox] = useState(false);
const [initialLoad, setInitialLoad] = useState(true);


  const [font, setFont] = useState('Helvetica, sans-serif');
  const [fontSize, setFontSize] = useState('11px');
// Function to handle font change
const handleFontChange = (newFont) => {
  setFont(newFont);
};

// Function to handle font size change
const handleFontSizeChange = (newFontSize) => {
  setFontSize(newFontSize);
};

// Effect to update font settings in Supabase whenever font or fontSize changes
useEffect(() => {
  if (resumeCode) {
    saveFontSettings(resumeCode, font, fontSize);
  }
}, [font, fontSize, resumeCode]); // Update when font or fontSize changes




  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    linkedin: '', 
    education: [
      {
        university: '',
        location: '',
        graduation: '',
        degree: '',
        major: '',
        gpa: ''
      }
    ],
    experience: [
      {
        company: '',
        jobTitle: '',
        location: '',
        duration: '',
        description: [''] // Ensure description is an array

      },
    ],
    leadership: [
      {
        organization: '',
        location: '',
        duration: '',
        title: '',
        accomplishment: [''] // Ensure accomplishment is an array

      },
    ],
    awards: [
      {
        award: '',
        location: '',
        date: ''
      },
    ],

    certifications: [
      {
        certification_name: '',
        certification_location: '',
        certification_year: ''
      }
    ],
    skills: {
      technical: '',
      languages: ''
    },
    skillsVisibility: {
      technical: "no",  // explicitly set to "no" initially
    languages: "no"   // explicitly set to "no" initially
    },
    customSections: [],
    sectionOrder: [
      'education',
      'experience',
      'leadership',
      'awards',
      'certifications',
      'skills'
    ]
  });


  const [isGeneratingBullets, setIsGeneratingBullets] = useState(false);
  const [showBulletSelector, setShowBulletSelector] = useState(false);
  const [generatedBullets, setGeneratedBullets] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  const [showLeadershipBulletSelector, setShowLeadershipBulletSelector] = useState(false);
  const [isGeneratingLeadershipBullets, setIsGeneratingLeadershipBullets] = useState(false);
  const [generatedLeadershipBullets, setGeneratedLeadershipBullets] = useState([]);

  const validateField = (section, index, name, value) => {
    let errors = { ...validationErrors };
  
    const isValueEmpty = !value || value.trim() === '';
  
    switch (section) {
      case 'personalInfo':
        if (isValueEmpty) {
          errors[section] = {
            ...errors[section],
            [name]: 'This field is required',
          };
        } else {
          if (errors[section]) {
            delete errors[section][name];
            if (Object.keys(errors[section]).length === 0) {
              delete errors[section];
            }
          }
        }
        break;
  
      case 'education':
      case 'experience':
        if (isValueEmpty) {
          errors[section] = errors[section] || {};
          errors[section][index] = errors[section][index] || {};
          errors[section][index][name] = 'This field is required';
        } else {
          if (errors[section] && errors[section][index]) {
            delete errors[section][index][name];
            if (Object.keys(errors[section][index]).length === 0) {
              delete errors[section][index];
              if (Object.keys(errors[section]).length === 0) {
                delete errors[section];
              }
            }
          }
        }
        break;
  
      default:
        break;
    }
  
    setValidationErrors(errors);
  };
  const [errorMessages, setErrorMessages] = useState([]);


  const populateResumeData = async (resumeCode) => {

    if (resumeCode) {
        let resumeData;
        let error;

        // Try to fetch from resumes_ai first
        ({ data: resumeData, error } = await supabase
            .from('resumes_ai')
            .select('*')
            .eq('code', resumeCode)
            .single());

        if (!resumeData) {
            // If not found in resumes_ai, try resumes table
            ({ data: resumeData, error } = await supabase
                .from('resumes')
                .select('*')
                .eq('code', resumeCode)
                .single());
        }

        if (error) {
            console.error('Error retrieving resume data:', error);
            return;
        }

      

        if (resumeData) {
          setFileName(resumeData.name);

      
          const educationData = resumeData.education?.map((edu) => ({
            university: edu.university || '',
            location: edu.location || '',
            graduation: Array.isArray(edu.graduation) ? edu.graduation[0] : edu.graduation || '',
            degree: edu.degree || '',
            major: edu.major || '',
            gpa: edu.gpa || 'N/A',
          })) || [];
      
          const experienceData = resumeData.experience?.map((exp) => {
            const { startDate, endDate, isCurrentPosition } = parseDurationString(exp.duration);
            return {
              ...exp,
              startDate,
              endDate,
              isCurrentPosition,
              duration: exp.duration,
            };
          }) || [];
      
          const leadershipData = resumeData.leadership?.map((leader) => {
            const { startDate, endDate, isCurrentPosition } = parseDurationString(leader.duration);
            return {
              ...leader,
              startDate,
              endDate,
              isCurrentPosition,
              duration: leader.duration,
            };
          }) || [];
      
          setFormData({
            name: resumeData.personal_information?.name || '',
            phone: resumeData.personal_information?.phone || '',
            email: resumeData.personal_information?.email || '',
            linkedin: resumeData.personal_information?.linkedin || '',
            education: educationData.length > 0 ? educationData : [{
              university: '',
              location: '',
              graduation: '',
              degree: '',
              major: '',
              gpa: ''
            }],
            experience: experienceData.length > 0 ? experienceData : [{
              company: '',
              jobTitle: '',
              location: '',
              duration: '',
              description: [''],
              startDate: null,
              endDate: null,
              isCurrentPosition: false
            }],
            leadership: leadershipData.length > 0 ? leadershipData : [{
              organization: '',
              location: '',
              duration: '',
              title: '',
              accomplishment: [''],
              startDate: null,
              endDate: null,
              isCurrentPosition: false
            }],
            awards: resumeData.awards?.length > 0 ? resumeData.awards : [{
              award: '',
              location: '',
              date: ''
            }],
            certifications: resumeData.certifications?.length > 0 ? resumeData.certifications : [{
              certification_name: '',
              certification_location: '',
              certification_year: ''
            }],
            skills: resumeData.skills || { technical: '', languages: '' },
            skillsVisibility: {
             technical: resumeData.skillsVisibility?.technical || "no",
    languages: resumeData.skillsVisibility?.languages || "no"
            },
            customSections: resumeData.customSections || [],
            sectionOrder: resumeData.sectionOrder || [
              'education',
              'experience',
              'leadership',
              'awards',
              'certifications',
              'skills'
            ],
          });
      
          setHiddenSections({
            leadership: resumeData.leadership_visibility === 'yes',
            awards: resumeData.awards_visibility === 'yes',
            skills: resumeData.skills_visibility === 'yes',
            certifications: resumeData.certifications_visibility === 'yes'
          });
          setIsResumeRetrieved(true);
          setInitialLoad(false); // Set to false after initial data load

        }
    }
};


  const [fileName, setFileName] = useState(`${new Date().toLocaleDateString()}_${new Date().toLocaleTimeString()}`);
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = (e) => {
    e.preventDefault();
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    setFileName(e.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  const [isResumeRetrieved, setIsResumeRetrieved] = useState(false);


  const [showAIGenerator, setShowAIGenerator] = useState(false);
  const [currentExperienceIndex, setCurrentExperienceIndex] = useState(null);

  // Add these state declarations
const [aiPrompts, setAiPrompts] = useState({});
const [leadershipAiPrompts, setLeadershipAiPrompts] = useState({});

const handleAIGeneration = async (event, index) => {
  event.preventDefault();
  
  if (!formData.experience[index].company || 
      !formData.experience[index].jobTitle || 
      !formData.experience[index].location || 
      !formData.experience[index].duration || 
      !aiPrompts[index]) {
    toast.error("Please fill in all job details and the AI input area before generating.");
    return;
  }

  setIsGeneratingBullets(true);
  setShowAIGenerator(false);
  setShowBulletSelector(true);

  const jobData = {
    company: formData.experience[index].company,
    jobTitle: formData.experience[index].jobTitle,
    location: formData.experience[index].location,
    duration: formData.experience[index].duration,
    explanation: aiPrompts[index],
  };

  try {
    const bullets = await generateBulletPoints(jobData);
    setGeneratedBullets(bullets);
    // Clear the prompt for this index after successful generation
    setAiPrompts(prev => ({...prev, [index]: ''}));
    
    const { data: { user }, error: userError } = await supabase.auth.getUser();
    if (userError) throw userError;

    await supabase
      .from('ai_resume_generations')
      .insert({
        user_id: user.id,
        created_at: new Date().toISOString(),
        job_company: jobData.company,
        job_title: jobData.jobTitle,
        job_location: jobData.location,
        job_duration: jobData.duration,
        user_prompt: jobData.explanation,
        ai_generated_bullets: bullets,
        selected_bullets: [],
      });

  } catch (error) {
    console.error("Error in AI generation:", error);
    toast.error("An error occurred while generating bullet points. Please try again.");
    setShowBulletSelector(false);
  } finally {
    setIsGeneratingBullets(false);
  }
};


const handleAcceptBullet = async (bullet, index) => {
  // First, create a deep copy of the experience array
  const updatedExperience = [...formData.experience];
  
  // Ensure the experience at this index exists and has necessary properties
  if (!updatedExperience[currentExperienceIndex]) {
    updatedExperience[currentExperienceIndex] = {
      company: '',
      jobTitle: '',
      location: '',
      duration: '',
      description: []
    };
  }

  // Ensure description is an array
  if (!Array.isArray(updatedExperience[currentExperienceIndex].description)) {
    updatedExperience[currentExperienceIndex].description = [];
  }

  // Add the new bullet point
  updatedExperience[currentExperienceIndex].description = [
    ...updatedExperience[currentExperienceIndex].description,
    bullet.startsWith('•') ? bullet : `• ${bullet}`
  ];

  // Update form data
  setFormData(prevData => ({
    ...prevData,
    experience: updatedExperience
  }));

  // Remove the accepted bullet from generatedBullets
  setGeneratedBullets(prev => prev.filter(b => b !== bullet));

  // If no more bullets, close the selector
  if (generatedBullets.length <= 1) {
    setShowBulletSelector(false);
  }

};


const handleRejectBullet = (bullet) => {
  setGeneratedBullets(prev => prev.filter(b => b !== bullet));
  if (generatedBullets.length <= 1) {
    setShowBulletSelector(false);
  }
};
  const [resumeScore, setResumeScore] = useState(0);
  const [resumeFeedback, setResumeFeedback] = useState({});
  const [resumeSuggestions, setResumeSuggestions] = useState([]);

  const calculateResumeScore = useCallback(() => {
    const scorer = new ResumeScorer(formData);
    const result = scorer.calculateScore();
    setResumeScore(result.totalScore);
    setResumeFeedback(result.feedback);
    setResumeSuggestions(result.suggestions);
  }, [formData]);
  
  useEffect(() => {
    calculateResumeScore();
  }, [formData, calculateResumeScore]);



  
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const parsedDate = parseISO(dateString);
    if (isValid(parsedDate)) {
      return format(parsedDate, 'MMMM yyyy');
    }
    return dateString; // Fallback to the original string if parsing fails
  };
  
    


  const renderMoveButtons = (sectionKey) => {
    const sectionOrder = formData.sectionOrder;
    const currentIndex = sectionOrder.indexOf(sectionKey);
    const isFirst = currentIndex === 0;
    const isLast = currentIndex === sectionOrder.length - 1;
  
    return (
      <div className="flex items-center space-x-2">
        <button
          type="button"
          onClick={() => moveSectionUpOrDown(sectionKey, 'up')}
          className={`p-1 rounded ${
            isFirst
              ? 'text-gray-300 cursor-not-allowed'
              : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100'
          }`}
          disabled={isFirst}
        >
          <ChevronUpIcon className="h-5 w-5" />
        </button>
        <button
          type="button"
          onClick={() => moveSectionUpOrDown(sectionKey, 'down')}
          className={`p-1 rounded ${
            isLast
              ? 'text-gray-300 cursor-not-allowed'
              : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100'
          }`}
          disabled={isLast}
        >
          <ChevronDownIcon className="h-5 w-5" />
        </button>
      </div>
    );
  };

  const toggleSkillVisibility = (skillType) => {
    setFormData({
      ...formData,
      skillsVisibility: {
        ...formData.skillsVisibility,
        [skillType]: formData.skillsVisibility[skillType] === "yes" ? "" : "no"
      }
    });
  };

  

  function checkAndAddPage(doc, currentY, lineHeight = 5, margin = 20) {
    const pageHeight = 297; // A4 page height in mm (210 x 297 mm)
    if (currentY + lineHeight + margin > pageHeight) {
        doc.addPage();
        return margin; // Reset Y position for the new page
    }
    return currentY;
}

const moveSectionUpOrDown = (sectionName, direction) => {
  const currentOrder = [...formData.sectionOrder];
  const currentIndex = currentOrder.indexOf(sectionName);
  if (currentIndex === -1) return;

  const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
  if (newIndex < 0 || newIndex >= currentOrder.length) return;

  [currentOrder[currentIndex], currentOrder[newIndex]] = [currentOrder[newIndex], currentOrder[currentIndex]];

  setFormData(prevData => ({
    ...prevData,
    sectionOrder: currentOrder
  }));
};






  const navigate = useNavigate();


  const [currentStep, setCurrentStep] = useState(1);
  const [hiddenSections, setHiddenSections] = useState({
    leadership: false,
    awards: false,
    skills: false,
  });

  const handleNext = () => setCurrentStep(currentStep + 1);
  const handlePrevious = () => setCurrentStep(currentStep - 1);


  const handleVisibilityChange = (e) => {
    const { name, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      skillsVisibility: {
        ...prevData.skillsVisibility,
        [name]: checked ? "yes" : "no"  // explicitly set to "yes" or "no"
      }
    }));
  };
  // useEffect(()=>{
  //   console.log("form data updated");
  // },[formData])

  const [resumeTableSource, setResumeTableSource] = useState(null);


  


  const updateResumeData = async (event) => {
    event.preventDefault(); // Prevent form submission

        const { isValid, errorMessages } = validateForm();
    if (!isValid) {
      setErrorMessages(errorMessages);
      setShowErrorSummary(true);
  
      // Scroll to the error summary
      const errorSummaryElement = document.getElementById('error-summary');
      if (errorSummaryElement) {
        errorSummaryElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }
    // Get the current user's ID and email from Supabase
    const { data: { user }, error: userError } = await supabase.auth.getUser();
    if (userError) {
        console.error('Error fetching user:', userError);
        toast.error('Failed to fetch user data.');
        return;
    }
    const userId = user.id;
    const userEmail = user.email;

    const personalInformation = {
        name: formData.name || '',
        phone: formData.phone || '',
        email: formData.email || '',
        linkedin: formData.linkedin || ''
    };

    const education = formData.education.map((edu) => ({
        university: edu.university || '',
        location: edu.location || '',
        graduation: edu.graduation || '',
        degree: edu.degree || '',
        major: edu.major || '',
        gpa: edu.gpa || 'N/A'
    }));

    const experience = formData.experience.map((exp) => ({
        company: exp.company || '',
        jobTitle: exp.jobTitle || '',
        location: exp.location || exp.jobLocation || '',
        duration: exp.duration || exp.jobDuration || '',
        description: Array.isArray(exp.jobDescription || exp.description) ? exp.jobDescription || exp.description : (exp.jobDescription || exp.description || [])
    }));

    const leadership = formData.leadership.map((leader) => ({
        organization: leader.organization || '',
        location: leader.location || leader.organizationCity || '',
        duration: leader.duration || leader.membershipStart || '',
        title: leader.title || '',
        accomplishment: Array.isArray(leader.accomplishment) ? leader.accomplishment : []
    }));

    const awards = formData.awards.map((award) => ({
        award: award.award || '',
        location: award.location || award.awardLocation || '',
        date: award.date || award.awardDate || ''
    }));

    const certifications = formData.certifications.map((cert) => ({
        certification_name: cert.certification_name || '',
        certification_location: cert.certification_location || '',
        certification_year: cert.certification_year || ''
    }));

    const skills = {
        technical: formData.skills.technical || '',
        languages: formData.skills.languages || ''
    };

    const skillsVisibility = {
      technical: formData.skillsVisibility.technical === "yes" ? "yes" : "no",
      languages: formData.skillsVisibility.languages === "yes" ? "yes" : "no"
    };

    const visibility = {
        leadership_visibility: hiddenSections.leadership ? 'yes' : '',
        awards_visibility: hiddenSections.awards ? 'yes' : '',
        skills_visibility: hiddenSections.skills ? 'yes' : ''
    };

    const customSections = formData.customSections.map((section) => ({
      title: section.title || '',
      content: Array.isArray(section.content) ? section.content : [section.content || '']
    }));
  
    // Get current date and time
    const currentDateTime = new Date().toISOString();


    // Create a JSON object with the resume data
    const resumeData = {
      name: fileName,
        user_id: userId,
        user_email: userEmail,
        personal_information: personalInformation,
        education,
        experience,
        leadership,
        awards,
        certifications,
        skills,
        skillsVisibility: skillsVisibility,
        customSections,
        sectionOrder: formData.sectionOrder,
        last_updated: currentDateTime,
        pageNumber,
        ...visibility
    };

    // Retrieve resume code from URL
    const urlParams = new URLSearchParams(window.location.search);
    const resumeCode = urlParams.get('code');
    try {
      let data;
      let error;
  
      if (resumeTableSource === 'resumes_ai') {
        const result = await supabase
          .from('resumes_ai')
          .update(resumeData)
          .eq('code', resumeCode);
        data = result.data;
        error = result.error;
      } else {
        const result = await supabase
          .from('resumes')
          .update(resumeData)
          .eq('code', resumeCode);
        data = result.data;
        error = result.error;
      }
  
  

      toast.success("Resume data has been updated successfully!");
    
    } catch (error) {
        console.error('Error updating resume data:', error);
        toast.error("An error occurred while updating the resume data. Please try again.");
    }
};


  
const handleChange = (e, section, index) => {
  const { name, value } = e.target;


  const parseBoldText = (text) => {
    const parts = text.split(/(\*\*.*?\*\*)/);
    return parts.map((part, index) => {
      if (part.startsWith('**') && part.endsWith('**')) {
        return <strong key={index}>{part.slice(2, -2)}</strong>;
      }
      return part;
    });

    validateField(section, index, name, value);

    handleFormChange(); // Add this line at the end

  };


  if (section === 'skills') {
    setFormData({
      ...formData,
      skills: {
        ...formData.skills,
        [name]: value
      }
    });
  } else if (Array.isArray(formData[section])) {
    const updatedSection = formData[section].map((item, idx) => {
      if (idx === index) {
        let lines = value.split('\n');

        if ((section === 'experience' && name === 'description') || 
            (section === 'leadership' && name === 'accomplishment')) {
          lines = lines.map(line => {
            if (line.trim() === '•') return ''; // Remove the bullet if it's the only character
            return line.startsWith('• ') ? line : `• ${line}`;
          }).filter(line => line.trim() !== ''); // Remove empty lines
        }

        // Check if the current field is an array
        if (Array.isArray(item[name])) {
          return {
            ...item,
            [name]: [lines.join('\n')] // Join lines and wrap in an array
          };
        } else {
          return {
            ...item,
            [name]: lines // Keep as an array of lines
          };
        }
      }
      return item;
    });

    setFormData({
      ...formData,
      [section]: updatedSection
    });
  } else {
    setFormData({
      ...formData,
      [name]: value
    });
  }
};





  
  // Adding a utility function to safely access array elements
  const safeJoin = (arr, separator = '\n') => Array.isArray(arr) ? arr.join(separator) : '';
  
  const handleAddEntry = (section) => {
    const newEntry = {
      education: {
        university: '',
        location: '',
        graduation: '',
        degree: '',
        major: '',
        gpa: ''
      },
      experience: {
        company: '',
        jobTitle: '',
        location: '',
        duration: '',
        description: ['']
      },
      leadership: {
        organization: '',
        location: '',
        duration: '',
        title: '',
        accomplishment: ['']
      },
      awards: {
        award: '',
        location: '',
        date: ''
      }
      ,
      certifications: {
        certification_name: '',
        certification_location: '',
        certification_year: ''
      }
    };

    setFormData({
      ...formData,
      [section]: [...formData[section], newEntry[section]]
    });
  };

  const handleRemoveEntry = (section, index) => {
    if (formData[section].length > 1) {
      const updatedSection = formData[section].filter((_, idx) => idx !== index);
      setFormData({
        ...formData,
        [section]: updatedSection
      });
    }
  };
  const toggleSectionVisibility = (section) => {
    setHiddenSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
    handleFormChange();

  };


  const handleCustomSectionChange = (index, event) => {
    const { name, value } = event.target;
    const updatedCustomSections = formData.customSections.map((section, idx) => {
      if (idx === index) {
        return { ...section, [name]: value };
      }
      return section;
    });
    setFormData({ ...formData, customSections: updatedCustomSections });
  };
  
  const handleAddCustomSection = () => {
    const newCustomSections = [...formData.customSections, { title: '', content: '' }];
    const newSectionOrder = [...formData.sectionOrder, `custom-${formData.customSections.length}`];
    
    setFormData(prevData => ({
      ...prevData,
      customSections: newCustomSections,
      sectionOrder: newSectionOrder
    }));
  };
  
  const handleRemoveCustomSection = (index) => {
    const newCustomSections = formData.customSections.filter((_, i) => i !== index);
    const newSectionOrder = formData.sectionOrder.filter(section => section !== `custom-${index}`);
    
    setFormData(prevData => ({
      ...prevData,
      customSections: newCustomSections,
      sectionOrder: newSectionOrder
    }));
  };
  

  const validateForm = () => {
    let errors = {};
    let errorMessages = [];
  
    // Personal Information
    if (!formData.name || formData.name.trim() === '') {
      errors.personalInfo = { ...errors.personalInfo, name: 'Name is required' };
      errorMessages.push('Name is required in Personal Information');
    }
    if (!formData.phone || formData.phone.trim() === '') {
      errors.personalInfo = { ...errors.personalInfo, phone: 'Phone number is required' };
      errorMessages.push('Phone number is required in Personal Information');
    }
    if (!formData.email || formData.email.trim() === '') {
      errors.personalInfo = { ...errors.personalInfo, email: 'Email is required' };
      errorMessages.push('Email is required in Personal Information');
    }
    if (!formData.linkedin || formData.linkedin.trim() === '') {
      errors.personalInfo = { ...errors.personalInfo, linkedin: 'LinkedIn profile is required' };
      errorMessages.push('LinkedIn profile is required in Personal Information');
    }
  
    // Education
    formData.education.forEach((edu, index) => {
      if (!edu.university || (typeof edu.university === 'string' && edu.university.trim() === '')) {
        errors.education = errors.education || {};
        errors.education[index] = errors.education[index] || {};
        errors.education[index].university = 'University name is required';
        errorMessages.push(`University name is required in Education entry ${index + 1}`);
      }
      if (!edu.location || (typeof edu.location === 'string' && edu.location.trim() === '') || (Array.isArray(edu.location) && edu.location.length === 0)) {
        errors.education = errors.education || {};
        errors.education[index] = errors.education[index] || {};
        errors.education[index].location = 'Location is required';
        errorMessages.push(`Location is required in Education entry ${index + 1}`);
      }
      if (!edu.graduation || (typeof edu.graduation === 'string' && edu.graduation.trim() === '')) {
        errors.education = errors.education || {};
        errors.education[index] = errors.education[index] || {};
        errors.education[index].graduation = 'Graduation date is required';
        errorMessages.push(`Graduation date is required in Education entry ${index + 1}`);
      }
      if (!edu.degree || (typeof edu.degree === 'string' && edu.degree.trim() === '')) {
        errors.education = errors.education || {};
        errors.education[index] = errors.education[index] || {};
        errors.education[index].degree = 'Degree is required';
        errorMessages.push(`Degree is required in Education entry ${index + 1}`);
      }
      if (!edu.major || (typeof edu.major === 'string' && edu.major.trim() === '')) {
        errors.education = errors.education || {};
        errors.education[index] = errors.education[index] || {};
        errors.education[index].major = 'Major is required';
        errorMessages.push(`Major is required in Education entry ${index + 1}`);
      }
    });
  
    // Experience
    formData.experience.forEach((exp, index) => {
      if (!exp.company || (typeof exp.company === 'string' && exp.company.trim() === '')) {
        errors.experience = errors.experience || {};
        errors.experience[index] = errors.experience[index] || {};
        errors.experience[index].company = 'Company name is required';
        errorMessages.push(`Company name is required in Experience entry ${index + 1}`);
      }
      if (!exp.jobTitle || (typeof exp.jobTitle === 'string' && exp.jobTitle.trim() === '')) {
        errors.experience = errors.experience || {};
        errors.experience[index] = errors.experience[index] || {};
        errors.experience[index].jobTitle = 'Job title is required';
        errorMessages.push(`Job title is required in Experience entry ${index + 1}`);
      }
      if (!exp.location || (typeof exp.location === 'string' && exp.location.trim() === '')) {
        errors.experience = errors.experience || {};
        errors.experience[index] = errors.experience[index] || {};
        errors.experience[index].location = 'Location is required';
        errorMessages.push(`Location is required in Experience entry ${index + 1}`);
      }
      if (!exp.duration || exp.duration.trim() === '') {
        errors.experience = errors.experience || {};
        errors.experience[index] = errors.experience[index] || {};
        errors.experience[index].duration = 'Duration is required';
        errorMessages.push(`Duration is required in Experience entry ${index + 1}`);
      }
      if (
        !exp.description ||
        exp.description.length === 0 ||
        exp.description[0].trim() === ''
      ) {
        errors.experience = errors.experience || {};
        errors.experience[index] = errors.experience[index] || {};
        errors.experience[index].description = 'Job description is required';
        errorMessages.push(`Job description is required in Experience entry ${index + 1}`);
      }
    });
  
    setValidationErrors(errors);
  
    return {
      isValid: Object.keys(errors).length === 0,
      errorMessages,
    };
  };
  
  const [showErrorSummary, setShowErrorSummary] = useState(false);

  const isCurrentSectionValid = () => {
    switch (currentStep) {
      case 1:
        return !validationErrors.personalInfo;
      case 2:
        return !validationErrors.education;
      case 3:
        return !validationErrors.experience;
      default:
        return true;
    }
  };
  
  
  const saveResumeData = async (event) => {
    event.preventDefault(); // Prevent form submission

    const { isValid, errorMessages } = validateForm();
    if (!isValid) {
      setErrorMessages(errorMessages);
      setShowErrorSummary(true);
  
      // Scroll to the error summary
      const errorSummaryElement = document.getElementById('error-summary');
      if (errorSummaryElement) {
        errorSummaryElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }
  

    // Get the current user's ID and email from Supabase
    const { data: { user }, error: userError } = await supabase.auth.getUser();
    if (userError) {
        console.error('Error fetching user:', userError);
        toast.error('Failed to fetch user data.');
        return;
    }
    const userId = user.id;
    const userEmail = user.email;

    const personalInformation = {
        name: formData.name || '',
        phone: formData.phone || '',
        email: formData.email || '',
        linkedin: formData.linkedin || ''
    };

    const education = formData.education.map((edu) => ({
        university: edu.university || '',
        location: edu.location || '',
        graduation: edu.graduation || '',
        degree: edu.degree || '',
        major: edu.major || '',
        gpa: edu.gpa || 'N/A'
    }));

    const experience = formData.experience.map((exp) => ({
        company: exp.company || '',
        jobTitle: exp.jobTitle || '',
        location: exp.location || exp.jobLocation || '',
        duration: exp.duration || exp.jobDuration || '',
        description: Array.isArray(exp.jobDescription || exp.description) ? exp.jobDescription || exp.description : (exp.jobDescription || exp.description || [])
    }));

    const leadership = formData.leadership.map((leader) => ({
        organization: leader.organization || '',
        location: leader.location || leader.organizationCity || '',
        duration: leader.duration || leader.membershipStart || '',
        title: leader.title || '',
        accomplishment: Array.isArray(leader.accomplishment) ? leader.accomplishment : []
    }));

    const awards = formData.awards.map((award) => ({
        award: award.award || '',
        location: award.location || award.awardLocation || '',
        date: award.date || award.awardDate || ''
    }));

    const certifications = formData.certifications.map((cert) => ({
        certification_name: cert.certification_name || '',
        certification_location: cert.certification_location || '',
        certification_year: cert.certification_year || ''
    }));

    const skills = {
        technical: formData.skills.technical || '',
        languages: formData.skills.languages || ''
    };

  
  // Extract skills visibility from formData
  const skillsVisibility = formData.skillsVisibility || {
    technical: true,
    languages: true
  };
  

    const visibility = {
        leadership_visibility: hiddenSections.leadership ? 'yes' : '',
        awards_visibility: hiddenSections.awards ? 'yes' : '',
        skills_visibility: hiddenSections.skills ? 'yes' : ''
    };

    const customSections = formData.customSections.map((section) => ({
        title: section.title || '',
        content: section.content.split('\n')
    }));

    // Get current date and time
    const currentDateTime = new Date().toISOString();

    // Creating Resume Name
    const resumeName = `${formData.name}_${new Date().toLocaleDateString()}_${new Date().toLocaleTimeString()}`;

    // Create a JSON object with the resume data
    const resumeData = {
        name: resumeName,
        code: generateUniqueCode(),
        user_id: userId,
        user_email: userEmail,
        personal_information: personalInformation,
        education: formData.education,
        experience: formData.experience,
        leadership: formData.leadership,
        awards: formData.awards.map((award) => ({
          award: award.award || '',
          location: award.location || '',
          date: award.date || ''
        })),
        certifications: formData.certifications.map((cert) => ({
          certification_name: cert.certification_name || '',
          certification_location: cert.certification_location || '',
          certification_year: cert.certification_year || ''
        })),
        skills,
        skillsVisibility: skillsVisibility,
        customSections,
        sectionOrder: formData.sectionOrder,
        last_updated: currentDateTime,
        ...visibility
    };

    try {
        const { data, error } = await supabase
            .from('resumes')
            .insert([resumeData]);

        if (error) {
            throw error;
        }

        console.log('Resume data saved successfully:', data);
        toast.success("Resume data has been saved successfully!");
        setTimeout(() => {
            window.location.href = "/dashboard";
        }, 2000); // Delay of 2 seconds
    } catch (error) {
        console.error('Error saving resume data:', error);
        toast.error("An error occurred while saving the resume data. Please try again.");
    }
};


const parseGraduationDate = (dateInput) => {
  if (!dateInput) return null;

  // If dateInput is an array, take the first element
  if (Array.isArray(dateInput)) {
    dateInput = dateInput[0];
  }

  // Ensure dateInput is a string
  if (typeof dateInput !== 'string') {
    return null;
  }

  let parsedDate;

  // Try parsing 'MMMM yyyy' (e.g., 'May 2025')
  parsedDate = parse(dateInput, 'MMMM yyyy', new Date());
  if (isValid(parsedDate)) {
    return parsedDate;
  }

  // Try parsing 'yyyy-MM' (e.g., '2025-05')
  parsedDate = parse(dateInput, 'yyyy-MM', new Date());
  if (isValid(parsedDate)) {
    return parsedDate;
  }

  // Try parsing ISO format
  parsedDate = parseISO(dateInput);
  if (isValid(parsedDate)) {
    return parsedDate;
  }

  return null; // Return null if parsing fails
};


  // Function to generate a unique code for the resume
  const generateUniqueCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const codeLength = 8;
    let code = '';
  
    for (let i = 0; i < codeLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters.charAt(randomIndex);
    }
  
    return code;
  };
  useEffect(() => {
    if (Object.keys(validationErrors).length === 0 && showErrorSummary) {
      setShowErrorSummary(false);
    }
  }, [validationErrors, showErrorSummary]);
  
  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut(); // Perform logout using Supabase
  
    if (error) {
      console.error('Error logging out:', error.message);
    } else {
      toast.success('Logout successful!'); // Show success toast
  
      // Wait 3 seconds, then navigate to the login page
      setTimeout(() => {
        navigate('/login'); // Redirect to login page
      }, 3000);
    }
  };

 

  const renderEntryMoveButtons = (sectionName, index) => {
    const sectionData = formData[sectionName];
    const isFirst = index === 0;
    const isLast = index === sectionData.length - 1;
    const isOnly = sectionData.length === 1;
  
    return (
      <div className="flex items-center space-x-2">
        <button
          type="button"
          onClick={() => moveEntryUpOrDown(sectionName, index, 'up')}
          className={`p-1 rounded ${isFirst ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100'}`}
          disabled={isFirst}
        >
          <ChevronUpIcon className="h-5 w-5" />
        </button>
        <button
          type="button"
          onClick={() => moveEntryUpOrDown(sectionName, index, 'down')}
          className={`p-1 rounded ${isLast ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100'}`}
          disabled={isLast}
        >
          <ChevronDownIcon className="h-5 w-5" />
        </button>
        {!isOnly && (
          <button
            type="button"
            onClick={() => handleRemoveEntry(sectionName, index)}
            className="p-1 rounded text-red-500 hover:text-red-700 hover:bg-red-100"
          >
            <Trash2 className="h-5 w-5" />
          </button>
        )}
      </div>
    );
  };
  


  const moveEntryUpOrDown = (sectionName, index, direction) => {
    const sectionData = [...formData[sectionName]];
    if (direction === 'up' && index > 0) {
      [sectionData[index - 1], sectionData[index]] = [sectionData[index], sectionData[index - 1]];
    } else if (direction === 'down' && index < sectionData.length - 1) {
      [sectionData[index], sectionData[index + 1]] = [sectionData[index + 1], sectionData[index]];
    }
    setFormData({
      ...formData,
      [sectionName]: sectionData,
    });
  };

  const handleDateChange = (date, section, index, dateType) => {
    const formattedDate = date ? date.toISOString() : '';
    const updatedEntries = [...formData[section]];
    updatedEntries[index] = {
      ...updatedEntries[index],
      date: date.toISOString().split('T')[0], // Format date as YYYY-MM-DD
    };
    setFormData({ ...formData, [section]: updatedEntries });
    
    if (section === 'education') {
      const updatedEducation = formData.education.map((eduItem, idx) => {
        if (idx === index) {
          return { ...eduItem, graduation: formattedDate };
        }
        return eduItem;
      });
      setFormData({ ...formData, education: updatedEducation });
    }
    if (section === 'experience') {
      const updatedExperience = formData.experience.map((expItem, idx) => {
        if (idx === index) {
          return { ...expItem, [dateType]: formattedDate };
        }
        return expItem;
      });
      setFormData({ ...formData, experience: updatedExperience });
    } else if (section === 'leadership') {
      const updatedLeadership = formData.leadership.map((leaderItem, idx) => {
        if (idx === index) {
          return { ...leaderItem, [dateType]: formattedDate };
        }
        return leaderItem;
      });
      setFormData({ ...formData, leadership: updatedLeadership });
    }
  };
  
  // Add these to your existing state declarations
const [showLeadershipAIGenerator, setShowLeadershipAIGenerator] = useState(false);
const [isGeneratingLeadership, setIsGeneratingLeadership] = useState(false);
const [leadershipAIPrompt, setLeadershipAIPrompt] = useState('');
const [currentLeadershipIndex, setCurrentLeadershipIndex] = useState(null);


const handleLeadershipAIGeneration = async (event, index) => {
  event.preventDefault();
  
  if (!formData.leadership[index].organization || 
      !formData.leadership[index].title || 
      !formData.leadership[index].location || 
      !formData.leadership[index].duration || 
      !leadershipAiPrompts[index]) {
    toast.error("Please fill in all leadership details and the AI input area before generating.");
    return;
  }

  setIsGeneratingLeadershipBullets(true);
  setShowLeadershipAIGenerator(false);
  setShowLeadershipBulletSelector(true);

  const leadershipData = {
    organization: formData.leadership[index].organization,
    title: formData.leadership[index].title,
    location: formData.leadership[index].location,
    duration: formData.leadership[index].duration,
    explanation: leadershipAiPrompts[index],
  };

  try {
    const bullets = await generateLeadershipBullets(leadershipData);
    setGeneratedLeadershipBullets(bullets);
    // Clear the prompt for this index after successful generation
    setLeadershipAiPrompts(prev => ({...prev, [index]: ''}));
    
    const { data: { user }, error: userError } = await supabase.auth.getUser();
    if (userError) throw userError;

    await supabase
      .from('ai_leadership_generations')
      .insert({
        user_id: user.id,
        created_at: new Date().toISOString(),
        organization_name: leadershipData.organization,
        leadership_title: leadershipData.title,
        location: leadershipData.location,
        duration: leadershipData.duration,
        user_prompt: leadershipData.explanation,
        ai_generated_bullets: bullets,
        selected_bullets: [],
      });

  } catch (error) {
    console.error("Error in AI generation:", error);
    toast.error("An error occurred while generating bullet points. Please try again.");
    setShowLeadershipBulletSelector(false);
  } finally {
    setIsGeneratingLeadershipBullets(false);
  }
};


const parseDurationString = (duration) => {
  if (!duration) return { startDate: null, endDate: null, isCurrentPosition: false };

  if (Array.isArray(duration)) duration = duration[0];

  const durationStr = String(duration).replace(/[–—]/g, '-').trim();
  const isCurrentPosition = /present|current/i.test(durationStr);

  const parts = durationStr.split('-').map(part => part.trim());

  const parseMonthYear = (dateStr) => {
    if (!dateStr) return null;

    const match = dateStr.match(/([A-Za-z]+)\s*(\d{4})/i);
    if (!match) return new Date(dateStr);

    const month = match[1];
    const year = match[2];
    const monthNames = {
      jan: 0, feb: 1, mar: 2, apr: 3, may: 4, jun: 5,
      jul: 6, aug: 7, sep: 8, oct: 9, nov: 10, dec: 11
    };
    const monthNum = monthNames[month.toLowerCase().slice(0, 3)];
    return new Date(year, monthNum, 1);
  };

  const startDate = parseMonthYear(parts[0]);
  const endDate = !isCurrentPosition && parts[1] ? parseMonthYear(parts[1]) : null;

  return { startDate, endDate, isCurrentPosition };
};


const handleAcceptLeadershipBullet = async (bullet, index) => {
  const updatedLeadership = [...formData.leadership];
  
  // Ensure leadership and accomplishment exist
  if (!updatedLeadership[currentLeadershipIndex]) {
    updatedLeadership[currentLeadershipIndex] = {
      organization: '',
      title: '',
      location: '',
      duration: '',
      accomplishment: []
    };
  }

  // Get current accomplishment array
  let currentAccomplishment = updatedLeadership[currentLeadershipIndex].accomplishment || [];
  
  // Convert to array if it's a string
  if (typeof currentAccomplishment === 'string') {
    currentAccomplishment = currentAccomplishment.split('\n').filter(line => line.trim());
  }

  // Add new bullet point, only add a blank line before if there are existing items
  const newBullet = bullet.startsWith('•') ? bullet : `• ${bullet}`;
  const newAccomplishment = currentAccomplishment.length > 0 
    ? [...currentAccomplishment, newBullet]
    : [newBullet];

  // Update the leadership with new accomplishment
  updatedLeadership[currentLeadershipIndex] = {
    ...updatedLeadership[currentLeadershipIndex],
    accomplishment: newAccomplishment
  };

  // Update form data
  setFormData(prevData => ({
    ...prevData,
    leadership: updatedLeadership
  }));

  // Remove the accepted bullet from generatedLeadershipBullets
  setGeneratedLeadershipBullets(prev => prev.filter(b => b !== bullet));

  // Close selector if no more bullets
  if (generatedLeadershipBullets.length <= 1) {
    setShowLeadershipBulletSelector(false);
  }
};
const handleRejectLeadershipBullet = (bullet) => {
  setGeneratedLeadershipBullets(prev => prev.filter(b => b !== bullet));
  if (generatedLeadershipBullets.length <= 1) {
    setShowLeadershipBulletSelector(false);
  }
};

const formatDateRange = (startDate, endDate, isCurrentPosition) => {
  if (!startDate) return '';

  // Parse dates if they're strings
  const start = startDate instanceof Date ? startDate : parseISO(startDate);
  const end = endDate instanceof Date ? endDate : (endDate ? parseISO(endDate) : null);

  // Format the dates
  const formatDate = (date) => {
    if (!date || !isValid(date)) return '';
    return format(date, 'MMMM yyyy');
  };

  const startStr = formatDate(start);
  const endStr = isCurrentPosition ? 'Present' : formatDate(end);

  // Return formatted string
  return endStr ? `${startStr} - ${endStr}` : startStr;
};

useEffect(() => {
  if (!initialLoad) {
    setShowSaveBox(true);
  }
}, [formData, initialLoad]); // Watch formData changes

// Adjust initialLoad setting in useEffect
useEffect(() => {
  const fetchData = async () => {
    await populateResumeData(resumeCode);
    setInitialLoad(false); // Set initialLoad to false after data is loaded
  };
  fetchData();
}, [resumeCode]);
// Add this function after your other function declarations
// Adjust handleFormChange to just update formData
const handleFormChange = () => {
  setFormData({ ...formData });
};


// Create the SaveBox component inside ResumeBuilder
const SaveBox = () => {
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await updateResumeData({ preventDefault: () => {} });
      setShowSaveBox(false);
      toast.success("Changes saved successfully!");
    } catch (error) {
      toast.error("Failed to save changes");
    } finally {
      setIsSaving(false);
    }
  };

  

  return (
    <Card className="w-full bg-white border-blue-200 mb-4 mt-4" style={{ fontFamily: "Poppins" }}>
    <CardContent className="p-6">
      <div className="flex items-start space-x-4">
     
        <div className="flex-1">
          <h3 className="text-lg font-medium text-gray-900">Save your changes</h3>
          <p className="mt-1 text-sm text-gray-500">
          Any Changes to the resume needs to be saved on cloud, Click on save to store your updates in the cloud so you don't lose them.
          </p>
          <div className="mt-4">
            <Button 
              onClick={handleSave} 
              disabled={isSaving}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {isSaving ? (
                <>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Saving...
                </>
              ) : (
                <>
                  <CloudIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  Save Changes
                </>
              )}
            </Button>
          </div>
        </div>
      </div>
    </CardContent>
  </Card>
  );
};



  return (


    <div className="flex flex-col md:flex-row h-screen bg-gray-100" style={{ fontFamily: "Poppins" }}>
  {/* Sidebar */}
  <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
  
  {/* Main Content */}
  <div className="flex-1 flex flex-col overflow-hidden">


    

<div className="grid grid-cols-1 md:grid-cols-2 h-full">



      {/* Left Column - Form Fields */}

      <div className="overflow-y-auto p-6 bg-grey-50">

     

          <Modal level={currentStep} setLevel={setCurrentStep}/>
          {showSaveBox && isResumeRetrieved && <SaveBox />}

          <form id="resumeForm" className="flex flex-col gap-8 mt-8"   onSubmit={(e) => e.preventDefault()}>
          {showErrorSummary && (
  <Card id="error-summary" className="w-full bg-white border-red-200 mb-4 mt-4" style={{ fontFamily: "Poppins" }}>
    <CardContent className="p-6">
      <div className="flex items-start space-x-4">
        <div className="flex-1">
          <h3 className="text-lg font-medium text-red-900">Please complete the following fields before saving:</h3>
          <ul className="mt-2 text-sm text-red-700 list-disc list-inside">
            {errorMessages.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
        </div>
      </div>
    </CardContent>
  </Card>
)}




            {currentStep === 1 && (
             <div className="rounded-2xl bg-card bg-white box-shadow p-6">
             <h2 className="text-lg font-bold">Personal Information</h2>
             <div className="grid grid-cols-1 gap-6 mt-4 md:grid-cols-2">
               <div>
                 <label htmlFor="name" className="block text-m font-medium text-black">
                   Name
                 </label>
                 <input
                   type="text"
                   id="name"
                   name="name"
                   value={formData.name}
                   onChange={(e) => handleChange(e, 'personalInfo')}
                   placeholder="Full name"
                   required
                   className="input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                 />
                  {validationErrors.personalInfo?.name && (
    <p className="mt-1 text-sm text-red-600">{validationErrors.personalInfo.name}</p>
  )}
               </div>
               <div>
                 <label htmlFor="phone" className="block text-m font-medium text-black">
                   Phone Number
                 </label>
                 <input
                   type="tel"
                   id="phone"
                   name="phone"
                   value={formData.phone}
                   onChange={(e) => handleChange(e, 'personalInfo')}
                   placeholder="(123) 456-7890"
                   required
                   className="input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                 />
                 {validationErrors.personalInfo?.phone && (
    <p className="mt-1 text-sm text-red-600">{validationErrors.personalInfo.phone}</p>
  )}
               </div>
               <div>
                 <label htmlFor="email" className="block text-m font-medium text-black">
                   Email Address
                 </label>
                 <input
                   type="email"
                   id="email"
                   value={formData.email}
                   onChange={(e) => handleChange(e, 'personalInfo')}
                   name="email"
                   placeholder="email@ohio.edu"
                   required
                   className="input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                 />
                 {validationErrors.personalInfo?.email && (
    <p className="mt-1 text-sm text-red-600">{validationErrors.personalInfo.email}</p>
  )}
               </div>
               <div>
                 <label htmlFor="linkedin" className="block text-m font-medium text-black">
                   LinkedIn Profile
                 </label>
                 <input
                   type="url"
                   id="linkedin"
                   name="linkedin"
                   value={formData.linkedin}
                   onChange={(e) => handleChange(e, 'personalInfo')}
                   placeholder="https://linkedin.com/in/your-linkedin-url"
                   required
                   className="input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                 />
                 {validationErrors.personalInfo?.linkedin && (
    <p className="mt-1 text-sm text-red-600">{validationErrors.personalInfo.linkedin}</p>
  )}
               </div>
             </div>
                <div className="flex justify-end mt-6">
                <Button onClick={handleNext}>Next</Button>
                </div>
              </div>
            )}

            {currentStep === 2 && (
  <div className="rounded-2xl bg-card bg-white box-shadow p-6" id="education-section">
    <div className="flex justify-between items-center">
      <h2 className="text-2xl font-bold">Education</h2>
      <div className="flex items-center space-x-2">
        {renderMoveButtons('education')}
        <button type="button" onClick={() => toggleSectionVisibility('education')}>
          {hiddenSections.education ? (
            <EyeOffIcon className="h-6 w-6 text-gray-600" />
          ) : (
            <EyeIcon className="h-6 w-6 text-gray-600" />
          )}
        </button>
      </div>
    </div>

    {formData.education.map((edu, index) => (
      <div key={index} className="mt-6 border-t pt-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-sm font-medium">Education Entry {index + 1}</h3>
          {renderEntryMoveButtons('education', index)}
        </div>

        <div className="education-entry grid grid-cols-1 gap-6 mt-4 md:grid-cols-2">
          <div>
            <label htmlFor={`university-${index}`} className="block text-m font-medium text-black">
              University Name
            </label>
            <input
              type="text"
              id={`university-${index}`}
              name="university"
              placeholder="Ohio University, College of Business"
              value={edu.university}
              onChange={(e) => handleChange(e, 'education', index)}
              required
              className="input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
            {validationErrors.education?.[index]?.university && (
        <p className="mt-1 text-sm text-red-600">
          {validationErrors.education[index].university}
        </p>
      )}
          </div>
          <div>
            <label htmlFor={`location-${index}`} className="block text-m font-medium text-black">
              Location
            </label>
            <input
              type="text"
              id={`location-${index}`}
              name="location"
              value={edu.location}
              placeholder="Athens, Ohio"
              onChange={(e) => handleChange(e, 'education', index)}
              required
              className="input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
            {validationErrors.education?.[index]?.location && (
        <p className="mt-1 text-sm text-red-600">
          {validationErrors.education[index].location}
        </p>
      )}
          </div>
          <div>
            <label htmlFor={`graduation-${index}`} className="block text-m font-medium text-black">
              Expected Graduation Date
            </label>
      
            <DatePicker
        id={`graduation-${index}`}
        selected={parseGraduationDate(edu.graduation)}
        onChange={(date) => handleDateChange(date, 'education', index)}
        dateFormat="MMMM yyyy"
        showMonthYearPicker
        placeholderText="Select Graduation Date"
        className="input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"

      />
      {validationErrors.education?.[index]?.graduation && (
        <p className="mt-1 text-sm text-red-600">
          {validationErrors.education[index].graduation}
        </p>
      )}
          </div>
          <div>
            <label htmlFor={`degree-${index}`} className="block text-m font-medium text-black">
              Degree
            </label>
            <input
              type="text"
              id={`degree-${index}`}
              name="degree"
              placeholder="Bachelor of Business Administration"
              value={edu.degree}
              onChange={(e) => handleChange(e, 'education', index)}
              required
              className="input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
            {validationErrors.education?.[index]?.degree && (
        <p className="mt-1 text-sm text-red-600">
          {validationErrors.education[index].degree}
        </p>
      )}
          </div>
          <div>
            <label htmlFor={`major-${index}`} className="block text-m font-medium text-black">
              Major & Minor
            </label>
            <input
              type="text"
              id={`major-${index}`}
              name="major"
              value={edu.major}
              placeholder="List all majors, and Minors"
              onChange={(e) => handleChange(e, 'education', index)}
              required
              className="input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
            {validationErrors.education?.[index]?.major && (
        <p className="mt-1 text-sm text-red-600">
          {validationErrors.education[index].major}
        </p>
      )}
          </div>
          <div>
            <label htmlFor={`gpa-${index}`} className="block text-m font-medium text-black">
              GPA
            </label>
            <input
              type="number"
              step="0.01"
              id={`gpa-${index}`}
              name="gpa"
              placeholder="3.0"
              value={edu.gpa}
              onChange={(e) => handleChange(e, 'education', index)}
              min="0"
              max="4.0"
              className="input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
            {validationErrors.education?.[index]?.gpa && (
        <p className="mt-1 text-sm text-red-600">
          {validationErrors.education[index].gpa}
        </p>
      )}
          </div>
          
        </div>
        <p className="mt-2 text-sm text-gray-500">
              If you would like to hide your GPA in the resume, you can set it to 0, and it will not be displayed.
            </p>
      </div>
    ))}

    <div className="flex justify-end mt-6">
      <Button onClick={() => handleAddEntry('education')}>Add Education</Button>
    </div>
  </div>
)}

{currentStep === 2 && (
  <div className="flex justify-between mt-12">
    <Button onClick={handlePrevious}>Go Back</Button>
    <Button onClick={handleNext} >Next</Button>
  </div>
)}


{currentStep === 3 && (
  <div className="rounded-2xl bg-card bg-white box-shadow p-6" id="experience-section">
    <div className="flex justify-between items-center">
      <h2 className="text-2xl font-bold">Experience</h2>
      <div className="flex items-center space-x-2">
        {renderMoveButtons('experience')}
        <button type="button" onClick={() => toggleSectionVisibility('experience')}>
          {hiddenSections.experience ? (
            <EyeOffIcon className="h-6 w-6 text-gray-600" />
          ) : (
            <EyeIcon className="h-6 w-6 text-gray-600" />
          )}
        </button>
      </div>
    </div>

    {formData.experience.map((exp, index) => (
      <div key={index} className="mt-6 border-t pt-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-sm font-medium">Experience Entry {index + 1}</h3>
          {renderEntryMoveButtons('experience', index)}
        </div>

        <div className="experience-entry grid grid-cols-1 gap-6 mt-4">
          <div>
            <label htmlFor={`company-${index}`} className="block text-m font-medium text-black">
              Company Name
            </label>
            <input
              type="text"
              id={`company-${index}`}
              name="company"
              value={exp.company}
              placeholder="Name of Company"
              onChange={(e) => handleChange(e, 'experience', index)}
              required
              className="input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
             {validationErrors.experience?.[index]?.company && (
        <p className="mt-1 text-sm text-red-600">
          {validationErrors.experience[index].company}
        </p>
      )}
          </div>
          <div>
            <label htmlFor={`jobTitle-${index}`} className="block text-m font-medium text-black">
              Job Title
            </label>
            <input
              type="text"
              id={`jobTitle-${index}`}
              name="jobTitle"
              value={exp.jobTitle}
              placeholder="Title"
              onChange={(e) => handleChange(e, 'experience', index)}
              required
              className="input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
             {validationErrors.experience?.[index]?.jobTitle && (
        <p className="mt-1 text-sm text-red-600">
          {validationErrors.experience[index].jobTitle}
        </p>
      )}
          </div>
          <div>
            <label htmlFor={`location-${index}`} className="block text-m font-medium text-black">
              Job Location (City, State)
            </label>
            <input
              type="text"
              id={`location-${index}`}
              name="location"
              placeholder="City, State"
              value={exp.location || exp.jobLocation}
              onChange={(e) => handleChange(e, 'experience', index)}
              required
              className="input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
             {validationErrors.experience?.[index]?.location && (
        <p className="mt-1 text-sm text-red-600">
          {validationErrors.experience[index].location}
        </p>
      )}
          </div>


          <div className="hidden"> {/* Add className="hidden" to hide the entire div */}
            <label htmlFor={`duration-${index}`} className="block text-m font-medium text-black">
              Job Duration
            </label>
            <input
              type="text"
              id={`duration-${index}`}
              name="duration"
              value={exp.duration || exp.jobDuration}
              onChange={(e) => handleChange(e, 'experience', index)}
              placeholder="e.g., May 2021 - Present"
              required
              className="input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
             {validationErrors.experience?.[index]?.duration && (
        <p className="mt-1 text-sm text-red-600">
          {validationErrors.experience[index].duration}
        </p>
      )}
          </div>
  
          <DateRangeSelector
  startDate={exp.startDate}
  endDate={exp.endDate}
  isCurrentPosition={exp.isCurrentPosition}
  onStartDateChange={(date) => {
    const updatedExperience = [...formData.experience];
    updatedExperience[index] = {
      ...updatedExperience[index],
      startDate: date,
      duration: formatDateRange(date, exp.endDate, exp.isCurrentPosition)
    };
    setFormData({
      ...formData,
      experience: updatedExperience
    });
  }}
  onEndDateChange={(date) => {
    const updatedExperience = [...formData.experience];
    updatedExperience[index] = {
      ...updatedExperience[index],
      endDate: date,
      duration: formatDateRange(exp.startDate, date, exp.isCurrentPosition)
    };
    setFormData({
      ...formData,
      experience: updatedExperience
    });
  }}
  onCurrentPositionChange={(isCurrentPos) => {
    const updatedExperience = [...formData.experience];
    updatedExperience[index] = {
      ...updatedExperience[index],
      isCurrentPosition: isCurrentPos,
      endDate: null,
      duration: formatDateRange(exp.startDate, null, isCurrentPos)
    };
    setFormData({
      ...formData,
      experience: updatedExperience
    });
  }}
  sectionName="experience"
  index={index}
/>
          
          <div>
            <div className="flex items-center justify-between">
              <label htmlFor={`jobDescription-${index}`} className="block text-m font-medium text-black">
                Job Description
              </label>
              <button
                onClick={() => {
                  setShowAIGenerator(!showAIGenerator);
                  setCurrentExperienceIndex(index);
                }}
                className="input-minimal inline-flex items-center px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition-colors duration-200"
              >
                <svg
                  className="w-4 h-4 mr-2 text-yellow-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                  />
                </svg>
                Generate bullet points with AI
              </button>
            </div>
            <textarea
  id={`jobDescription-${index}`}
  name="description"
  value={Array.isArray(exp.description) ? exp.description.join('\n') : exp.description || ''}
  onChange={(e) => {
    const value = e.target.value;
    const lines = value.split('\n');
    
    // Don't filter empty lines during editing
    const formattedLines = lines.map(line => {
      const trimmedLine = line.trim();
      // Don't add bullet to empty lines
      if (trimmedLine === '') return '';
      // Keep existing bullet points
      if (trimmedLine.startsWith('•')) return trimmedLine;
      // Add bullet point only to non-empty lines without bullets
      return `• ${trimmedLine}`;
    });

    const updatedExperience = [...formData.experience];
    updatedExperience[index] = {
      ...updatedExperience[index],
      description: formattedLines
    };
    setFormData({
      ...formData,
      experience: updatedExperience
    });
  }}
  rows="4"
  required
  className="resize-y block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
  placeholder="Enter your job description..."
/>


{validationErrors.experience?.[index]?.description && (
        <p className="mt-1 text-sm text-red-600">
          {validationErrors.experience[index].description}
        </p>
      )}

          </div>

          {showAIGenerator && currentExperienceIndex === index && (

<div className="mt-4">
  <h4 className="text-lg font-semibold mb-2">AI Bullet Points Generator</h4>
  <textarea
    value={aiPrompts[index] || ''}
    onChange={(e) =>
      setAiPrompts((prev) => ({ ...prev, [index]: e.target.value }))
    }
    rows="4"
    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
    placeholder="Enter a description of your job responsibilities..."
  />
  <p className="text-sm text-gray-500 mt-1">
    {aiPrompts[index]?.length || 0} / 200 characters
  </p>
  {(aiPrompts[index]?.length || 0) < 200 && (
    <p className="text-sm text-red-500 mt-1">
      Please enter at least 200 characters.
    </p>
  )}
  <Button
    onClick={(e) => handleAIGeneration(e, index)}
    className="mt-4 inline-flex items-center rounded-3xl bg-purple-700 px-4 py-2 text-sm font-medium text-white shadow-sm"
    disabled={(aiPrompts[index]?.length || 0) < 200}
  >
    {isGeneratingBullets ? 'Generating...' : 'Generate Bullet Points'}
  </Button>
</div>


)}

        </div>
      </div>
    ))}

    {/* Add this near your AI generation section */}
    <BulletPointSelector 
  isOpen={showBulletSelector}
  onClose={() => setShowBulletSelector(false)}
  suggestions={generatedBullets}
  onAcceptSuggestion={(bullet) => handleAcceptBullet(bullet, currentExperienceIndex)}
  onRejectSuggestion={handleRejectBullet}
  isLoading={isGeneratingBullets}
  type="experience"
/>

    <div className="flex justify-end mt-6">
      <Button onClick={() => handleAddEntry('experience')}>Add Experience</Button>
    </div>
  </div>
)}

{currentStep === 3 && (
  <div className="flex justify-between mt-12">
    <Button onClick={handlePrevious}>Go Back</Button>
    <Button onClick={handleNext}>Next</Button>
  </div>
)}


         
{currentStep === 4 && (
  <div className="rounded-2xl bg-card bg-white box-shadow p-6" id="leadership-section">
    <div className="flex justify-between items-center">
      <h2 className="text-2xl font-bold">Leadership Activities and Memberships</h2>
      <div className="flex items-center space-x-2">
        {renderMoveButtons('leadership')}
        <button type="button" onClick={() => toggleSectionVisibility('leadership')}>
          {hiddenSections.leadership ? (
            <EyeOffIcon className="h-6 w-6 text-gray-600" />
          ) : (
            <EyeIcon className="h-6 w-6 text-gray-600" />
          )}
        </button>
      </div>
    </div>

    {formData.leadership.map((leader, index) => (
      <div key={index} className="mt-6 border-t pt-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-sm font-medium">Leadership Entry {index + 1}</h3>
          {renderEntryMoveButtons('leadership', index)}
        </div>

        <div className="leadership-entry grid grid-cols-1 gap-6 mt-4">
          <div>
            <label htmlFor={`organization-${index}`} className="block text-m font-medium text-black">
              Organization
            </label>
            <input
              type="text"
              id={`organization-${index}`}
              placeholder="Organization Name"
              name="organization"
              value={leader.organization}
              onChange={(e) => handleChange(e, 'leadership', index)}
              required
              className="input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
          </div>

          <div>
            <label htmlFor={`title-${index}`} className="block text-sm font-medium text-gray-700">Title</label>
            <input 
              type="text" 
              id={`title-${index}`} 
              placeholder='Title' 
              name="title" 
              value={leader.title} 
              onChange={(e) => handleChange(e, 'leadership', index)} 
              required 
              className="input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" 
            />
          </div>

          <div>
            <label htmlFor={`location-${index}`} className="block text-m font-medium text-black">
              City, State
            </label>
            <input
              type="text"
              id={`location-${index}`}
              name="location"
              placeholder="City, State"
              value={leader.location || leader.organizationCity}
              onChange={(e) => handleChange(e, 'leadership', index)}
              required
              className="input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
          </div>

          <div className="hidden"> {/* Add className="hidden" to hide the entire div */}
          <label htmlFor={`duration-${index}`} className="block text-m font-medium text-black">
              Duration
            </label>
            <input
              type="text"
              id={`duration-${index}`}
              name="duration"
              value={leader.duration || leader.membershipStart}
              onChange={(e) => handleChange(e, 'leadership', index)}
              placeholder="e.g., May 2021 - Present"
              required
              className="input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
          </div>

          <DateRangeSelector
  startDate={leader.startDate}
  endDate={leader.endDate}
  isCurrentPosition={leader.isCurrentPosition}
  onStartDateChange={(date) => {
    const updatedLeadership = [...formData.leadership];
    updatedLeadership[index] = {
      ...updatedLeadership[index],
      startDate: date,
      duration: formatDateRange(date, leader.endDate, leader.isCurrentPosition)
    };
    setFormData({
      ...formData,
      leadership: updatedLeadership
    });
  }}
  onEndDateChange={(date) => {
    const updatedLeadership = [...formData.leadership];
    updatedLeadership[index] = {
      ...updatedLeadership[index],
      endDate: date,
      duration: formatDateRange(leader.startDate, date, leader.isCurrentPosition)
    };
    setFormData({
      ...formData,
      leadership: updatedLeadership
    });
  }}
  onCurrentPositionChange={(isCurrentPos) => {
    const updatedLeadership = [...formData.leadership];
    updatedLeadership[index] = {
      ...updatedLeadership[index],
      isCurrentPosition: isCurrentPos,
      endDate: null,
      duration: formatDateRange(leader.startDate, null, isCurrentPos)
    };
    setFormData({
      ...formData,
      leadership: updatedLeadership
    });
  }}
  sectionName="leadership"
  index={index}
/>

  

          <div>
          <div className="flex items-center justify-between">

            <label htmlFor={`accomplishment-${index}`} className="block text-m font-medium text-black">
              Accomplishments, Projects, or Experiences
            </label>
            <button
              onClick={() => {
                setShowLeadershipAIGenerator(!showLeadershipAIGenerator);
                setCurrentLeadershipIndex(index);
              }}
              className="input-minimal inline-flex items-center px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary transition-colors duration-200"
              >
              <svg
                className="w-4 h-4 mr-2 text-yellow-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                />
              </svg>
              Generate bullet points with AI
            </button>
            </div>
            <textarea
  id={`accomplishment-${index}`}
  name="accomplishment"
  value={Array.isArray(leader.accomplishment) ? leader.accomplishment.join('\n') : leader.accomplishment || ''}
  onChange={(e) => {
    const value = e.target.value;
    const lines = value.split('\n');
    
    // Don't filter empty lines during editing
    const formattedLines = lines.map(line => {
      const trimmedLine = line.trim();
      // Don't add bullet to empty lines
      if (trimmedLine === '') return '';
      // Keep existing bullet points
      if (trimmedLine.startsWith('•')) return trimmedLine;
      // Add bullet point only to non-empty lines without bullets
      return `• ${trimmedLine}`;
    });

    const updatedLeadership = [...formData.leadership];
    updatedLeadership[index] = {
      ...updatedLeadership[index],
      accomplishment: formattedLines
    };
    setFormData({
      ...formData,
      leadership: updatedLeadership
    });
  }}
  rows="4"
  required
  className="resize-y block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
  placeholder="Enter your accomplishments..."
/>
          </div>
          {showLeadershipAIGenerator && currentLeadershipIndex === index && (

<div className="mt-4">
  <h4 className="text-lg font-semibold mb-2">AI Bullet Points Generator</h4>
  <textarea
    value={leadershipAiPrompts[index] || ''}
    onChange={(e) =>
      setLeadershipAiPrompts((prev) => ({ ...prev, [index]: e.target.value }))
    }
    rows="4"
    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
    placeholder="Describe your leadership role, responsibilities, and achievements..."
  />
  <p className="text-sm text-gray-500 mt-1">
    {leadershipAiPrompts[index]?.length || 0} / 200 characters
  </p>
  {(leadershipAiPrompts[index]?.length || 0) < 200 && (
    <p className="text-sm text-red-500 mt-1">
      Please enter at least 200 characters.
    </p>
  )}
  <Button
    onClick={(e) => handleLeadershipAIGeneration(e, index)}
    className="mt-4 inline-flex items-center rounded-3xl bg-purple-700 px-4 py-2 text-sm font-medium text-white shadow-sm"
    disabled={(leadershipAiPrompts[index]?.length || 0) < 200}
  >
    {isGeneratingLeadershipBullets ? 'Generating...' : 'Generate Bullet Points'}
  </Button>
</div>



)}

<BulletPointSelector 
  isOpen={showLeadershipBulletSelector}
  onClose={() => setShowLeadershipBulletSelector(false)}
  suggestions={generatedLeadershipBullets}
  onAcceptSuggestion={(bullet) => handleAcceptLeadershipBullet(bullet, currentLeadershipIndex)}
  onRejectSuggestion={handleRejectLeadershipBullet}
  isLoading={isGeneratingLeadershipBullets}
  type="leadership"
/>
        </div>
      </div>
    ))}

    <div className="flex justify-end mt-6">
      <Button onClick={() => handleAddEntry('leadership')}>Add Leadership</Button>
    </div>
  </div>
)}

{currentStep === 4 && (
  <div className="flex justify-between mt-12">
    <Button onClick={handlePrevious}>Go Back</Button>
    <Button onClick={handleNext}>Next</Button>
  </div>
)}


{currentStep === 5 && (
  <div className="rounded-2xl bg-card bg-white box-shadow p-6" id="awards-section">
    <div className="flex justify-between items-center">
      <h2 className="text-2xl font-bold">Honors and Awards</h2>
      <div className="flex items-center space-x-2">
        {renderMoveButtons('awards')} {/* Move section up/down */}
        <button type="button" onClick={() => toggleSectionVisibility('awards')}>
          {hiddenSections.awards ? (
            <EyeOffIcon className="h-6 w-6 text-gray-600" />
          ) : (
            <EyeIcon className="h-6 w-6 text-gray-600" />
          )}
        </button>
      </div>
    </div>

    {formData.awards.map((award, index) => (
      <div key={index} className="mt-6 border-t pt-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-sm font-medium">Award Entry {index + 1}</h3>
          {renderEntryMoveButtons('awards', index)} {/* Move entry up/down */}
        </div>

        <div className="awards-entry grid grid-cols-1 gap-6 mt-4">
          <div>
            <label htmlFor={`award-${index}`} className="block text-m font-medium text-black">
              Award/Title/Scholarship
            </label>
            <input
              type="text"
              id={`award-${index}`}
              name="award"
              placeholder="Award/Title/Scholarship"
              value={award.award}
              onChange={(e) => handleChange(e, 'awards', index)}
              required
              className="input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
          </div>
          <div>
            <label htmlFor={`location-${index}`} className="block text-m font-medium text-black">
              City, State
            </label>
            <input
              type="text"
              id={`location-${index}`}
              name="location"
              placeholder="City, State"
              value={award.location || award.awardLocation}
              onChange={(e) => handleChange(e, 'awards', index)}
              required
              className="input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
          </div>
          <div>
            <label htmlFor={`date-${index}`} className="block text-m font-medium text-black">
              Month, Year
            </label>
            <DatePicker
    selected={award.date ? new Date(award.date) : null}
    onChange={(date) => handleDateChange(date, 'awards', index)}
    dateFormat="MM/yyyy"
    showMonthYearPicker
    placeholderText="Select Month and Year"
    className="input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 
    text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 
    placeholder:text-gray-400 sm:text-sm sm:leading-6"
  />
          </div>
        </div>

        <div className="flex justify-between mt-6">
          {formData.awards.length > 1 && (
            <Button onClick={() => handleRemoveEntry('awards', index)}>Remove</Button>
          )}
          <Button onClick={() => handleAddEntry('awards')}>Add Award</Button>
        </div>
      </div>
    ))}

    <div className="flex justify-between mt-12">
      <Button onClick={handlePrevious}>Go Back</Button>
      <Button onClick={handleNext}>Next</Button>
    </div>
  </div>
)}



{currentStep === 6 && (
  <div className="rounded-2xl bg-card bg-white box-shadow p-6" id="certifications-section">
    <div className="flex justify-between items-center">
      <h2 className="text-2xl font-bold">Certifications</h2>
      <div className="flex items-center space-x-2">
        {renderMoveButtons('certifications')} {/* Move section up/down */}
        <button type="button" onClick={() => toggleSectionVisibility('certifications')}>
          {hiddenSections.certifications ? (
            <EyeOffIcon className="h-6 w-6 text-gray-600" />
          ) : (
            <EyeIcon className="h-6 w-6 text-gray-600" />
          )}
        </button>
      </div>
    </div>

    {/* Map over the certification entries */}
    {formData.certifications.map((cert, index) => (
      <div key={index} className="mt-6 border-t pt-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-sm font-medium">Certification {index + 1}</h3>
          {renderEntryMoveButtons('certifications', index)} {/* Move entry up/down */}
        </div>

        <div className="grid grid-cols-1 gap-6">
          <div>
            <label htmlFor={`certification_name-${index}`} className="block text-m font-medium text-black">
              Certification Name
            </label>
            <input
              type="text"
              id={`certification_name-${index}`}
              name="certification_name"
              placeholder="Certification Name"
              value={cert.certification_name}
              onChange={(e) => handleChange(e, 'certifications', index)}
              required
              className="input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
          </div>
          <div>
            <label htmlFor={`certification_location-${index}`} className="block text-m font-medium text-black">
              Issuer Name
            </label>
            <input
              type="text"
              id={`certification_location-${index}`}
              name="certification_location"
              placeholder="Issuer Name"
              value={cert.certification_location}
              onChange={(e) => handleChange(e, 'certifications', index)}
              required
              className="input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
          </div>
          <div>
            <label htmlFor={`certification_year-${index}`} className="block text-m font-medium text-black">
              Year
            </label>
            <input
              type="text"
              id={`certification_year-${index}`}
              name="certification_year"
              placeholder="2023"
              value={cert.certification_year}
              onChange={(e) => handleChange(e, 'certifications', index)}
              required
              className="input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      </div>
    ))}

    {/* Add and remove certification buttons */}
    <div className="flex justify-between mt-6">
      <Button onClick={() => handleAddEntry('certifications')}>Add Certification</Button>
    </div>
  </div>
)}

{currentStep === 6 && (
  <div className="rounded-2xl bg-card bg-white box-shadow p-6 mb-24">
    <div className="flex justify-between items-center">
      <h2 className="text-lg font-bold">Technical and Language Skills</h2>
      <div className="flex items-center space-x-2">
        {renderMoveButtons('skills')} {/* Move skills section up/down */}
        <button type="button" onClick={() => toggleSectionVisibility('skills')}>
          {hiddenSections.skills ? (
            <EyeOffIcon className="h-6 w-6 text-gray-600" />
          ) : (
            <EyeIcon className="h-6 w-6 text-gray-600" />
          )}
        </button>
      </div>
    </div>

    <div className="grid grid-cols-1 gap-6 mt-4">
      <div>
        <label htmlFor="technicalSkills" className="block text-m font-medium text-black">
          Technical Skills
        </label>
        <input
          type="text"
          id="technicalSkills"
          name="technical"
          value={formData.skills?.technical || ''}
          onChange={(e) => handleChange(e, 'skills')}
          placeholder="e.g., Proficient in Java, C++"
          className="input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
        />
     <div className="flex items-center space-x-2 mt-4">
 <button
   id="technicalSkillsVisibility"
   role="switch"
   aria-checked={formData.skillsVisibility?.technical === 'yes'}
   onClick={(e) => handleVisibilityChange({
     target: {
       name: 'technical',
       checked: !(formData.skillsVisibility?.technical === 'yes')
     }
   })}
   className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
     formData.skillsVisibility?.technical === 'yes' ? 'bg-blue-600' : 'bg-gray-200'
   }`}
 >
   <span
     className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
       formData.skillsVisibility?.technical === 'yes' ? 'translate-x-6' : 'translate-x-1'
     }`}
   />
 </button>
 <label
   htmlFor="technicalSkillsVisibility"
   className="text-sm font-medium text-gray-700"
 >
   Display Technical Skills
 </label>
</div>
      </div>
      <div>
        <label htmlFor="languageSkills" className="block text-m font-medium text-black">
          Language Skills
        </label>
        <input
          type="text"
          id="languageSkills"
          name="languages"
          value={formData.skills?.languages || ''}
          onChange={(e) => handleChange(e, 'skills')}
          placeholder="e.g., Fluent in Spanish"
          className="input-minimal block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
        />
       <div className="flex items-center space-x-2 mt-4">
  <button
    id="languageSkillsVisibility"
    role="switch"
    aria-checked={formData.skillsVisibility?.languages === 'yes'}
    onClick={(e) => handleVisibilityChange({
      target: {
        name: 'languages',
        checked: !(formData.skillsVisibility?.languages === 'yes')
      }
    })}
    className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
      formData.skillsVisibility?.languages === 'yes' ? 'bg-blue-600' : 'bg-gray-200'
    }`}
  >
    <span
      className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
        formData.skillsVisibility?.languages === 'yes' ? 'translate-x-6' : 'translate-x-1'
      }`}
    />
  </button>
  <label
    htmlFor="languageSkillsVisibility"
    className="text-sm font-medium text-gray-700"
  >
    Display Language Skills
  </label>
</div>
      </div>
    </div>

    <div className="flex justify-between mt-6">
      <Button onClick={handlePrevious}>Go Back</Button>
      <Button onClick={handleAddCustomSection}>Add Custom Section</Button>
    </div>

    {formData.customSections.map((section, index) => (
      <div key={`custom-${index}`} className="mt-6">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-bold">
            {section.title || `Custom Section ${index + 1}`}
          </h3>
          {renderMoveButtons(`custom-${index}`)} {/* Move custom section up/down */}
        </div>
        <div className="mt-4">
          <label
            htmlFor={`custom-title-${index}`}
            className="block text-m font-medium text-black">
            Title
          </label>
          <input
            type="text"
            id={`custom-title-${index}`}
            name="title"
            value={section.title}
            onChange={(e) => handleCustomSectionChange(index, e)}
            className="block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
          />
        </div>
        <div className="mt-4">
          <label
            htmlFor={`custom-content-${index}`}
            className="block text-m font-medium text-black">
            Content
          </label>
          <textarea
            id={`custom-content-${index}`}
            name="content"
            value={section.content}
            onChange={(e) => handleCustomSectionChange(index, e)}
            rows="4"
            className="block mt-2 w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
          />
        </div>
        <div className="flex justify-end mt-4">
          <button
            type="button"
            onClick={() => handleRemoveCustomSection(index)}
            className="inline-flex items-center rounded-3xl bg-red-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
            Remove Custom Section
          </button>
        </div>
      </div>
    ))}

    <div className="flex justify-end mt-12">
      <Button onClick={handleNext}>Finish</Button>
    </div>
  </div>
)}









            {currentStep === 7 && (
                <div className="rounded-2xl bg-card bg-white box-shadow p-6">


{!isResumeRetrieved ? (
 <h2 className="text-lg font-bold"> You've successfully created your custom resume. You can now update your resume for any final changes, download a PDF version, or go back to make further edits.
 </h2>                ) : (
 <h2 className="text-lg font-bold"> You've successfully edited your resume. Click on Update Resume to make the changes live. You can also download a PDF version, or go back to make further edits.
 </h2>                )}


               
                <div className="flex justify-between mt-6">
                {!isResumeRetrieved ? (
                      <Button onClick={saveResumeData}>Save Resume</Button>
                    ) : (
                      <Button onClick={updateResumeData}>Update Resume</Button>
                    )}
                <Button 
  type="button" 
  className="inline-flex items-center rounded-md border border-gray-300 bg-white px-6 py-3 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
  onClick={() => downloadPDF(formData, hiddenSections, fileName, font, fontSize)}
>
  Download PDF
</Button>

              </div>
                <div className="flex justify-center mt-4">
                  <span className="text-gray-500">----or----</span>
                </div>
                <div className="flex justify-between mt-6">
                <Button onClick={handlePrevious}>Go back, and Edit more</Button>
                </div>
              </div>
            )}
          </form>

          </div>

          <div className="overflow-y-auto p-6">
            <div className="space-y-6">
              <ResumeCard
                formData={formData}
                score={resumeScore}
                feedback={resumeFeedback}
                suggestions={resumeSuggestions}
                onRefresh={calculateResumeScore}
              />
            </div>
            <div className=" rounded-3xl mb-2" style={{border:"1px solid #dddddd"}}>
            <ResumePreviewWithPDF 
                setPageNumber={setPageNumber}
                formData={formData} 
                hiddenSections={hiddenSections}
                font={font}
                fontSize={fontSize}
              />            </div>
          </div>
        </div>





        {/* Right column - ResumeCard and ResumePreviewWithPDF */}
        
       

        <ToastContainer />



  </div>
</div>  




  );
};