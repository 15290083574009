import React from 'react';

import '@fontsource/poppins/index.css';
import '@fontsource/poppins/400-italic.css';
import '@fontsource/poppins/400.css';

const TestingHome = () => {
    return (






    <div className="flex flex-col min-h-[100dvh]">
      <header className="bg-white shadow">
        <div className="container mx-auto flex justify-between items-center py-4 px-6">
          <div className="flex items-center">
            <span className="ml-3 text-xl font-bold">.ai</span>
          </div>
          <nav className="flex space-x-6">
            <a className="text-gray-700" href="#">
              All Features
            </a>
            <a className="text-gray-700" href="#">
              Pricing
            </a>
            <a className="text-gray-700" href="#">
              Resources
            </a>
          </nav>
          <div className="flex space-x-4">
            <button className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2">
              Login
            </button>
            <button className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2">
              Get Started
            </button>
          </div>
        </div>
      </header>
      <main className="container mx-auto py-12 px-6">
        <section className="flex flex-col md:flex-row items-center justify-between mb-16">
          <div className="md:w-1/2 space-y-6">
            <div className="inline-flex w-fit items-center whitespace-nowrap rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80">
              New Technology
            </div>
            <h1 className="text-4xl font-bold">AI-Resume Analysis for Your Career Development</h1>
            <p className="text-gray-700">
              Change the way you approach your next job search. Move 1 step closer to your dream job!
            </p>
            <div className="flex space-x-4">
              <button className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2">
                Try for Free
              </button>
              <button className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2">
                Contact Sales
              </button>
            </div>
          </div>
          <div className="md:w-1/2 mt-8 md:mt-0">
            <img src="/placeholder.svg" alt="Resume Preview" className="w-full" />
          </div>
        </section>
        <section className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center mb-16">
          <div>
            <h2 className="text-3xl font-bold">60,98%</h2>
            <p className="text-gray-700">Interview Rate</p>
          </div>
          <div>
            <h2 className="text-3xl font-bold">8.25/10</h2>
            <p className="text-gray-700">Avg. User Review</p>
          </div>
          <div>
            <h2 className="text-3xl font-bold">~1,8k</h2>
            <p className="text-gray-700">Total Users (and continues to grow)</p>
          </div>
        </section>
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-center mb-8">Why Choose Cadenz.ai</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="rounded-lg border bg-card text-card-foreground shadow-sm">
              <div className="flex flex-col space-y-1.5 p-6">
                <h3 className="whitespace-nowrap text-2xl font-semibold leading-none tracking-tight">
                  Build ATS friendly, keyword rich resumes
                </h3>
              </div>
              <div className="p-6">
                <p className="text-gray-700">Everything you need to make your next career move</p>
              </div>
            </div>
            <div className="rounded-lg border bg-card text-card-foreground shadow-sm">
              <div className="flex flex-col space-y-1.5 p-6">
                <h3 className="whitespace-nowrap text-2xl font-semibold leading-none tracking-tight">
                  Get your resume scored by our AI powered tool
                </h3>
              </div>
              <div className="p-6">
                <p className="text-gray-700">The most powerful resume reviewer, powered by AI</p>
              </div>
            </div>
            <div className="rounded-lg border bg-card text-card-foreground shadow-sm">
              <div className="flex flex-col space-y-1.5 p-6">
                <h3 className="whitespace-nowrap text-2xl font-semibold leading-none tracking-tight">
                  Interview Prep. Relevant Interview questions answers
                </h3>
              </div>
              <div className="p-6">
                <p className="text-gray-700">
                  One-step destination to crack the Interview of your dreams. 500+ Job Roles
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="flex flex-col md:flex-row items-center justify-between mb-16">
          <div className="md:w-1/2">
            <img src="/placeholder.svg" alt="Template Preview" className="w-full" />
          </div>
          <div className="md:w-1/2 mt-8 md:mt-0 space-y-6">
            <h2 className="text-3xl font-bold">Start With Professional Resume Design Template</h2>
            <p className="text-gray-700">
              Customizable resume templates provide well-optimized designs that can be adjusted to your needs in the AI
              resume builder.
            </p>
            <button className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2">
              Create Free Resume
            </button>
          </div>
        </section>
        <section className="flex flex-col md:flex-row items-center justify-between mb-16">
          <div className="md:w-1/2 space-y-6">
            <h2 className="text-3xl font-bold">Using an AI-Powered Resume Builder</h2>
            <p className="text-gray-700">
              An AI-powered resume builder combines the best of both worlds: data-based analysis with the freedom of
              human creativity. A data-driven approach (smart suggestions, resume evaluation) helps eliminate guesswork
              and achieve better results.
            </p>
            <button className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2">
              Get Started Now
            </button>
          </div>
          <div className="md:w-1/2 mt-8 md:mt-0">
            <img src="/placeholder.svg" alt="AI Resume Builder" className="w-full" />
          </div>
        </section>
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-center mb-8">Our Customers Love</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="rounded-lg border bg-card text-card-foreground shadow-sm">
              <div className="flex flex-col space-y-1.5 p-6">
                <h3 className="whitespace-nowrap text-2xl font-semibold leading-none tracking-tight flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-6 h-6 text-yellow-500"
                  >
                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-6 h-6 text-yellow-500"
                  >
                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-6 h-6 text-yellow-500"
                  >
                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-6 h-6 text-yellow-500"
                  >
                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-6 h-6 text-yellow-500"
                  >
                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                  </svg>
                </h3>
              </div>
              <div className="p-6">
                <p className="text-gray-700">
                  "The amount of interviews I got post using Cadenz versus my own resume, it’s a stark difference. I’d
                  guestimate roughly 300% increase in responses, interviews, and feedback."
                </p>
                <p className="text-gray-700 font-bold mt-4">- Maliks Janovy</p>
                <p className="text-gray-700">Product Designer</p>
              </div>
            </div>
            <div className="rounded-lg border bg-card text-card-foreground shadow-sm">
              <div className="flex flex-col space-y-1.5 p-6">
                <h3 className="whitespace-nowrap text-2xl font-semibold leading-none tracking-tight flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-6 h-6 text-yellow-500"
                  >
                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-6 h-6 text-yellow-500"
                  >
                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-6 h-6 text-yellow-500"
                  >
                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-6 h-6 text-yellow-500"
                  >
                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-6 h-6 text-yellow-500"
                  >
                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                  </svg>
                </h3>
              </div>
              <div className="p-6">
                <p className="text-gray-700">
                  "I had a near-zero interview rate using my old resume to apply for positions all over the web. Within
                  just one month using Cadenz, I was contacted by nearly half of all the positions I applied for. Thanks
                  Cadenz!"
                </p>
                <p className="text-gray-700 font-bold mt-4">- Revit Vine</p>
                <p className="text-gray-700">Front-End Developer</p>
              </div>
            </div>
            <div className="rounded-lg border bg-card text-card-foreground shadow-sm">
              <div className="flex flex-col space-y-1.5 p-6">
                <h3 className="whitespace-nowrap text-2xl font-semibold leading-none tracking-tight flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-6 h-6 text-yellow-500"
                  >
                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-6 h-6 text-yellow-500"
                  >
                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-6 h-6 text-yellow-500"
                  >
                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-6 h-6 text-yellow-500"
                  >
                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-6 h-6 text-yellow-500"
                  >
                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                  </svg>
                </h3>
              </div>
              <div className="p-6">
                <p className="text-gray-700">
                  "I had a near-zero interview rate using my old resume to apply for positions all over the web. Within
                  just one month using Cadenz, I was contacted by nearly half of all the positions I applied for. Thanks
                  Cadenz!"
                </p>
                <p className="text-gray-700 font-bold mt-4">- Revit Vine</p>
                <p className="text-gray-700">Front-End Developer</p>
              </div>
            </div>
          </div>
          <div className="text-center mt-8">
            <button className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2">
              See All Stories
            </button>
          </div>
        </section>
        <section className="bg-secondary text-white py-16 text-center ">
          <h2 className="text-4xl font-bold mb-4">Supercharge Your Job Search With Cadenz.ai</h2>
          <div className="flex justify-center space-x-4">
            <button className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2">
              Try for Free
            </button>
            <button className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2">
              Contact Sales
            </button>
          </div>
        </section>
      </main>
      <footer className="bg-white py-12">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <img src="/placeholder.svg" alt="Cadenz.ai" className="h-8 w-8 mb-4" />
            <p className="text-gray-700">Join over 35,307,000 users worldwide</p>
            <button className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2 mt-4">
              Create Free Account
            </button>
          </div>
          <div>
            <h3 className="text-lg font-bold mb-4">AI WRITER</h3>
            <ul className="space-y-2">
              <li>
                <a className="text-gray-700" href="#">
                  Resume Builder
                </a>
              </li>
              <li>
                <a className="text-gray-700" href="#">
                  Cover Letter
                </a>
              </li>
              <li>
                <a className="text-gray-700" href="#">
                  Summary
                </a>
              </li>
              <li>
                <a className="text-gray-700" href="#">
                  Resignation Letter
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-bold mb-4">Company</h3>
            <ul className="space-y-2">
              <li>
                <a className="text-gray-700" href="#">
                  About Us
                </a>
              </li>
              <li>
                <a className="text-gray-700" href="#">
                  Careers{" "}
                  <div className="inline-flex w-fit items-center whitespace-nowrap rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80">
                    NEW
                  </div>
                </a>
              </li>
              <li>
                <a className="text-gray-700" href="#">
                  Contact Us
                </a>
              </li>
              <li>
                <a className="text-gray-700" href="#">
                  User Reviews
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-bold mb-4">Resources</h3>
            <ul className="space-y-2">
              <li>
                <a className="text-gray-700" href="#">
                  Pricing
                </a>
              </li>
              <li>
                <a className="text-gray-700" href="#">
                  LinkedIn Extension
                </a>
              </li>
              <li>
                <a className="text-gray-700" href="#">
                  Cadenz News
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="container mx-auto text-center mt-8">
          <p className="text-gray-700">© 2023 Cadenz.ai. All rights reserved.</p>
        </div>
      </footer>
    </div>
    );
};

export default TestingHome;