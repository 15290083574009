import React from "react";

const NewFooter = () => {
  return (
    <footer className="bg-primary text-primary-foreground py-12" style={{background:'black'}}>
    <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
      <div className="mb-8 md:mb-0">
        <a href="/" className="text-3xl font-bold">Career Redesigned</a>
        <p className="mt-2 text-sm">Building careers, one resume at a time.</p>
      </div>
      <div className="flex flex-col md:flex-row items-center gap-8">
        <nav>
          <ul className="flex space-x-6">
            <li><a href="/terms" className="hover:text-accent-foreground transition-colors">Terms</a></li>
            <li><a href="/privacy" className="hover:text-accent-foreground transition-colors">Privacy</a></li>
          </ul>
        </nav>
       
      </div>
    </div>
    <div className="text-center text-sm">
      <p>&copy; {new Date().getFullYear()} Career Redesigned. All rights reserved.</p>
    </div>
  </footer>
  );
};

export default NewFooter;