import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NewSidebar from '../comp/NewSidebar';
import { supabase } from '../supabaseClient';
import { sendToGoogleAI } from '../comp/sendToGoogleAI';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UpgradePopup from '../comp/UpgradePopup';

// Import UI components
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from '../comp/ui/card';
import { RadioGroup, RadioGroupItem } from '../comp/ui/radio-group';
import { Label } from '../comp/ui/label';
import { Button } from '../comp/ui/button';
import { Input } from '../comp/ui/input';
import { Textarea } from '../comp/ui/textarea';
import { ChevronDown, LogOut } from 'lucide-react';

const ResumeCreator = () => {
  const navigate = useNavigate();

  const [selectedResume, setSelectedResume] = useState(null);
  const [resumes, setResumes] = useState([]);
  const [resumeLimit, setResumeLimit] = useState(10);
  const [jobDetails, setJobDetails] = useState({
    jobTitle: '',
    company: '',
    employmentType: 'Full-time',
    description: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');

  useEffect(() => {
    fetchResumes();
  }, [resumeLimit]);

  const fetchResumes = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const { data, error } = await supabase
        .from('resumes')
        .select('*')
        .eq('user_id', user.id)
        .eq('visibility', true)
        .order('created_at', { ascending: false })
        .limit(resumeLimit);

      if (error) throw error;
      setResumes(data);
    } catch (error) {
      console.error('Error fetching resumes:', error);
      toast.error('Failed to load resumes. Please try again.');
    }
  };

  const handleResumeSelect = (resumeId) => {
    setSelectedResume(resumeId);
  };

  const handleLoadMoreResumes = () => {
    setResumeLimit((prevLimit) => prevLimit + 10);
  };

  const isFormComplete = () => {
    return selectedResume && Object.values(jobDetails).every(val => val !== '');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setJobDetails(prev => ({ ...prev, [name]: value }));
  };

  const handleGenerateResume = async (e) => {
    e.preventDefault();

    if (!isFormComplete()) {
      toast.error("Please complete all fields and select a resume.");
      return;
    }

    setIsLoading(true);
    try {
     

      // Get the current user's email from Supabase Auth
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!user.email) {
        throw new Error('User email not found in authentication data');
      }

      // Fetch the user's bigint ID from the users table using the email
      const { data: userData, error: userDataError } = await supabase
        .from('users')
        .select('id')
        .eq('email', user.email)
        .single();

      if (userDataError) throw userDataError;
      if (!userData) {
        throw new Error('User not found in the database');
      }

      const originalResumeData = await fetchResumeData(selectedResume);
      const aiResponse = await sendToGoogleAI(originalResumeData, jobDetails);

      // Save the optimized resume
      const savedResumeCode = await saveOptimizedResume(aiResponse, originalResumeData);

      toast.success("AI-optimized resume has been saved successfully!");
      setTimeout(() => {
        navigate(`/resumebyai?code=${savedResumeCode}`);
      }, 2000); // Delay of 2 seconds
    } catch (error) {
      console.error('Error generating or saving resume:', error);
      toast.error('Failed to generate or save resume. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchResumeData = async (resumeId) => {
    const { data, error } = await supabase
      .from('resumes')
      .select('*')
      .eq('id', resumeId)
      .single();

    if (error) throw error;
    return data;
  };

  const saveOptimizedResume = async (aiResponse, originalResumeData) => {
    const { data: { user }, error: userError } = await supabase.auth.getUser();
    if (userError) {
      console.error('Error fetching user:', userError);
      throw new Error('Failed to fetch user data.');
    }
    const userId = user.id;
    const userEmail = user.email;

    const newCode = generateUniqueCode();
    const currentDateTime = new Date().toISOString();

    const resumeName = `AI_Optimized_${originalResumeData.name}_${new Date().toLocaleDateString()}_${new Date().toLocaleTimeString()}`;

    const isEmpty = (value) => {
      if (Array.isArray(value)) return value.length === 0;
      if (typeof value === 'object' && value !== null) return Object.keys(value).length === 0;
      return !value;
    };

    const getValueOrOriginal = (aiValue, originalValue) => {
      return isEmpty(aiValue) ? originalValue : aiValue;
    };

    const optimizedResume = aiResponse.optimizedResume || {};

    const resumeToSave = {
      name: resumeName,
      code: newCode,
      user_id: userId,
      user_email: userEmail,
      personal_information: getValueOrOriginal(optimizedResume.personal_information, originalResumeData.personal_information),
      education: getValueOrOriginal(optimizedResume.education, originalResumeData.education),
      experience: getValueOrOriginal(optimizedResume.experience, originalResumeData.experience),
      leadership: getValueOrOriginal(optimizedResume.leadership, originalResumeData.leadership),
      awards: getValueOrOriginal(optimizedResume.awards, originalResumeData.awards),
      certifications: getValueOrOriginal(optimizedResume.certifications, originalResumeData.certifications),
      skills: getValueOrOriginal(optimizedResume.skills, originalResumeData.skills),
      customSections: getValueOrOriginal(optimizedResume.customSections, originalResumeData.customSections),
      sectionOrder: getValueOrOriginal(optimizedResume.sectionOrder, originalResumeData.sectionOrder),
      leadership_visibility: originalResumeData.leadership_visibility || '',
      awards_visibility: originalResumeData.awards_visibility || '',
      skills_visibility: originalResumeData.skills_visibility || '',
      skillsVisibility: getValueOrOriginal(optimizedResume.skillsVisibility, originalResumeData.skillsVisibility),
      aiChanges: JSON.stringify(aiResponse.aiChanges || []),
      improvementSuggestions: JSON.stringify(aiResponse.improvementSuggestions || []),
      jobLikelihoodImprovement: aiResponse.jobLikelihoodImprovement?.toString() || '0',
      created_at: currentDateTime,
      last_updated: currentDateTime,
      original_resume: originalResumeData,
      original_resume_code: originalResumeData.code
    };

    try {
      const { data, error } = await supabase
        .from('resumes_ai')
        .insert([resumeToSave])
        .select('code');

      if (error) {
        throw error;
      }

      console.log('AI-optimized resume data saved successfully');
      return data[0].code;
    } catch (error) {
      console.error('Error saving AI-optimized resume data:', error);
      throw new Error("An error occurred while saving the AI-optimized resume data.");
    }
  };

  const generateUniqueCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const codeLength = 8;
    let code = '';

    for (let i = 0; i < codeLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters.charAt(randomIndex);
    }

    return code;
  };

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
      toast.error('Error logging out.');
    }
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100" style={{ fontFamily: "Poppins" }}>
      <NewSidebar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <header className="flex flex-col md:flex-row justify-between items-center p-4 bg-white shadow-sm">
          <h2 className="text-xl font-semibold text-gray-800 mb-4 md:mb-0">
            AI Resume Creator
          </h2>
          <div className="flex items-center">
            <button
              className="bg-black text-white flex items-center px-3 py-2 rounded-md transition-colors hover:bg-gray-800"
              onClick={handleLogout}
            >
              <LogOut className="w-4 h-4 mr-2" />
              Log Out
            </button>
          </div>
        </header>
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-6">
          {isLoading && (
            <div class="loading-container">
            <div class="spinner"></div>
            <div class="loading-text">Loading...</div>
          </div>
          )}
          <div className="container mx-auto p-4">
            <header className="text-center mb-8">
              <h1 className="text-3xl font-bold mb-2">Create AI-Optimized Resume</h1>
              <p className="text-gray-600">
                Generate a resume optimized for a specific job.
              </p>
            </header>

            <form onSubmit={handleGenerateResume} className="flex flex-col lg:flex-row gap-8">
              <div className="w-full lg:w-1/2">
                <Card className="h-full">
                  <CardHeader>
                    <CardTitle>Select Your Resume</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <RadioGroup value={selectedResume || ""} onValueChange={handleResumeSelect}>
                      {resumes.map((resume) => (
                        <div key={resume.id} className="flex items-start space-x-2 mb-4">
                          <RadioGroupItem value={resume.id} id={`resume-${resume.id}`} />
                          <Label htmlFor={`resume-${resume.id}`} className="flex-grow">
                            <span className="font-medium">{resume.name || 'Untitled Resume'}</span>
                            <p className="text-sm text-gray-500">
                              Created: {new Date(resume.created_at).toLocaleDateString()} 
                            </p>
                          </Label>
                        </div>
                      ))}
                    </RadioGroup>
                    {resumes.length >= resumeLimit && (
                      <Button
                        type="button"
                        variant="outline"
                        className="mt-4 w-full"
                        onClick={handleLoadMoreResumes}
                      >
                        Load More Resumes <ChevronDown className="ml-2 h-4 w-4" />
                      </Button>
                    )}
                  </CardContent>
                </Card>
              </div>

              <div className="w-full lg:w-1/2">
                <Card className="h-full">
                  <CardHeader>
                    <CardTitle>Job Details</CardTitle>
                  </CardHeader>
                  <CardContent className="space-y-4">
                    <div className="space-y-2">
                      <Label htmlFor="jobTitle">Job Title</Label>
                      <Input
                        id="jobTitle"
                        name="jobTitle"
                        value={jobDetails.jobTitle}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="company">Company Name</Label>
                      <Input
                        id="company"
                        name="company"
                        value={jobDetails.company}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="employmentType">Employment Type</Label>
                      <select
                        id="employmentType"
                        name="employmentType"
                        value={jobDetails.employmentType}
                        onChange={handleInputChange}
                        required
                        className="mt-1 block w-full rounded-md border border-gray-400 p-3 shadow-sm sm:text-base"
                      >
                        <option value="Full-time">Full-time</option>
                        <option value="Part-time">Part-time</option>
                        <option value="Contract">Contract</option>
                        <option value="Internship">Internship</option>
                      </select>
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="description">Job Description</Label>
                      <Textarea
                        id="description"
                        name="description"
                        value={jobDetails.description}
                        onChange={handleInputChange}
                        className="min-h-[150px] h-24"
                        required
                      />
                    </div>
                  </CardContent>
                </Card>
              </div>
            </form>

            <Button
              type="submit"
              className="w-full mt-8"
              onClick={handleGenerateResume}
              disabled={!isFormComplete() || isLoading}
            >
              {isLoading ? 'Generating...' : 'Generate Resume'}
            </Button>
          </div>
        </main>
      </div>
      <ToastContainer />
      {showPopup && (
        <UpgradePopup
          message={popupMessage}
          onClose={() => setShowPopup(false)}
          onUpgrade={() => {
            // Implement your upgrade logic here
            console.log('Upgrading plan');
            setShowPopup(false);
          }}
        />
      )}
    </div>
  );
};

export default ResumeCreator;
