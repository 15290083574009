import React, { useState, useEffect } from 'react';
import Sidebar from "../comp/Sidebar"; // Replacing MinimalSidebar with NewSidebar
import { supabase } from '../supabaseClient';
import { sendResumeToGoogleAI } from '../comp/sendResumeToGoogleAI';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UpgradePopup from '../comp/UpgradePopup';
import { Link } from 'react-router-dom';
import { Zap, Briefcase, BarChart2, TrendingUp, Clock, Lightbulb, UserCheck, LineChart, BookOpen } from "lucide-react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from '../comp/ui/card'; // Adjust the import path
import { RadioGroup, RadioGroupItem } from '../comp/ui/radio-group';
import { Label } from '../comp/ui/label';
import { Button } from '../comp/ui/button';
import { Input } from '../comp/ui/input';
import { CheckCircle2, ChevronDown, Search } from 'lucide-react'; // Adjust if necessary

import { useNavigate } from 'react-router-dom';
import JobSearchDialog from '../comp/JobSearchDialog'; 


const JobProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedResume, setSelectedResume] = useState(null);
  const [skills, setSkills] = useState([]);
  const [recommendedJobTitles, setRecommendedJobTitles] = useState([]);
  const [showSkills, setShowSkills] = useState(false);
  const [jobType, setJobType] = useState('full-time');
  const [location, setLocation] = useState('');
  const [resumes, setResumes] = useState([]);
  const [resumeLimit, setResumeLimit] = useState(10);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [user, setUser] = useState(null); // Initialize state to store the user
  const [isOpen, setIsOpen] = useState(false); // Sidebar state
  const [postingTime, setPostingTime] = useState('anytime'); // New state for posting time filter
  const [company, setCompany] = useState('');
  const [experienceLevel, setExperienceLevel] = useState('');
  const [industry, setIndustry] = useState('');
  const [companySize, setCompanySize] = useState('');
  const [onSiteRemote, setOnSiteRemote] = useState('any');
  const [easyApply, setEasyApply] = useState(false);
  const [salaryRange, setSalaryRange] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [pendingLinkedInUrl, setPendingLinkedInUrl] = useState('');

  useEffect(() => {
    const fetchUser = async () => {
      // Fetch the current authenticated user from Supabase
      const { data, error } = await supabase.auth.getUser();

      if (error) {
        console.error('Error fetching user:', error.message);
      } else {
        // Set the user in state if available
        setUser(data.user);
      }
    };

    fetchUser(); // Call the function to fetch user data
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    fetchResumes();
  }, [resumeLimit]);

  const fetchResumes = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const { data, error } = await supabase
        .from('resumes')
        .select('*')
        .eq('user_id', user.id)
        .eq('visibility', true)
        .order('created_at', { ascending: false })
        .limit(resumeLimit);

      if (error) throw error;
      setResumes(data);
    } catch (error) {
      console.error('Error fetching resumes:', error);
      toast.error('Failed to load resumes. Please try again.');
    }
  };

  const handleResumeSelect = (resumeId) => {
    setSelectedResume(resumeId);
  };

  const fetchResumeData = async (resumeId) => {
    try {
      const { data, error } = await supabase
        .from('resumes')
        .select('*')
        .eq('id', resumeId)
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error fetching resume data:', error);
      throw new Error('Failed to fetch resume data');
    }
  };

  const handleSubmitResume = async () => {
    if (!selectedResume) {
      alert("Please select a resume first");
      return;
    }

    setIsLoading(true);
    try {
      const resumeData = await fetchResumeData(selectedResume);
      const aiResponse = await sendResumeToGoogleAI(resumeData);
      setSkills(aiResponse.skills);
      setRecommendedJobTitles(aiResponse.recommendedJobTitles);

      // Save data to Supabase
      const { error } = await supabase
        .from('user_job_profiles')
        .upsert({
          user_id: resumeData.user_id,
          skills: aiResponse.skills,
          recommended_job_titles: aiResponse.recommendedJobTitles,
          created_at: new Date().toISOString(),
        });

      if (error) throw error;

      setShowSkills(true);
    } catch (error) {
      console.error("Error processing resume or saving data:", error);
      alert("An error occurred while processing your resume. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleJobSearchSubmit = async () => {
    setIsLoading(true);
  
    try {
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!user.email) throw new Error('User email not found in authentication data');
  
      const jobTitlesWithType = recommendedJobTitles.map(title =>
        jobType === "internship" ? `${title} internship` : title
      );
  
      const query = jobTitlesWithType.join(' OR ');
      const encodedQuery = encodeURIComponent(query);
      const encodedLocation = encodeURIComponent(location);
      const encodedCompany = encodeURIComponent(company);
      const encodedIndustry = encodeURIComponent(industry);
  
      // Set job type filter
      let jobTypeFilter;
      switch (jobType) {
        case "full-time":
          jobTypeFilter = "F";
          break;
        case "part-time":
          jobTypeFilter = "P";
          break;
        case "contract":
          jobTypeFilter = "C";
          break;
        case "temporary":
          jobTypeFilter = "T";
          break;
        case "volunteer":
          jobTypeFilter = "V";
          break;
        case "internship":
          jobTypeFilter = "I";
          break;
        default:
          jobTypeFilter = "F"; // Default to full-time if unspecified
      }
  
      // Set posting time filter
      const postingTimeFilter = postingTime === 'anytime'
        ? ''
        : postingTime === 'past24hours' ? 'f_TPR=r86400'
        : postingTime === 'pastWeek' ? 'f_TPR=r604800'
        : postingTime === 'pastMonth' ? 'f_TPR=r2592000'
        : '';
  
      // Set experience level filter
 // Corrected experience level mappings
 const experienceLevelFilter = experienceLevel
 ? `f_E=${experienceLevel === '1' ? '2' : experienceLevel === '2' ? '3' : experienceLevel === '3' ? '4' : experienceLevel === '4' ? '5' : '6'}`
 : '';

     // Corrected company size mappings for LinkedIn filter
    const companySizeFilter = companySize
    ? `f_CS=${companySize === '1-10' ? 'A' : companySize === '11-50' ? 'B' : companySize === '51-200' ? 'C' : companySize === '201-500' ? 'D' : companySize === '501-1000' ? 'E' : companySize === '1001-5000' ? 'F' : companySize === '5001-10000' ? 'G' : 'H'}`
    : '';

  // Corrected on-site/remote filter mappings for LinkedIn
  const onSiteRemoteFilter = onSiteRemote === 'on-site'
    ? 'f_WT=1'
    : onSiteRemote === 'remote'
    ? 'f_WT=2'
    : onSiteRemote === 'hybrid'
    ? 'f_WT=3'
    : '';
  
      // Set easy apply filter
      const easyApplyFilter = easyApply ? 'f_AL=true' : '';
  
      // Set salary range filter
      const salaryFilter = salaryRange ? `f_SB2=${salaryRange}` : '';
  
      // Construct the LinkedIn job search URL with all filters
      const linkedInUrl = `https://www.linkedin.com/jobs/search/?keywords=${encodedQuery}&location=${encodedLocation}&f_JT=${jobTypeFilter}&${postingTimeFilter}&${experienceLevelFilter}&${companySizeFilter}&${onSiteRemoteFilter}&${easyApplyFilter}&${salaryFilter}&company=${encodedCompany}&industry=${encodedIndustry}`;
  
   // Store the URL and show the dialog
   setPendingLinkedInUrl(linkedInUrl);
   setIsDialogOpen(true);

 } catch (error) {
   console.error('Error constructing LinkedIn job search URL:', error);
   toast.error('An error occurred while searching for jobs. Please try again.');
 } finally {
   setIsLoading(false);
 }
};
  
const handleDialogConfirm = () => {
  if (pendingLinkedInUrl) {
    window.open(pendingLinkedInUrl, '_blank');
  }
  setIsDialogOpen(false);
};

const handleDialogClose = () => {
  setIsDialogOpen(false);
};

  const handleLoadMoreResumes = () => {
    setResumeLimit((prevLimit) => prevLimit + 10);
  };

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100" style={{ fontFamily: "Poppins" }}>
      {/* Sidebar */}
      <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-6">
          {isLoading && (
          <div class="loading-container">
          <div class="spinner"></div>
          <div class="loading-text">Loading...</div>
        </div>
          )}
          <div className="container mx-auto p-4">
            <header className="text-center mb-4">
              <h1 className="text-3xl font-bold mb-2">AI-Powered Job Match: Find Your Ideal Role</h1>
              <p className="text-gray-600">
              Let our intelligent system align your unique skills with perfect opportunities.
              </p>
            </header>

            <div className="flex justify-center items-center p-4">
            <Card className="w-full max-w-6xl">

        <CardContent className="grid md:grid-cols-2 mt-2 gap-6">
          <div className="space-y-4">
            <h3 className="text-xl font-semibold flex items-center gap-2">
              <Zap className="h-6 w-6 text-primary" />
              What Our AI Job Match Does
            </h3>
            <ul className="space-y-2">
              {[
                { text: "Analyzes your resume for key skills and experiences", icon: Search },
                { text: "Scans thousands of job postings in real-time", icon: Briefcase },
                { text: "Matches you with roles that fit your profile", icon: UserCheck },
                { text: "Provides a \"match score\" for each job opportunity", icon: BarChart2 },
                { text: "Suggests skills to develop for your dream roles", icon: TrendingUp },
              ].map((item, index) => (
                <li key={index} className="flex items-center gap-2">
                  <item.icon className="h-5 w-5 text-primary" />
                  <span>{item.text}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="space-y-4">
            <h3 className="text-xl font-semibold flex items-center gap-2">
              <Lightbulb className="h-6 w-6 text-primary" />
              Why Use AI Job Match?
            </h3>
            <ul className="space-y-2">
              {[
                { text: "Save time by focusing on highly relevant opportunities", icon: Clock },
                { text: "Discover roles you might have overlooked", icon: Search },
                { text: "Understand how employers view your qualifications", icon: UserCheck },
                { text: "Track your competitiveness in the job market", icon: LineChart },
                { text: "Receive personalized job search insights", icon: BookOpen },
              ].map((item, index) => (
                <li key={index} className="flex items-center gap-2">
                  <item.icon className="h-5 w-5 text-primary" />
                  <span>{item.text}</span>
                </li>
              ))}
            </ul>
          </div>
        </CardContent>
        <CardContent>
          <p className="text-sm text-muted-foreground italic mt-4">
            <strong>Pro Tip:</strong> Update your resume regularly to ensure the most accurate job matches!
          </p>
        </CardContent>
      </Card>
    </div>

            {!showSkills ? (
              <Card className="mb-8">
                <CardHeader>
                  <CardTitle>Select the resume that best represents your current career goals:
                  </CardTitle>
                </CardHeader>
                <CardContent>
                <RadioGroup value={selectedResume || ""} onValueChange={handleResumeSelect}>
                      {resumes.map((resume) => (
                        <div key={resume.id} className="flex items-start space-x-2 mb-4">
                          <RadioGroupItem value={resume.id} id={`resume-${resume.id}`} />
                          <Label htmlFor={`resume-${resume.id}`} className="flex-grow">
                            <p className="block text-l font-large text-black" style={{fontSize:'16px'}}>{resume.name || 'Untitled Resume'}</p>
                            <p className="text-sm text-gray-500">
                              Created: {new Date(resume.created_at).toLocaleDateString()} 
                            </p>
                          </Label>
                        </div>
                      ))}
                    </RadioGroup>
                    {resumes.length >= resumeLimit && (
                      <Button
                        type="button"
                        variant="outline"
                        className="mt-4 w-full flex text-center align-middle justify-center"
                        onClick={handleLoadMoreResumes}
                      >
                        Load More Resumes <ChevronDown className="ml-2 h-4 w-4" />
                      </Button>
                    )}
                  

<Link
      to="/builder"
      className="group mt-8 mb-8 flex flex-col items-center justify-center p-6 space-y-4 rounded-lg border border-gray-200 bg-white shadow-sm transition-all duration-300 hover:shadow-md hover:border-primary/50"
    >
      <p className="text-lg font-medium text-black group-hover:text-primary">
        Don't see the right resume?
      </p>
      <Button className="w-full sm:w-auto transition-transform duration-300 group-hover:scale-105">
        Create a New Resume
      </Button>
    </Link>
                  <Button className="mt-4 w-full" onClick={handleSubmitResume}>
                    {isLoading ? 'Processing...' : 'Submit Resume'}
                  </Button>
                </CardContent>
              
              </Card>
            ) : (
              <>
                <Card className="mb-8">
                  <CardHeader>
                    <CardTitle>Your Key Skills</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="flex flex-wrap gap-2">
                      {skills.map((skill, index) => (
                        <span key={index} className="bg-blue-100 text-blue-800 px-2 py-1 rounded">
                          {skill}
                        </span>
                      ))}
                    </div>
                  </CardContent>
                </Card>

                <Card className="mb-8">
                  <CardHeader>
                    <CardTitle>Recommended Job Titles</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <ul className="list-disc pl-5">
                      {recommendedJobTitles.map((title, index) => (
                        <li key={index}>{title}</li>
                      ))}
                    </ul>
                  </CardContent>
                </Card>

                <Card className="mb-8">
                  <CardHeader>
                    <CardTitle>Filter Your Job Search</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                      <div>
                        <Label htmlFor="jobType">Job Type</Label>
                        <select
                          id="jobType"
                          name="jobType"
                          value={jobType}
                          onChange={(e) => setJobType(e.target.value)} 
                          className="mt-1 block w-full py-2 px-3 border bg-white rounded-md"
                        >
                          <option value="full-time">Full-Time</option>
                          <option value="internship">Internship</option>
                          <option value="part-time">Part-Time</option>
                          <option value="contract">Contract</option>
                          <option value="volunteer">Volunteer</option>
                        </select>
                      </div>
                      <div>
                        <Label htmlFor="postingTime">Posting Time</Label>
                        <select
                          id="postingTime"
                          value={postingTime}
                          onChange={(e) => setPostingTime(e.target.value)}
                          className="mt-1 block w-full py-2 px-3 border bg-white rounded-md"
                        >
                          <option value="anytime">Anytime</option>
                          <option value="past24hours">Past 24 Hours</option>
                          <option value="pastWeek">Past Week</option>
                          <option value="pastMonth">Past Month</option>
                        </select>
                      </div>
                      <div>
                        <Label htmlFor="location">Location</Label>
                        <Input
                          type="text"
                          id="location"
                          name="location"
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                          placeholder="Enter location"
                        />
                      </div>  
                      <div>
                        <Label htmlFor="experienceLevel">Experience Level</Label>
                        <select
                          id="experienceLevel"
                          value={experienceLevel}
                          onChange={(e) => setExperienceLevel(e.target.value)}
                          className="mt-1 block w-full py-2 px-3 border bg-white rounded-md"
                        >
                          <option value="">Any Level</option>
                          <option value="1">Entry Level</option>
                          <option value="2">Associate</option>
                          <option value="3">Mid-Senior Level</option>
                          <option value="4">Director</option>
                          <option value="5">Executive</option>
                        </select>
                      </div>
                    
                  
                      <div>
                        <Label htmlFor="onSiteRemote">On-site/Remote</Label>
                        <select
                          id="onSiteRemote"
                          value={onSiteRemote}
                          onChange={(e) => setOnSiteRemote(e.target.value)}
                          className="mt-1 block w-full py-2 px-3 border bg-white rounded-md"
                        >
                          <option value="any">Any</option>
                          <option value="on-site">On-site</option>
                          <option value="remote">Remote</option>
                          <option value="hybrid">Hybrid</option>
                        </select>
                      </div>
                      <div>
                        <Label htmlFor="easyApply">Easy Apply</Label>
                        <div className="flex items-center mt-2">
                          <RadioGroup
                            id="easyApply"
                            checked={easyApply}
                            onCheckedChange={() => setEasyApply(!easyApply)}
                          />
                          <span className="ml-2">Easy Apply Only</span>
                        </div>
                      </div>
                    </div>
                    <Button className="mt-4 w-full" onClick={handleJobSearchSubmit}>
                      {isLoading ? 'Searching...' : 'Search Jobs'}
                    </Button>
                  </CardContent>
                </Card>
              </>
            )}
          </div>
        </main>
      </div>
      <ToastContainer />
      {showPopup && (
        <UpgradePopup
          message={popupMessage}
          onClose={() => setShowPopup(false)}
          onUpgrade={() => {
            // Implement your upgrade logic here
            console.log('Upgrading plan');
            setShowPopup(false);
          }}
        />
      )}
      <JobSearchDialog 
  isOpen={isDialogOpen}
  onClose={handleDialogClose}
  onConfirm={handleDialogConfirm}
/>
    </div>
  );
};

export default JobProfile;

