import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { supabase } from '../supabaseClient';
import MinimalSidebar from "../comp/MinimalSidebar";
import IssueReportButton from '../comp/IssueReportButton';
import GradingResumes from "../comp/grading-resumes";

const ResumeGrader = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    checkAdminStatus();
  }, []);

  const checkAdminStatus = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) {
        console.log("No user logged in");
        toast.error("Please log in to access this feature.");
        setIsLoading(false);
        return;
      }

      console.log("Authenticated user ID:", user.id);

      let { data, error } = await supabase
        .from('users')
        .select('admin')
        .eq('email', user.email)
        .single();

      if (error || !data) {
        console.log("User not found by email, attempting to create new user record");
        const { data: newUser, error: insertError } = await supabase
          .from('users')
          .insert([
            { email: user.email, name: user.user_metadata?.full_name || '', admin: 'no' }
          ])
          .select()
          .single();

        if (insertError) throw insertError;
        data = newUser;
      }

      console.log("Supabase admin data:", data);

      if (data && data.admin === 'yes') {
        setIsAdmin(true);
        console.log("User is an admin");
      } else {
        console.log("User is not an admin");
        toast.error("Sorry, this feature is limited to certain users right now.");
      }
    } catch (error) {
      console.error('Error checking admin status:', error);
      toast.error("An error occurred while checking permissions.");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <div className="flex items-center justify-center h-screen">
      <p className="text-xl text-gray-600">Loading...</p>
    </div>;
  }

  if (!isAdmin) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <p className="text-xl text-gray-600 mb-4">Sorry, you don't have permission to access this feature.</p>
        <button
          onClick={() => navigate('/dashboard')}
          className="bg-cutler-green hover:bg-darker-cutler-green text-white font-bold py-2 px-4 rounded transition duration-300"
        >
          Return to Dashboard
        </button>
      </div>
    );
  }

  return (
    <main className="flex-1 flex gap-8 overflow-y-auto bg-white" style={{ fontFamily: "Poppins" }}>
      <MinimalSidebar />
      <div className="w-full max-w-7xl mx-auto py-12">
        <div className="space-y-8">
          <div>
            <h5 className="text-3xl font-bold tracking-tighter text-cutler-green"
                style={{ fontFamily:'Poppins', color:'cutler-green'}}>
              Select Your Resume
            </h5>
            <div className="bg-white rounded-lg shadow-md mt-4">
              <GradingResumes/>
            </div>
          </div>
        </div>
      </div>
      <IssueReportButton />
    </main>
  );
};

export default ResumeGrader;