
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import OhioLogo from "../img/Ohio-Logo.png";
import { supabase } from '../supabaseClient';
import { Analytics } from "@vercel/analytics/react"
import NewLogo from "../img/logo-ai-black.png";
import { Button } from "../comp/ui/button"

const NewHeader = () => {

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
      const checkUser = async () => {
        const { data: { user } } = await supabase.auth.getUser();
        setIsLoggedIn(!!user);
      };
  
      checkUser();
    }, []);
  
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (

<header className="sticky top-0 z-50 bg-white shadow-md h-20">
  <div className="container mx-auto px-4 h-full flex justify-between items-center">
    <div className="flex items-center h-full">
      <Link className="flex items-center" to="/">
        <img src={NewLogo} alt="Logo" className="h-14 w-auto object-contain" />
      </Link>
    </div>

    <nav className="hidden md:flex">
      <ul className="flex space-x-8 items-center text-sm">
        <li>
          <a href="/terms" className="text-gray-700 hover:text-blue-600 transition-colors">
            Terms of Service
          </a>
        </li>
        <li>
          <a href="/privacy" className="text-gray-700 hover:text-blue-600 transition-colors">
            Privacy
          </a>
        </li>
        <li>
          <Button variant="outline">
            <Link to={isLoggedIn ? "/dashboard" : "/login"}>
              {isLoggedIn ? "Dashboard" : "Sign in"}
            </Link>
          </Button>
        </li>
        {!isLoggedIn && (
          <li>
            <Button>
              <Link to="/signup">Get Started</Link>
            </Button>
          </li>
        )}
      </ul>
    </nav>

    {/* Mobile menu toggle */}
    <div className="md:hidden">
      <button
        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        className="text-gray-500 focus:outline-none"
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16m-7 6h7"
          />
        </svg>
      </button>
    </div>
  </div>

  {/* Mobile Menu */}
  {mobileMenuOpen && (
    <nav className="md:hidden">
      <ul className="flex flex-col space-y-4 px-4 pt-4 pb-4 bg-white ml-auto text-right">
        <li>
          <a href="/terms" className="text-gray-700 hover:text-blue-600 transition-colors">
            Terms of Service
          </a>
        </li>
        <li>
          <a href="/privacy" className="text-gray-700 hover:text-blue-600 transition-colors">
            Privacy
          </a>
        </li>
        <li>
          <Button variant="outline">
            <Link to={isLoggedIn ? "/dashboard" : "/login"}>
              {isLoggedIn ? "Dashboard" : "Sign in"}
            </Link>
          </Button>
        </li>
        {!isLoggedIn && (
          <li>
            <Button>
              <Link to="/signup">Get Started</Link>
            </Button>
          </li>
        )}
      </ul>
    </nav>
  )}
</header>



);
};

export default NewHeader;