import React, { useState, useEffect } from 'react';
import { gradeResumeWithGoogleAI } from './GradingAI';
import { supabase } from '../supabaseClient';


const generateUniqueCode = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const codeLength = 8;
  let code = '';

  for (let i = 0; i < codeLength; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    code += characters.charAt(randomIndex);
  }

  return code;
};

const ClassResumeCard = ({ resumeData }) => {
  const [cardData, setCardData] = useState({
    studentName: "",
    score: 0,
    feedback: {
      strengths: [],
      areasForImprovement: []
    },
    breakdownScores: {}
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editedFeedback, setEditedFeedback] = useState({
    score: 0,
    strengths: [],
    areasForImprovement: []
  });
  const [uniqueCode, setUniqueCode] = useState('');

  useEffect(() => {
    const gradeResume = async () => {
      if (resumeData) {
        setIsLoading(true);
        try {
          const result = await gradeResumeWithGoogleAI(resumeData);
          const processedFeedback = processFeedback(result.feedback);
          const gradingData = {
            ...result,
            feedback: processedFeedback
          };
          setCardData(gradingData);
          setEditedFeedback({
            score: gradingData.score,
            strengths: processedFeedback.strengths,
            areasForImprovement: processedFeedback.areasForImprovement
          });
          const code = generateUniqueCode();
          setUniqueCode(code);

          await saveGradingDataToSupabase(gradingData, resumeData, code);
        } catch (error) {
          console.error("Error grading resume:", error);
          setCardData({
            studentName: resumeData.personal_information?.name || "Error",
            score: 0,
            feedback: {
              strengths: [],
              areasForImprovement: ["An error occurred while grading the resume. Please try again later."]
            },
            breakdownScores: {}
          });
        } finally {
          setIsLoading(false);
        }
      }
    };

    gradeResume();
  }, [resumeData]);

  const saveGradingDataToSupabase = async (gradingData, originalResumeData, code) => {
    try {
      const { data, error } = await supabase
          .from('resume_grades')
          .insert({
            user_id: originalResumeData.user_id,
            student_name: gradingData.studentName,
            created_at: new Date().toISOString(),
            overall_score: gradingData.score,
            strengths: gradingData.feedback.strengths,
            areas_for_improvement: gradingData.feedback.areasForImprovement,
            breakdown_scores: gradingData.breakdownScores,
            resume_data: originalResumeData,
            unique_code: code
          });

      if (error) throw error;
      console.log("Grading data saved successfully");
    } catch (error) {
      console.error("Error saving grading data to Supabase:", error);
    }
  };

  const saveEditedFeedbackToSupabase = async () => {
    try {
      const { data, error } = await supabase
          .from('edited_resume_feedback')
          .insert({
            user_id: resumeData.user_id,
            student_name: cardData.studentName,
            original_score: cardData.score,
            original_strengths: cardData.feedback.strengths,
            original_areas_for_improvement: cardData.feedback.areasForImprovement,
            edited_score: editedFeedback.score,
            edited_strengths: editedFeedback.strengths,
            edited_areas_for_improvement: editedFeedback.areasForImprovement,
            edited_at: new Date().toISOString(),
            unique_code: uniqueCode
          });

      if (error) throw error;
      console.log("Edited feedback saved successfully");
      setIsEditing(false);
      // Update cardData with edited feedback
      setCardData(prev => ({
        ...prev,
        score: editedFeedback.score,
        feedback: {
          strengths: editedFeedback.strengths,
          areasForImprovement: editedFeedback.areasForImprovement
        }
      }));
    } catch (error) {
      console.error("Error saving edited feedback to Supabase:", error);
    }
  };

    const processFeedback = (feedback) => {
      if (typeof feedback === 'object' && feedback !== null) {
        return {
          strengths: Array.isArray(feedback.strengths) ? feedback.strengths : [],
          areasForImprovement: Array.isArray(feedback.areasForImprovement) ? feedback.areasForImprovement : []
        };
      } else if (Array.isArray(feedback)) {
        return {
          strengths: feedback.filter(item => item.toLowerCase().includes('strength')),
          areasForImprovement: feedback.filter(item => item.toLowerCase().includes('improve'))
        };
      } else if (typeof feedback === 'string') {
        return {
          strengths: [],
          areasForImprovement: [feedback]
        };
      }
      return {
        strengths: [],
        areasForImprovement: []
      };
    };
  

  const feedbackCategories = processFeedback(cardData.feedback);

  const getCategoryColor = (category) => {
    const lowerCategory = category.toLowerCase();
    if (lowerCategory.includes('strength') || lowerCategory.includes('positive')) {
      return 'text-green-600';
    } else if (lowerCategory.includes('improve') || lowerCategory.includes('area')) {
      return 'text-amber-600';
    }
    return 'text-blue-600';
  };

  if (isLoading) {
    return (
      <div class="loading-container">
      <div class="spinner"></div>
      <div class="loading-text">Loading...</div>
    </div>
    );
  }

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    saveEditedFeedbackToSupabase();
  };

  const handleInputChange = (category, index, value) => {
    setEditedFeedback(prev => ({
      ...prev,
      [category]: prev[category].map((item, i) => i === index ? value : item)
    }));
  };

  const handleScoreChange = (value) => {
    setEditedFeedback(prev => ({
      ...prev,
      score: Math.max(0, Math.min(50, parseInt(value) || 0))
    }));
  };

  const handleAddItem = (category) => {
    setEditedFeedback(prev => ({
      ...prev,
      [category]: [...prev[category], ""]
    }));
  };

  const handleRemoveItem = (category, index) => {
    setEditedFeedback(prev => ({
      ...prev,
      [category]: prev[category].filter((_, i) => i !== index)
    }));
  };

  if (isLoading) {
    return (
        <div className="spinner-container">
          <div className="spinner">
            <div className="spinnerin"></div>
          </div>
        </div>
    );
  }

  return (
      <div className="rounded-lg border bg-white shadow-sm w-full max-w-2xl mx-auto my-4" data-v0-t="card" style={{ fontFamily:'Poppins'}}>
        <div
            className="bg-primary text-primary-foreground p-6 rounded-t-md"
            style={{ backgroundColor: "#00694E" }}
        >
          <div className="flex items-center justify-between">
            <div>
              <h2 className="text-2xl font-bold text-white">{cardData.studentName || 'Student'}</h2>
              <div className="text-4xl text-white mt-6">
                {isEditing ? (
                    <input
                        type="number"
                        value={editedFeedback.score}
                        onChange={(e) => handleScoreChange(e.target.value)}
                        className="w-16 bg-transparent text-white border-b border-white"
                        min="0"
                        max="50"
                    />
                ) : (
                    cardData.score || 0
                )}
                /50
              </div>
            </div>
            <GraduationCapIcon className="w-12 h-12 text-white" />
          </div>
          <p className="text-sm mt-2 text-white">
            {cardData.score >= 40
                ? "Excellent work! Your resume is well-crafted."
                : cardData.score >= 30
                    ? "Good effort. There's room for some improvements."
                    : "Your resume needs significant work. See feedback below."}
          </p>
          <p className="text-sm mt-2 text-white">Unique Code: {uniqueCode}</p>
        </div>

        <div className="p-6 space-y-4">
          <div className="flex justify-end">
            {isEditing ? (
                <button onClick={handleSave} className="text-blue-600 hover:text-blue-800">
                  <SaveIcon className="w-5 h-5" />
                </button>
            ) : (
                <button onClick={handleEdit} className="text-gray-600 hover:text-gray-800">
                  <EditIcon className="w-5 h-5" />
                </button>
            )}
          </div>
          <div>
            <div className="mt-2 space-y-4 text-gray-700">
              <div>
                <h4 className="font-semibold text-green-600">Strengths:</h4>
                <ul className="list-disc pl-5 mt-2">
                  {(isEditing ? editedFeedback.strengths : cardData.feedback.strengths).map((item, index) => (
                      <li key={index} className="text-m">
                        {isEditing ? (
                            <div className="flex items-center">
                              <input
                                  type="text"
                                  value={item}
                                  onChange={(e) => handleInputChange('strengths', index, e.target.value)}
                                  className="border-b border-gray-300 focus:border-blue-500 outline-none w-full"
                              />
                              <button onClick={() => handleRemoveItem('strengths', index)} className="ml-2 text-red-500">
                                <RemoveIcon className="w-4 h-4" />
                              </button>
                            </div>
                        ) : (
                            item
                        )}
                      </li>
                  ))}
                </ul>
                {isEditing && (
                    <button onClick={() => handleAddItem('strengths')} className="mt-2 text-blue-500">
                      + Add Strength
                    </button>
                )}
              </div>
              <div>
                <h4 className="font-semibold text-amber-600">Areas for Improvement:</h4>
                <ul className="list-disc pl-5 mt-2">
                  {(isEditing ? editedFeedback.areasForImprovement : cardData.feedback.areasForImprovement).map((item, index) => (
                      <li key={index} className="text-m">
                        {isEditing ? (
                            <div className="flex items-center">
                              <input
                                  type="text"
                                  value={item}
                                  onChange={(e) => handleInputChange('areasForImprovement', index, e.target.value)}
                                  className="border-b border-gray-300 focus:border-blue-500 outline-none w-full"
                              />
                              <button onClick={() => handleRemoveItem('areasForImprovement', index)} className="ml-2 text-red-500">
                                <RemoveIcon className="w-4 h-4" />
                              </button>
                            </div>
                        ) : (
                            item
                        )}
                      </li>
                  ))}
                </ul>
                {isEditing && (
                    <button onClick={() => handleAddItem('areasForImprovement')} className="mt-2 text-blue-500">
                      + Add Area for Improvement
                    </button>
                )}
              </div>
            </div>
          </div>
          {Object.keys(cardData.breakdownScores).length > 0 && (
              <div>
                <h4 className="font-semibold text-blue-600">Score Breakdown:</h4>
                <ul className="mt-2">
                  {Object.entries(cardData.breakdownScores).map(([criterion, score]) => (
                      <li key={criterion} className="text-sm">
                        {criterion.replace(/([A-Z])/g, ' $1').trim()}: {score}
                      </li>
                  ))}
                </ul>
              </div>
          )}
        </div>
      </div>
  );
};
    
    function GraduationCapIcon(props) {
      return (
        <svg
          {...props}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M22 10v6M2 10l10-5 10 5-10 5z" />
          <path d="M6 12v5c3 3 9 3 12 0v-5" />
        </svg>
      );
    }
    
    function CheckCircleIcon(props) {
      return (
        <svg
          {...props}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
          <polyline points="22 4 12 14.01 9 11.01" />
        </svg>
      );
    }


function EditIcon(props) {
  return (
      <svg
          {...props}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
      >
        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
      </svg>
  );
}

function SaveIcon(props) {
  return (
      <svg
          {...props}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
      >
        <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z" />
        <polyline points="17 21 17 13 7 13 7 21" />
        <polyline points="7 3 7 8 15 8" />
      </svg>
  );
}

function RemoveIcon(props) {
  return (
      <svg
          {...props}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
      >
        <line x1="18" y1="6" x2="6" y2="18" />
        <line x1="6" y1="6" x2="18" y2="18" />
      </svg>
  );
}


export default ClassResumeCard;