import React, { useState, useEffect } from 'react';
import {
  BarChart3,
  FileText,
  LayoutDashboard,
  MessageSquare,
  Search,
  Settings,
  LogOut,
  PenLineIcon
} from 'lucide-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NewLogo from '../img/Logo Ai.png';
import { supabase } from '../supabaseClient';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';


const Sidebar = ({ isOpen, setIsOpen }) => {
  const location = useLocation(); // Get current path
  const [selectedItem, setSelectedItem] = useState(location.pathname); // Set current path as selected
  const [userName, setUserName] = useState(Cookies.get('userName'));

  useEffect(() => {
    const fetchUserName = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data, error } = await supabase
          .from('users')
          .select('name')
          .eq('email', user.email)
          .single();
  
        if (!error && data) {
          const firstName = data.name.split(' ')[0];
          setUserName(firstName);
          // Optionally, update the cookie or local storage here if needed
        }
      }
    };
  
    fetchUserName();
  }, []);
  
  

  const navigate = useNavigate();

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut(); // Perform logout using Supabase

    if (error) {
      console.error('Error logging out:', error.message);
    } else {
      toast.success('Logout successful!'); // Show success toast
      Cookies.remove('userName'); // Remove username cookie on logout
      // Wait 3 seconds, then navigate to the login page
      setTimeout(() => {
        navigate('/login'); // Redirect to login page
      }, 3000);
    }
  };

  const sidebarItems = [
    { id: 'dashboard', name: 'Dashboard', icon: LayoutDashboard, href: '/dashboard' },
    { id: 'builder', name: 'Resume Builder', icon: FileText, href: '/builder' },
    { id: 'cover letter', name: 'Cover Letter Creator', icon: PenLineIcon, href: '/cover-letter' },
    { id: 'jobsearch', name: 'Find Jobs', icon: Search, href: '/jobprofile' },
    { id: 'analysis', name: 'Compare Resume', icon: BarChart3, href: '/compareResume' },
    { id: 'interview', name: 'Interview Prep AI', icon: MessageSquare, href: '/myinterview' },
    { id: 'settings', name: 'Settings', icon: Settings, href: '/settings' },
    { id: 'logout', name: 'Log out', icon: LogOut, href: '#', onClick: handleLogout },
  ];

  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 md:hidden z-10"
          onClick={() => setIsOpen(false)}
        ></div>
      )}

      {/* Sidebar */}
      <aside
        className={`fixed inset-y-0 left-0 transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } md:relative md:translate-x-0 md:inset-0 md:mt-5 md:ml-5 w-64 bg-white shadow-md overflow-y-auto transition-transform duration-300 ease-in-out z-20 rounded-xl md:rounded-xl`}
      >
        <div className="p-4">
          <Link className="flex items-center" to="/dashboard">
            <img
              src={NewLogo}
              alt="Logo"
              className="h-16 w-auto object-contain"
            />
          </Link>
        </div>
        <div className="p-4 text-lg font-poppins font-medium text-gray-900">
          👋 Hey, {userName}
        </div>
        <hr className="my-3 border-gray-200 box-shadow m-4" />

        <nav className="mt-4">
          {sidebarItems.map((item) => (
            <div>
              {item.id === "settings" && (
                <hr className="my-3 border-gray-200 box-shadow m-4" />
              )}
              <Link
                key={item.id}
                to={item.href}
                className={`flex items-center px-4 py-2 m-2 rounded-xl text-m font-poppins transition-colors duration-200 ${
                  selectedItem === item.href
                    ? "bg-violet-100 text-violet-700"
                    : "text-gray-900 hover:bg-gray-300 hover:text-primary"
                }`}
                onClick={() => {
                  setSelectedItem(item.href); // Set the selected item on click
                  setIsOpen(false); // Close sidebar on mobile
                  if (item.onClick) item.onClick(); // Trigger logout if logout item is clicked
                }}
              >
                <item.icon className="w-5 h-5 mr-3" />
                <span>{item.name}</span>
              </Link>
            </div>
          ))}
        </nav>
      </aside>
    </>
  );
};

export default Sidebar;