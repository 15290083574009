import React from 'react';
import UnderLine from '../../img/underline.svg';

function Header() {
  return (
    <header className="flex flex-col pb-8 w-full">
      <div className="flex flex-col px-5 pt-3.5 w-full">
        <h1 className="self-center text-4xl md:text-5xl lg:text-6xl font-bold tracking-tight leading-none text-center text-indigo-950">
        Craft Your Future, 
        </h1>
        <div className="block z-10 flex-wrap gap-2 mt-2 w-full">
          <p className="flex-auto self-start mt-4 text-4xl md:text-5xl lg:text-6xl font-bold tracking-tight leading-none text-center text-indigo-950">
          One AI-Powered Resume at a Time
          </p>
          <div className="mt-2 justify-end flex">
            <img
              className="p-2 w-full max-w-xs md:max-w-sm lg:max-w-sm"
              src={UnderLine}
              alt="Underline"
            />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
