import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { supabase } from "../supabaseClient.js"; // Import your Supabase client
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Input } from '../comp/ui/input';
import { Label } from '@radix-ui/react-label';
import { Button } from '../comp/ui/button'
import PurpleFooter from '../comp/PurpleFooter';
import PurpleHeader from '../comp/PurpleHeader';

const Login = () => {
  const location = useLocation();
  const [isLoading,setLoading] = useState(false);

  useEffect(() => {
    const checkUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();

      if (user) {
        window.location.href = "/dashboard";
      }
    };

    checkUser();
  }, []);

  useEffect(() => {
    if (location.state?.loggedOut) {
      toast.success('Logged out successfully!');
    }
  }, [location.state]);

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    const email = event.target.elements.email.value;
    const password = event.target.elements.password.value;

    try {
      const { data, error } = await supabase.auth.signInWithPassword({ email, password });

      if (error) {
        if (error.status === 400) {
          toast.error("Invalid email or password. Please try again.");
        } else {
          throw new Error("An unexpected error occurred. Please try again later.");
        }
      } else {
        toast.success("Login successful!");
        window.location.href = "/dashboard";
      }
      setLoading(false)
    } catch (error) {
      console.error("Error logging in:", error);
      toast.error("An unexpected error occurred. Please try again later.");
      setLoading(false);
    }
  };


  return (
    <div className="flex flex-col min-h-screen" style={{ fontFamily: "Poppins" }}>
      <Helmet>
        <title>Login - Career Resigned</title>
        <link rel="icon" href="" />
      </Helmet>

      <PurpleHeader />
      <main className="flex items-center justify-center flex-grow bg-white text-black mt-24">
      <div className="w-full max-w-md px-4">
        <div className="space-y-6">
          <h1 className="text-3xl font-bold">Welcome Back!</h1>
          <p className="text-gray-400">Enter your email below to login to your account</p>
        </div>
        <form id="loginForm" className="space-y-6 mt-8" onSubmit={handleLogin}>
          <div className="space-y-2">
            <Label htmlFor="email" className="text-sm font-medium">
              Email
            </Label>
            <Input
              id="email"
              type="email"
              placeholder="you@example.com"
              required
              className="w-full h-12 px-4 py-2 rounded-md bg-white text-black focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="password" className="text-sm font-medium">
              Password
            </Label>
            <Input
              id="password"
              type="password"
              required
              className="w-full h-12 px-4 py-2 rounded-md bg-white text-black placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <Button
            type="submit"
            disabled={isLoading}
            className="w-full h-12 flex items-center justify-center bg-blue-600 hover:bg-blue-700 rounded-md text-sm font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50"
          >
            {isLoading ? 'Loading...' : 'Login'}
          </Button>
        </form>
        <div className="mt-8 text-center text-sm">
          <p>
            Don't have an account?{' '}
            <a className="underline text-green-800 hover:text-blue-400" href="/signup">
              Sign up
            </a>
          </p>
        </div>

        <div className="mt-4 mb-14 text-center text-sm">
          <p>
           Forgot your password?{' '}
            <a className="underline text-green-800 hover:text-blue-400" href="/forgot-password">
              Reset Password
            </a>
          </p>
        </div>
        <p className="text-sm text-muted-foreground">
          By logging in, you agree to our{' '}
          <a href="/terms" className="underline underline-offset-4 hover:text-primary">
            Terms and Conditions
          </a>
        </p>
      </div>
    </main>

      <ToastContainer />


      <PurpleFooter />
    </div>
  );
};

export default Login;