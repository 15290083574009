import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../img/favicon.png';

const Footer = () => {
  return (
    <footer className="p-4 bg-white md:p-8 lg:p-10 dark:bg-gray-800 box-shadow ">
      <div className="mx-auto max-w-screen-xl text-center">
        <a
          href="#"
          className="flex justify-center items-center text-2xl font-semibold text-gray-900 dark:text-white"
        >
          <img src={logo} alt="Logo" className="mr-2 h-8" />
          Career Redesigned
        </a>
        <p className="my-6 text-gray-600 dark:text-gray-400">
          Create your perfect resume effortlessly with our AI-powered resume builder.
        </p>
        <ul className="flex flex-wrap justify-center items-center mb-6 text-gray-900 dark:text-white gap-5">
          <li>
            <Link to="/terms" class="hover:underline me-4 md:me-6">
              Terms of Service
            </Link>
          </li>
          <li>
            <Link to="/privacy" class="hover:underline me-4 md:me-6">
              Privary Policy
            </Link>
          </li>
        </ul>
        <span className="text-sm text-gray-600 sm:text-center dark:text-gray-400">
          © {new Date().getFullYear()}{' '}
          <a href="#" className="hover:underline">
            Career Redesigned™
          </a>
          . All Rights Reserved.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
