import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { Check } from 'lucide-react';
import React, { forwardRef } from 'react';

const Checkbox = forwardRef(({ className = '', ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={`
      peer h-4 w-4 shrink-0 rounded-sm border border-primary ring-offset-background 
      focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring 
      focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 
      data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground
      ${className}
    `.trim()}
    {...props}
  >
    <CheckboxPrimitive.Indicator className="flex items-center justify-center text-current">
      <Check className="h-4 w-4" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));

Checkbox.displayName = 'Checkbox';

export { Checkbox };
