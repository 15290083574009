import React, { useState, useEffect } from 'react';
import MinimalSidebar from "../comp/MinimalSidebar";
import { supabase } from '../supabaseClient';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DOMAIN = 'https://www.careerredesigned.com';

const Referral = () => {
  const [copied, setCopied] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const [invitedUsers, setInvitedUsers] = useState(0);
  const [userInitials, setUserInitials] = useState('');
  const [userName, setUserName] = useState('');
  const [usageLimits, setUsageLimits] = useState({
    ai_resume_limit: 0,
    job_search_limit: 0,
    interview_prep_limit: 0
  });



  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data: userData, error: userError } = await supabase
          .from('users')
          .select('name, referral_code, id')
          .eq('email', user.email)
          .single();

        if (userError) throw userError;

        if (userData) {
          setUserName(userData.name || 'User');
          setUserInitials(userData.name ? userData.name.split(' ').map(n => n[0]).join('').toUpperCase() : 'U');
          setReferralCode(userData.referral_code || await createReferralCode(userData.id));
        }

        const { count, error: referralsError } = await supabase
          .from('referrals')
          .select('id', { count: 'exact', head: true })
          .eq('referrer_id', userData.id)
          .eq('status', 'completed');

        if (referralsError) throw referralsError;
        setInvitedUsers(count || 0);

        const { data: usageData, error: usageError } = await supabase
          .from('subscriptions')
          .select('ai_resume_limit, job_search_limit, interview_prep_limit')
          .eq('user_id', userData.id)
          .single();

        if (usageError) throw usageError;
        if (usageData) setUsageLimits(usageData);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      toast.error('Failed to load user data. Please try again.');
    }
  };

  const createReferralCode = async (userId) => {
    const newCode = Math.random().toString(36).substring(2, 8).toUpperCase();
    try {
      const { error } = await supabase
        .from('users')
        .update({ referral_code: newCode })
        .eq('id', userId);
      
      if (error) throw error;
      return newCode;
    } catch (error) {
      console.error('Error creating referral code:', error);
      toast.error('Failed to create referral code. Please try again.');
      return '';
    }
  };

  const referralLink = `${DOMAIN}/signup?ref=${referralCode}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink);
    setCopied(true);
    toast.success('Referral link copied to clipboard!');
    setTimeout(() => setCopied(false), 2000);
  };

  const shareOnSocialMedia = (platform) => {
    let url;
    switch (platform) {
      case 'Facebook':
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(referralLink)}`;
        break;
      case 'Twitter':
        url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent('Check out this amazing resume builder!')}`;
        break;
      case 'LinkedIn':
        url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(referralLink)}&title=${encodeURIComponent('Amazing Resume Builder')}&summary=${encodeURIComponent('Join me on this great resume building platform!')}`;
        break;
      default:
        return;
    }
    window.open(url, '_blank');
  };


  const cardStyle = {
    flex: '1 1 30%',
    minWidth: '250px',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    margin: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  };

  const titleStyle = {
    color: '#333',
    marginBottom: '10px',
    fontSize: '1.2rem',
    fontWeight: 'bold',
  };

  
  return (
    <main className="flex-1 flex gap-8 overflow-y-auto bg-white" style={{ fontFamily: "poppins" }}>
      <MinimalSidebar />
      <div className="container mx-auto px-12 py-8">
        <header style={{ textAlign: 'center', marginBottom: '30px' }}>
          <h1 style={{ color: '#333', marginBottom: '10px' }}>Referral Program</h1>
          <p style={{ color: '#666', fontSize: '18px' }}>
            Upgrade your daily limit by referring friends to our resume builder!
          </p>
        </header>
      
        <div style={{ marginBottom: '20px' }}>
          <h3 style={{ color: '#333', marginBottom: '10px' }}>Your Referral Link</h3>
          <div style={{ display: 'flex' }}>
            <input 
              type="text" 
              value={referralLink} 
              readOnly 
              style={{ flexGrow: 1, padding: '10px', border: '1px solid #ccc', borderRadius: '4px 0 0 4px' }}
            />
            <button 
              onClick={copyToClipboard} 
              style={{ 
                padding: '10px 20px', 
                backgroundColor: copied ? '#00694E' : '#00694E', 
                color: 'white', 
                border: 'none', 
                borderRadius: '0 4px 4px 0', 
                cursor: 'pointer' 
              }}
            >
              {copied ? 'Copied!' : 'Copy'}
            </button>
          </div>
        </div>

        <div>
          <h3 style={{ color: '#333', marginBottom: '10px' }}>Share Your Link</h3>
          <div style={{ display: 'flex', gap: '10px' }}>
            {['Facebook', 'Twitter', 'LinkedIn'].map((platform) => (
              <button 
                key={platform}
                onClick={() => shareOnSocialMedia(platform)}
                style={{ 
                  padding: '10px 20px', 
                  backgroundColor: '#00694E', 
                  color: 'white', 
                  border: 'none', 
                  borderRadius: '4px', 
                  cursor: 'pointer' 
                }}
              >
                {platform}
              </button>
            ))}
          </div>
        </div>


        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: '30px', marginBottom: '30px' }}>
      {/* Referral Status Card */}
      <div style={cardStyle}>
        <h3 style={titleStyle}>Referral Status</h3>
        <p style={{ fontSize: '18px' }}>
          Users Invited: <strong>{invitedUsers}</strong>
        </p>
      </div>

      {/* Rewards Card */}
      <div style={cardStyle}>
        <h3 style={titleStyle}>Rewards</h3>
        <div style={{ marginBottom: '15px' }}>
          <h4 style={{ color: '#444', marginBottom: '5px', fontWeight: 'bold' }}>For you:</h4>
          <p>Get +2 tasks per day for each successful referral</p>
        </div>
        <div>
          <h4 style={{ color: '#444', marginBottom: '5px', fontWeight: 'bold' }}>For your friend:</h4>
          <p>They also get +2 tasks per day when they sign up</p>
        </div>
      </div>

      {/* Current Daily Limits Card */}
      <div style={cardStyle}>
        <h3 style={titleStyle}>Current Daily Limits</h3>
        <p>AI Resume: {usageLimits.ai_resume_limit} per day</p>
        <p>Job Search: {usageLimits.job_search_limit} per day</p>
        <p>Interview Prep: {usageLimits.interview_prep_limit} per day</p>
      </div>
    </div>


       
      </div>
      <ToastContainer />
    </main>
  );
}

export default Referral;