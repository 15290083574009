import React, { forwardRef } from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { Circle } from 'lucide-react';

const RadioGroup = forwardRef(({ className = '', ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root className={`grid gap-2 ${className}`.trim()} {...props} ref={ref} />
  );
});

RadioGroup.displayName = 'RadioGroup';

const RadioGroupItem = forwardRef(({ className = '', ...props }, ref) => {
  const baseClasses = `
    aspect-square h-4 w-4 rounded-full border border-primary text-primary 
    ring-offset-background focus:outline-none focus-visible:ring-2 
    focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed 
    disabled:opacity-50
  `;

  return (
    <RadioGroupPrimitive.Item ref={ref} className={`${baseClasses} ${className}`.trim()} {...props}>
      <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
        <Circle className="h-2.5 w-2.5 fill-current text-current" />
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
  );
});

RadioGroupItem.displayName = 'RadioGroupItem';

export { RadioGroup, RadioGroupItem };
