import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Button } from "../comp/ui/button";
import { Card, CardContent } from "../comp/ui/card";
import { FileDown } from "lucide-react";
import Sidebar from "../comp/Sidebar";
import { supabase } from "../supabaseClient";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { downloadCoverLetterPDF } from "../comp/CoverLetterDownloadPDF";
import { generateCoverLetterWord } from "../comp/CoverLetterDownloadWord";

const CoverLetterDisplay = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const [coverLetterData, setCoverLetterData] = useState(null);
  const [resumeData, setResumeData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCoverLetter = async () => {
      try {
        const code = searchParams.get("code");
        if (!code) {
          console.error("No code provided in URL");
          toast.error("No cover letter code provided");
          return;
        }

        // Fetch cover letter data
        const { data: coverLetter, error: coverLetterError } = await supabase
          .from("cover_letters")
          .select("*, resume_id")
          .eq("unique_code", code)
          .single();

        if (coverLetterError) throw coverLetterError;
        setCoverLetterData(coverLetter);

        // Fetch resume data
        const { data: resume, error: resumeError } = await supabase
          .from("resumes")
          .select("*")
          .eq("id", coverLetter.resume_id)
          .single();

        if (resumeError) throw resumeError;
        setResumeData(resume);
      } catch (error) {
        toast.error("Failed to load cover letter");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCoverLetter();
  }, [searchParams]);

  if (isLoading) {
    return (
      <div className="flex flex-col md:flex-row h-screen bg-gray-100">
        <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
        <div className="flex-1 flex items-center justify-center">
          <p>Loading cover letter...</p>
        </div>
      </div>
    );
  }

  if (!coverLetterData || !resumeData) {
    return (
      <div className="flex flex-col md:flex-row h-screen bg-gray-100">
        <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
        <div className="flex-1 flex items-center justify-center">
          <p>Cover letter not found or data incomplete</p>
        </div>
      </div>
    );
  }

  const { personal_information: personalInfo } = resumeData;
  const formatDate = (date) =>
    new Date(date).toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });

  return (
    <div
      className="flex flex-col md:flex-row h-screen bg-gray-100"
      style={{ fontFamily: "Poppins" }}
    >
      <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />

      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          <div className="container mx-auto px-4 py-8 max-w-4xl">
            <div className="flex flex-col items-center gap-6 mb-5">
              {/* Cover Letter Details */}
              <div className="text-center">
                <h1 className="text-4xl font-bold text-primary">
                  Cover Letter
                </h1>
                <p className="text-lg text-gray-600 mt-2">
                  {coverLetterData.name}
                </p>
                <p className="text-sm text-gray-500">
                  {formatDate(coverLetterData.created_at)}
                </p>
              </div>

              {/* Download Buttons */}
              <div className="flex gap-4">
                <Button
                  onClick={() =>
                    downloadCoverLetterPDF(coverLetterData, resumeData)
                  }
                  variant="outline"
                  className="flex items-center gap-2 px-6 py-3"
                >
                  <FileDown className="h-5 w-5" />
                  Download PDF
                </Button>
                <Button
                  onClick={() =>
                    generateCoverLetterWord(coverLetterData, resumeData)
                  }
                  variant="outline"
                  className="flex items-center gap-2 px-6 py-3"
                >
                  <FileDown className="h-5 w-5" />
                  Download Word
                </Button>
              </div>
            </div>

            <Card>
              <CardContent className="p-6 space-y-4">
                <div className="text-center mb-8">
                  <h1 className="text-4xl font-bold">
                    {personalInfo?.name || "Name not provided"}
                  </h1>
                  <p className="text-muted-foreground">
                    {personalInfo?.phone && `${personalInfo.phone}`}
                    {personalInfo?.email && ` | ${personalInfo.email}`}
                    {personalInfo?.linkedin &&
                      ` | ${personalInfo.linkedin.replace(
                        " https://",
                        ""
                      )}`}
                  </p>
                </div>

                <div className="whitespace-pre-line leading-relaxed">
                  {coverLetterData.cover_letter_content}
                </div>
              </CardContent>
            </Card>

            {/* Actions */}
          </div>
        </main>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CoverLetterDisplay;
