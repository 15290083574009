import { setFontFamily } from 'pdfjs-dist/build/pdf.worker';
import React from 'react';

// Button Component
export const Button = ({
  children,
  className = '',
  type = 'button',
  disabled = false,
  onClick,
  ...props
}) => {
  const baseStyle = {
    padding: '10px 20px',
    fontSize: '14px',
    setFontFamily: 'font-poppin',
    border: 'none',
    borderRadius: '0.5rem',
    cursor: 'pointer',
    transform: 'scale(1)',
    // transition: 'background-color 0.3s, opacity 0.3s',
    transition: 'transform 1s ease',
    backgroundColor: '#603CFF', // Primary color
    color: '#ffffff'
  };

  const hoverStyle = {
    // backgroundColor: '#120F43',
    transform: 'scale(1.03)',
    boxShadow: '18px 30px 50px 2px rgba(98, 156, 215, 0.18)'
  };

  const disabledStyle = {
    opacity: 0.6,
    cursor: 'not-allowed'
  };

  return (
    <button
      type={type}
      className={className}
      style={{
        ...baseStyle,
        ...(disabled ? disabledStyle : {})
      }}
      disabled={disabled}
      onClick={onClick}
      onMouseEnter={(e) => {
        if (!disabled) {
          Object.assign(e.target.style, hoverStyle);
        }
      }}
      onMouseLeave={(e) => {
        if (!disabled) {
          Object.assign(e.target.style, baseStyle);
        }
      }}
      {...props}
    >
      {children}
    </button>
  );
};
