import { GoogleGenerativeAI } from "@google/generative-ai";
import { jsonrepair } from 'jsonrepair'; // Import jsonrepair

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const genAI = new GoogleGenerativeAI(API_KEY);

export async function generateInterviewQuestions(jobDetails) {
  const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

  const prompt = `
Given the following job details:
${JSON.stringify(jobDetails)}

Please generate 8-10 potential interview questions with answers and suggestions, ranging from basic questions like "Tell me about yourself" to more challenging questions specific to the job.

Return the results in **valid JSON format**, and output **only** the JSON, with no additional text.

The JSON should have the following structure:

{
  "interview_questions": [
    {
      "number": 1,
      "question": "Question text here",
      "suggested_answer": "Suggested answer here",
      "suggestions": ["Suggestion 1", "Suggestion 2", "Suggestion 3"]
    },
    ...
  ]
}

Ensure that all JSON keys and string values are double-quoted, all key-value pairs are properly separated by commas, and the JSON is syntactically correct. Do not include any text outside of the JSON object. Do not add any extra commas or quotation marks.
`;

  try {
    const result = await model.generateContent(prompt);
    const response = await result.response;
    const text = await response.text();


    // Try parsing methods in order of preference
    const parsedData = tryParseMethods(text);

    if (!parsedData || !parsedData.interview_questions || parsedData.interview_questions.length === 0) {
      throw new Error("No valid questions found in the AI response");
    }

    return parsedData;
  } catch (error) {
    console.error("Error generating interview questions:", error);
    throw new Error("Failed to generate interview questions");
  }
}

function tryParseMethods(text) {
  const parseMethods = [
    tryParseJSON,
    tryRepairJSON,
    tryExtractAndRepairJSON,
    tryParseStructured,
    tryParseUnstructured
  ];

  for (const method of parseMethods) {
    try {
      const result = method(text);
      if (result && result.interview_questions && result.interview_questions.length > 0) {
        return result;
      }
    } catch (error) {
      console.warn(`Parsing method failed: ${method.name}`, error);
    }
  }

  return null;
}

function tryParseJSON(text) {
  return JSON.parse(text);
}

function tryRepairJSON(text) {
  try {
    const repairedJson = jsonrepair(text);
    return JSON.parse(repairedJson);
  } catch (error) {
    throw error;
  }
}

function tryExtractAndRepairJSON(text) {
  // Extract JSON object from text
  const jsonRegex = /\{[\s\S]*\}/;
  const match = text.match(jsonRegex);

  if (match) {
    const jsonText = match[0];

    // Use jsonrepair to fix any JSON syntax errors
    try {
      const repairedJson = jsonrepair(jsonText);
      return JSON.parse(repairedJson);
    } catch (error) {
      throw error;
    }
  }

  throw new Error("No valid JSON object found in the text");
}


function tryParseStructured(text) {
  const questions = [];
  const lines = text.split('\n');
  let currentQuestion = {};

  for (const line of lines) {
    if (line.startsWith('Number:')) {
      if (Object.keys(currentQuestion).length > 0) {
        questions.push(currentQuestion);
      }
      currentQuestion = { number: parseInt(line.split(':')[1].trim()) };
    } else if (line.startsWith('Question:')) {
      currentQuestion.question = line.split(':')[1].trim();
    } else if (line.startsWith('Suggested Answer:')) {
      currentQuestion.suggested_answer = line.split(':')[1].trim();
    } else if (line.startsWith('Suggestions:')) {
      currentQuestion.suggestions = [];
    } else if (line.trim().startsWith('-')) {
      currentQuestion.suggestions.push(line.trim().substring(1).trim());
    }
  }

  if (Object.keys(currentQuestion).length > 0) {
    questions.push(currentQuestion);
  }

  return { interview_questions: questions };
}

function tryParseUnstructured(text) {
  const questions = [];
  const questionRegex = /(\d+)\.\s*(.+?)(?=\d+\.|\Z)/gs;
  let match;

  while ((match = questionRegex.exec(text)) !== null) {
    const [, number, content] = match;
    const lines = content.split('\n').map(line => line.trim()).filter(Boolean);

    if (lines.length >= 2) {
      questions.push({
        number: parseInt(number),
        question: lines[0],
        suggested_answer: lines[1],
        suggestions: lines.slice(2).map(line => line.replace(/^-\s*/, ''))
      });
    }
  }

  return { interview_questions: questions };
}
