import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NewLogo from "../img/logo-ai-black.png";
import {
  LayoutDashboard,
  FileText,
  BarChart3,
  Search,
  MessageSquare,
  Settings,
  Menu,
  X,
} from 'lucide-react';

const NewSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const sidebarItems = [
    { name: 'Dashboard', icon: LayoutDashboard, href: '/dashboard' },
    { name: 'Builder', icon: FileText, href: '/builder' },
    { name: 'AI Analysis', icon: BarChart3, href: '/compareResume' },
    { name: 'Job Search', icon: Search, href: '/jobprofile' },
    { name: 'Interview', icon: MessageSquare, href: '/myinterview' },
    { name: 'Settings', icon: Settings, href: '/settings' },
  ];

  return (
    <>
      {/* Mobile Header */}
      <div className="md:hidden flex items-center justify-between p-4 bg-white shadow-md">
        <Link to="/dashboard">
          <img src={NewLogo} alt="Logo" className="h-8 w-auto object-contain" />
        </Link>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="text-gray-600 hover:text-primary focus:outline-none"
        >
          {isOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
        </button>
      </div>

      {/* Overlay */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 md:hidden z-10"
          onClick={() => setIsOpen(false)}
        ></div>
      )}

      {/* Sidebar */}
      <aside
        className={`fixed inset-y-0 left-0 transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } md:translate-x-0 md:relative md:inset-0 w-55 bg-white shadow-md overflow-y-auto transition-transform duration-300 ease-in-out z-20`}
      >
        <div className="p-4">
          <Link className="flex items-center" to="/dashboard">
            <img src={NewLogo} alt="Logo" className="h-16 w-auto object-contain" />
          </Link>
        </div>
        <nav className="mt-8">
          {sidebarItems.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className="flex items-center px-4 py-2 text-m text-gray-600 hover:bg-gray-100 hover:text-primary transition-colors duration-200"
              onClick={() => setIsOpen(false)} // Close sidebar on item click (mobile)
            >
              <item.icon className="w-5 h-5 mr-3" />
              {item.name}
            </Link>
          ))}
        </nav>
      </aside>
    </>
  );
};

export default NewSidebar;
