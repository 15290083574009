// utils/sendResumeToGoogleAI.js
import { GoogleGenerativeAI } from "@google/generative-ai";

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
const genAI = new GoogleGenerativeAI(apiKey);

export const sendResumeToGoogleAI = async (resumeData) => {
  const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

  const prompt = `
Given the following resume data:
${JSON.stringify(resumeData)}

Please perform the following tasks:

1. Extract and list the key skills from this resume. Consider both technical skills and soft skills.
2. Based on the resume content, suggest 5 job titles that this candidate should consider applying for.

Return the results in the following JSON format:

{
  "skills": ["Skill 1", "Skill 2", "Skill 3", ...],
  "recommendedJobTitles": ["Job Title 1", "Job Title 2", "Job Title 3", "Job Title 4", "Job Title 5"]
}
`;

  try {
    const result = await model.generateContent(prompt);
    const response = await result.response;
    const responseText = response.text();
    
    // Use a regular expression to extract the JSON object
    const jsonMatch = responseText.match(/\{[\s\S]*\}/);
    if (!jsonMatch) {
      throw new Error("No valid JSON found in the response");
    }
    
    const jsonString = jsonMatch[0];
    
    let aiResponse;
    try {
      aiResponse = JSON.parse(jsonString);
    } catch (parseError) {
      console.error("Error parsing AI response:", parseError);
      throw new Error("Failed to parse AI response");
    }
    
    // Ensure the response has the expected structure
    if (!aiResponse.skills || !aiResponse.recommendedJobTitles) {
      throw new Error("AI response is missing required fields");
    }
    
    return aiResponse;
  } catch (error) {
    console.error("Error in AI generation:", error);
    throw new Error("Failed to process resume with AI");
  }
};