import React, { useMemo } from 'react';
import { parse, format } from 'date-fns';


const ResumePreview = ({ formData, hiddenSections }) => {
  const dummyData = {
    name: 'John Doe',
    phone: '(123) 456-7890',
    email: 'john.doe@example.com',
    linkedin: 'https://linkedin.com/in/johndoe',
    education: [{
      university: 'Ohio University, College of Business',
      location: 'Athens, Ohio',
      graduation: '2023-05',
      degree: 'Bachelor of Business Administration',
      major: 'List all majors',
      gpa: '3.0'
    }],
    experience: [{
      company: 'Name of Company',
      jobTitle: 'Title',
      location: 'City, State',
      duration: 'Month Year – Present',
      description: ['Sample job description']
    }],
    leadership: [{
      organization: 'Organization',
      location: 'City, State',
      duration: 'Month Year – Present',
      title: 'Title',
      accomplishment: ['Sample accomplishment']
    }],
    awards: [{
      award: 'Award/Title/Scholarship',
      location: 'City, State',
      date: '2023-05'
    }],
    certifications: [{
      certification_name: 'Certification Name',
      certification_location: 'Issuer Name',
      certification_year: 'Year'
    }],
    skills: {
      technical: 'Listed proficiency in software programming (Do not list Microsoft Office products)',
      languages: 'Listed proficiency in languages including level of mastery (conversational, fluent)'
    },
    skillsVisibility: { technical: true, languages: true },
    customSections: [],
    sectionOrder: ['education', 'experience', 'leadership', 'awards', 'certifications', 'skills']
  };




  const formatDate = (dateArray) => {
    if (!dateArray || !Array.isArray(dateArray) || dateArray.length === 0) return '';
    const dateString = dateArray[0];
    if (typeof dateString !== 'string') return '';
    try {
      const parsedDate = parse(dateString, 'yyyy-MM', new Date());
      return format(parsedDate, 'MMMM yyyy');
    } catch (error) {
      console.error('Error parsing date:', error);
      return dateString; // Fallback to the original string if parsing fails
    }
  };

  const renderBulletPoints = (points) => {
    if (Array.isArray(points)) {
      // If it's an array of arrays (original structure)
      if (Array.isArray(points[0])) {
        points = points[0];
      }
      return points.map((point, index) => (
        <li key={index} className="ml-4">
          {point.startsWith('•') ? point.substring(1).trim() : point}
        </li>
      ));
    } else if (typeof points === 'string') {
      // If it's a single string (AI-generated output)
      return points.split('\n').map((point, index) => (
        <li key={index} className="ml-4">
          {point.startsWith('•') ? point.substring(1).trim() : point}
        </li>
      ));
    }
    return null;
  };

  const combinedData = useMemo(() => {
    const combined = JSON.parse(JSON.stringify(dummyData));
    
    
    Object.keys(formData).forEach(key => {
      if (key === 'personal_information') {
        combined.name = formData.personal_information.name || combined.name;
        combined.phone = formData.personal_information.phone || combined.phone;
        combined.email = formData.personal_information.email || combined.email;
        combined.linkedin = formData.personal_information.linkedin || combined.linkedin;
      } else if (key === 'sectionOrder') {
        combined.sectionOrder = formData.sectionOrder || combined.sectionOrder;
      } else if (typeof formData[key] === 'object' && formData[key] !== null) {
        if (Array.isArray(formData[key])) {
          combined[key] = formData[key].map((item, index) => {
            const dummyItem = combined[key] && combined[key][index] ? combined[key][index] : {};
            return Object.keys(item).reduce((acc, itemKey) => {
              acc[itemKey] = item[itemKey] || dummyItem[itemKey] || '';
              return acc;
            }, {});
          });
        } else {
          combined[key] = { ...combined[key], ...formData[key] };
        }
      } else {
        combined[key] = formData[key] || combined[key];
      }
    });

    return combined;
  }, [formData]);


  const renderedSections = new Set(); ///

  const renderSection = (sectionName) => {
    if (typeof sectionName !== 'string' || hiddenSections[sectionName]) return null;

    switch(sectionName) {
      case 'education':
        return combinedData.education && combinedData.education.length > 0 ? (
          <div className="mb-4" key="education">
            <h3 className="text-xl font-semibold">EDUCATION</h3>
            <hr className="my-2 border-black" />
            {combinedData.education.map((edu, index) => (
              <div key={index} className="mb-2">
                <div className="flex justify-between">
                  <p className="font-semibold">{edu.university || ''} | {edu.location || ''}</p>
                  <p className="text-right">{formatDate(edu.graduation) || ''}</p>
                </div>
                <p className="italic">{edu.degree || ''}</p>
            <p>Major: {edu.major || ''}</p>
            <p>GPA: {edu.gpa && edu.gpa[0] || ''}</p>
              </div>
            ))}
          </div>
        ) : null;
        case 'experience':
          return combinedData.experience && combinedData.experience.length > 0 ? (
            <div className="mb-4" key="experience">
              <h3 className="text-xl font-semibold">EXPERIENCE</h3>
              <hr className="my-2 border-black" />
              {combinedData.experience.map((exp, index) => (
    <div key={index} className="mb-2">
      <div className="flex justify-between">
        <p className="font-semibold">{exp.company} | {exp.location}</p>
        <p className="text-right">{exp.duration}</p>
      </div>
      <p className="italic">{exp.jobTitle}</p>
      <ul className="list-disc list-inside">
        {renderBulletPoints(exp.description)}
      </ul>
    </div>
  ))}

          </div>
        ) : null;
        case 'leadership':
          return !hiddenSections.leadership && combinedData.leadership && combinedData.leadership.length > 0 ? (
            <div className="mb-4" key="leadership">
              <h3 className="text-xl font-semibold">LEADERSHIP ACTIVITIES AND MEMBERSHIPS</h3>
              <hr className="my-2 border-black" />
              {combinedData.leadership.map((leader, index) => (
    <div key={index} className="mb-2">
      <div className="flex justify-between">
        <p className="font-semibold">{leader.organization} | {leader.location}</p>
        <p className="text-right">{leader.duration}</p>
      </div>
      <p className="italic">{leader.title}</p>
      <ul className="list-disc list-inside">
        {renderBulletPoints(leader.accomplishment)}
      </ul>
    </div>
  ))}

          </div>
        ) : null;
        case 'awards':
          return !hiddenSections.awards && combinedData.awards && combinedData.awards.length > 0 ? (
            <div className="mb-4" key="awards">
              <h3 className="text-xl font-semibold">HONORS AND AWARDS</h3>
              <hr className="my-2 border-black" />
              {combinedData.awards.map((award, index) => (
              <div key={index} className="mb-2 flex justify-between">
                <p className="font-semibold">{award.award || ''} | {award.location || ''}</p>
                <p className="text-right">{formatDate(award.date) || ''}</p>
              </div>
            ))}
          </div>
        ) : null;
        case 'certifications':
        return !hiddenSections.certifications && combinedData.certifications && combinedData.certifications.length > 0 ? (
          <div className="mb-4" key="certifications">
            <h3 className="text-xl font-semibold">CERTIFICATIONS</h3>
            <hr className="my-2 border-black" />
            {combinedData.certifications.map((cert, index) => (
              <div key={index} className="mb-2 flex justify-between">
                <p className="font-semibold">{cert.certification_name || ''} | {cert.certification_location || ''}</p>
                <p className="text-right">{cert.certification_year || ''}</p>
              </div>
            ))}
          </div>
        ) : null;
        case 'skills':
        return !hiddenSections.skills && combinedData.skills && (combinedData.skillsVisibility.technical === "yes" || combinedData.skillsVisibility.languages === "yes") ? (
          <div className="mb-4" key="skills">
            <h3 className="text-xl font-semibold">TECHNICAL AND LANGUAGE SKILLS</h3>
            <hr className="my-2 border-black" />
            {combinedData.skillsVisibility.technical === "yes" && combinedData.skills.technical && (
              <p>
                <span className="font-semibold">Technical:</span> {combinedData.skills.technical}
              </p>
            )}
            {combinedData.skillsVisibility.languages === "yes" && combinedData.skills.languages && (
              <p>
                <span className="font-semibold">Language:</span> {combinedData.skills.languages}
              </p>
            )}
          </div>
        ) : null;
      default:
        if (sectionName.startsWith('custom-')) {
          const index = parseInt(sectionName.split('-')[1]);
          const customSection = combinedData.customSections && combinedData.customSections[index];
          if (!customSection) return null;
  
          return (
            <div key={sectionName} className="mb-4">
              <h3 className="text-xl font-semibold">{(customSection.title || '').toUpperCase()}</h3>
              <hr className="my-2 border-black" />
              <p>{customSection.content || ''}</p>
            </div>
          );
        }
        return null;
    }
  };


  

  return (
    <div className="p-8 shadow-md rounded-lg border bg-card" style={{fontFamily:'Poppins'}}
    >
        {/* Personal Information */}
        <h2 className="text-2xl font-bold text-center">{combinedData.name}</h2>
      <p className="text-center mb-4 flex justify-center space-x-2">
        <span>{combinedData.phone}</span>
        <span>|</span>
        <span>{combinedData.email}</span>
        <span>|</span>
        <a href={combinedData.linkedin} target="_blank" rel="noopener noreferrer" className="text-blue-500">{combinedData.linkedin}</a>
      </p>

     {/* Render all sections */}
     {(combinedData.sectionOrder || [
        'education',
        'experience',
        'leadership',
        'awards',
        'certifications',
        'skills',
        ...(combinedData.customSections || []).map((_, index) => `custom-${index}`)
      ]).map((sectionName) => renderSection(sectionName))}
    </div>
  );
};

export default ResumePreview;