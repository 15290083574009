// ResumeSuggestions.js

import React from 'react';
import { 
  Edit2 as PenIcon, 
  AlignLeft as AlignLeftIcon, 
  Paperclip as PaperclipIcon,
  Zap as ZapIcon,
  FileText as FileTextIcon,
  User as UserIcon,
  Briefcase as BriefcaseIcon,
  Award as AwardIcon,
  Code as CodeIcon,
  BarChart as BarChartIcon,
  Globe as GlobeIcon,
  BookOpen as BookOpenIcon
} from 'react-feather';

const resumeSuggestions = [
  {
    icon: <PenIcon />,
    title: "Improve your action verbs",
    description: "Start each bullet point with a strong, varied action verb to showcase your achievements and responsibilities. Consider using words like 'Led', 'Designed', 'Achieved', 'Implemented', and 'Reduced'."
  },
  {
    icon: <AlignLeftIcon />,
    title: "Enhance formatting and structure",
    description: "Ensure consistent formatting throughout your resume, including font sizes, bullet points, and spacing. For example, make sure all job titles and dates are aligned uniformly."
  },
  {
    icon: <PaperclipIcon />,
    title: "Add relevant skills and certifications",
    description: "Highlight your technical skills, software proficiency, and any professional certifications that are relevant to the job. This can help you stand out for specific roles."
  },
  {
    icon: <ZapIcon />,
    title: "Quantify your achievements",
    description: "Include specific numbers, percentages, or metrics to demonstrate the impact of your work. For instance, 'Increased sales by 20%' or 'Reduced costs by 15%'."
  },
  {
    icon: <FileTextIcon />,
    title: "Tailor your resume",
    description: "Customize your resume for each job application by incorporating keywords from the job description. This can increase the chances of passing through automated screening systems."
  },
  {
    icon: <UserIcon />,
    title: "Improve your contact information",
    description: "Ensure your contact information is complete and up-to-date, including your LinkedIn profile if applicable. Missing any key contact details may reduce your chances of being contacted."
  },
  {
    icon: <BriefcaseIcon />,
    title: "Expand on your work experience",
    description: "Provide more details about your responsibilities and achievements in each role, focusing on the most relevant experiences for the job you're applying to."
  },
  {
    icon: <AwardIcon />,
    title: "Highlight your achievements",
    description: "Emphasize your accomplishments and recognition received in your roles or academic pursuits. Awards can help differentiate you from other candidates."
  },
  {
    icon: <CodeIcon />,
    title: "Showcase technical proficiency",
    description: "List relevant programming languages, software, and tools you're proficient in, especially those mentioned in the job description. Make sure to highlight those skills in which you have practical experience."
  },
  {
    icon: <BarChartIcon />,
    title: "Demonstrate growth and progression",
    description: "Show how you've grown in your roles and taken on increasing responsibilities over time. This can demonstrate your adaptability and ambition."
  },
  {
    icon: <GlobeIcon />,
    title: "Include relevant global experience",
    description: "If applicable, highlight any international experience, language skills, or cross-cultural competencies. This can be especially beneficial for companies with a global presence."
  },
  {
    icon: <BookOpenIcon />,
    title: "Emphasize continuous learning",
    description: "Include any relevant coursework, workshops, or professional development activities you've undertaken. This shows a commitment to staying up-to-date with industry trends."
  },
  {
    icon: <ZapIcon />,
    title: "Include leadership achievements",
    description: "If you’ve led teams or projects, highlight these leadership roles. Make sure to quantify the impact of your leadership, such as leading a team to a specific goal or improving productivity."
  },
  {
    icon: <ZapIcon />,
    title: "Use concise, impactful language",
    description: "Avoid long, convoluted sentences. Use concise language that gets straight to the point and makes your accomplishments clear and easy to read."
  }
];

export default resumeSuggestions;
