import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { supabase } from "../supabaseClient";
import { Input } from '../comp/ui/input';
import { Label } from '@radix-ui/react-label';
import { Button } from '../comp/ui/button'
import PurpleFooter from '../comp/PurpleFooter';
import PurpleHeader from '../comp/PurpleHeader';

const SignUp = () => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const ref = params.get('ref');
    if (ref) {
      setReferralCode(ref);
    }
  }, [location]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const name = event.target.elements.name.value;
    const email = event.target.elements.email.value;

    if (!validatePassword(password)) {
      setPasswordError('Password must be at least 8 characters long and include at least one letter and number.');
      return;
    }

    setLoading(true);

    try {
      const { data: { user }, error } = await supabase.auth.signUp({
        email,
        password,
      });
  
      if (error) throw error;

      const newReferralCode = generateReferralCode();

      const { data: userData, error: insertError } = await supabase
        .from('users')
        .insert([{ 
          email, 
          name, 
          referral_code: newReferralCode,
          admin: 'no'
        }])
        .select()
        .single();

      if (insertError) throw insertError;

      await setInitialUsageLimits(userData.id);

      if (referralCode) {
        await processReferral(referralCode, userData.id);
      }

      toast.success('Account created successfully!');

      setTimeout(() => {
        window.location.href = '/dashboard';
      }, 2000);
    } catch (error) {
      console.error('Error:', error.message);
      toast.error(error.message || 'An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  const processReferral = async (code, newUserId) => {
    try {
      // Find the referrer based on the referral code
      const { data: referrer, error: referrerError } = await supabase
        .from('users')
        .select('id')
        .eq('referral_code', code)
        .single();
  
      if (referrerError) throw referrerError;
  
      // Create a new referral record
      const { error: referralError } = await supabase
        .from('referrals')
        .insert({
          referrer_id: referrer.id,
          referred_id: newUserId,
          referral_code: code,
          status: 'completed'
        });
  
      if (referralError) throw referralError;
  
      // Update usage limits for the referrer (+2 for each limit)
      await updateReferrerLimits(referrer.id);
  
      // Update usage limits for the new user (using the existing function)
      await updateUsageLimits(newUserId);
  
    } catch (error) {
      console.error('Error processing referral:', error);
      throw error;
    }
  };


  const setInitialUsageLimits = async (userId) => {
    const { error } = await supabase
      .from('subscriptions')
      .insert({
        user_id: userId,
        ai_resume_limit: 3,
        job_search_limit: 2,
        interview_prep_limit: 3
      });

    if (error) throw error;
  };

  const updateReferrerLimits = async (userId) => {
    try {
      const { data: currentLimits, error: fetchError } = await supabase
        .from('subscriptions')
        .select('ai_resume_limit, job_search_limit, interview_prep_limit')
        .eq('user_id', userId)
        .single();
  
      if (fetchError) throw fetchError;
  
      if (!currentLimits) {
        console.error('No subscription found for referrer');
        return;
      }
  
      const { error: updateError } = await supabase
        .from('subscriptions')
        .update({
          ai_resume_limit: currentLimits.ai_resume_limit + 2,
          job_search_limit: currentLimits.job_search_limit + 2,
          interview_prep_limit: currentLimits.interview_prep_limit + 2
        })
        .eq('user_id', userId);
  
      if (updateError) throw updateError;
  
      console.log('Referrer limits updated successfully');
    } catch (error) {
      console.error('Error updating referrer usage limits:', error);
      throw error;
    }
  };

  const updateUsageLimits = async (userId) => {
    try {
      const { data: currentLimits, error: fetchError } = await supabase
        .from('subscriptions')
        .select('ai_resume_limit, job_search_limit, interview_prep_limit')
        .eq('user_id', userId)
        .single();

      if (fetchError) throw fetchError;

      if (!currentLimits) {
        return setInitialUsageLimits(userId);
      }

      const { error: updateError } = await supabase
        .from('subscriptions')
        .update({
          ai_resume_limit: currentLimits.ai_resume_limit + 2,
          job_search_limit: currentLimits.job_search_limit + 2,
          interview_prep_limit: currentLimits.interview_prep_limit + 2
        })
        .eq('user_id', userId);

      if (updateError) throw updateError;
    } catch (error) {
      console.error('Error updating usage limits:', error);
      throw error;
    }
  };

  const validatePassword = (password) => {
    return /(?=.*[a-zA-Z])(?=.*\d).{8,}/.test(password);
  };

  const generateReferralCode = () => {
    return Math.random().toString(36).substring(2, 8).toUpperCase();
  };

  return (
    <div className="flex flex-col min-h-screen" style={{ fontFamily: "Poppins" }}>
      <Helmet>
        <title>Signup - Career Redesigned</title>
        <link rel="icon" href="" />
      </Helmet>

      <PurpleHeader />

      <main className="flex items-center justify-center flex-grow bg-white text-black mt-20">
      <div className="w-full max-w-md px-4">
        <div className="space-y-6">
          <div className="mt-8 space-y-2 text-center">
            <h1 className="text-3xl font-bold">Let's Get You Started!</h1>
            <p className="text-gray-400">Enter your details below to create your account</p>
          </div>
          <form onSubmit={handleSubmit} className="space-y-6 mt-8">
            <div className="space-y-2">
              <Label htmlFor="name" className="text-sm font-medium">
                Full Name
              </Label>
              <Input
                id="name"
                type="text"
                placeholder="John Doe"
                required
                className="w-full h-12 px-4 py-2 rounded-md bg-white text-black placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="email" className="text-sm font-medium">
                Email
              </Label>
              <Input
                id="email"
                type="email"
                placeholder="you@example.com"
                required
                className="w-full h-12 px-4 py-2 rounded-md bg-white text-black placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="password" className="text-sm font-medium">
                Password
              </Label>
              <Input
                id="password"
                type="password"
                placeholder="••••••••"
                required
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordError('');
                }}
                className="w-full h-12 px-4 py-2 rounded-md bg-white text-black placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              {passwordError && <p className="text-red-500 text-sm">{passwordError}</p>}
            </div>
            {referralCode && (
              <div className="space-y-2">
                <Label htmlFor="referralCode" className="text-sm font-medium">
                  Referral Code
                </Label>
                <Input
                  id="referralCode"
                  type="text"
                  value={referralCode}
                  disabled
                  className="w-full h-12 px-4 py-2 rounded-md bg-gray-700 text-gray-400"
                />
              </div>
            )}
            <Button
              type="submit"
              disabled={loading}
              className="w-full h-12 flex items-center justify-center bg-blue-600 hover:bg-blue-700 rounded-md text-sm font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50 mt-4"
            >
              {loading ? 'Signing up...' : 'Sign up'}
            </Button>
          </form>
          <div className="mt-8 text-center text-sm">
            <p>
              Already have an account?{' '}
              <a className="underline text-blue-500 hover:text-blue-400" href="/login">
                Log in
              </a>
            </p>
          </div>

          <p className="text-sm text-muted-foreground">
          By signing up, you agree to our{' '}
          <a href="/terms" className="underline underline-offset-4 hover:text-primary">
            Terms and Conditions
          </a>
        </p>

        </div>
      </div>
      <ToastContainer />
    </main>

      <PurpleFooter />
    </div>
  );
};

export default SignUp;