// Input Component
export const Input = ({
  className = '',
  type = 'text',
  placeholder,
  value,
  onChange,
  required = false,
  ...props
}) => {
  const inputStyle = {
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #CBD5E0',
    borderRadius: '4px',
    width: '100%',
    transition: 'border-color 0.3s'
  };

  const focusStyle = {
    outline: 'none',
    borderColor: 'black', // Primary color
    boxShadow: 'black'
  };

  return (
    <input
      type={type}
      className={className}
      style={inputStyle}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      required={required}
      onFocus={(e) => Object.assign(e.target.style, focusStyle)}
      onBlur={(e) => Object.assign(e.target.style, inputStyle)}
      {...props}
    />
  );
};
