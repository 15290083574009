import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ComparisonVisualizations from './ComparisonVisualizations';
import ResumePreviewWithPDF from '../comp/ResumePreviewWithPDF.js';
import { toast, ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router-dom';
import Sidebar from '../comp/Sidebar';
import { Checkbox } from '../comp/ui/checkbox';
import { Button } from '../comp/ui/button';
import { generateAIPoweredResume } from '../comp/resumeAnalysisAI';
import { generateWord } from '../comp/DownloadWord';
import downloadPDF from '../comp/DownloadPDF';
import HighlightedChanges from '../comp/HighlightedChanges.js';


const ComparisonResult = () => {
  const [comparisonData, setComparisonData] = useState(null);
  const [resumeData, setResumeData] = useState(null);
  const [error, setError] = useState(null);
  const [hiddenSections, setHiddenSections] = useState({});
  const [jobDetails, setJobDetails] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [proposedChanges, setProposedChanges] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [showDownloadDialog, setShowDownloadDialog] = useState(false);
  const [resumeToDownload, setResumeToDownload] = useState(null);

  const toggleSkillSelection = (skill) => {
    setSelectedSkills(prevSelected =>
      prevSelected.includes(skill)
        ? prevSelected.filter(s => s !== skill)
        : [...prevSelected, skill]
    );
  };
  const [user, setUser] = useState(null); // Initialize state to store the user
  const [isOpen, setIsOpen] = useState(false); // Sidebar state

  const toggleSidebar = () => setIsOpen(!isOpen);

  useEffect(() => {
    const fetchUser = async () => {
      // Fetch the current authenticated user from Supabase
      const { data, error } = await supabase.auth.getUser();

      if (error) {
        console.error('Error fetching user:', error.message);
      } else {
        // Set the user in state if available
        setUser(data.user);
      }
    };

    fetchUser(); // Call the function to fetch user data
  }, []);


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const uniqueCode = searchParams.get('code');
    if (uniqueCode) {
      fetchComparisonData(uniqueCode);
    }
  }, [location]);

  const fetchComparisonData = async (uniqueCode) => {
    try {
      // Fetch the comparison data
      const { data: comparisonData, error: comparisonError } = await supabase
        .from('resume_comparisons')
        .select('*')
        .eq('unique_code', uniqueCode)
        .single();

      if (comparisonError) throw comparisonError;
      if (!comparisonData) {
        setError('No comparison data found.');
        return;
      }

      setComparisonData(comparisonData.analysis_result);
      setJobDetails(comparisonData.job_details); // Set job details

      // Fetch the associated resume data using the resume_code
      const { data: resumeData, error: resumeError } = await supabase
        .from('resumes')
        .select('*')
        .eq('code', comparisonData.resume_code)
        .single();

      if (resumeError) throw resumeError;
      if (!resumeData) {
        setError('Associated resume data not found.');
        return;
      }

      setResumeData(resumeData);
      setHiddenSections({
        leadership: resumeData.leadership_visibility === 'yes',
        awards: resumeData.awards_visibility === 'yes',
        skills: resumeData.skills_visibility === 'yes',
      });
    } catch (error) {
      console.error('Error fetching comparison data:', error);
      setError('Failed to load comparison results. Please try again.');
    }
  };

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  if (error) {
    return <div className="text-center mt-10 text-red-500">{error}</div>;
  }

  if (!comparisonData || !resumeData) {
    return <div className="text-center mt-10">Loading...</div>;
  }

  const handleGenerateAIResume = async () => {
    setIsLoading(true);
    try {
      const jobDescriptionWordCloud = comparisonData.jobDescriptionWordCloud;
      const aiResponse = await generateAIPoweredResume(resumeData, jobDescriptionWordCloud, selectedSkills);

      // Set proposed changes for review
      setProposedChanges(aiResponse.updatedExperience);
      setShowDownloadButton(true);
      toast.success("AI-Powered Resume generated successfully!");
    } catch (error) {
      toast.error("Failed to generate AI-powered resume.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFontSettings = async (resumeCode) => {
    try {
      const { data, error } = await supabase
        .from('resumes')
        .select('font_family, font_size')
        .eq('code', resumeCode)
        .single();
  
      if (error) {
        console.error('Error fetching font settings:', error);
        return { font_family: 'Helvetica', font_size: 12 }; // default values
      }
  
      return data;
    } catch (error) {
      console.error('Unexpected error fetching font settings:', error);
      return { font_family: 'Helvetica', font_size: 12 }; // default values
    }
  };

  

  const handleDownloadAndSaveResume = async () => {
    setIsLoading(true);
    try {
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw new Error('Failed to fetch user data.');

      const userId = user.id;
      const userName = resumeData.personal_information?.name || user.email.split('@')[0];
      const resumeName = `${userName}_${jobDetails.title}_${jobDetails.company}`;

      const updatedResumeData = {
        personal_information: resumeData.personal_information,
        education: resumeData.education,
        experience: proposedChanges,
        leadership: resumeData.leadership,
        awards: resumeData.awards,
        skills: resumeData.skills,
        certifications: resumeData.certifications,
        customSections: resumeData.customSections,
        sectionOrder: resumeData.sectionOrder,
        leadership_visibility: resumeData.leadership_visibility,
        awards_visibility: resumeData.awards_visibility,
        skills_visibility: resumeData.skills_visibility,
        skillsVisibility: resumeData.skillsVisibility,
        jobLikelihoodImprovement: "0",
        original_resume: resumeData,
        original_resume_code: resumeData.code,
      };

      const newCode = generateUniqueCode();
      const currentDateTime = new Date().toISOString();

      const resumeToSave = {
        user_id: userId,
        user_email: user.email,
        name: resumeName,
        code: newCode,
        created_at: currentDateTime,
        last_updated: currentDateTime,
        visibility: true,
        ...updatedResumeData,
        aiChanges: JSON.stringify(proposedChanges),
        improvementSuggestions: JSON.stringify([]),
      };

      const { data, error } = await supabase
        .from('resumes_ai')
        .insert([resumeToSave])
        .select('id, name, code');

      if (error) throw error;

      setResumeToDownload({ ...resumeToSave, id: data[0].id });
      setShowDownloadDialog(true); // Show the download dialog
      toast.success("Resume saved successfully!");

    } catch (error) {
      console.error('Error saving the resume:', error);
      toast.error("An error occurred while saving the resume.");
    } finally {
      setIsLoading(false);
    }
  };
const handleDownloadPDF = async () => {
  if (!resumeToDownload) {
    toast.error('Resume data not available');
    return;
  }

  const { font_family, font_size } = await fetchFontSettings(resumeToDownload.code);

  // Ensure the file name follows the desired format
  const pdfFileName = `${resumeToDownload.name || 'Resume'}.pdf`;

  downloadPDF(
    resumeToDownload,
    hiddenSections,
    pdfFileName,
    font_family,
    font_size
  );
  setShowDownloadDialog(false);
};

  
  
  const handleDownloadWord = () => {
    if (!resumeToDownload) {
      toast.error('Resume data not available');
      return;
    }
    generateWord(resumeToDownload);
    setShowDownloadDialog(false);
  };

  const handleCloseDialog = () => {
    setShowDownloadDialog(false);
  };

  const generateUniqueCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const codeLength = 8;
    let code = '';

    for (let i = 0; i < codeLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters.charAt(randomIndex);
    }

    return code;
  };

  if (!comparisonData || !resumeData) {
    return <div className="text-center mt-10">Loading...</div>;
  }

  return (


    <div className="flex flex-col md:flex-row h-screen bg-gray-100" style={{ fontFamily: "Poppins" }}>
    {/* Sidebar */}
    <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
    {/* Main Content */}
    <div className="flex-1 flex flex-col overflow-hidden">
      {/* Content */}
      <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-6">


      <div className="flex">
      <div className="w-1/2 p-4">

  
        <div>
      <ComparisonVisualizations data={comparisonData} />
            {/* Add more comparison result components here */}
          </div>

          </div>

          <div className="w-1/2 p-4">

     
  {/* Skills/Keywords Selection */}

<div className="bg-white p-6 shadow-md rounded-lg border bg-card mb-4">


 
            <h3 className="text-xl font-semibold">Add Keywords/Skills to Enhance Your Resume</h3>
            <div className="flex flex-wrap gap-4 mb-4">
              {comparisonData.keywordComparison.missing.map((skill, index) => (
                <div key={index} className="flex items-center">
                  <input
                    type="checkbox"
                    id={`skill-${index}`}
                    checked={selectedSkills.includes(skill)}
                    onChange={() => toggleSkillSelection(skill)}
                    className="form-checkbox h-5 w-5 text-blue-600"
                  />
                  <label htmlFor={`skill-${index}`} className="ml-2 text-gray-700">{skill}</label>
                </div>
              ))}
            </div>
            <Button
              type="button"
              className="mt-4"
              disabled={selectedSkills.length === 0 || isLoading}
              onClick={handleGenerateAIResume}
            >
              {isLoading ? "Generating AI-Powered Resume..." : "Generate AI-Powered Resume"}
            </Button>
          </div>

          {/* Display Proposed Changes */}
          {proposedChanges.length > 0 && (
            <div className="bg-white p-6 shadow-md rounded-lg border mb-4">
             <HighlightedChanges 
      proposedChanges={proposedChanges}
      selectedSkills={selectedSkills}
      originalExperience={resumeData.experience}
    />
              {showDownloadButton && (
                <Button className="mt-4" onClick={handleDownloadAndSaveResume}>
                  Download AI-Powered Resume
                </Button>
              )}
            </div>
          )}



</div>

  {/* Download Dialog */}
  {showDownloadDialog && (
          <div className="modal fixed inset-0 z-10 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    Download {resumeToDownload.name}
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Choose a format to download your resume.
                    </p>
                  </div>
                </div>
                
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    onClick={handleCloseDialog}
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={handleDownloadPDF}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">
                    Download PDF
                  </button>
                  <button
                    type="button"
                    onClick={handleDownloadWord}
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-black text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                    Download Word
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

</div>

<div className="bg-white p-6 shadow-md rounded-lg border bg-card mb-4">
              <h3 className="text-xl font-semibold text-black-700">Job Details</h3>

              {jobDetails && (
                <div className="mb-6">
                  <p><strong>Title:</strong> {jobDetails.title}</p>
                  <p><strong>Company:</strong> {jobDetails.company}</p>

                  {/* Description with Show More functionality */}
                  <p>
                    <strong>Description:</strong>
                    <span className={showMore ? '' : 'line-clamp-4'}>
                      {jobDetails.description}
                    </span>
                    {jobDetails.description.length > 400 && ( // Check if the description is long
                      <span
                        onClick={toggleShowMore}
                        className="text-blue-900 cursor-pointer ml-2"
                      >
                        {showMore ? 'Show Less' : 'Show More'}
                      </span>
                    )}
                  </p>
                </div>
              )}


               





            </div>
      </main>
    </div>
    <ToastContainer />
  </div>


  );
};

export default ComparisonResult;
