import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../comp/Sidebar'; // Adjust the path as needed
import { Link, useNavigate } from 'react-router-dom';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { processResumeData } from '../comp/AIParser';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { supabase } from '../supabaseClient'; // Adjust the path as needed

import { PenSquare, FileUp } from 'lucide-react'; // Adjust if necessary
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '../comp/ui/card'; // Adjust the path
import { Button } from '../comp/ui/button'; // Adjust the path

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const Build = () => {
  const [resumeData, setResumeData] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [extractedText, setExtractedText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null); // Initialize state to store the user


  useEffect(() => {
    const fetchUser = async () => {
      // Fetch the current authenticated user from Supabase
      const { data, error } = await supabase.auth.getUser();

      if (error) {
        console.error('Error fetching user:', error.message);
      } else {
        // Set the user in state if available
        setUser(data.user);
      }
    };

    fetchUser(); // Call the function to fetch user data
  }, []);

  const [isOpen, setIsOpen] = useState(false); // Sidebar state

const toggleSidebar = () => setIsOpen(!isOpen);


  const navigate = useNavigate();

const handleLogout = async () => {
  const { error } = await supabase.auth.signOut(); // Perform logout using Supabase

  if (error) {
    console.error('Error logging out:', error.message);
  } else {
    toast.success('Logout successful!'); // Show success toast

    // Wait 3 seconds, then navigate to the login page
    setTimeout(() => {
      navigate('/login'); // Redirect to login page
    }, 3000);
  }
};



  // Add a ref for the file input
  const fileInputRef = useRef(null);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check file size
      if (file.size > 5 * 1024 * 1024) {
        toast.error("File size exceeds 5MB limit. Please upload a smaller file.");
        return;
      }

      // Check file type
      if (file.type !== 'application/pdf') {
        toast.error("Invalid file type. Please upload a PDF file.");
        return;
      }

      setFileUploaded(true);
      try {
        const text = await extractTextFromPDF(file);
        if (text.trim() === '') {
          throw new Error('No text extracted from PDF');
        }
        setExtractedText(text);
        console.log('Extracted text:', text);
      } catch (error) {
        console.error('Error extracting text from PDF:', error);
        toast.error("Unable to extract text from this PDF. Please use the resume form builder to create your resume.");
        setFileUploaded(false);
        setExtractedText('');
      }
    }
  };

  const extractTextFromPDF = async (file) => {
    const arrayBuffer = await file.arrayBuffer();
    const pdfDocument = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;

    let extractedText = '';
    for (let i = 1; i <= pdfDocument.numPages; i++) {
      const page = await pdfDocument.getPage(i);
      const textContent = await page.getTextContent();
      const textItems = textContent.items.map(item => item.str).join(' ');
      extractedText += `${textItems}\n`;
    }

    return extractedText;
  };

  const saveExtractionToSupabase = async (extractedText, aiJsonOutput, resumeCode) => {
    console.log('Attempting to save extraction data...');
    try {
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) {
        console.error('Error fetching user:', userError);
        toast.error('Failed to fetch user data.');
        return;
      }
      const userId = user.id;
      console.log('User ID:', userId);

      const extractionData = {
        user_id: userId,
        resume_id: resumeCode,
        extracted_text: extractedText,
        ai_json_output: aiJsonOutput
      };
      console.log('Extraction data prepared:', extractionData);

      const { data, error } = await supabase
        .from('resume_extractions')
        .insert([extractionData]);

      if (error) {
        throw error;
      }

      console.log('Extraction data saved successfully:', data);
      toast.success("Extraction data has been saved successfully!");
    } catch (error) {
      console.error('Error saving extraction data:', error);
      toast.error("An error occurred while saving the extraction data. Please try again.");
    }
  };

  const sendToGoogleAI = async () => {
    if (!fileUploaded) {
      toast.error("Please upload a resume before submitting.");
      return;
    }

    setIsLoading(true);
    try {
      const processedData = await processResumeData(extractedText);
      if (processedData) {
        setResumeData(processedData);

        const resumeCode = await saveProcessedResumeToSupabase(processedData);

        if (resumeCode) {
          console.log('Saving extraction data...');
          await saveExtractionToSupabase(extractedText, processedData, resumeCode);

          toast.success("Resume saved! Redirecting to editor...");
          setTimeout(() => {
            window.location.href = `/resume-builder?code=${resumeCode}`;
          }, 2000);
        }
      } else {
        throw new Error("Failed to process resume data");
      }
    } catch (error) {
      console.error('Error processing resume:', error);
      setResumeData(null);
      toast.error("An error occurred while processing the resume. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Add this function before saveProcessedResumeToSupabase
const validateAndSanitizeResumeData = (data) => {
  try {
    // Create a deep copy of the data to avoid modifying the original
    let sanitizedData = JSON.parse(JSON.stringify(data));

    // Validate and sanitize personal information
    sanitizedData.name = data.name?.trim() || '';
    sanitizedData.phone = data.phone?.trim() || '';
    sanitizedData.email = data.email?.trim() || '';
    sanitizedData.linkedin = data.linkedin?.trim() || '';

    // Validate and sanitize education
    if (Array.isArray(sanitizedData.education)) {
      sanitizedData.education = sanitizedData.education.map(edu => ({
        university: edu.university?.trim() || '',
        location: edu.location?.trim() || '',
        graduation: formatDateToMonthYear(edu.graduation),
        degree: edu.degree?.trim() || '',
        major: edu.major?.trim() || '',
        gpa: edu.gpa ? parseFloat(edu.gpa).toString() : ''
      }));
    } else {
      sanitizedData.education = [];
    }

    // Validate and sanitize experience
    if (Array.isArray(sanitizedData.experience)) {
      sanitizedData.experience = sanitizedData.experience.map(exp => ({
        company: exp.company?.trim() || '',
        jobTitle: exp.jobTitle?.trim() || '',
        location: exp.location?.trim() || '',
        duration: formatDuration(exp.duration),
        description: Array.isArray(exp.description) 
          ? exp.description.filter(desc => desc?.trim()).map(desc => desc.trim())
          : []
      }));
    } else {
      sanitizedData.experience = [];
    }

    // Validate and sanitize leadership
    if (Array.isArray(sanitizedData.leadership)) {
      sanitizedData.leadership = sanitizedData.leadership.map(lead => ({
        organization: lead.organization?.trim() || '',
        location: lead.location?.trim() || '',
        duration: formatDuration(lead.duration),
        title: lead.title?.trim() || '',
        accomplishment: Array.isArray(lead.accomplishment)
          ? lead.accomplishment.filter(acc => acc?.trim()).map(acc => acc.trim())
          : []
      }));
    } else {
      sanitizedData.leadership = [];
    }

    // Validate and sanitize awards
    if (Array.isArray(sanitizedData.awards)) {
      sanitizedData.awards = sanitizedData.awards.map(award => ({
        award: award.award?.trim() || '',
        location: award.location?.trim() || '',
        date: formatDateToMonthYear(award.date)
      }));
    } else {
      sanitizedData.awards = [];
    }

    // Validate and sanitize certifications
    if (Array.isArray(sanitizedData.certifications)) {
      sanitizedData.certifications = sanitizedData.certifications.map(cert => ({
        certification_name: cert.certification_name?.trim() || '',
        certification_location: cert.certification_location?.trim() || '',
        certification_year: cert.certification_year?.toString().trim() || ''
      }));
    } else {
      sanitizedData.certifications = [];
    }

    // Validate and sanitize skills
    sanitizedData.skills = {
      technical: data.skills?.technical?.trim() || '',
      languages: data.skills?.languages?.trim() || ''
    };

    // Add default visibility settings
    sanitizedData.skillsVisibility = {
      technical: "no",
      languages: "no"
    };

    return sanitizedData;
  } catch (error) {
    console.error('Error in validation:', error);
    throw new Error('Data validation failed');
  }
};

// Helper functions for date and duration formatting
const formatDateToMonthYear = (dateString) => {
  if (!dateString) return '';
  
  try {
    // Remove any "Present" or similar text
    dateString = dateString.replace(/present|current/i, '').trim();
    
    // Try to parse the date
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      // If parsing fails, try to extract month and year
      const match = dateString.match(/([A-Za-z]+)\s*(\d{4})/i);
      if (match) {
        return `${match[1]} ${match[2]}`;
      }
      return '';
    }
    
    return date.toLocaleString('default', { month: 'long', year: 'numeric' });
  } catch {
    return '';
  }
};

const formatDuration = (durationString) => {
  if (!durationString) return '';
  
  try {
    // Remove multiple spaces and standardize separators
    durationString = durationString.replace(/\s+/g, ' ')
                                 .replace(/[-–—]/g, '-')
                                 .trim();
    
    // Split into start and end dates
    const parts = durationString.split('-').map(part => part.trim());
    
    // Format start date
    const startDate = formatDateToMonthYear(parts[0]);
    
    // Check for "Present" in end date
    const endDate = parts[1]?.toLowerCase().includes('present') 
      ? 'Present'
      : formatDateToMonthYear(parts[1]);
    
    return startDate && endDate ? `${startDate} - ${endDate}` : '';
  } catch {
    return '';
  }
};

// Update the saveProcessedResumeToSupabase function to use validation
const saveProcessedResumeToSupabase = async (processedData) => {
  try {
    // First validate and sanitize the data
    const validatedData = validateAndSanitizeResumeData(processedData);
    
    if (!validatedData) {
      throw new Error('Data validation failed');
    }

    const { data: { user }, error: userError } = await supabase.auth.getUser();
    if (userError) throw userError;

    const resumeData = {
      name: `${validatedData.name || 'Unnamed'}_${new Date().toLocaleDateString()}_${new Date().toLocaleTimeString()}`,
      code: generateUniqueCode(),
      user_id: user.id,
      user_email: user.email,
      personal_information: {
        name: validatedData.name,
        phone: validatedData.phone,
        email: validatedData.email,
        linkedin: validatedData.linkedin
      },
      education: validatedData.education,
      experience: validatedData.experience,
      leadership: validatedData.leadership,
      awards: validatedData.awards,
      certifications: validatedData.certifications,
      skills: validatedData.skills,
      skillsVisibility: validatedData.skillsVisibility,
      customSections: [],
      sectionOrder: ['education', 'experience', 'leadership', 'awards', 'certifications', 'skills'],
      last_updated: new Date().toISOString()
    };

    const { data, error } = await supabase
      .from('resumes')
      .insert([resumeData])
      .select('code');

    if (error) throw error;

    console.log('Resume data saved successfully:', data);
    toast.success("Resume data has been saved successfully!");

    return data[0].code;
  } catch (error) {
    console.error('Error saving resume data:', error);
    toast.error(`Validation Error: ${error.message}`);
    return null;
  }
};
  const generateUniqueCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const codeLength = 8;
    let code = '';

    for (let i = 0; i < codeLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      code += characters.charAt(randomIndex);
    }

    return code;
  };

  return (
    <div
    className="flex flex-col md:flex-row h-screen bg-gray-100"
    style={{ fontFamily: 'Poppins' }}
  >
    {/* Sidebar */}
    <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />

       {/* Main Content */}
       <div className="flex-1 flex flex-col overflow-hidden">
        <ToastContainer /> {/* Toastify container for showing notifications */}
        {/* Content */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-6">
          {isLoading && (
           <div class="loading-container">
           <div class="spinner"></div>
           <div class="loading-text">Loading...</div>
         </div>
          )}
          <div className="min-h-screen flex flex-col items-center p-4">
            <header className="text-center mb-8">
              <h1 className="text-4xl font-bold text-gray-800 mb-2">Resume Wizard</h1>
              <p className="text-xl text-gray-600">Your Gateway to Professional Success
</p>
            </header>

            <div className="grid gap-12 md:grid-cols-2 max-w-5xl w-full">
              <Card className="w-full">
                <CardHeader>
                  <CardTitle className="flex items-center gap-2">
                    <PenSquare className="h-6 w-6" />
                    Create New Resume
                  </CardTitle>
                  <CardDescription>Start fresh with our AI-powered resume builder</CardDescription>
                </CardHeader>
                <CardContent>
                  <p className="block text-m font-medium text-black mb-8">
                  Our intelligent, step-by-step process guides you through creating a standout resume tailored to your industry and career goals.                  </p>
                  <Link to="/resume-builder">
                    <Button className="w-full items-center justify-center px-3 py-2 rounded-lg">Build My Resume</Button>
                  </Link>
                </CardContent>
              </Card>
              <Card className="w-full">
                <CardHeader>
                  <CardTitle className="flex items-center gap-2">
                    <FileUp className="h-6 w-6" />
                    Upgrade Existing Resume
                                      </CardTitle>
                  <CardDescription>Import and transform your current resume</CardDescription>
                </CardHeader>
                <CardContent>
                  <p className="block text-m font-medium text-black mb-8">
                  Already have a resume? Let our AI enhance it with optimized formatting, powerful language, and industry-specific keywords.                  </p>
                  {/* Adjusted code starts here */}
                  <div className="flex flex-col items-center mb-8">
                    <Button
                      className="w-full mb-8"
                      onClick={() => fileInputRef.current && fileInputRef.current.click()}
                    >
                      Choose File
                    </Button>
                    <input
                      ref={fileInputRef}
                      type="file"
                      accept="application/pdf"
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />
                    {fileUploaded && (
                      <p className="mt-2 text-sm text-green-600">File uploaded successfully!</p>
                    )}
                    <Button
                      type="button"
                      onClick={sendToGoogleAI}
                      className="w-full mt-4"
                      disabled={isLoading || !fileUploaded}
                    >
                      {isLoading ? 'Processing...' : 'Submit your Resume'}
                    </Button>
                  </div>
                  {/* Adjusted code ends here */}
                </CardContent>
              </Card>
            </div>
          </div>
        </main>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Build;
