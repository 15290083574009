import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MinimalSidebar from '../comp/MinimalSidebar';
import IssueReportButton from '../comp/IssueReportButton';
import ClassResumeCard from '../comp/ClassResumeCard';
import ResumePreview from '../comp/ResumePreview';
import { supabase } from '../supabaseClient';

function ResumeResult() {
    const [resumeData, setResumeData] = useState(null);
    const [hiddenSections, setHiddenSections] = useState({});
    const location = useLocation();
  
    useEffect(() => {
      const fetchResumeData = async () => {
        const params = new URLSearchParams(location.search);
        const code = params.get('code');
        if (code) {
          const { data, error } = await supabase
            .from('resumes')
            .select('*')
            .eq('code', code)
            .single();
  
          if (error) {
            console.error('Error fetching resume:', error);
          } else {
            setResumeData(data);
            setHiddenSections({
              leadership: data.leadership_visibility !== 'yes',
              awards: data.awards_visibility !== 'yes',
              skills: data.skills_visibility !== 'yes',
            });
          }
        }
      };
  
      fetchResumeData();
    }, [location]);
  

  return (
    <div className="flex min-h-dvh" style={{ fontFamily: "Poppins" }}>
    <MinimalSidebar />
    <div className="flex-grow pl-20">
      <div className="flex">
        <div className="w-1/2 p-4">
          <ClassResumeCard resumeData={resumeData} />
        </div>
        <div className="w-1/2 p-4">
          {resumeData && (
            <ResumePreview 
              formData={resumeData} 
              hiddenSections={hiddenSections}
            />
          )}
        </div>
      </div>
      <IssueReportButton />
    </div>
  </div>
);
}

export default ResumeResult;