import React from 'react';

const HighlightedChanges = ({ proposedChanges, selectedSkills, originalExperience }) => {
  // Calculate similarity between two strings
  const calculateSimilarity = (str1, str2) => {
    const s1 = str1.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "");
    const s2 = str2.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "");
    const words1 = new Set(s1.split(' '));
    const words2 = new Set(s2.split(' '));
    
    // Calculate Jaccard similarity
    const intersection = new Set([...words1].filter(word => words2.has(word)));
    const union = new Set([...words1, ...words2]);
    
    return intersection.size / union.size;
  };

  const isNewBulletPoint = (bullet, company, jobTitle) => {
    const originalJob = originalExperience?.find(
      job => job.company === company && job.jobTitle === jobTitle
    );

    if (!originalJob?.description) return true;

    // Check similarity with all original bullets
    const similarityThreshold = 0.5; // Adjust this value to fine-tune what's considered "new"
    const similarities = originalJob.description.map(originalBullet => 
      calculateSimilarity(originalBullet, bullet)
    );

    // If any similarity is above threshold, it's a modified bullet rather than new
    return !similarities.some(similarity => similarity > similarityThreshold);
  };

  const getChangeType = (bullet, company, jobTitle) => {
    const originalJob = originalExperience?.find(
      job => job.company === company && job.jobTitle === jobTitle
    );

    if (!originalJob?.description) return 'new';

    const similarities = originalJob.description.map(originalBullet => ({
      similarity: calculateSimilarity(originalBullet, bullet),
      original: originalBullet
    }));

    const mostSimilar = similarities.reduce((prev, current) => 
      current.similarity > prev.similarity ? current : prev
    , { similarity: 0 });

    if (mostSimilar.similarity > 0.8) return 'unchanged';
    if (mostSimilar.similarity > 0.5) return 'modified';
    return 'new';
  };

  const highlightKeywords = (text, keywords) => {
    if (!keywords || keywords.length === 0) return text;
    
    const pattern = new RegExp(`(${keywords.join('|')})`, 'gi');
    const parts = text.split(pattern);
    
    return parts.map((part, index) => {
      if (keywords.some(keyword => keyword.toLowerCase() === part.toLowerCase())) {
        return <span key={index} className="text-red-600 font-semibold">{part}</span>;
      }
      return part;
    });
  };

  return (
    <div className="space-y-6">
      <h3 className="text-xl font-semibold mb-2">AI-Enhanced Experience Section (Added keywords in Red)</h3>
      <div className="grid gap-6">
        {proposedChanges.map((change, index) => (
          <div key={index} className="bg-white rounded-lg p-6 shadow-sm border border-gray-200">
            <div >
              <h4 className="text-2xl font-medium text-gray-900" style={{marginTop:'-10px'}}>{change.company}</h4>
              <p className="text-black-600">{change.jobTitle}</p>
              {change.duration && (
                <p className="text-sm text-black-500">{change.duration}</p>
              )}
            </div>
            
            <ul className="space-y-3 mt-4">
              {change.description.map((bullet, bulletIndex) => {
                const isNew = isNewBulletPoint(bullet, change.company, change.jobTitle);
                return (
                  <li
                    key={bulletIndex}
                    className={`flex items-start ${isNew ? 'bg-green-50 -mx-4 px-4 py-2 rounded' : ''}`}
                  >
                    <span className="mr-2">•</span>
                    <span className="flex-1">
                   
                      {highlightKeywords(bullet, selectedSkills)}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HighlightedChanges;