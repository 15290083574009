// ResumeCard.js
import React, { useState } from 'react';
import SuggestionsLoader from './ux/suggestionsLoader';

const ResumeCard = ({ formData, score, feedback, suggestions, onRefresh }) => {
  const [loading, setLoading] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const updateStatus = async () => {
    setLoading(true);
    try {
      await onRefresh();
    } catch (error) {
      console.error('Error refreshing resume score:', error);
    } finally {
      setLoading(false);
    }
  };

  const getScoreColor = (score) => {
    if (score >= 80) return '#28A745'; // Bright Green
    if (score >= 60) return '#FFC107'; // Gold Yellow
    return '#FF5733'; // Bright Orange
  };

  return (
    <>
      {loading ? (
        <SuggestionsLoader />
      ) : (
        <div className="rounded-lg bg-white text-gray-800 shadow-lg w-full mx-auto" data-v0-t="card">
          <div
            className="flex flex-col space-y-1.5 text-primary-foreground p-6 rounded-t-md"
            style={{ color: "#343A40" }}
          >
            <div className="flex items-center justify-between">
              <div className="text-4xl font-bold" style={{ color: getScoreColor(score) }}>
                {score}%
              </div>
              <TrophyIcon className="w-10 h-10" style={{ color: '#FFC107' }} />
            </div>
            <p className="text-sm mt-2">
              {score >= 80
                ? "Your resume looks great!"
                : score >= 60
                ? "Your resume is good, but there's room for improvement."
                : "Your resume needs some work."}
            </p>

            <div className="mt-2 flex justify-between">
              <button
                className="text-blue-400 mt-4"
                onClick={() => setShowSuggestions(!showSuggestions)}
              >
                <ChevronDownIcon className="w-4 h-4 inline-block align-middle mr-2" />
                {showSuggestions ? "Hide" : "Expand"} suggested improvements
              </button>
            </div>
          </div>

          {showSuggestions && (
            <div className="p-6 space-y-4 bg-white rounded-b-md">
              <div>
                <h3 className="text-lg font-medium" style={{ color: '#007BFF' }}>Suggested Improvements</h3>
                <div className="mt-2 space-y-2">
                  {suggestions.map((suggestion, index) => (
                    <div key={index} className="flex items-start gap-3">
                      {suggestion.icon}
                      <div>
                        <p className="font-medium" style={{ color: '#343A40' }}>{suggestion.title}</p>
                        <p className="text-sm" style={{ color: '#6C757D' }}>{suggestion.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

function ChevronDownIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m6 9 6 6 6-6" />
    </svg>
  );
}

function TrophyIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M6 9H4.5a2.5 2.5 0 0 1 0-5H6" />
      <path d="M18 9h1.5a2.5 2.5 0 0 0 0-5H18" />
      <path d="M4 22h16" />
      <path d="M10 14.66V17c0 .55-.47.98-.97 1.21C7.85 18.75 7 20.24 7 22" />
      <path d="M14 14.66V17c0 .55.47.98.97 1.21C16.15 18.75 17 20.24 17 22" />
      <path d="M18 2H6v7a6 6 0 0 0 12 0V2Z" />
    </svg>
  );
}

export default ResumeCard;
