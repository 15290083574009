import { GoogleGenerativeAI } from "@google/generative-ai";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const genAI = new GoogleGenerativeAI(API_KEY);

export async function generateLeadershipBullets(leadershipData) {
  const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });

  const prompt = `
    Generate 4-5 professional bullet points for this leadership experience that highlight achievements and impact.
    Format the response as a clean JSON array of strings.
    
    Leadership Details:
    Organization: ${leadershipData.organization}
    Title: ${leadershipData.title}
    Location: ${leadershipData.location}
    Duration: ${leadershipData.duration}
    Context: ${leadershipData.explanation}
    
    Guidelines:
    - Focus on leadership impact and team achievements
    - Use action verbs to start each bullet
    - Include metrics where possible (%, $, numbers)
    - Highlight organizational impact
    - Format as ["bullet 1", "bullet 2", "bullet 3"]
    - Do not include any other text or explanation
    
    Return only the JSON array with the bullet points.
  `;

  try {
    const result = await model.generateContent(prompt);
    const response = await result.response;
    const text = response.text();
    
    // Remove any extra whitespace or characters that might be around the JSON
    const cleanedText = text.trim().replace(/```json\s*|\s*```/g, '');
    
    try {
      // Attempt to parse the JSON
      const bulletPoints = JSON.parse(cleanedText);
      
      // Verify that we got an array of strings
      if (Array.isArray(bulletPoints)) {
        // Clean up each bullet point and ensure they start with a bullet point character
        return bulletPoints.map(bullet => 
          bullet.trim().startsWith('•') ? bullet.trim() : `• ${bullet.trim()}`
        );
      } else {
        // If response isn't an array, create one from the text
        console.warn('Response was not an array, attempting to format text');
        return text
          .split('\n')
          .filter(line => line.trim())
          .map(line => line.trim().startsWith('•') ? line.trim() : `• ${line.trim()}`);
      }
    } catch (parseError) {
      // If JSON parsing fails, split by newlines as fallback
      console.warn('Failed to parse JSON response, using fallback parsing');
      return text
        .split('\n')
        .filter(line => line.trim())
        .map(line => line.trim().startsWith('•') ? line.trim() : `• ${line.trim()}`);
    }
  } catch (error) {
    console.error("Error generating leadership bullet points:", error);
    throw new Error('Failed to generate leadership bullet points. Please try again.');
  }
}