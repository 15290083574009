import { GoogleGenerativeAI } from "@google/generative-ai";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const genAI = new GoogleGenerativeAI(API_KEY);

const processResumeData = (resumeData) => {
  console.log('Processing resume data:', resumeData);
  
  try {
    // Extract personal information
    const personalInfo = resumeData.personal_information || {};
    
    // Process education
    const education = (resumeData.education || []).map(edu => ({
      degree: Array.isArray(edu.degree) ? edu.degree[0] : edu.degree,
      major: Array.isArray(edu.major) ? edu.major[0] : edu.major,
      university: Array.isArray(edu.university) ? edu.university[0] : edu.university,
      graduation: edu.graduation
    }));

    // Process experience
    const experience = (resumeData.experience || []).map(exp => ({
      title: Array.isArray(exp.jobTitle) ? exp.jobTitle[0] : exp.jobTitle,
      company: Array.isArray(exp.company) ? exp.company[0] : exp.company,
      duration: exp.duration,
      description: Array.isArray(exp.description) ? exp.description : [exp.description]
    }));

    // Process skills
    const skills = resumeData.skills?.technical || '';

    // Process certifications
    const certifications = (resumeData.certifications || []).map(cert => ({
      name: Array.isArray(cert.certification_name) ? cert.certification_name[0] : cert.certification_name,
      provider: Array.isArray(cert.certification_location) ? cert.certification_location[0] : cert.certification_location,
      year: Array.isArray(cert.certification_year) ? cert.certification_year[0] : cert.certification_year
    }));

    return {
      personalInfo: {
        name: personalInfo.name || resumeData.name || '',
        email: personalInfo.email || '',
        phone: personalInfo.phone || '',
        linkedin: personalInfo.linkedin || ''
      },
      education,
      experience,
      skills,
      certifications
    };
  } catch (error) {
    console.error('Error processing resume data:', error);
    // Return a minimal valid structure instead of throwing
    return {
      personalInfo: {
        name: resumeData.name || 'Candidate',
        email: '',
        phone: '',
        linkedin: ''
      },
      education: [],
      experience: [],
      skills: '',
      certifications: []
    };
  }
};

export async function generateCoverLetter(resumeData, jobDetails) {
  try {
    console.log('Generating cover letter with:', { resumeData, jobDetails });

    const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });
    const processedData = processResumeData(resumeData);
    
    console.log('Processed resume data:', processedData);

    const today = new Date().toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });

    // Create educational background string
    const educationString = processedData.education
      .map(edu => `${edu.degree} in ${edu.major} from ${edu.university}`)
      .join('. ');

    // Create experience string
    const experienceString = processedData.experience
      .map(exp => `${exp.title} at ${exp.company} (${exp.duration}): ${exp.description.join(' ')}`)
      .join('\n\n');

    // Create certifications string
    const certificationsString = processedData.certifications
      .map(cert => `${cert.name} from ${cert.provider} (${cert.year})`)
      .join('. ');

    const prompt = `
Generate a professional cover letter with the following EXACT structure and information:

${processedData.personalInfo.name}
${processedData.personalInfo.phone ? `Phone: ${processedData.personalInfo.phone}` : ''}
${processedData.personalInfo.email ? `Email: ${processedData.personalInfo.email}` : ''}
${processedData.personalInfo.linkedin ? `LinkedIn: ${processedData.personalInfo.linkedin}` : ''}

${today}

Hiring Manager
${jobDetails.company}

Dear Hiring Manager,

USE THE FOLLOWING INFORMATION TO CREATE A COMPELLING COVER LETTER:

Position applying for: ${jobDetails.title}
Company: ${jobDetails.company}

Candidate's Background:
Education: ${educationString}
Experience: ${experienceString}
Technical Skills: ${processedData.skills}
${certificationsString ? `Certifications: ${certificationsString}` : ''}

Job Description:
${jobDetails.description}

REQUIREMENTS:
1. Use the candidate's actual experience and skills
2. Address key requirements from the job description
3. Highlight relevant achievements
4. Keep content professional and engaging
5. Include specific examples from their experience
6. Show enthusiasm for the role and company
7. End with a clear call to action

End the letter with:

Sincerely,
${processedData.personalInfo.name}

Generate ONLY the cover letter, no explanations or additional notes.`;

    console.log('Sending prompt to AI:', prompt);

    const result = await model.generateContent(prompt);
    const response = await result.response;
    let coverLetter = response.text().trim();

    console.log('Generated cover letter:', coverLetter);

    // Basic cleanup
    coverLetter = coverLetter
      .replace(/```/g, '')
      .replace(/\[.*?\]/g, '')
      .replace(/\n{3,}/g, '\n\n')
      .trim();

    // Less strict validation
    if (coverLetter.length < 100) {
      throw new Error('Generated content is too short');
    }

    return coverLetter;

  } catch (error) {
    console.error('Error in cover letter generation:', error);
    throw new Error(
      'Unable to generate cover letter. Please try again. ' +
      (error.message || '')
    );
  }
}

/**
 * Validates the generated cover letter
 */
export const validateCoverLetter = (coverLetter, resumeData, jobDetails) => {
  const processedData = processResumeData(resumeData);
  
  return {
    isValid: 
      coverLetter.includes(processedData.contactInfo.name) &&
      coverLetter.includes(jobDetails.company) &&
      coverLetter.length > 300,
    checks: {
      hasName: coverLetter.includes(processedData.contactInfo.name),
      hasCompany: coverLetter.includes(jobDetails.company),
      hasProperLength: coverLetter.length > 300,
      hasContactInfo: coverLetter.includes(processedData.contactInfo.email)
    }
  };
};